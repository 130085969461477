import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Search from '../../shared/components/ui/Search';
import Button from '../../shared/components/ui/Button';
import Table from '../../shared/components/layout/Table';
import PaginationComponent from '../../shared/components/PaginationComponent';
import Modal from '../../shared/components/ui/Modal';
import toast from 'react-hot-toast';
import NoDataFound from '../components/layout/NoDataFound';

// If you have a "transformData" utility
import { transformData } from '../../super-admin/utils/transformData';
// If you have a "filterData" utility
import { filterData } from '../../shared/utils/filterData';

// Example placeholders for forms
// import { PurchaseForm } from '../components/forms/PurchaseForm';

// We’ll import your new InventoryUpdateForm
import InventoryUpdateForm from '../components/forms/InventoryUpdateFrom';
import { set } from 'react-hook-form';

// If you want to fetch data via React Query
// import { useQuery } from '@tanstack/react-query';
// import { getInventoryItems } from '../services/api/implementation/impl';

const InventoryList = () => {
  // State
  const [inventoryItems, setInventoryItems] = useState([
    // Example default data
    {
      id: 1,
      itemName: 'White Cement',
      availableStock: 200,
      inTransit: 300,
      totalStock: 500,
      minToKeep: 100
    },
    {
      id: 2,
      itemName: 'PPC Cement',
      availableStock: 150,
      inTransit: 100,
      totalStock: 250,
      minToKeep: 100
    },
    {
      id: 3,
      itemName: 'AAC Blocks 100 mm',
      availableStock: 40,
      inTransit: 500,
      totalStock: 550,
      minToKeep: 50
    }
  ]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // For modals
  const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  // The only thing we pass to the Update form is this ID:
  const [selectedItemId, setSelectedItemId] = useState(null);

  // Pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page
  const totalPages = 1; // if you’re getting data from an API, compute from meta

  useEffect(() => {
    setFilteredData(inventoryItems);
  }, [inventoryItems]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Searching
  const handleSearchChange = useCallback(
    (incomingQuery) => {
      setSearchQuery(incomingQuery);
      if (incomingQuery) {
        const results = filterData('itemName', inventoryItems, incomingQuery);
        setFilteredData(results);
      } else {
        setFilteredData(inventoryItems);
      }
    },
    [inventoryItems]
  );

  const handleOnClickSearch = () => {
    if (!searchQuery) return;
    const results = filterData('itemName', inventoryItems, searchQuery);
    setFilteredData(results);
    setSearchQuery('');
  };

  // Handle purchase flow
  // const handlePurchase = (id) => {
  //   setSelectedItemId(id);
  //   setIsPurchaseOpen(true);
  // };

  // Handle update flow
  const handleUpdate = (id) => {
    console.log("id", id)
    setSelectedItemId(id);   // We ONLY pass the ID
    setIsUpdateOpen(true);
  };

  // Close modals
  const handleClosePurchase = () => {
    setIsPurchaseOpen(false);
    setSelectedItemId(null);
  };

  const handleCloseUpdate = () => {
    setIsUpdateOpen(false);
    setSelectedItemId(null);
  };

  const handlePurchaseSuccess = () => {
    toast.success('Purchase action completed!');
    handleClosePurchase();
  };

  const handleUpdateSuccess = () => {
    toast.success('Update action completed!');
    // You might refetch data or update local state here
    handleCloseUpdate();
  };

  // If you want to allow deletion
  const handleDeleteInventory = (id) => {
    const updated = filteredData.filter((item) => item.id !== id);
    setInventoryItems(updated);
    setFilteredData(updated);
    toast.success('Item deleted successfully!');
  };

  // Prepare data for the table
  const inventoryDataFormat = [
    'id',
    'itemName',
    'availableStock',
    'inTransit',
    'totalStock',
    'minToKeep',
    'stockStatus',
    'actions'
  ];

  // Example stock status color logic
  const getStockStatusColor = (available, minToKeep) => {
    if (available >= minToKeep * 2) return '2E7D32'; // 200% or above
    if (available >= minToKeep) return 'E97B37';      // 100% - 199%
    return 'DE3139';                                    // < 100%
  };

  const tableData =
    filteredData.length > 0
      ? filteredData.map((item) => {
          const color = getStockStatusColor(item.availableStock, item.minToKeep);
          const percentage = Math.round(
            (item.availableStock / item.minToKeep) * 100
          );

          return transformData(
            {
              ...item,
              stockStatus: `stockStatus-${color}-${percentage}`,
              actions: 'Actions'
            },
            inventoryDataFormat
          );
        })
      : [];

  return (
    <>
      {/* Purchase Modal */}
      {/* {isPurchaseOpen && (
        <Modal isDirty={true} confirmOnClose={true} width={40} name="Purchase" onClose={handleClosePurchase}>
          <PurchaseForm
            itemId={selectedItemId}
            onSuccess={handlePurchaseSuccess}
            onClose={handleClosePurchase}
          />
        </Modal>
      )} */}

      {/* Update Modal */}
      {isUpdateOpen && (
        <Modal isDirty={true} confirmOnClose={true} width={40} name="Update" onClose={handleCloseUpdate}>
          <InventoryUpdateForm
            itemId={selectedItemId}
            onSuccess={handleUpdateSuccess}
            onClose={handleCloseUpdate}
          />
        </Modal>
      )}

      <Layout>
        <div className="fixed top-0 left-[18vw] z-40 w-[82vw]">
          <Header data={{ name: 'Inventory' }} />
        </div>

        {tableData && tableData.length > 0 ? (
          <>
            <div className="flex z-20 items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
              <Search
                onChange={handleSearchChange}
                query={searchQuery}
                onClick={handleOnClickSearch}
              />
              <div className="flex gap-5 cursor-pointer">
                <Button onClick={() => toast('Add new item flow here!')}>Add Material</Button>
              </div>
            </div>

            <GridWrapper width={100}>
              <div className="mt-[12rem] h-[28rem] relative">
                <Table
                  headers={[
                    'Item Name',
                    'Available Stock',
                    'In transit',
                    'Total Stock',
                    'Min to Keep',
                    'Stock Status',
                    'Action'
                  ]}
                  data={tableData}
                  identifier={"id"}
                  onUpdate={handleUpdate}
                  onDelete={handleDeleteInventory}
                  isEditEnable={false}
                  isDeleteEnable={false}  
                  isInventoryActionEnabled={true}
                  // onInventoryPurchase={handlePurchase}
                  onInventoryUpdate={handleUpdate}

                />
                <PaginationComponent
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  isFetching={false}
                />
              </div>
            </GridWrapper>
          </>
        ) : (
          <NoDataFound
            buttonText="Add Material"
            onButtonClick={() => toast('Add new item flow here!')}
          />
        )}
      </Layout>
    </>
  );
};

export default InventoryList;
