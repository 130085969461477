import React from 'react';

const SecondaryTabbedComponent = ({ data, onChangePrimaryTab, activePrimary, children }) => {
  return (
    <div className="w-full flex flex-col justify-between items-center bg-white">
      <div
        className={`w-full flex justify-between items-center px-8 py-5 border-b-gray-300 border-t-gray-300 text-gray-600 space-x-3`}>
        <div className="flex justify-start items-center space-x-3">
          {data?.tabs?.map((item, index) => (
            <div
              className={`flex justify-between items-center py-2 px-2  ${item === activePrimary ? ' border-b-2 !border-b-primary text-primary' : 'border-gray-200'} border-b-2 border-b-transparent cursor-pointer transition-all duration-300 font-semibold`}
              onClick={() => onChangePrimaryTab(item)}
              key={index}>
              {item}
            </div>
          ))}
        </div>

        {children}
      </div>
    </div>
  );
};

export default SecondaryTabbedComponent;
