import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import Layout from './AppLayout';
import Header from '../../../shared/components/layout/Header';
import Button from '../../../shared/components/ui/Button';
import Search from '../../../shared/components/ui/Search';
import GridWrapper from '../../../shared/components/layout/GridWrapper';
import CompanyCard from '../ui/CompanyCard';

import filter from '../../../shared/assets/svg/filter.svg';

import '../../styles/main.scss';
import Modal from '../../../shared/components/ui/Modal';
import AddCompany from '../forms/AddCompany';
import { useForm } from 'react-hook-form';
import { createCompany, getAllCompanies } from '../../services/api/implementation/impl';
import Loader from '../../../shared/components/layout/Loader';

import { filterData } from '../../../shared/utils/filterData';
import Cookies from 'js-cookie';
import NoDataFound from './NoDataFound';

const CompaniesList = () => {
  const navigate = useNavigate();

  /* 
    Local state
  */
  const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [companiesToastShown, setCompaniesToastShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /* 
    Data fetching
  */
  const token = Cookies.get('token');
  const {
    isLoading,
    isError,
    data: companies,
    refetch: refetchCompanies
  } = useQuery({
    queryKey: ['companies'],
    queryFn: () => getAllCompanies(),
    enabled: !!token
  });

  useEffect(() => {
    if (token) {
      refetchCompanies();
    }
  }, [token, refetchCompanies]);

  /* 
    Data mutation
  */
  const { mutate: mutateCompany, isLoading: isLoadingNewUserMutation } = useMutation({
    mutationFn: createCompany,
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: async () => {
      toast.success('Company created successfully');
      reset();
      setOpenAddCompanyModal(false);
      setIsSubmitting(false);
      await refetchCompanies();
    },
    onError: (err) => {
      let errorMessage = 'An unexpected error occurred while creating the company.';
      setIsSubmitting(false);

      try {
        // Check if the error contains driverError details
        const detailMessage = err.response?.data?.message?.driverError?.detail;

        if (detailMessage && detailMessage.includes('Key (pan)=')) {
          // Extract PAN number using a regular expression
          const match = detailMessage.match(/Key \(pan\)=\((.*?)\)/);
          const panNumber = match?.[1]; // Extracted PAN number

          if (panNumber) {
            errorMessage = `PAN number ${panNumber} already exists.`;
          }
        } else if (err.message) {
          errorMessage = err.message; // Use general error message
        }
      } catch (error) {
        console.error('Error while parsing the error object:', error);
      }

      console.log('Final error message for toast:', errorMessage);
      toast.error(errorMessage);
    }
  });

  console.log({ isError });

  /* 
    Event handlers
  */
  const handleToggleAddCompanyModal = useCallback(() => {
    setOpenAddCompanyModal((prev) => !prev);
  }, []);

  const handleOnBack = useCallback(() => {
    navigate('/builder/company');
  }, [navigate]);

  const { reset } = useForm();

  useEffect(() => {
    setFilteredCompanies(companies);
  }, [companies]);

  const handleSearchChange = useCallback(
    (incomingQuery) => {
      setSearchQuery(incomingQuery);
      if (incomingQuery) {
        const filtered = filterData('company_name', companies, incomingQuery);
        setFilteredCompanies(filtered);
      } else {
        setFilteredCompanies(companies);
      }
    },
    [companies]
  );

  useEffect(() => {
    if (openAddCompanyModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [openAddCompanyModal]);

  useEffect(() => {
    if (!searchQuery?.length) {
      setFilteredCompanies(companies);
    }
  }, [companies, searchQuery]);

  useEffect(() => {
    if (filteredCompanies?.length > 0 && !companiesToastShown) {
      // toast.success(`Successfully fetched ${filteredCompanies?.length} companies`);
      setCompaniesToastShown(true);
    }
  }, [filteredCompanies, companiesToastShown]);

  /* 
    Toasts
  */
  if (isLoading || isLoadingNewUserMutation) {
    return (
      <div className="h-screen w-screen">
        <Loader />
      </div>
    );
  }

  if (isError) {
    toast.error(`Error fetching companies`);
    navigate('/');
    return null;
  }

  // console.log(filteredCompanies?.length);

  /* 
    JSX
  */
  return (
    <>
      {openAddCompanyModal && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Add Company" onClose={handleToggleAddCompanyModal}>
          <AddCompany
            onSubmit={mutateCompany}
            isSubmitting={isSubmitting}
            onClose={handleToggleAddCompanyModal}
          />
        </Modal>
      )}

      <Layout>
        <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
          <Header data={{ name: 'Company' }} onBack={handleOnBack} />
        </div>
        <div className="flex items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
              <Search query={searchQuery} onChange={handleSearchChange} />
              <div className="flex gap-5 cursor-pointer">
                <Button onClick={handleToggleAddCompanyModal}>Add Company</Button>
              </div>
            </div>
        {companies && companies?.length > 0 ? (
          <>
          
            <GridWrapper width={100}>
              <div className="flex justify-start items-center gap-8 mt-[11rem] flex-wrap">
                {filteredCompanies?.length === 0 ? (
                  <div className="flex justify-center items-center w-full h-[300px]">
                    <p className="text-lg font-semibold text-stone-300">No data found</p>
                  </div>
                ) : (
                  filteredCompanies?.map((item) => <CompanyCard key={item?.id} data={item} />)
                )}
              </div>
            </GridWrapper>
          </>
        ) : (
          <NoDataFound onButtonClick={handleToggleAddCompanyModal} buttonText="Add Company" />
        )}
      </Layout>
    </>
  );
};

export default CompaniesList;
