import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import cancelIcon from '../../../shared/assets/svg/cancel_icon.svg';
import { getPaymentStages } from '../../services/api/implementation/impl';

const AddPaymentStage = ({ handleOnPaymentStage, onClose, isSubmitting, blockId }) => {
  const [totalPercentage, setTotalPercentage] = useState(0);

  // 1. Fetch existing payment stages
  const {
    data: AllPaymentStages,
    refetch: refetchpaymentstages,
    error: paymentStagesError,
    isLoading: isPaymentStagesLoading
  } = useQuery({
    queryKey: ['payment_stages', blockId],
    queryFn: () => getPaymentStages(blockId),
    enabled: !!blockId,
    onError: (error) => {
      console.error('Error fetching payment stages:', error);
      toast.error('Failed to fetch payment stages');
    }
  });

  // 2. Define default payment stages if no existing data is available
  const defaultPaymentStages = [
    { name: 'Advance', percentage: 10 },
    ...Array(9)
      .fill()
      .map((_, index) => ({
        name: `Payment ${index + 1}`,
        percentage: ''
      }))
  ];

  // 3. Setup react-hook-form
  const { register, handleSubmit, control, watch, setValue, reset } = useForm({
    defaultValues: {
      payment: []
    }
  });

  // 4. UseFieldArray for dynamic addition/removal of payment stages
  const {
    fields: paymentStageFields,
    append: appendPaymentStage,
    remove: removePaymentStage
  } = useFieldArray({
    control,
    name: 'payment'
  });

  // 5. Prefill form with existing data OR default data
  useEffect(() => {
    if (AllPaymentStages && AllPaymentStages.length > 0) {
      // Map the API data to the shape your form expects
      const mappedStages = AllPaymentStages.map((stage) => ({
        name: stage.name,
        percentage: stage.percentage
      }));
      reset({ payment: mappedStages });
    } else {
      // If there's no existing data, use default
      reset({ payment: defaultPaymentStages });
    }
  }, [AllPaymentStages, reset]);

  // 6. Watch the fields to keep track of the total percentage
  const watchPaymentFields = watch('payment');

  useEffect(() => {
    const total = watchPaymentFields.reduce(
      (acc, curr) => acc + (parseFloat(curr.percentage) || 0),
      0
    );
    setTotalPercentage(total);
  }, [watchPaymentFields]);

  // 7. Handle percentage input changes
  const handlePercentageChange = (index, event) => {
    let value = parseFloat(event.target.value);

    if (value > 100) {
      value = 100;
    } else if (value < 0 || isNaN(value)) {
      value = 0;
    }

    setValue(`payment.${index}.percentage`, value);

    // Recalculate total
    const updatedPayment = [...watchPaymentFields];
    updatedPayment[index].percentage = value;
    const total = updatedPayment.reduce((acc, curr) => acc + (parseFloat(curr.percentage) || 0), 0);
    setTotalPercentage(total);
  };

  // 8. Add a new payment stage (disabled if total is >= 100)
  const handleAddNewStages = () => {
    if (totalPercentage >= 100) {
      toast.error("You've reached 100% of Payment");
    } else {
      appendPaymentStage({
        name: `Payment ${paymentStageFields.length}`,
        percentage: ''
      });
    }
  };

  // 9. Form submit: Check total is exactly 100% and submit
  const handleOnSubmit = (data) => {
    if (totalPercentage !== 100) {
      toast.error('Total percentage must be exactly 100% before saving');
      return;
    }
    handleOnPaymentStage(data);
  };

  return (
    <div className="mt-10">
      <div className="max-h-[30rem] overflow-y-auto hide-scrollbar">
        <table className="w-full table-auto border rounded-lg">
          <thead className="bg-gray-200 !text-gray-600 rounded-t-xl">
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Percentage</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {paymentStageFields?.map((field, index) => (
              <tr key={field.id}>
                <td className="px-4 py-2">
                  <FormInput
                    register={register}
                    register_key={`payment.${index}.name`}
                    type="text"
                    placeholder={`Payment ${index + 1}`}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    {...register(`payment.${index}.percentage`)}
                    type="number"
                    placeholder="Percentage"
                    min="0"
                    max="100"
                    step="0.01"
                    onChange={(e) => handlePercentageChange(index, e)}
                    value={watchPaymentFields[index].percentage || ''}
                    className="border rounded p-2 w-full"
                  />
                </td>
                <td className="px-4">
                  <div className="ml-6" onClick={() => removePaymentStage(index)}>
                    <img className="cursor-pointer" src={cancelIcon} alt="cancel" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Display total percentage and error if not 100 */}
      <div className="mt-4">
        <p>Total Percentage: {totalPercentage.toFixed(2)}%</p>
        {totalPercentage !== 100 && (
          <p className="text-red-500">Total percentage must be exactly 100% before saving</p>
        )}
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button displayType="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          displayType="secondary"
          onClick={handleAddNewStages}
          disabled={totalPercentage >= 100}
        >
          Add New Stage
        </Button>
        <Button onClick={handleSubmit(handleOnSubmit)} disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddPaymentStage;
