import React, { useCallback, useEffect, useState } from 'react';
import FormInput from '../../../shared/components/ui/FormInput';
import { useForm } from 'react-hook-form';
import Button from '../../../shared/components/ui/Button';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { multipleUploadFiles } from '../../features/files';
import { useDispatch, useSelector } from 'react-redux';

export const EditAccommodation = ({
  onClose,
  onEditIndependent,
  onEditParking,
  onEditGroup,
  id,
  accommodationData,
  isSubmitting
}) => {
  const dispatch = useDispatch();
  const projectId = useParams();

  // Pull in the Redux state that holds current files:
  const MultipleFiles = useSelector((state) => state.file.multifiles);

  console.log(MultipleFiles);

  // Filter to get the accommodation by `id` from props
  const value = accommodationData?.filter((item) => item.id === parseInt(id));
  console.log('This is the Value');
  console.log({ value });
  const accomodationType = value?.[0]?.accomodationType;

  // Grab measurement type from cookie
  const rawMeasurementUnit = Cookies.get('measurement_type');
  const measurementType =
    !rawMeasurementUnit || rawMeasurementUnit === 'null' || rawMeasurementUnit === 'undefined'
      ? 'sq/ft'
      : rawMeasurementUnit;

  // Set up react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      // Common fields
      projectId: projectId.id,
      accomodationType: accomodationType,
      name: value?.[0]?.name || '',
      unitType: value?.[0]?.unitType || '',
      measurementUnit: value?.[0]?.measurementUnit || measurementType,

      // For Independent
      plotType: value?.[0]?.plotType || 'regular',
      width: value?.[0]?.width || '',
      length: value?.[0]?.length || '',
      eastLength: value?.[0]?.eastLength || '',
      westLength: value?.[0]?.westLength || '',
      northLength: value?.[0]?.northLength || '',
      southLength: value?.[0]?.southLength || '',
      plotArea: value?.[0]?.plotArea || '',
      buildUpArea: value?.[0]?.buildUpArea || '',
      carpetArea: value?.[0]?.carpetArea || '',

      // For Parking
      electrified:
        value?.[0]?.electrified === true ? 'Yes' : value?.[0]?.electrified === false ? 'No' : '',

      // For file uploads
      floorPlanLink: value?.[0]?.floorPlanLink || [],
      unitSpecification: value?.[0]?.unitSpecification || [],
      materialListLink: value?.[0]?.materialListLink || []
    }
  });

  // Watchers
  const watchedPlotType = watch('plotType');
  const watchedWidth = watch('width');
  const watchedLength = watch('length');

  // Convert server array into { name, url } objects:
  const transformFileArray = (arr) => {
    if (!Array.isArray(arr)) return [];
    return arr.map((item) => {
      if (typeof item === 'string') {
        return { name: item, url: item };
      }
      return { name: item?.name, url: item?.url };
    });
  };

  // ===============================
  // PREVENT REPEATED DISPATCHES
  // ===============================
  const [initializedId, setInitializedId] = useState(null);

  useEffect(() => {
    // Already initialized for this id? Skip.
    if (initializedId === id) return;

    // If the accommodation data isn't loaded yet, skip now.
    if (!value?.[0]) return;

    // Convert the server data to the shape we need:
    const floorArray = transformFileArray(value[0].floorPlanLink);
    const unitSpecsArray = transformFileArray(value[0].unitSpecification);
    const materialArray = transformFileArray(value[0].materialListLink);

    // Dispatch once:
    dispatch(multipleUploadFiles({ fileType: 'floorPlanLink', urls: floorArray }));
    dispatch(multipleUploadFiles({ fileType: 'materialListLink', urls: materialArray }));
    dispatch(multipleUploadFiles({ fileType: 'unitSpecification', urls: unitSpecsArray }));

    // Mark that we have initialized this ID so it won't run again
    setInitializedId(id);
  }, [id, initializedId, dispatch]);

  // =============================================
  // Plot area logic for INDEPENDENT accommodations
  // =============================================

  // Auto-calc if "regular"
  useEffect(() => {
    if (accomodationType === 'INDEPENDENT') {
      if (watchedPlotType === 'regular') {
        const widthNum = parseFloat(watchedWidth);
        const lengthNum = parseFloat(watchedLength);
        if (!isNaN(widthNum) && !isNaN(lengthNum)) {
          const calculatedPlotArea = (widthNum * lengthNum).toFixed(2);
          setValue('plotArea', calculatedPlotArea);
        } else {
          setValue('plotArea', '');
        }
      }
    }
  }, [accomodationType, watchedPlotType, watchedWidth, watchedLength, setValue]);

  // Clear fields if toggling between regular & irregular
  useEffect(() => {
    if (watchedPlotType === 'regular') {
      setValue('eastLength', '');
      setValue('westLength', '');
      setValue('northLength', '');
      setValue('southLength', '');
    } else {
      setValue('width', '');
      setValue('length', '');
    }
  }, [watchedPlotType, setValue]);

  // Helpers for build-up area & manual plot area typing:
  const handleBuildUpAreaChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^0-9.]/g, '').slice(0, 8);
      setValue('buildUpArea', cleanedValue);
    },
    [setValue]
  );

  const handlePlotAreaChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^0-9.]/g, '').slice(0, 8);
      setValue('plotArea', cleanedValue);
    },
    [setValue]
  );

  // =============================
  // FINAL SUBMIT HANDLER
  // =============================
  const handleOnSubmitEditAccommodation = (formData) => {
    // Grab final Redux arrays
    const floorPlanLink = MultipleFiles?.floorPlanLink || [];
    const unitSpecification = MultipleFiles?.unitSpecification || [];
    const materialListLink = MultipleFiles?.materialListLink || [];

    if (accomodationType === 'INDEPENDENT') {
      const { electrified, ...rest } = formData;
      const payload = { ...rest, floorPlanLink, unitSpecification, materialListLink };
      onEditIndependent(payload);
    } else if (accomodationType === 'GROUP') {
      const { electrified, ...rest } = formData;
      const payload = { ...rest, floorPlanLink, unitSpecification, materialListLink };
      onEditGroup(payload);
    } else if (accomodationType === 'PARKING') {
      const payload = { ...formData, floorPlanLink, unitSpecification, materialListLink };
      onEditParking(payload);
    }
  };

  return (
    <div>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Accommodation Details
        </span>
      </div>
      <div className="flex flex-col gap-6 pt-6">
        {/* COMMON FIELDS */}
        <div className="flex gap-10 justify-between items-center w-full">
          <FormInput
            width={50}
            label="Project ID"
            register={register}
            isRequired={true}
            register_key="projectId"
            type="hidden"
          />

          <FormInput
            width={48}
            label="Accommodation Type"
            register={register}
            isRequired={true}
            register_key="accomodationType"
            type="dropdown"
            valuePre={accomodationType}
            readOnly
          />

          <FormInput
            width={48}
            label="Accommodation Name"
            register={register}
            isRequired={true}
            register_key="name"
            type="text"
          />
        </div>

        {/* ============== INDEPENDENT ============= */}
        {accomodationType === 'INDEPENDENT' && (
          <div className="flex flex-col gap-6">
            <div className="flex justify-between items-center w-full">
              <FormInput
                width={48}
                label="Unit Type"
                register_key="unitType"
                register={register}
                isRequired={true}
                type="dropdown"
                defaultValuePre={value?.[0]?.unitType || 'Residential'}
                options={[
                  { value: '', label: 'Select Unit Type', disabled: true },
                  { value: 'Residential', label: 'Residential' },
                  { value: 'Commercial', label: 'Commercial' }
                ]}
              />
              <FormInput
                width={48}
                label="Plot Type"
                register_key="plotType"
                register={register}
                isRequired={true}
                type="dropdown"
                options={[
                  { value: 'regular', label: 'Regular' },
                  { value: 'irregular', label: 'Irregular' }
                ]}
              />
            </div>

            {/* REGULAR vs IRREGULAR */}
            {watch('plotType') === 'regular' ? (
              <div className="flex justify-between items-center w-full">
                <FormInput
                  key="width"
                  width={32}
                  label={`Width (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                  register_key="width"
                  register={register}
                  isRequired={true}
                  type="text"
                  onType={(val) => setValue('width', val)}
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Width must be a number'
                    }
                  }}
                />

                <FormInput
                  key="length"
                  width={32}
                  label={`Depth (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                  register_key="length"
                  register={register}
                  isRequired={true}
                  type="text"
                  onType={(val) => setValue('length', val)}
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Depth must be a number'
                    }
                  }}
                />

                <FormInput
                  key="measurementUnit"
                  width={32}
                  label="Measurement Unit"
                  register_key="measurementUnit"
                  register={register}
                  isRequired={true}
                  type="text"
                  valuePre={measurementType}
                  readOnly={true}
                />
              </div>
            ) : (
              <div className="flex justify-between gap-3 flex-wrap items-center w-full">
                <FormInput
                  key="eastLength"
                  width={18}
                  label={`East (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                  register_key="eastLength"
                  register={register}
                  isRequired={true}
                  type="text"
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'East must be a number'
                    }
                  }}
                />
                <FormInput
                  key="westLength"
                  width={18}
                  label={`West (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                  register_key="westLength"
                  register={register}
                  isRequired={true}
                  type="text"
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'West must be a number'
                    }
                  }}
                />
                <FormInput
                  key="northLength"
                  width={18}
                  label={`North (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                  register_key="northLength"
                  register={register}
                  isRequired={true}
                  type="text"
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'North must be a number'
                    }
                  }}
                />
                <FormInput
                  key="southLength"
                  width={18}
                  label={`South (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                  register_key="southLength"
                  register={register}
                  isRequired={true}
                  type="text"
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'South must be a number'
                    }
                  }}
                />
                <FormInput
                  key="measurementUnitirregular"
                  width={16}
                  label="Measurement Unit"
                  register_key="measurementUnit"
                  register={register}
                  isRequired={true}
                  type="text"
                  valuePre={measurementType}
                  readOnly={true}
                />
              </div>
            )}

            {/* PLOT AREA, BUILDUP AREA, CARPET AREA */}
            <div className="flex justify-between items-center w-full">
              {watch('plotType') === 'regular' ? (
                <FormInput
                  width={32}
                  label={`Plot Area (${measurementType})`}
                  register={register}
                  register_key="plotArea"
                  isRequired={true}
                  type="text"
                  readOnly
                  valuePre={watch('plotArea')}
                  validation={{
                    required: 'Plot Area is required',
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Plot Area must be a number'
                    }
                  }}
                  errors={errors}
                />
              ) : (
                <FormInput
                  width={32}
                  label={`Plot Area (${measurementType})`}
                  register={register}
                  register_key="plotArea"
                  isRequired={true}
                  type="text"
                  onType={handlePlotAreaChange}
                  validation={{
                    required: 'Plot Area is required for Irregular plots',
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Plot Area must be a number'
                    }
                  }}
                  errors={errors}
                />
              )}

              <FormInput
                width={32}
                label={`Build Up Area (${measurementType})`}
                register={register}
                isRequired={true}
                register_key="buildUpArea"
                type="text"
                onType={handleBuildUpAreaChange}
                validation={{
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Build Up Area must be a number'
                  }
                }}
              />
              <FormInput
                width={32}
                label={`Carpet Area (${measurementType})`}
                register={register}
                isRequired={true}
                register_key="carpetArea"
                type="text"
                validation={{
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Carpet Area must be a number'
                  }
                }}
              />
            </div>

            {/* File Uploads */}
            <div className="flex justify-start space-x-4 items-center w-full">
              <FormInput
                width={32}
                register={register}
                isRequired={false}
                type="file-btn"
                register_key="floorPlanLink"
                label="Add Floor Plan"
                // Only read from Redux now:
                prefilledFiles={
                  MultipleFiles?.floorPlanLink &&
                  value[0].floorPlanLink &&
                  value[0].floorPlanLink.length > 0
                    ? MultipleFiles?.floorPlanLink
                    : []
                }
              />
              <FormInput
                width={32}
                register={register}
                isRequired={false}
                type="file-btn"
                register_key="unitSpecification"
                label="Unit Specification"
                // Only read from Redux now:
                prefilledFiles={
                  MultipleFiles?.unitSpecification &&
                  value[0].unitSpecification &&
                  value[0].unitSpecification.length > 0
                    ? MultipleFiles?.unitSpecification
                    : []
                }
              />
              {/* 
              <FormInput
                width={32}
                register={register}
                isRequired={false}
                type="file-btn"
                register_key="materialListLink"
                label="Material List"
                prefilledFiles={MultipleFiles?.materialListLink || []}
              />
              */}
            </div>

            {/* Submit Buttons */}
            <div className="w-full flex justify-end items-center space-x-4 mt-8">
              <Button type="reset" onClick={onClose} displayType="secondary">
                Cancel
              </Button>
              <Button onClick={handleSubmit(handleOnSubmitEditAccommodation)} type="submit">
                Save
              </Button>
            </div>
          </div>
        )}

        {/* ============== GROUP ============= */}
        {accomodationType === 'GROUP' && (
          <div className="flex flex-col gap-6">
            <div className="flex justify-between items-center w-full">
              <FormInput
                width={48}
                label={`Build Up Area (${measurementType})`}
                register={register}
                isRequired={true}
                register_key="buildUpArea"
                type="text"
                onType={handleBuildUpAreaChange}
                validation={{
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Build Up Area must be a number'
                  }
                }}
              />
              <FormInput
                width={48}
                label={`Carpet Area (${measurementType})`}
                register={register}
                isRequired={true}
                register_key="carpetArea"
                type="text"
                validation={{
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Carpet Area must be a number'
                  }
                }}
              />
            </div>
            <div className="flex justify-between items-center w-full">
              <FormInput
                width={48}
                label="Unit Type"
                register_key="unitType"
                register={register}
                isRequired={true}
                type="dropdown"
                defaultValuePre={value?.[0]?.unitType || 'Residential'}
                options={[
                  { value: '', label: 'Select type', disabled: true },
                  { value: 'Residential', label: 'Residential' },
                  { value: 'Commercial', label: 'Commercial' }
                ]}
              />

              <FormInput
                width={48}
                register={register}
                isRequired={false}
                type="file-btn"
                register_key="floorPlanLink"
                label="Add Floor Plan"
                prefilledFiles={
                  MultipleFiles?.floorPlanLink &&
                  value[0].floorPlanLink &&
                  value[0].floorPlanLink.length > 0
                    ? MultipleFiles?.floorPlanLink
                    : []
                }
              />
              <FormInput
                width={48}
                register={register}
                isRequired={false}
                type="file-btn"
                register_key="unitSpecification"
                label="Unit Specification"
                prefilledFiles={
                  MultipleFiles?.unitSpecification &&
                  value[0].unitSpecification &&
                  value[0].unitSpecification.length > 0
                    ? MultipleFiles?.unitSpecification
                    : []
                }
              />
            </div>
            <div className="flex justify-start space-x-4 items-center w-full">
              {/* 
              <FormInput
                width={32}
                register={register}
                isRequired={false}
                type="file-btn"
                register_key="materialListLink"
                label="Material List"
                prefilledFiles={MultipleFiles?.materialListLink || []}
              />
              */}
            </div>

            {/* Submit Buttons */}
            <div className="w-full flex justify-end items-center space-x-4 mt-8">
              <Button type="reset" onClick={onClose} displayType="secondary">
                Cancel
              </Button>
              <Button onClick={handleSubmit(handleOnSubmitEditAccommodation)} type="submit">
                Save
              </Button>
            </div>
          </div>
        )}

        {/* ============== PARKING ============= */}
        {accomodationType === 'PARKING' && (
          <div className="flex flex-col gap-6">
            <div className="flex justify-between items-center w-full space-x-8">
              <FormInput
                width={46}
                label="Unit Type"
                register={register}
                isRequired={true}
                register_key="unitType"
                type="dropdown"
                defaultValuePre={value?.[0]?.unitType}
                options={[
                  { value: '', label: 'Select type', disabled: true },
                  { value: '2-wheeler', label: '2-wheeler' },
                  { value: '4-wheeler', label: '4-wheeler' }
                ]}
              />
              <FormInput
                width={46}
                label={`Build Up Area (${measurementType})`}
                register={register}
                isRequired={true}
                register_key="buildUpArea"
                type="text"
                onType={handleBuildUpAreaChange}
                validation={{
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Build Up Area must be a number'
                  }
                }}
              />
            </div>
            <div className="flex justify-start items-center">
              <div className="text-md flex flex-col justify-start gap-2 w-[46%]">
                <span className="text-gray-600">Electrified</span>
                <div className="w-fit flex justify-start space-x-6">
                  <div className="flex justify-between space-x-2">
                    <input
                      type="radio"
                      name="electrified"
                      className="border-stone-300 rounded"
                      value="Yes"
                      {...register('electrified')}
                      defaultChecked={value?.[0]?.electrified === true}
                    />
                    <label>Yes</label>
                  </div>

                  <div className="flex justify-between space-x-2">
                    <input
                      type="radio"
                      name="electrified"
                      className="border-stone-300 rounded"
                      value="No"
                      {...register('electrified')}
                      defaultChecked={value?.[0]?.electrified === false}
                    />
                    <label>No</label>
                  </div>
                </div>
                {errors.electrified && (
                  <p className="text-red-500 text-xs mt-1">{errors.electrified.message}</p>
                )}
              </div>
            </div>

            {/* Submit Buttons */}
            <div className="w-full flex justify-end items-center space-x-4 mt-8">
              <Button type="reset" onClick={onClose} displayType="secondary">
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit(handleOnSubmitEditAccommodation)}
                disabled={isSubmitting}>
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
