import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../builder/components/layout/AppLayout';
import Table from '../../shared/components/layout/Table';
import Button from '../../shared/components/ui/Button';
import Search from '../../shared/components/ui/Search';
import Header from '../../shared/components/layout/Header';
import Modal from '../../shared/components/ui/Modal';
import { transformData } from '../../super-admin/utils/transformData';
import AddLand from '../components/forms/AddLand';
import EditLand from '../components/forms/EditLand';
import { createLand, editLandById, getAllLands } from '../services/api/implementation/impl';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { filterData } from '../../shared/utils/filterData';
import NoDataFound from '../components/layout/NoDataFound';

/**
 * Converts a numeric value to a string with up to 2 decimals,
 * or returns it as an integer if the decimals are `.00`.
 */
const formatNumber = (input) => {
  const number = parseFloat(input);
  if (isNaN(number)) {
    return 'N/A';
  }

  const fixedNumber = number.toFixed(2);

  if (fixedNumber.split('.')[1] === '00') {
    return parseInt(fixedNumber, 10);
  }

  return fixedNumber; // Return as a string with up to 2 decimals
};

const parseAreaType = (areaType) => {
  if (!areaType) return '';

  let type = areaType.toLowerCase().trim();      
  type = type.replace('sq/', '');               
  type = type.replace(/\s+/g, '');               

  if (type.includes('guntha')) type = type.replace('guntha', 'gunta');
  if (type === 'gunta' || type === 'guntha') type = 'gunta';

  if (type === 'acres') type = 'acre';

  if (type === 'hectares') type = 'hectare';

  return type;
};


const calculateFinalArea = (mainArea, mainAreaType, subArea, subAreaType) => {
  const mArea = parseFloat(mainArea) || 0;
  const sArea = parseFloat(subArea) || 0;

  const mType = parseAreaType(mainAreaType);
  const sType = parseAreaType(subAreaType);

  let finalMainArea = mArea;
  let finalMainAreaType = mType;
  let finalSubArea = sArea;
  let finalSubAreaType = sType;

  if (sType === 'gunta') {
    // CASE 1: main area is acre
    if (mType === 'acre') {
      const gunthaPerAcre = 40; // 1 acre = 40 gunta
      // How many acres do we get from the sub-area?
      const acresFromSub = Math.floor(sArea / gunthaPerAcre);
      const leftoverGunta = sArea % gunthaPerAcre;

      finalMainArea = mArea + acresFromSub;
      finalMainAreaType = 'acre';
      finalSubArea = leftoverGunta;
      finalSubAreaType = 'gunta';
    }
    // CASE 2: main area is hectare
    else if (mType === 'hectare') {
      const gunthaPerHectare = 98.84; // 1 hectare ~ 98.84 gunta
      // Convert leftover gunta to hectares
      const totalHectaresFromSub = sArea / gunthaPerHectare;

      // The integer portion to add to main area
      const integerHectares = Math.floor(totalHectaresFromSub);
      // Fractional portion
      const fractionalHectares = totalHectaresFromSub - integerHectares;

      finalMainArea = mArea + integerHectares;
      finalMainAreaType = 'hectare';

      // leftover gunta = fractional part * 98.84
      const leftoverGunta = fractionalHectares * gunthaPerHectare;
      finalSubArea = leftoverGunta;
      finalSubAreaType = 'gunta';
    }
  }

  return { finalMainArea, finalMainAreaType, finalSubArea, finalSubAreaType };
};

const LandsList = () => {
  /**
   * Hooks
   */
  const navigate = useNavigate();

  /**
   * Local state
   */
  const [isOpenAddLand, setIsOpenAddLand] = useState(false);
  const [isOpenEditLand, setIsOpenEditLand] = useState(false);
  const [LandsStaticId, setLandsStaticId] = useState(null);
  const [filterLand, setFilterLand] = useState([]);
  const [landQuery, setLandQuery] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Meta data
   */
  const landsFormat = ['id', 'name', 'number', 'area', 'tehsil', 'actions'];

  /**
   * Data fetching
   */
  const {
    data: lands,
    refetch: refetchLands,
    isError
  } = useQuery({
    queryKey: ['lands'],
    queryFn: getAllLands
  });

  useEffect(() => {
    setFilterLand(lands);
  }, [lands]);

  useEffect(() => {
    refetchLands();
  }, [refetchLands]);

  /**
   * Data mutation
   */
  const { mutate: mutateLand } = useMutation({
    mutationFn: (land) => createLand(land),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Land created successfully');
      setIsOpenAddLand(false);
      await refetchLands(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new land', err);
      toast.error('Error creating land');
    }
  });

  const { mutate: handleEditLand } = useMutation({
    mutationFn: (updatedLand) => {
      // Log the payload for debugging
      console.log('Updated Land Payload:', updatedLand);
      return editLandById(parseInt(LandsStaticId), updatedLand);
    },
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Land edited successfully');
      setIsOpenEditLand(false);
      window.location.reload();
      await refetchLands();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error editing land:', err); // Log the error for debugging
      toast.error('Error editing land');
    }
  });

  /**
   * Helper method calls
   */
  const handleSearchChange = useCallback(
    (incomingQuery) => {
      setLandQuery(incomingQuery);
      if (incomingQuery) {
        const filtered = filterData('name', lands, incomingQuery);
        setFilterLand(filtered);
      } else {
        setFilterLand(lands);
      }
    },
    [lands]
  );

  /**
   * We prepare the data for the table by:
   *   - Collecting all khasra (or survey) numbers
   *   - Calculating final main/sub area if needed
   *   - Formatting the final area string
   */
  const landsFormatted = filterLand?.map((item) => {
    let allKhasraNumbers = [];

    // Safely iterate over all ownerLandMappings
    if (Array.isArray(item?.ownerLandMappings)) {
      item.ownerLandMappings.forEach((mapping) => {
        if (Array.isArray(mapping?.owner?.khasra)) {
          mapping.owner.khasra.forEach((k) => {
            allKhasraNumbers.push(k.number);
          });
        }
      });
    }

    // Convert all khasra numbers to a single string
    const numbers =
      allKhasraNumbers.length > 0
        ? `${allKhasraNumbers.join(', ')} ${
            item?.state === 'Karnataka' ? '(survey)' : '(khasra)'
          }`
        : 'N/A';

    // Calculate final area for top-level "land" data
    const {
      finalMainArea,
      finalMainAreaType,
      finalSubArea,
      finalSubAreaType
    } = calculateFinalArea(
      item?.total_mainArea,
      item?.total_mainAreaType,
      item?.total_subArea,
      item?.total_subAreaType
    );

    // Build display string for total area
    let displayArea = 'N/A';

    if (finalMainArea || finalSubArea) {
      if (parseFloat(finalSubArea) === 0) {
        // If sub area is 0, don't show it
        displayArea = `${formatNumber(finalMainArea)} ${finalMainAreaType}`;
      } else {
        displayArea = `${formatNumber(finalMainArea)} ${finalMainAreaType}, ${formatNumber(
          finalSubArea
        )} ${finalSubAreaType}`;
      }
    }

    // Append (taluk) or (tehsil)
    const tehsilDisplay =
      item?.tehsil +
      ' ' +
      (item?.state === 'Karnataka' ? '(taluk)' : '(tehsil)') ||
      'N/A';

    return transformData(
      {
        ...item,
        number: numbers,
        area: displayArea,
        tehsil: tehsilDisplay
      },
      landsFormat
    );
  });

  /**
   * Event handlers
   */
  const handleOnSubmitAddLand = (data) => {
    mutateLand(data);
  };

  const handleToggleAddLand = useCallback(() => {
    setIsOpenAddLand((prev) => !prev);
  }, []);

  const handleToggleEditLand = useCallback(
    (id) => {
      setLandsStaticId(id);
      setIsOpenEditLand((prev) => !prev);
    },
    []
  );

  if (isError) {
    toast.error('Error fetching lands');
    navigate('/');
    return null;
  }

  return (
    <>
      {isOpenAddLand && (
        <Modal isDirty={true} confirmOnClose={true} name={'Add Land'} onClose={handleToggleAddLand} width={88} upShift>
          <AddLand
            isSubmitting={isSubmitting}
            onSubmit={handleOnSubmitAddLand}
            onClose={handleToggleAddLand}
          />
        </Modal>
      )}

      {isOpenEditLand && (
        <Modal isDirty={true} confirmOnClose={true} name={'Edit Land'} onClose={handleToggleEditLand} width={88} upShift>
          <EditLand
            isSubmitting={isSubmitting}
            onSubmit={handleEditLand}
            id={LandsStaticId}
            onClose={handleToggleEditLand}
          />
        </Modal>
      )}

      <div className="bg-gray-100">
        <Layout>
          {/* Header */}
          <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
            <Header data={{ name: 'Lands' }} />
          </div>

          {landsFormatted && landsFormatted.length > 0 ? (
            <>
              {/* Search and Add Button */}
              <div className="flex items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 w-[82vw] shadow-sm">
                <Search onChange={handleSearchChange} query={landQuery} />

                <div className="flex gap-5 cursor-pointer">
                  <Button onClick={handleToggleAddLand}>Add Land</Button>
                </div>
              </div>

              {/* Table content */}
              <div className="w-[82%] fixed top-[10rem] h-[34rem] overflow-hidden flex justify-center items-start px-6">
                <Table
                  headers={[
                    'Land Name',
                    'Allotment Number',
                    'Total Area',
                    'Adminstrative Division',
                    'Action'
                  ]}
                  identifier={'id'}
                  isViewEnable={true}
                  onView={(id) => navigate(`/builder/management/land/${id}`)}
                  onEdit={(id) => handleToggleEditLand(id)}
                  data={landsFormatted}
                />
              </div>
            </>
          ) : (
            <NoDataFound buttonText="Add Land" onButtonClick={handleToggleAddLand} />
          )}
        </Layout>
      </div>
    </>
  );
};

export default LandsList;
