import React, { useCallback, useEffect, useState } from 'react';
import Search from '../../shared/components/ui/Search';
import Table from '../../shared/components/layout/Table';
import Modal from '../../shared/components/ui/Modal';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getAllCompanies,
  getConsolidatedUnitList,
  getUnitStatus,
  updateUnitStatus // <-- Reuse this from your "UnitPricingList" snippet
} from '../services/api/implementation/impl';
import { useForm } from 'react-hook-form';
import { UpdateStatus } from '../components/forms/UpdateStatus';
import { transformData } from '../../super-admin/utils/transformData';
import { filterData } from '../../shared/utils/filterData';
import FormInput from '../../shared/components/ui/FormInput';
import NoDataFound from '../components/layout/NoDataFound';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import toast from 'react-hot-toast';

const ConsolidatedList = () => {
  const { register } = useForm();

  // State Variables
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [selectedConsolidatedItemId, setSelectedConsolidatedItemId] = useState(null);

  // Keep track of possible statuses from the backend
  const [updateStatusOptions, setUpdateStatusOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // For local data filtering
  const [ConsolidateFilter, setConsolidateFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // For Company filter
  const [CompanyId, setCompanyId] = useState('');

  // Fetch companies
  const { data: companies } = useQuery({
    queryKey: ['company'],
    queryFn: () => getAllCompanies()
  });

  // Fetch consolidated list data
  const {
    data: consolidatedData,
    refetch: refetchConsolidatedList
  } = useQuery({
    queryKey: ['consolidatedUnitList'],
    queryFn: () => getConsolidatedUnitList()
  });

  // Fetch the unit statuses (like in UnitPricingList)
  const {
    data: unitStatus,
    refetch: refetchUnitStatus
  } = useQuery({
    queryKey: ['unitStatus'],
    queryFn: () => getUnitStatus()
  });

  console.log('unitStatus', unitStatus);

  /**
   * Reuse the SAME mutation style from UnitPricingList:
   * handleUnitStatusUpdate -> calls updateUnitStatus(selectedItemId, payload)
   */
  const { mutate: handleUnitStatusUpdate } = useMutation({
    mutationFn: (payload) => updateUnitStatus(selectedConsolidatedItemId, payload),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: async () => {
      toast.success('Unit status updated successfully');
      setIsSubmitting(false);
      setOpenUpdateStatus(false);
      await refetchConsolidatedList();
    },
    onError: (err) => {
      setIsSubmitting(false);
      toast.error('Error updating unit status', err);
      console.error('Error updating unit status', err);
    }
  });

  // Once we have the unitStatus data, update our local state of possible statuses
  useEffect(() => {
    if (unitStatus) {
      refetchUnitStatus();
      // Example: filter out or transform statuses as needed
      const modifiedArray = unitStatus?.filter((item) => item !== 'Booked' && item !== 'Vacant' && item !== 'Blocked_for_Broker' && item !== 'Temporary_Booked' && item !== 'Blocked');
      setUpdateStatusOptions(modifiedArray);
    }
  }, [unitStatus, refetchUnitStatus]);

  // Keep local filter data in sync with the main data
  useEffect(() => {
    if (consolidatedData) {
      setConsolidateFilter(consolidatedData);
    }
  }, [consolidatedData]);

  // Manually re-fetch consolidated list if needed
  useEffect(() => {
    // (In most cases, you'd only want to call refetchConsolidatedList on certain triggers,
    //  not every time consolidatedData changes. But leaving your original structure.)
    refetchConsolidatedList();
  }, [consolidatedData, refetchConsolidatedList]);

  // Filter by Company
  const handleCompany = (id) => {
    setCompanyId(id);
    if (!id) {
      // If "Select Company" is chosen, show all
      setConsolidateFilter(consolidatedData);
    } else {
      const data = consolidatedData.filter(
        (item) => parseInt(item?.block?.company_id) === parseInt(id)
      );
      setConsolidateFilter(data);
    }
  };

  // Table header format
  const ConsolidatedDataFormat = [
    'id',
    'customer_name',
    'unit_name',
    'complete_payment_stage',
    'paid_amount',
    'pending_amount',
    'salesPerson',
    'status',
    'actions' // We'll add an Action column
  ];

  // Transform data for the table
  const ConsolidatedData =
    ConsolidateFilter && ConsolidateFilter.length > 0
      ? ConsolidateFilter.map((item) => {
          const transformedItem = transformData(
            {
              // Use the actual primary key from your backend, if available
              id: item.unit.id,
              customer_name: item.leadName || 'N/A',
              unit_name: item.unitName || 'N/A',
              complete_payment_stage:
                item.completedPaymentStage === 100 ? 'Completed' : 'Pending',
              paid_amount: item.total_paid_amount || 0,
              pending_amount: item.due_amount || 0,
              salesPerson: item.salesPerson || '-',
              status: item.unitStatus?.replace(/_/g, ' ')?.trim() || '-'
            },
            ConsolidatedDataFormat
          );
          return transformedItem;
        })
      : [];

  // Search
  const handleSearchChange = useCallback(
    (incomingQuery) => {
      setSearchQuery(incomingQuery);
      if (incomingQuery) {
        const filteredData = filterData('leadName', consolidatedData, incomingQuery);
        setConsolidateFilter(filteredData);
      } else {
        setConsolidateFilter(consolidatedData);
      }
    },
    [consolidatedData]
  );

  // Clicking Action -> open the "Update Status" modal
  const handleStatusUpdateClick = (id) => {
    console.log('Update status for ID:', id);
    setSelectedConsolidatedItemId(id);
    setOpenUpdateStatus(true);
  };

  return (
    <>
      {openUpdateStatus && (
        <Modal isDirty={true} confirmOnClose={true} width={40} name="Update Status" onClose={() => setOpenUpdateStatus(false)}>
          <UpdateStatus
            onClose={() => setOpenUpdateStatus(false)}
            statuses={updateStatusOptions}
            isSubmitting={isSubmitting}
            onSubmit={handleUnitStatusUpdate}
            type={'unitPricing'}
          />
        </Modal>
      )}

      <div className="w-full flex flex-col absolute z-10 bg-gray-100 pb-10 overflow-hidden hide-scrollbar">
        {consolidatedData && consolidatedData.length > 0 ? (
          <>
            <div className="flex z-40 items-center justify-between px-6 py-4 bg-white fixed top-[9rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
              <Search query={searchQuery} onChange={handleSearchChange} />
            </div>

            <div className="mt-[15rem] left-0 w-full h-full overflow-clip bg-gray-100">
              <GridWrapper width={100}>
                <div>
                  <FormInput
                    width={24}
                    register={register}
                    register_key={'company'}
                    label={'Companies'}
                    type={'dropdown'}
                    options={[
                      { label: 'Select Company', value: '' },
                      ...(companies && Array.isArray(companies)
                        ? companies.map((item) => ({
                            label: item.company_name,
                            value: item.id
                          }))
                        : [])
                    ]}
                    onDropdownChange={handleCompany}
                  />
                </div>
                <div className="h-[30rem] mt-10 overflow-scroll hide-scrollbar">
                  <Table
                    headers={[
                      'Customer Name',
                      'Unit Name',
                      'Complete Payment Stage',
                      'Paid Amount',
                      'Pending Amount',
                      'Sales Person',
                      'Status',
                      'Action'
                    ]}
                    data={ConsolidatedData}
                    identifier={'id'}
                    // IMPORTANT: Enable status update so the Table
                    // can render the "Action" column with a button
                    isEditEnable={false}
                    isStatusUpdateEnable={true}
                    onStatusUpdate={handleStatusUpdateClick}
                  />
                </div>
              </GridWrapper>
            </div>
          </>
        ) : (
          <div className="pt-[3rem]">
            <NoDataFound disabledBtn screenHeight={true} />
          </div>
        )}
      </div>
    </>
  );
};

export default ConsolidatedList;
