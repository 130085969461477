import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  getAllBlocks,
  getPlcbyAssociatedBlockId,
  getPlcbyBlockId
} from '../../services/api/implementation/impl';
import Button from '../../../shared/components/ui/Button';
import CreatableSelect from 'react-select/creatable';

export const AddPLCUnit = ({ handleUnitPLC, onClose, isSubmitting }) => {
  const { register, handleSubmit, setValue, watch } = useForm();
  const { id: projectId } = useParams();

  // Track selected block/floor
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
  const [BlockIdSelected, setBlockIdSelected] = useState(0);

  // For replication logic
  const [selectedViews, setSelectedViews] = useState({});
  const [hasUserModifiedLevel, setHasUserModifiedLevel] = useState({});
  const [isFormComplete, setIsFormComplete] = useState(false);

  const [selectedBlockType, setSelectedBlockType] = useState('');

  // ========== Fetch Blocks ==========
  const { data: allBlocks, refetch: refetchBlocks } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(parseInt(projectId)),
    enabled: !!projectId
  });

  // ========== Fetch PLC data based on selected block ==========
  const { data: plc_associated, refetch: refetchplc_blockid } = useQuery({
    queryKey: ['plc_associated', BlockIdSelected],
    queryFn: () => getPlcbyAssociatedBlockId(BlockIdSelected),
    enabled: !!BlockIdSelected
  });

  const { data: plc_block_id } = useQuery({
    queryKey: ['plc_blockbyid', BlockIdSelected],
    queryFn: () => getPlcbyBlockId(BlockIdSelected),
    enabled: !!BlockIdSelected
  });

  // RHF watchers
  const selectedBlockId = watch('block_id');
  const selectedLevelId = watch('level_id');

  // Re-fetch once block is set
  useEffect(() => {
    if (BlockIdSelected) refetchplc_blockid(BlockIdSelected);
  }, [BlockIdSelected, refetchplc_blockid]);

  // Re-fetch blocks on mount or project change
  useEffect(() => {
    refetchBlocks();
  }, [projectId, refetchBlocks]);

  // ========== MEMOIZE the Single Block (with floors) the user selected ==========
  const allLevels = useMemo(() => {
    if (!allBlocks) return null;
    return allBlocks
      .filter((block) => block?.levels?.length > 0)
      .map((block) => ({
        ...block,
        levels: block.levels.filter((lvl) => lvl.floorType !== 'PARKING'),
      }))
      .find((block) => block.id === parseInt(BlockIdSelected));
  }, [allBlocks, BlockIdSelected]);

  // ========== Filtering levels to only RESIDENTIAL/COMMERCIAL ==========
  const filterLevels = (block) => {
    return block?.levels?.filter(
      (level) => level.floorType === 'RESIDENTIAL' || level.floorType === 'COMMERCIAL'
    );
  };

  // ========== Memoize the final floorOption ==========
  const floorOption = useMemo(() => {
    if (!allLevels) return [];
    return filterLevels(allLevels);
  }, [allLevels]);

  // Find the current level from the selectedLevelId
  const selectedLevel = allLevels?.levels?.find(
    (item) => item.id === parseInt(selectedLevelId)
  );

  // ========== When user selects a block ==========
  const handleSelectBlockForLevel = (e) => {
    setValue('block_id', e.target.value);
    setBlockIdSelected(e.target.value);

    // Find if block is "Independent"
    const selectedBlock = allBlocks?.find((b) => b.id === parseInt(e.target.value));
    setSelectedBlockType(selectedBlock?.type || '');

    // Reset everything when block changes
    setValue('level_id', '');
    setSelectedViews({});
    setHasUserModifiedLevel({});
    setCurrentLevelIndex(0);
  };

  // ========== When user selects a floor ==========
  const handleSelectFloorForUnit = (e) => {
    const newSelectedLevelId = e.target.value;
    setValue('level_id', newSelectedLevelId);

    // Adjust currentLevelIndex to match the selected floor
    const newIndex = allLevels?.levels.findIndex(
      (level) => level.id === parseInt(newSelectedLevelId)
    );
    if (newIndex !== undefined && newIndex !== -1) {
      setCurrentLevelIndex(newIndex);
    }
  };

  // Auto-select the first valid floor if available
  useEffect(() => {
    if (floorOption && floorOption.length > 0) {
      // If we haven't chosen a floor yet, auto-select the first
      handleSelectFloorForUnit({
        target: { value: floorOption[0].id } // a mocked event object
      });
    }
  }, [floorOption]);

  // ========== Navigation (next level / finish) ==========
  const handleNextLevel = () => {
    if (currentLevelIndex < allLevels?.levels.length - 1) {
      const nextLevel = allLevels.levels[currentLevelIndex + 1];
      setValue('level_id', nextLevel.id.toString());
      setCurrentLevelIndex(currentLevelIndex + 1);
    } else {
      setIsFormComplete(true);
    }
  };
  const isLastLevel = currentLevelIndex === allLevels?.levels.length - 1;

  // Checks if a level was modified
  const isLevelModified = (levelId) => {
    return hasUserModifiedLevel[levelId] || false;
  };

  // ========== PLC Items ==========
  const plcOptions = useMemo(() => {
    if (!plc_associated?.plc_block_mapping) return [];
    return plc_associated.plc_block_mapping.map((item) => ({
      value: `plcs-${item?.plc?.id}`,
      label: item?.plc?.name || ''
    }));
  }, [plc_associated]);

  const levelOptionForUnits = useMemo(() => {
    if (!selectedLevel?.units) return [];
    return selectedLevel.units.map((unit, index) => ({
      value: `common-${index + 1}`,
      label: unit.unitName || ''
    }));
  }, [selectedLevel]);

  // ========== Static "Common" items ==========
  const StaticOption = useMemo(
    () => [
      { value: 'common-1000', label: 'Staircase' },
      { value: 'common-1001', label: 'Corridor' },
      { value: 'common-1003', label: 'Lift' },
      { value: 'common-1004', label: 'Adjacent Block' },
      { value: 'common-1005', label: 'Road' }
    ],
    []
  );

  const groupedOptions = useMemo(() => {
    const commonGroup = {
      label: 'Common',
      options: [...StaticOption, ...levelOptionForUnits]
    };
    const plcGroup = {
      label: 'Plcs',
      options: plcOptions
    };
    return selectedBlockType === 'Independent'
      ? [plcGroup]
      : [commonGroup, plcGroup];
  }, [StaticOption, levelOptionForUnits, plcOptions, selectedBlockType]);

  const filterOptions = useCallback((options, currentUnitName) => {
    return options.filter(
      (option) =>
        !(option.label === currentUnitName && option.value.startsWith('common-'))
    );
  }, []);

  const sortUnits = (units) => {
    return [...units].sort((a, b) =>
      a.unitName.localeCompare(b.unitName, undefined, { numeric: true, sensitivity: 'base' })
    );
  };

  const handleViewSelection = (levelId, unitIndex, direction, newIds) => {
    setSelectedViews((prev) => {
      const newViews = { ...prev };

      if (!newViews[levelId]) newViews[levelId] = {};
      if (!newViews[levelId][unitIndex]) newViews[levelId][unitIndex] = {};

      newViews[levelId][unitIndex][direction] = newIds;

      const currentLvlIdx = allLevels?.levels.findIndex((lvl) => lvl.id === levelId);
      if (currentLvlIdx !== undefined && currentLvlIdx >= 0) {
        if (currentLvlIdx === 0) {
          allLevels?.levels.forEach((lvl, idx) => {
            if (idx !== currentLvlIdx && !hasUserModifiedLevel[lvl.id]) {
              if (!newViews[lvl.id]) newViews[lvl.id] = {};
              if (!newViews[lvl.id][unitIndex]) newViews[lvl.id][unitIndex] = {};
              newViews[lvl.id][unitIndex][direction] = newIds;
            }
          });
        } else {
          // replicate downward only
          for (let i = currentLvlIdx + 1; i < allLevels.levels.length; i++) {
            const lowerLevel = allLevels.levels[i];
            if (!hasUserModifiedLevel[lowerLevel.id]) {
              if (!newViews[lowerLevel.id]) newViews[lowerLevel.id] = {};
              if (!newViews[lowerLevel.id][unitIndex]) {
                newViews[lowerLevel.id][unitIndex] = {};
              }
              newViews[lowerLevel.id][unitIndex][direction] = newIds;
            }
          }
        }
      }

      return newViews;
    });

    // Mark as user-modified
    setHasUserModifiedLevel((prev) => ({
      ...prev,
      [levelId]: true
    }));
  };

  const getValueForDirection = (levelId, unitIndex, direction) => {
    const ids = selectedViews[levelId]?.[unitIndex]?.[direction] || [];

    const allOptionsFlat = groupedOptions.flatMap((group) => group.options);

    return ids.map((id) => {
      if (/^common-user-/.test(id)) {
        const userLabel = id.replace('common-user-', '');
        return { value: id, label: userLabel };
      }
      const found = allOptionsFlat.find((opt) => opt.value === id);
      if (found) return found;

      // Fallback
      return { value: id, label: id };
    });
  };

  // ========== Called when user picks or removes items in the multi-select ==========
  const handleDirectionChange = (newValue, actionMeta, levelId, unitIndex, direction) => {
    const newIds = newValue.map((item) => item.value);
    handleViewSelection(levelId, unitIndex, direction, newIds);
  };

  // ========== Called when user creates a new item ==========
  const handleCreateOption = (inputValue, levelId, unitIndex, direction, currentSelected) => {
    const newId = `common-user-${inputValue}`;
    const updatedIds = [...currentSelected.map((item) => item.value), newId];
    handleViewSelection(levelId, unitIndex, direction, updatedIds);
  };

  // ========== Final Submit Handler ==========
  const onSubmit = () => {
  
    const payload = allLevels?.levels?.map((level) => {
      const sortedUnits = sortUnits(level.units || []);

      return {
        level_id: parseInt(level.id),
        units: sortedUnits.map((unit, unitIndex) => {
          // Build default structure
          const unitPayload = {
            unit_id: unit.id,
            plcs: { east: [], west: [], north: [], south: [] },
            entity: { east: [], west: [], north: [], south: [] },
            other: []
          };

          const directions = ['north', 'south', 'east', 'west', 'others'];

          directions.forEach((direction) => {
            const viewData = selectedViews[level.id]?.[unitIndex]?.[direction] || [];
            if (viewData.length > 0) {
              const plcs = viewData
                .filter((id) => id.startsWith('plcs-'))
                .map((id) => parseInt(id.replace('plcs-', '')));

              const entities = viewData
                .filter((id) => id.startsWith('common-'))
                .map((id) => {
                  if (id.startsWith('common-user-')) {
                    // user-created
                    return id.replace('common-user-', '');
                  } else {
                    // built-in "common-xxxx"
                    const allOptionsFlat = groupedOptions.flatMap((g) => g.options);
                    const found = allOptionsFlat.find((opt) => opt.value === id);
                    return found ? found.label : null;
                  }
                })
                .filter(Boolean);

              if (direction === 'others') {
                unitPayload.other = plcs; // store PLC IDs for "others"
              } else {
                if (plcs.length > 0) {
                  unitPayload.plcs[direction] = plcs;
                }
                if (entities.length > 0) {
                  unitPayload.entity[direction] = entities;
                }
              }
            }
          });

          return unitPayload;
        })
      };
    });

    // Pass final payload outward
    handleUnitPLC(payload);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`overflow-y-scroll hide-scrollbar  ${
        selectedLevel ? 'h-[32rem]' : 'h-[10rem]'
      }`}
    >
      {/* Block and Floor Selectors */}
      <div className="flex justify-between">
        <div className="flex flex-col w-[30rem]">
          <label>Select Block</label>
          <select
            {...register('block_id')}
            onChange={handleSelectBlockForLevel}
            className="h-10 mt-2 border"
          >
            <option value="">Select Block</option>
            {allBlocks &&
              allBlocks
                .filter((item) => item?.levels?.length > 0)
                .map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
          </select>
        </div>

        <div className="flex flex-col w-[30rem]">
          <label>Select Floor</label>
          <select
            {...register('level_id')}
            onChange={handleSelectFloorForUnit}
            className="h-10 mt-2 border"
            disabled={!selectedBlockId}
          >
            <option value="">Select Floor</option>
            {floorOption.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* If a level is selected, show directional multi-selects */}
      {selectedLevel && (
        <div className="mt-10">
          <h3 className="text-xl font-semibold border-b-[1px] border-stone-200 w-full pb-2 mb-4">
            {selectedLevel?.name}{' '}
            {isLevelModified(selectedLevel.id) && (
              <span className="text-sm font-normal text-gray-500">(Modified)</span>
            )}
          </h3>

          {selectedLevel.units &&
            selectedLevel.units.length > 0 &&
            sortUnits(selectedLevel.units).map((unit, unitIndex) => (
              <div key={unit.id} className="mb-4">
                <h4 className="text-lg font-medium">{unit.unitName}</h4>
                <div className="grid grid-cols-2 gap-3">
                  {['north', 'south', 'east', 'west', 'others'].map((direction) => {
                    const valueForDirection = getValueForDirection(
                      selectedLevel.id,
                      unitIndex,
                      direction
                    );

                    // Build the actual set of options for this direction
                    // "others" => only PLC group
                    const directionOptions =
                      direction === 'others'
                        ? [{ label: 'Plcs', options: plcOptions }]
                        : groupedOptions.map((group) => {
                            // If group is "Common", filter out item that matches the unitName
                            if (group.label === 'Common') {
                              return {
                                ...group,
                                options: filterOptions(group.options, unit.unitName)
                              };
                            }
                            return group;
                          });

                    return (
                      <div key={direction}>
                        <label className="capitalize">{direction}</label>
                        <CreatableSelect
                          isMulti
                          options={directionOptions}
                          value={valueForDirection}
                          onChange={(newValue, actionMeta) =>
                            handleDirectionChange(
                              newValue,
                              actionMeta,
                              selectedLevel.id,
                              unitIndex,
                              direction
                            )
                          }
                          onCreateOption={(inputValue) =>
                            handleCreateOption(
                              inputValue,
                              selectedLevel.id,
                              unitIndex,
                              direction,
                              valueForDirection
                            )
                          }
                          styles={{
                            groupHeading: (provided) => ({
                              ...provided,
                              fontWeight: 'bold'
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              color: state.isSelected ? '#fff' : '#000',
                              backgroundColor: state.isFocused ? '#eee' : '#fff'
                            }),
                            multiValue: (provided) => ({
                              ...provided,
                              backgroundColor: '#2A565E'
                            }),
                            multiValueLabel: (provided) => ({
                              ...provided,
                              color: '#fff'
                            })
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
        </div>
      )}

      {/* Footer buttons */}
      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button
          type="button"
          displayType="secondary"
          onClick={() => {
            setCurrentLevelIndex(0);
            setIsFormComplete(false);
            onClose();
          }}
        >
          Cancel
        </Button>
        {isFormComplete ? (
          <Button type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        ) : (
          <Button type="button" onClick={handleNextLevel}>
            {isLastLevel ? 'Finish' : 'Next'}
          </Button>
        )}
      </div>
    </form>
  );
};
