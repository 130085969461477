import React, { useEffect, useCallback } from 'react';
import cross from '../../assets/svg/dowload.svg';

const Modal = ({
  name,
  width,
  onClose,
  upShift,
  children,
  hideClose,
  // New props
  isDirty,
  confirmOnClose
}) => {
  // Helper function to handle closure
  const handleClose = useCallback(() => {
    // If we must confirm on close, and the form is dirty => confirm first
    if (confirmOnClose && isDirty) {
      const userConfirmed = window.confirm(
        'Are you sure you want to close? Any unsaved changes will be lost.'
      );
      if (!userConfirmed) return; // stop closing if user cancels
    }
    // Otherwise proceed
    onClose();
  }, [confirmOnClose, isDirty, onClose]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose(); // Same confirm logic
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose]);

  return (
    <div
      className="h-screen w-screen bg-[#0000002a] z-[90] flex justify-center items-center fixed top-0 left-0"
    >
      <div
        className={`bg-white rounded-lg ${upShift ? 'h-[94%]' : 'h-fit'}`}
        style={{
          width: `${width}%`
        }}
      >
        {/* Head */}
        <div className="flex justify-between items-center px-6 py-4 bg-[#E9F5F6] rounded-t-lg">
          <span className="text-gray-700 font-semibold text-lg">{name}</span>
          {!hideClose && (
            <img
              src={cross}
              alt="Close modal"
              className="cursor-pointer hover:scale-110 transition-all duration-300"
              onClick={handleClose}
            />
          )}
        </div>

        {/* Body */}
        <div className="py-6 px-8 max-h-[550px] overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
