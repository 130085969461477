import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Toast notifications (adjust to your toast system)
import { toast } from 'react-hot-toast';

// UI components (adjust paths as needed)
import Button from '../../../shared/components/ui/Button';

// === API Functions (adjust paths accordingly) ===

import { getDynamicFields, getAnnexures, updateTemplate, getTemplateById } from '../../services/api/implementation/impl';

const EditDocumentTemplate = ({ templateid, onClose, onSuccess }) => {
  // ---------- 1) Fetch the Template Data ----------
  const {
    data: templateData,
    isLoading: isLoadingTemplate,
    isError: isErrorTemplate,
  } = useQuery({
    queryKey: ['template', templateid],
    queryFn: () => getTemplateById(templateid),
    enabled: !!templateid,
  });

  // ---------- 2) Fetch Dynamic Fields ----------
  // Expected format:
  // [ { id: 1, column_name: 'CLOSED_DEAL_ID', readable_name: 'Closed Deal Id' }, ... ]
  const {
    data: dynamicFieldsList,
    isLoading: isLoadingDynamicFields,
    isError: isErrorDynamicFields,
  } = useQuery({
    queryKey: ['dynamic-fields'],
    queryFn: getDynamicFields,
  });

  // ---------- 3) Fetch Annexures ----------
  // Expected format:
  // [ { id: 1, column_name: "PROJECT_SPECIFICATION", readable_name: "Project Specification" }, ... ]
  const {
    data: annexureList,
    isLoading: isLoadingAnnexures,
    isError: isErrorAnnexures,
  } = useQuery({
    queryKey: ['annexures'],
    queryFn: getAnnexures,
  });

  // ---------- 4) Component States ----------
  const [documentName, setDocumentName] = useState('');
  const [description, setDescription] = useState('');
  const [customDetails, setCustomDetails] = useState('');
  const [selectedDynamicFields, setSelectedDynamicFields] = useState([]); // For react‑select
  const [selectedAnnexures, setSelectedAnnexures] = useState([]); // For react‑select

  // ---------- 5) Build React‑Select Options ----------
  const dynamicFieldOptions = useMemo(() => {
    if (!dynamicFieldsList || !Array.isArray(dynamicFieldsList)) return [];
    return dynamicFieldsList.map((field) => ({
      value: field.column_name,
      label: field.readable_name,
    }));
  }, [dynamicFieldsList]);

  const annexureOptions = useMemo(() => {
    if (!annexureList || !Array.isArray(annexureList)) return [];
    return annexureList.map((ann) => ({
      value: ann.column_name,
      label: ann.readable_name,
    }));
  }, [annexureList]);

  // ---------- 6) Pre-Fill the Form When Data Is Loaded ----------
  useEffect(() => {
    if (templateData && dynamicFieldOptions.length > 0 && annexureOptions.length > 0) {
      setDocumentName(templateData.document_name || '');
      setDescription(templateData.description || '');
      setCustomDetails(templateData.content || '');

      // Convert the stored dynamic fields (array of column names) to the react‑select option format:
      const preSelectedDynamicFields = (templateData.dynamic_fields || [])
        .map((val) => dynamicFieldOptions.find((option) => option.value === val))
        .filter((option) => option);
      setSelectedDynamicFields(preSelectedDynamicFields);

      // Similarly for annexures:
      const preSelectedAnnexures = (templateData.annexure || [])
        .map((val) => annexureOptions.find((option) => option.value === val))
        .filter((option) => option);
      setSelectedAnnexures(preSelectedAnnexures);
    }
  }, [templateData, dynamicFieldOptions, annexureOptions]);

  // ---------- 7) Update Template Mutation ----------
  const { mutate: mutateUpdateTemplate, isLoading: isUpdating } = useMutation({
    mutationFn: (payload) => updateTemplate(templateid, payload),
    onSuccess: () => {
      toast.success('Template updated successfully!');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    },
    onError: (error) => {
      console.error('Error updating template:', error);
      toast.error('Failed to update template');
    },
  });

  // ---------- 8) Save Handler ----------
  const handleSave = () => {
    if (!templateData) {
      toast.error('Template data not loaded yet.');
      return;
    }

    // Build the payload to update the template.
    // We re-use some values (project_id, company_id, html_template) from the loaded templateData.
    const payload = {
      id: templateid,
      document_name: documentName,
      description: description,
      dynamic_fields: selectedDynamicFields.map((item) => item.value),
      annexure: selectedAnnexures.map((item) => item.value),
      content: customDetails,
      project_id: templateData.project_id,
      company_id: templateData.company_id,
    };

    mutateUpdateTemplate(payload);
  };

  // ---------- 9) Cancel Handler ----------
  const handleCancel = () => {
    if (onClose) onClose();
  };

  // ---------- 10) Loading/Error States ----------
  const isLoadingAll = isLoadingTemplate || isLoadingDynamicFields || isLoadingAnnexures;
  const isErrorAll = isErrorTemplate || isErrorDynamicFields || isErrorAnnexures;

  if (isLoadingAll) {
    return <div>Loading ...</div>;
  }
  if (isErrorAll) {
    return <div>Error loading data. Please try again later.</div>;
  }

  // ---------- 11) Render the Form ----------
  return (
    <div className="w-full p-4">
      <div className="flex justify-between items-center mb-4 border-b pb-2">
        <h2 className="text-xl font-semibold">Edit Document Template</h2>
        <div className="flex gap-2">
          <Button variant="outline" onClick={handleCancel} displayType="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={isUpdating}>
            {isUpdating ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>

      {/* Document Details */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="font-semibold">Document Name</label>
          <input
            type="text"
            className="w-full p-2 border rounded mt-1"
            placeholder="Document Name..."
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
          />
        </div>
        <div>
          <label className="font-semibold">Description</label>
          <input
            type="text"
            className="w-full p-2 border rounded mt-1"
            placeholder="Short description..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>

      {/* Dynamic Fields Multi‑Select */}
      <div className="mb-6">
        <label className="font-semibold block mb-2">Dynamic Fields</label>
        <Select
          isMulti
          value={selectedDynamicFields}
          onChange={(newVal) => setSelectedDynamicFields(newVal)}
          options={dynamicFieldOptions}
          placeholder="Select dynamic fields..."
          styles={{
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: '#2A565E'
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              color: '#fff'
            })
          }}
        />
      </div>

      {/* Annexure Multi‑Select */}
      <div className="mb-6">
        <label className="font-semibold block mb-2">Annexure (Additional Docs)</label>
        <Select
          isMulti
          value={selectedAnnexures}
          onChange={(newVal) => setSelectedAnnexures(newVal)}
          options={annexureOptions}
          placeholder="Select annexure documents..."
          styles={{
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: '#2A565E'
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              color: '#fff'
            })
          }}
        />
      </div>

      {/* Rich Text Editor for Template Body */}
      <div className="mb-6">
        <label className="font-semibold block mb-2">Custom Details (Body)</label>
        <ReactQuill
          theme="snow"
          value={customDetails}
          onChange={setCustomDetails}
          placeholder="Type or paste the main body of your template here..."
          className="bg-white"
        />
      </div>
    </div>
  );
};

export default EditDocumentTemplate;
