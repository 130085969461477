import React, { useEffect, useState } from 'react'
import Button from '../../../shared/components/ui/Button'
import Layout from '../layout/AppLayout'
import Modal from '../../../shared/components/ui/Modal'
import { useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom'
import { ReportAdmin } from './ReportAdmin'

const SuperAdminAnalytics = () => {
    const [ReportModal, setReportModal] = useState(false)
    const Params = useLocation();
    const navigation = useNavigate()
    useEffect(() => {
        if (Params.pathname == "/super-admin/analytics") {
            setReportModal(true)
        }
    }, [])

    const handleOnClose = () => {
        setReportModal(false)
        navigation('/super-admin/home')

    }
    return (
        <>
            <Layout>
                {
                    ReportModal && (<Modal isDirty={true} confirmOnClose={true} name={"Reports"} width={60} onClose={handleOnClose}>
                        <ReportAdmin />
                    </Modal>)
                }
            </Layout>
        </>
    )
}

export default SuperAdminAnalytics