import React, { useCallback, useEffect, useState } from 'react';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Search from '../../shared/components/ui/Search';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Table from '../../shared/components/layout/Table';
import { transformData } from '../../super-admin/utils/transformData';
import { AddMaterials } from '../components/forms/AddMaterials';
import Modal from '../../shared/components/ui/Modal';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { filterData } from '../../shared/utils/filterData';
import { AddBrands } from '../components/forms/AddBrands';
import {
  getAllCategories,
  getAllMaterials
} from '../services/api/implementation/impl';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ItemList } from '../components/layout/ItemList';

import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';

const MaterialList = () => {
  const navigate = useNavigate();

  // Local state
  const [OpenAddMaterial, setOpenAddMaterial] = useState(false);
  const [isOpenEditMaterial, setIsOpenEditMaterial] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [materials, setMaterials] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isOpenAddBrands, setIsOpenAddBrands] = useState(false);
  const [selectedMaterialBrands, setSelectedMaterialBrands] = useState([]);
  const [categoryIdForBrands, setCategoryIdForBrands] = useState(null);
  const [isOpenItemList, setIsOpenItemList] = useState(false);
  const [selectedMaterialItems, setSelectedMaterialItems] = useState([]);

  const [categoryIdForEdit, setCategoryIdForEdit] = useState(null);

  // pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  
 

  const {
    data: allcategory,
    refetch: refetchAllcategory,
    isFetching
  } = useQuery({
    queryKey: ['category', page],
    queryFn: () => getAllCategories(page, limit),
    keepPreviousData: true
  });

  const totalPages = allcategory?.meta ? Math.ceil(allcategory?.meta.total / limit) : 1;

  const handlePageChange = (newPage) => {
    setMaterials([]);
    setFilteredData([]);
    setSearchQuery('');
    setPage(newPage);
  };

  useEffect(() => {
    if (allcategory?.data) {
      refetchAllcategory();
    }
  }, [allcategory?.data]);

  console.log('All Materials', allcategory);

  useEffect(() => {
    if (allcategory?.data) {
      setMaterials(allcategory?.data);
      setFilteredData(allcategory?.data);
    }
  }, [allcategory?.data]);

  const handleSearch = (data) => {
    setSearchQuery(data);
  };

  const handleOnClickSearch = () => {
    const filtered = materials.filter((item) =>
      item.category_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    setSearchQuery('');
  };

  const handleToggleAddMaterial = () => {
    setOpenAddMaterial(!OpenAddMaterial);
    console.log('Add Material close');
  };

  const handleToggleEditMaterial = (id) => {
    setCategoryIdForEdit(id);
    setIsOpenEditMaterial(!isOpenEditMaterial);
    // refetchAllcategory();
    //page reload
  };

  const handleNavigateMaterial = (id) => {
    console.log('Navigate to material', id);
    navigate(`/builder/vendors/${id}`);
  };

  const handleDeleteMaterial = (materialId) => {
    const updatedMaterials = filteredData.filter((material) => material.categoryId !== materialId);
    setMaterials(updatedMaterials);
    setFilteredData(updatedMaterials);
    toast.success('Material deleted successfully');
  };

  const materialsDataFormat = ['id', 'name', 'itemList', 'brandList', 'actions'];

  const { reset } = useForm();

  useEffect(() => {
    setFilteredData(materials);
  }, [materials]);

  const handleSearchChange = useCallback(
    (incomingQuery) => {
      if (incomingQuery) {
        const filteredMaterials = filterData('name', materials, incomingQuery);
        setFilteredData(filteredMaterials);
      } else {
        setFilteredData(materials);
      }
    },
    [materials]
  );

  console.log(materialsDataFormat, 'materialsDataFormat');
  const transformedData =
    filteredData && filteredData.length > 0
      ? filteredData?.map((item) =>
          transformData(
            { ...item, actions: item.typeProp == 'Actions' ? '' : 'Actions' },
            materialsDataFormat
          )
        )
      : [];
  console.log(transformData, 'transformeData');
  console.log(filteredData, 'filteredData');

  const onSubmit_Material = (data) => {
    toast.success('Material created successfully');
    reset();
    refetchAllcategory();
  };

  const handleOnViewBrands = (id) => {
    console.log('View Brands', id);
    const selectedMaterial = materials.find((material) => parseInt(material.id) === parseInt(id));
    console.log(selectedMaterial);
    const selectedCategoryId = selectedMaterial.id;
    setCategoryIdForBrands(selectedCategoryId);
    setSelectedMaterialBrands(selectedMaterial.brands);
    setIsOpenAddBrands(true);
  };

  const handleOnViewItems = (id) => {
    console.log('View Items', id);
    const selectedMaterial = materials.find((material) => parseInt(material.id) === parseInt(id));
    console.log(selectedMaterial, 'selectedMaterial');
    setSelectedMaterialItems(selectedMaterial.items);
    setIsOpenItemList(true);
  };

  return (
    <>
      {OpenAddMaterial && (
        <Modal isDirty={true} confirmOnClose={true} width={40} name="Add Materials" onClose={handleToggleAddMaterial}>
          <AddMaterials
            onSubmit={onSubmit_Material}
            onClose={handleToggleAddMaterial}
            onSuccess={refetchAllcategory}
          />
        </Modal>
      )}

      {isOpenAddBrands && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Brand List" onClose={() => setIsOpenAddBrands(false)}>
          <AddBrands brandList={selectedMaterialBrands} categoryId={categoryIdForBrands} />
        </Modal>
      )}
      {isOpenItemList && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Item List" onClose={() => setIsOpenItemList(false)}>
          <ItemList itemList={selectedMaterialItems} />
        </Modal>
      )}

      {isOpenEditMaterial && (
        <Modal isDirty={true} confirmOnClose={true} width={40} name="Edit Material" onClose={() => setIsOpenEditMaterial(false)}>
          <AddMaterials
            onSubmit={onSubmit_Material}
            onClose={handleToggleEditMaterial}
            onSuccess={refetchAllcategory}
            id={categoryIdForEdit}
          />
        </Modal>
      )}

      <Layout>
        <div className="fixed top-0 left-[18vw] z-40 w-[82vw]">
          <Header data={{ name: 'Materials' }} />
        </div>

        {transformData && transformedData?.length > 0 ? (
          <>
            <div className="flex z-20 items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
              <Search
                onChange={handleSearchChange}
                query={searchQuery}
                onClick={handleOnClickSearch}
              />
              <div className="flex gap-5 cursor-pointer items-center">
                <Button
                  onClick={handleToggleAddMaterial}>
                  Add Material
                </Button>
              </div>
            </div>

            <GridWrapper width={100}>
              <div className="mt-[12rem] h-[28rem] relative">
                <Table
                  headers={['Category', 'Items', 'Brands', 'Action']}
                  data={transformedData}
                  identifier={'id'}
                  onEdit={handleToggleEditMaterial}
                  onView={handleNavigateMaterial}
                  onDelete={handleDeleteMaterial}
                  // isDeleteEnable={true}
                  isEditEnable={true}
                  onViewBrands={handleOnViewBrands}
                  onViewItems={handleOnViewItems}
                />
                <PaginationComponent
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  isFetching={isFetching}
                />
              </div>
            </GridWrapper>
          </>
        ) : (
          <NoDataFound buttonText="Add Material" onButtonClick={handleToggleAddMaterial} />
        )}
      </Layout>
    </>
  );
};

export default MaterialList;
