import React, { useState } from 'react';
import TableHead from '../ui/TableHead';
import TableRow from '../ui/TableRow';
import TableFooter from '../ui/TableFooter';

function Table({
  isFor,
  name,
  headers,
  data,
  handleRowClick,
  isDownloadable,
  sortOptions,
  isFilterable,
  identifier,
  isDeleteEnable,
  isViewEnable,
  isEditEnable,
  onEdit,
  onDelete,
  onView,
  onChangeStatus,
  isChangeStatusEnable,
  bigHeaders,
  onAccept,
  isAcceptEnable,
  onCancel,
  isCancelEnable,
  onViewBrands,
  onViewItems,
  isStatusUpdateEnable,
  onStatusUpdate,
  isMarkEnable,
  onMark,
  isDollerEnable,
  onDollor,
  onViewByName,
  isViewByName,
  rowName,
  isBtnEnable,
  onBtn,
  onViewNote,
  isDownloadInvoiceEnable,
  onDownloadInvoice,
  isDownloadBillEnable,
  onDownloadBill,
  onResendInvite,
  isInventoryActionEnabled,
  onInventoryPurchase,
  onInventoryUpdate,
  onDownloadDoc,
  isDownloadDocEnable,
  onUploadDoc,
  uploadDocId,
  onDownloadSignDoc
}) {
  /* 
    Local state
  */
  const [query, setQuery] = useState('');

  /* 
    Computation
  */
  const width = 100 / headers.length >= 25 ? 100 / headers.length - 1 : 100 / headers.length;

  function handleQueryChange(e) {
    const targetValue = e.target.value;
    setQuery(targetValue);
  }
  // console.log("Table -> data", data)
  return (
    <div className="w-full h-full bg-white mb-4 rounded-xl relative">
      <div role="table" className="table-container h-full" style={{ position: 'relative' }}>
        <div
          className="w-full justify-between bg-gray-200 p-3 flex !text-gray-600 rounded-t-xl"
          style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>
          {headers?.map((item) => (
            <TableHead key={item} name={item} width={width} />
          ))}
        </div>

        <div
          className={`md:block absolute top-15 bg-white w-full h-full table-body rounded-b-lg ${bigHeaders && 'mt-[2rem]'}`}
          style={{ overflowY: 'auto', maxHeight: '100%', paddingBottom: '3.2rem' }}>
          {data && data.length > 0 ? (
            data?.map((item) => (
              <TableRow
                key={`${JSON.stringify(item)}`}
                data={item}
                width={width}
                identifier={identifier}
                handleRowClick={
                  handleRowClick
                    ? handleRowClick
                    : () => console.log('No row click handler assigned!')
                }
                isDeleteEnable={isDeleteEnable}
                isViewEnable={isViewEnable}
                isEditEnable={isEditEnable}
                onMark={onMark}
                onView={onView}
                onEdit={onEdit}
                onDelete={onDelete}
                isAcceptEnable={isAcceptEnable}
                onAccept={onAccept}
                onChangeStatus={onChangeStatus}
                isChangeStatusEnable={isChangeStatusEnable}
                isCancelEnable={isCancelEnable}
                onCancel={onCancel}
                onViewBrands={onViewBrands}
                onViewItems={onViewItems}
                isStatusUpdateEnable={isStatusUpdateEnable}
                onStatusUpdate={onStatusUpdate}
                isMarkEnable={isMarkEnable}
                isDollerEnable={isDollerEnable}
                onDollor={onDollor}
                onViewByName={onViewByName}
                isViewByName={isViewByName}
                rowName={rowName}
                isBtnEnable={isBtnEnable}
                onBtn={onBtn}
                onViewNote={onViewNote}
                isDownloadInvoiceEnable={isDownloadInvoiceEnable}
                onDownloadInvoice={onDownloadInvoice}
                isDownloadBillEnable={isDownloadBillEnable}
                onDownloadBill={onDownloadBill}
                onResendInvite={onResendInvite}
                isInventoryActionEnabled={isInventoryActionEnabled}
                onInventoryPurchase={onInventoryPurchase}
                onInventoryUpdate={onInventoryUpdate}
                onDownloadDoc={onDownloadDoc}
                isDownloadDocEnable={isDownloadDocEnable}
                onUploadDoc={onUploadDoc}
                uploadDocId={uploadDocId}
                onDownloadSignDoc={onDownloadSignDoc}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-40">
              <p className="text-gray-400">No data found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Table;
