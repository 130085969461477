import React, { useEffect, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import { getBlockByID } from '../../services/api/implementation/impl';

const UpdateLevel = ({
  blockId,
  // This prop will come as a string like:
  // "dropdown-[{\"value\":\"RESIDENTIAL\",\"label\":\"RESIDENTIAL\"}, ...]"
  // or possibly as an array. We'll parse it below.
  floorTypeOptions,
  onClose,
  handleUpdateLevel,
  isSubmitting
}) => {
  // 1) Get the projectId from route params
  const { id: projectId } = useParams();

  // 2) Convert/parse the floorTypeOptions string to an array we can use in a dropdown
  // If it's already an array, we use it as-is.
  // Otherwise, if it starts with "dropdown-", we parse the JSON after that prefix.
  const parsedFloorTypeOptions = useMemo(() => {
    if (Array.isArray(floorTypeOptions)) {
      // Already good
      return floorTypeOptions;
    }

    // If it's a string, remove the "dropdown-" prefix and parse
    if (typeof floorTypeOptions === 'string' && floorTypeOptions.startsWith('dropdown-')) {
      const rawJson = floorTypeOptions.slice('dropdown-'.length);
      try {
        return JSON.parse(rawJson);
      } catch (err) {
        console.error('Error parsing floorTypeOptions:', err);
        return [];
      }
    }

    // Fallback: if it doesn't match expected format
    return [];
  }, [floorTypeOptions]);

  // 3) Set up react-hook-form
  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      levels: []
    }
  });

  // 4) Query the block data
  const {
    data: blockData,
    isLoading: isBlockLoading,
    isError: isBlockError
  } = useQuery({
    queryKey: ['block', blockId],
    queryFn: () => getBlockByID(blockId),
    enabled: !!blockId
  });

  // 5) Handle loading/error states
  if (isBlockLoading) {
    return <div>Loading...</div>;
  }
  if (isBlockError) {
    return <div>Error loading block data. Please try again.</div>;
  }
  if (!blockData) {
    return <div>No block data found.</div>;
  }

  // 6) Prepare initial levels (read‐only for existing)
  // For each existing level, we store: { name, floor_type, no_of_units }
  const initialLevelsComputed = useMemo(() => {
    if (!blockData?.levels) return [];
    return blockData.levels.map((lvl) => ({
      name: lvl.name,
      floor_type: lvl.floorType,
      no_of_units: lvl.noOfUnits
    }));
  }, [blockData]);

  // 7) Set up the field array for "levels"
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'levels'
  });

  // 8) Load the existing levels into the form
  useEffect(() => {
    if (initialLevelsComputed.length) {
      replace(initialLevelsComputed);
    }
  }, [initialLevelsComputed, replace]);

  // How many are existing (read‐only)
  const initialLevelsCount = initialLevelsComputed.length;

  // 9) On submit → only send newly added levels
  const onSubmit = (formData) => {
    // All fields in data.levels, but we only want the new ones
    const newLevels = formData.levels
      .slice(initialLevelsCount)
      .map((level) => ({
        name: level.name,
        floor_type: level.floor_type,
        no_of_units: parseInt(level.no_of_units, 10) || 0
      }));

    const payload = {
      block_id: blockId,
      project_id: parseInt(projectId, 10),
      levels: newLevels
    };

    handleUpdateLevel(payload);
  };

  // 10) Render
  return (
    <div className="p-4 w-full">
      <h2 className="text-lg font-bold mb-4">Update Levels</h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Display block name, read-only */}
        <div className="mb-6">
          <FormInput
            label="Block"
            type="text"
            disabled
            register={register}
            register_key="blockName"
            valuePre={blockData.name || `Block ${blockId}`}
          />
        </div>

        {/* Render the levels list */}
        <div className="space-y-4">
          {fields.map((field, index) => {
            const isExisting = index < initialLevelsCount;
            return (
              <div key={field.id} className="grid grid-cols-12 gap-4 items-end">
                {/* Level Name */}
                <div className="col-span-4">
                  {isExisting ? (
                    <FormInput
                      label="Level Name"
                      type="text"
                      disabled
                      register={register}
                      register_key={`levels[${index}].name`}
                      valuePre={field.name}
                    />
                  ) : (
                    <FormInput
                      label="Level Name"
                      type="text"
                      register={register}
                      register_key={`levels[${index}].name`}
                      placeholder="Enter level name"
                    />
                  )}
                </div>

                {/* Floor Type */}
                <div className="col-span-4">
                  {isExisting ? (
                    <FormInput
                      label="Floor Type"
                      type="text"
                      disabled
                      register={register}
                      register_key={`levels[${index}].floor_type`}
                      valuePre={field.floor_type}
                    />
                  ) : (
                    <FormInput
                      label="Floor Type"
                      type="dropdown"
                      register={register}
                      register_key={`levels[${index}].floor_type`}
                      options={parsedFloorTypeOptions}
                    />
                  )}
                </div>

                {/* No. of Units */}
                <div className="col-span-3">
                  {isExisting ? (
                    <FormInput
                      label="No of Units"
                      type="number"
                      disabled
                      register={register}
                      register_key={`levels[${index}].no_of_units`}
                      valuePre={String(field.no_of_units)}
                    />
                  ) : (
                    <FormInput
                      label="No of Units"
                      type="number"
                      register={register}
                      register_key={`levels[${index}].no_of_units`}
                      placeholder="Enter number of units"
                    />
                  )}
                </div>

                {/* Remove button for newly appended levels */}
                <div className="col-span-1 flex items-center justify-center">
                  {!isExisting && (
                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      displayType="secondary"
                      customStyle={{ padding: '0.5rem', fontSize: '0.75rem' }}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* Button to Add More Levels */}
        <div className="mt-4">
          <button
            type="button"
            className="text-primary underline"
            onClick={() => {
              // Optionally pre-select the first floor type from parsedFloorTypeOptions
              const defaultFloorType = parsedFloorTypeOptions?.[0]?.value || '';
              append({
                name: '',
                floor_type: defaultFloorType,
                no_of_units: 0
              });
            }}
          >
            Add More Levels
          </button>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpdateLevel;
