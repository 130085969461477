import React, { useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// If you have a toast system
import { toast } from 'react-hot-toast';

// Layout & UI components (adjust paths accordingly)
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Button from '../../shared/components/ui/Button';

// === Import your API functions (adjust paths) ===
import {
  getProjectByID,
  getDynamicFields,
  getAnnexures,
  createTemplate,
} from '../services/api/implementation/impl';

const NewDocumentTemplate = () => {
  const navigate = useNavigate();
  const { id: projectId } = useParams(); // e.g. /projects/:id

  // ====================== 1) Fetch Project (for company_id) ======================
  const {
    data: project,
    isLoading: isLoadingProject,
    isError: isErrorProject
  } = useQuery({
    queryKey: ['project', projectId],
    queryFn: () => getProjectByID(projectId),
    enabled: !!projectId
  });



  // ====================== 2) Fetch Dynamic Fields ======================
  // Returned data looks like:
  // [
  //   { id: 1, column_name: 'CLOSED_DEAL_ID', readable_name: 'Closed Deal Id' },
  //   ...
  // ]
  const {
    data: dynamicFieldsList,
    isLoading: isLoadingDynamicFields,
    isError: isErrorDynamicFields
  } = useQuery({
    queryKey: ['dynamic-fields'],
    queryFn: getDynamicFields // or pass projectId if needed
  });

  // ====================== 3) Fetch Annexures ======================
  // Example:
  // [
  //   { id: 1, column_name: "PROJECT_SPECIFICATION", readable_name: "Project Specification" },
  //   ...
  // ]
  const {
    data: annexureList,
    isLoading: isLoadingAnnexures,
    isError: isErrorAnnexures
  } = useQuery({
    queryKey: ['annexures'],
    queryFn: getAnnexures
  });

  // ====================== Component States ======================
  const [documentName, setDocumentName] = useState('');
  const [description, setDescription] = useState('');
  const [customDetails, setCustomDetails] = useState('');

  // Multi-select states
  const [selectedDynamicFields, setSelectedDynamicFields] = useState([]); // array of { value, label }
  const [selectedAnnexures, setSelectedAnnexures] = useState([]); // array of { value, label }

  // ====================== Build React-Select Options ======================
  //  We'll use `readable_name` as the label, and `column_name` as the value:
  //  e.g. { value: 'CLOSED_DEAL_ID', label: 'Closed Deal Id' }
  const dynamicFieldOptions = useMemo(() => {
    if (!dynamicFieldsList || !Array.isArray(dynamicFieldsList)) return [];
    return dynamicFieldsList.map((field) => ({
      value: field.column_name,
      label: field.readable_name
    }));
  }, [dynamicFieldsList]);

  const annexureOptions = useMemo(() => {
    if (!annexureList || !Array.isArray(annexureList)) return [];
    return annexureList.map((ann) => ({
      value: ann.column_name,
      label: ann.readable_name
    }));
  }, [annexureList]);

  // ====================== 4) Create Template Mutation ======================
  const { mutate: mutateCreateTemplate, isLoading: isCreating } = useMutation({
    mutationFn: (payload) => createTemplate(payload),
    onSuccess: () => {
      toast.success('Template created successfully!');
      navigate(-1); // or wherever you want to go
    },
    onError: (error) => {
      console.error('Error creating template:', error);
      toast.error('Failed to create template');
    }
  });

  // ====================== Submit Handler ======================
  const handleSave = () => {
    // Ensure we have project data to get company_id
    if (!project) {
      toast.error('Project data not loaded yet.');
      return;
    }

    // Build payload with column_name
    const payload = {
      document_name: documentName,
      description: description,
      dynamic_fields: selectedDynamicFields.map((item) => item.value),
      annexure: selectedAnnexures.map((item) => item.value),
      content: customDetails,
      project_id: Number(projectId),
      company_id: Number(project.company_id),
      html_template: ""
    };

    mutateCreateTemplate(payload);
  };

  // ====================== Cancel / Go Back ======================
  const handleCancel = () => {
    navigate(-1);
  };

  // ====================== Render ======================
  const isLoadingAll =
    isLoadingProject || isLoadingDynamicFields || isLoadingAnnexures;
  const isErrorAll = isErrorProject || isErrorDynamicFields || isErrorAnnexures;

  if (isLoadingAll) {
    return <div>Loading ...</div>;
  }
  if (isErrorAll) {
    return <div>Error loading data. Please try again later.</div>;
  }

  return (
    <Layout>
      {/* If you have a custom Header */}
      <div className="fixed top-0 left-[18vw] z-40 w-[82vw]">
        <Header data={{ name: 'Create New Template' }} />
      </div>

      <div className="w-full flex justify-center items-center">
        <div className="w-[95%] flex flex-col absolute top-[6rem] z-10 bg-white p-4">
          {/* Page Header */}
          <div className="flex justify-between items-center mb-4 border-b pb-2">
            <h2 className="text-xl font-semibold">New Document Template</h2>
            <div className="flex gap-2">
              <Button variant="outline" onClick={handleCancel} displayType="secondary">
                Cancel
              </Button>
              <Button onClick={handleSave} disabled={isCreating}>
                {isCreating ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>

          {/* Document Details */}
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <label className="font-semibold">Document Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded mt-1"
                placeholder="Allotment Letter..."
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
              />
            </div>
            <div>
              <label className="font-semibold">Description</label>
              <input
                type="text"
                className="w-full p-2 border rounded mt-1"
                placeholder="Short description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          {/* Dynamic Fields Multi-Select */}
          <div className="mb-6">
            <label className="font-semibold block mb-2">Dynamic Fields</label>
            <Select
              isMulti
              value={selectedDynamicFields}
              onChange={(newVal) => setSelectedDynamicFields(newVal)}
              options={dynamicFieldOptions}
              placeholder="Select dynamic fields..."
              styles={{
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: '#2A565E'
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: '#fff'
                })
              }}
            />
          </div>

          {/* Annexure Multi-Select */}
          <div className="mb-6">
            <label className="font-semibold block mb-2">Annexure (Additional Docs)</label>
            <Select
              isMulti
              value={selectedAnnexures}
              onChange={(newVal) => setSelectedAnnexures(newVal)}
              options={annexureOptions}
              placeholder="Select annexure documents..."
              styles={{
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: '#2A565E'
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: '#fff'
                })
              }}
            />
          </div>

          {/* Rich Text Editor for Template Body */}
          <div className="mb-6">
            <label className="font-semibold block mb-2">Custom Details (Body)</label>
            <ReactQuill
              theme="snow"
              value={customDetails}
              onChange={setCustomDetails}
              placeholder="Type or paste the main body of your template here..."
              className="bg-white"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewDocumentTemplate;
