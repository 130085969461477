import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { uploadImage } from '../../services/api/implementation/impl';
import { multipleUploadFiles, uploadFile } from '../../../builder/features/files';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri';
import Button from './Button';
import { FiUpload } from 'react-icons/fi';
import { useRef } from 'react';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const FormInput = ({
  type,
  options,
  register,
  label,
  width,
  isRequired,
  register_key,
  defaultValuePre,
  valuePre,
  onType,
  maxLength,
  CustomStyle,
  CustomFeature,
  errors,
  minLength,
  placeholder,
  defaultbtn,
  onClick,
  accept,
  uploadFunction,
  uploadFunctionId,
  onDropdownChange,
  maxFileSize,
  prefilledFiles = [],
  autoComplete,
  // -----------------------
  // ADDED readOnly prop:
  readOnly = false
  // -----------------------
}) => {
  const style = { width: `${width}%` };
  const dispatch = useDispatch();

  const [selectedValues, setSelectedValues] = useState(valuePre || []);
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState(prefilledFiles || []);
  const [isEyeClick, setIsEyeClick] = useState(false);

  useEffect(() => {
    if (prefilledFiles.length > 0) {
      setUploadedFiles(prefilledFiles);
    }
  }, [prefilledFiles]);

  useEffect(() => {
    if (type === 'file-secondary') {
      console.log('Current uploadedFiles:', uploadedFiles);
    }
  }, [uploadedFiles, type]);

  const actualMaxFileSize = maxFileSize || MAX_FILE_SIZE;

  const validateFileSize = (file) => {
    if (!file || !file.size) {
      toast.error('Invalid file. Please try uploading again.');
      return false;
    }
    if (file.size > actualMaxFileSize) {
      const sizeInMB = actualMaxFileSize / (1024 * 1024);
      toast.error(`File "${file.name}" is too large. Maximum size is ${sizeInMB}MB.`);
      return false;
    }
    return true;
  };

  const handleFileChange = async (e) => {
    // ---------- Only change: if readOnly => skip ----------
    if (readOnly) return;
    // ------------------------------------------------------
    if (type === 'file-secondary') {
      const newFiles = Array.from(e.target.files).filter(validateFileSize);
      const uniqueNewFiles = newFiles.filter(
        (newFile) => !uploadedFiles.some((existingFile) => existingFile.name === newFile.name)
      );

      if (uniqueNewFiles.length < newFiles.length) {
        toast.warning('Some files were skipped (duplicates or size limits).');
      }
      await uploadMultipleFiles(uniqueNewFiles);
    } else if (type === 'file-btn' || type === 'file-icon-btn') {
      const selectedFile = e.target.files[0];
      if (selectedFile && validateFileSize(selectedFile)) {
        await uploadSingleFile(selectedFile);
      }
    }
  };

  const uploadMultipleFiles = async (files) => {
    if (files.length === 0) return;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    try {
      console.log('Attempting to upload files...');
      const result = await uploadImage(formData);
      const urls = result.data.files?.map((item) => ({
        name: item.originalName,
        url: item.presignedUrl
      }));

      setUploadedFiles((prevFiles) => [...prevFiles, ...urls]);

      dispatch(
        multipleUploadFiles({
          fileType: register_key,
          urls: [...uploadedFiles, ...urls]
        })
      );

      toast.success(`${files.length} file(s) uploaded successfully!`);
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.error('Error uploading files. Please try again.');
    }
  };

  const uploadSingleFile = async (file) => {
    if (!file) return;
    const formData = new FormData();
    formData.append('files', file);

    try {
      if (register_key === 'allotment_signed_doc' || register_key === 'agreement_signed_doc') {
        await uploadFunction(uploadFunctionId, formData);
        toast.success('File uploaded successfully!');
      } else {
        const result = await uploadImage(formData);
        const url = result.data.files?.map((item) => ({
          name: item.originalName,
          url: item.presignedUrl
        }));
        dispatch(
          uploadFile({
            fileType: register_key,
            url
          })
        );
        if (register_key === 'company_logo') {
          toast.success('Logo uploaded successfully!');
        } else {
          toast.success('File uploaded successfully!');
        }
        setUploadedFiles(url);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading file. Please try again.');
    }
  };

  const handleRemoveFile = (index) => {
    if (type === 'file-secondary') {
      const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
      setUploadedFiles(updatedFiles);
      dispatch(
        multipleUploadFiles({
          fileType: register_key,
          urls: updatedFiles
        })
      );
    } else if (type === 'file-btn' || type === 'file-icon-btn') {
      setUploadedFiles([]);
      setFile(null);
      dispatch(
        uploadFile({
          fileType: register_key,
          url: []
        })
      );
    }
  };

  const renderSelectedFiles = () => {
    if (uploadedFiles.length > 0) {
      return (
        <div className="flex flex-col gap-2 mt-2">
          <p className="text-gray-600 text-sm">Uploaded Files:</p>
          {uploadedFiles.map((f, index) => (
            <div key={index} className="flex items-center bg-gray-100 p-1 rounded text-sm">
              <span className="truncate max-w-[200px]">{f.name}</span>
              {
                // ---------- Only remove button if not readOnly ----------
                !readOnly && (
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(index)}
                    className="ml-2 text-red-500 hover:text-red-700">
                    ×
                  </button>
                )
              }
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const handleChangedropdown = (e) => {
    if (e.target.value === 'action-option') {
      onClick && onClick();
    } else {
      console.log('Selected:', e.target.value);
      onDropdownChange && onDropdownChange(e.target.value);
    }
    register(`${register_key}`).onChange(e);
  };

  // ============ HIDDEN ============
  if (type === 'hidden') {
    return (
      <input
        id={register_key}
        type="hidden"
        name={register_key}
        defaultValue={defaultValuePre}
        value={valuePre}
        required={isRequired}
        className={`w-full p-2 border-[1px] border-stone-300 rounded ${valuePre && 'bg-stone-200'}`}
        {...register(register_key)}
        onChange={onType ? (e) => onType(e.target.value) : () => {}}
        // ----------------
        // readOnly logic
        readOnly={readOnly}
        disabled={readOnly}
        // ----------------
      />
    );
  }

  // ============ PASSWORD ============
  if (type === 'password') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-red-500">*</span>}
        </div>
        <div className="relative">
          <input
            id={register_key}
            name={register_key}
            type={isEyeClick ? 'text' : 'password'}
            autoComplete={autoComplete || 'current-password'}
            defaultValue={defaultValuePre}
            value={valuePre}
            required={isRequired}
            className={`w-full p-2 pr-10 border border-gray-300 rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out ${
              readOnly ? 'bg-[#e7e5e4]' : ''
            }`}
            {...register(register_key)}
            onChange={onType ? (e) => onType(e.target.value) : () => {}}
            // ----------------
            // readOnly logic
            readOnly={readOnly}
            disabled={readOnly}
            // ----------------
          />
          {
            // Only show eye icon if not readOnly
            !readOnly && (
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={() => setIsEyeClick(!isEyeClick)}>
                {isEyeClick ? <RiEyeFill size={20} /> : <RiEyeCloseLine size={20} />}
              </button>
            )
          }
        </div>
      </div>
    );
  }

  // ============ DATE ============
  if (type === 'date') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-gray-500">*</span>}
        </div>
        <input
          id={register_key}
          name={register_key}
          type="date"
          required={isRequired}
          defaultValue={defaultValuePre}
          value={valuePre}
          className={`w-full p-2 border-[1px] border-stone-300 rounded ${
            valuePre && 'bg-stone-200'
          } ${readOnly ? 'bg-[#e7e5e4]' : ''}`}
          {...register(register_key)}
          onChange={onType ? (e) => onType(e.target.value) : () => {}}
          max={CustomFeature?.max}
          min={CustomFeature?.min}
          // ----------------
          // readOnly logic
          readOnly={readOnly}
          disabled={readOnly}
          // ----------------
        />
      </div>
    );
  }

  // ============ CUSTOM BuildingPermissonDate ============
  if (type === 'BuildingPermissonDate') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-gray-500">*</span>}
        </div>
        <input
          id={register_key}
          name={register_key}
          type="date"
          required={isRequired}
          defaultValue={defaultValuePre}
          value={valuePre}
          className={`w-full p-2 border-[1px] border-stone-300 rounded ${
            valuePre && 'bg-stone-200'
          } ${readOnly ? 'bg-[#e7e5e4]' : ''}`}
          {...register(register_key)}
          onChange={(e) => {
            onType && onType(e.target.value);
          }}
          max={CustomFeature?.max}
          min={CustomFeature?.min}
          // ----------------
          // readOnly logic
          readOnly={readOnly}
          disabled={readOnly}
          // ----------------
        />
      </div>
    );
  }

  // ============ CHECKBOX ============
  if (type === 'checkbox') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-gray-500">*</span>}
        </div>
        <input
          id={register_key}
          name={register_key}
          type="checkbox"
          required={isRequired}
          defaultValue={defaultValuePre}
          value={valuePre}
          className={`w-full p-2 border-[1px] border-stone-300 rounded ${
            valuePre && 'bg-stone-200'
          } ${readOnly ? 'bg-[#e7e5e4]' : ''}`}
          {...register(register_key)}
          onChange={onType ? (e) => onType(e.target.value) : () => {}}
          // ----------------
          // readOnly logic
          disabled={readOnly}
          // ----------------
        />
      </div>
    );
  }

  // ============ FILE (Basic) ============
  if (type === 'file') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-gray-500">*</span>}
        </div>
        <div className="flex justify-between w-full space-x-4 items-stretch">
          <input
            type="text"
            className="w-[60%] border-2 border-stone-200 rounded-md"
            // readOnly background
            readOnly
            style={readOnly ? { backgroundColor: '#e7e5e4' } : {}}
          />
          <label
            htmlFor={register_key}
            className={`relative flex justify-center items-center gap-2 w-[40%] ${
              readOnly ? 'bg-stone-200 text-gray-400' : 'bg-[#346C75] text-gray-100'
            } font-semibold rounded p-2 cursor-pointer`}>
            <input
              name={register_key}
              type="file"
              id={register_key}
              required={isRequired}
              className="absolute opacity-0 w-full h-full cursor-pointer"
              {...register(register_key)}
              onChange={handleFileChange}
              accept={accept}
              // readOnly logic
              disabled={readOnly}
            />
            {readOnly ? 'File Upload Disabled' : 'Choose File'}
          </label>
        </div>
        {renderSelectedFiles()}
      </div>
    );
  }

  // ============ FILE-BTN ============
  if (type === 'file-btn') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-gray-500">*</span>}
        </div>
        <div className="flex flex-col w-full space-y-2">
          <label
            htmlFor={register_key}
            className={`relative flex justify-center items-center gap-2 w-full bg-transparent font-semibold rounded p-2 cursor-pointer border-[1px] border-[#346C75] text-sm ${
              readOnly ? 'bg-stone-200 text-stone-500' : 'text-[#346C75]'
            }`}>
            <input
              name={register_key}
              type="file"
              id={register_key}
              required={isRequired}
              className="absolute opacity-0 w-full h-full cursor-pointer"
              onChange={handleFileChange}
              accept={accept}
              disabled={readOnly} // readOnly logic
            />
            {readOnly ? 'File Upload Disabled' : 'Choose File'}
          </label>
          {renderSelectedFiles()}
        </div>
      </div>
    );
  }

  //create a type file-icon-btn which should be just an upload icon and rest should be hidden and on click of that icon file should be uploaded
  // ============ FILE-ICON-BTN ============
  const fileInputRef = useRef(null);

  // ============ FILE-ICON-BTN ============
  if (type === 'file-icon-btn') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        {/* <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-gray-500">*</span>}
        </div> */}
        <div className="flex flex-col w-full space-y-2">
          {/* The upload icon button */}
          <Button
            displayType={'downloadButton'}
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
            customStyle={{
              padding: '0.5rem 1rem',
              fontSize: '13px',
              display: 'flex',
              gap: '5px',
              alignItems: 'center'
            }}
            disabled={readOnly}>
            Sign
            <FiUpload size={17} />
          </Button>
          {/* The hidden file input */}
          <input
            name={register_key}
            type="file"
            id={register_key}
            ref={fileInputRef}
            required={isRequired}
            className="hidden"
            onChange={handleFileChange}
            accept={accept}
            disabled={readOnly}
          />
          {renderSelectedFiles()}
        </div>
      </div>
    );
  }

  // ============ FILE-SECONDARY (multiple) ============
  if (type === 'file-secondary') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-gray-500">*</span>}
        </div>
        <div className="flex flex-col w-full space-y-2">
          <label
            htmlFor={register_key}
            className={`flex-grow relative flex justify-center items-center gap-2 border-[1px] border-[#346C75] text-[#346C75] font-semibold rounded p-2 cursor-pointer ${
              readOnly ? 'bg-stone-200 text-stone-500' : ''
            }`}>
            <input
              name={register_key}
              type="file"
              id={register_key}
              required={isRequired}
              multiple
              accept={accept}
              className="absolute opacity-0 w-full h-full cursor-pointer"
              onChange={handleFileChange}
              {...register(register_key, {
                onChange: (e) => handleFileChange(e)
              })}
              disabled={readOnly} // readOnly logic
            />
            {readOnly ? 'File Upload Disabled' : 'Choose Files'}
          </label>
          {renderSelectedFiles()}
        </div>
      </div>
    );
  }

  // ============ DROPDOWN (single) ============
  if (type === 'dropdown') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-600" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-red-500">*</span>}
        </div>
        <select
          name={register_key}
          required={isRequired}
          className={`w-full p-2 border-[1px] border-stone-300 rounded ${
            valuePre && 'bg-stone-200'
          } ${readOnly ? 'bg-[#e7e5e4]' : ''}`}
          {...register(register_key)}
          onChange={handleChangedropdown}
          disabled={readOnly} // readOnly logic
        >
          {valuePre && <option value={valuePre}>{valuePre}</option>}
          {defaultValuePre && <option defaultValue={defaultValuePre}>{defaultValuePre}</option>}
          {options?.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              selected={option.selected}>
              {option.label}
            </option>
          ))}
          {defaultbtn && <option value="action-option">{defaultbtn}</option>}
        </select>
        {errors?.message && <p className="text-red-500">{errors.message}</p>}
      </div>
    );
  }

  // ============ DROPDOWN-MULTIPLE ============
  if (type === 'dropdown-multiple') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-600" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-gray-500">*</span>}
        </div>

        {/* If readOnly => disable the Multiselect */}
        {readOnly ? (
          <div className={`p-2 border border-stone-300 rounded bg-[#e7e5e4]`}>
            {(selectedValues || []).map((item, index) => (
              <span key={index} className="m-1 p-1 bg-gray-400 text-white rounded text-sm">
                {item.name}
              </span>
            ))}
          </div>
        ) : (
          <Multiselect
            options={options} // Options to display in the dropdown
            selectedValues={selectedValues} // Preselected value
            onSelect={(selectedList) => {
              setSelectedValues(selectedList);
              register(register_key, {
                value: selectedList
              });
            }}
            onRemove={(selectedList) => {
              setSelectedValues(selectedList);
              register(register_key, {
                value: selectedList
              });
            }}
            displayValue="name"
            style={{
              optionContainer: {
                border: '1px solid',
                backgroundColor: 'white'
              },
              option: {
                color: 'black',
                backgroundColor: 'white'
              },
              chips: {
                background: '#2A565E',
                color: 'white'
              }
            }}
          />
        )}
      </div>
    );
  }

  // ============ TEXT ON TYPE ============
  if (type === 'textOnType') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-red-500">*</span>}
        </div>
        <input
          name={register_key}
          type="text"
          defaultValue={defaultValuePre}
          value={valuePre}
          required={isRequired}
          className={`w-full p-2 border-[1px] border-stone-300 rounded ${
            valuePre && 'bg-stone-200'
          } ${readOnly ? 'bg-[#e7e5e4]' : ''}`}
          {...register(register_key, {
            required: isRequired ? 'This field is required' : false,
            ...(register_key === 'google_map_link' && {
              pattern: {
                value: /^(https?:\/\/)?(www\.)?google\.com\/maps\/.+$/i,
                message: 'Please enter a valid Google Map link'
              }
            })
          })}
          onChange={onType ? (e) => onType(e) : () => {}}
          maxLength={maxLength}
          style={CustomStyle}
          minLength={minLength}
          placeholder={placeholder}
          // -----------
          // readOnly
          readOnly={readOnly}
          disabled={readOnly}
          // -----------
        />
        {errors && <p className="text-red-500">{errors.message}</p>}
      </div>
    );
  }

  // ============ TEXTAREA ============
  if (type === 'textarea') {
    return (
      <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
        <div className="flex justify-between items-start space-x-2 w-fit">
          <span className="text-gray-600">{label}</span>
          {isRequired && <span className="text-gray-500">*</span>}
        </div>
        <textarea
          id={register_key}
          name={register_key}
          defaultValue={defaultValuePre}
          value={valuePre}
          required={isRequired}
          className={`w-full p-2 border-[1px] border-stone-300 rounded ${
            valuePre && 'bg-stone-200'
          } ${readOnly ? 'bg-[#e7e5e4]' : ''}`}
          {...register(register_key)}
          onChange={onType ? (e) => onType(e.target.value) : () => {}}
          maxLength={maxLength}
          minLength={minLength}
          placeholder={placeholder}
          // ---------
          // readOnly
          readOnly={readOnly}
          disabled={readOnly}
          // ---------
        />
        {errors?.message && <p className="text-red-500">{errors.message}</p>}
      </div>
    );
  }

  // ============ DEFAULT (type="text") ============
  return (
    <div className="flex-shrink-0 flex flex-col gap-2 text-gray-700" style={style}>
      <div className="flex justify-between items-start space-x-2 w-fit">
        <span className="text-gray-600">{label}</span>
        {isRequired && <span className="text-gray-500">*</span>}
      </div>
      <input
        id={register_key}
        name={register_key}
        type="text"
        defaultValue={defaultValuePre}
        value={valuePre}
        autoComplete={autoComplete}
        required={isRequired}
        className={`w-full p-2 border-[1px] border-stone-300 rounded ${
          valuePre && 'bg-stone-200'
        } ${readOnly ? 'bg-[#e7e5e4]' : ''}`}
        {...register(register_key)}
        onChange={onType ? (e) => onType(e.target.value) : () => {}}
        maxLength={maxLength}
        style={CustomStyle}
        minLength={minLength}
        placeholder={placeholder}
        // ----------
        // readOnly
        readOnly={readOnly}
        disabled={readOnly}
        // ----------
      />
      {errors?.message && <p className="text-red-500">{errors.message}</p>}
    </div>
  );
};

export default FormInput;
