import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../../shared/components/ui/Modal';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';

const ZohoIntegration = ({ onClose, onConnect, defaultValues, isSubmitting }) => {
  const { register, handleSubmit, setValue } = useForm();

  const [showClientSecret, setShowClientSecret] = useState(false);

  const toggleClientSecretVisibility = () => {
    setShowClientSecret(!showClientSecret);
  };

  useEffect(() => {
    // Prefill the form with default values
    if (defaultValues) {
      setValue('zoho_client_id', defaultValues.zoho_client_id);
      setValue('zoho_client_secret', defaultValues.zoho_client_secret);
      setValue('zoho_self_code', defaultValues.zoho_self_code);
      setValue('zoho_organization_code', defaultValues.zoho_organization_code);
    }
  }, [defaultValues, setValue]);

  const onSubmit = (data) => {
    console.log('Zoho Integration Data:', data);
    onConnect(data);
  };

  return (
    <Modal isDirty={true} confirmOnClose={true} name="Zoho Integration" onClose={onClose} width={50}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Fields */}
        <div className="grid grid-cols-2 gap-6 mt-5">
          {/* Client ID */}
          <FormInput
            label="Client ID"
            register_key="zoho_client_id"
            register={register}
            isRequired={true}
            type="text"
            placeholder="Enter Client ID"
          />

          {/* Client Secret */}
          <FormInput
            label="Client Secret"
            register_key="zoho_client_secret"
            register={register}
            isRequired={true}
            type={showClientSecret ? 'text' : 'password'}
            placeholder="Enter Client Secret"
          />
          <div className="absolute right-3 top-9 text-gray-400 cursor-pointer">
            <span onClick={toggleClientSecretVisibility}>{showClientSecret ? '🙈' : '👁️'}</span>
          </div>

          {/* Self Code */}
          <FormInput
            label="Self Code"
            register_key="zoho_self_code"
            register={register}
            isRequired={true}
            type="text"
            placeholder="Enter Self Code"
          />

          {/* Organisation Code */}
          <FormInput
            label="Organisation Code"
            register_key="zoho_organization_code"
            register={register}
            isRequired={true}
            type="text"
            placeholder="Enter Organisation Code"
          />
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-10">
          <Button displayType="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Connect
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ZohoIntegration;
