import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import {
  getAllLands,
  getProjectByID,
  getStateAndCity
} from '../../services/api/implementation/impl';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import user from '../../../shared/assets/svg/organisation.svg';
import { toast } from 'react-hot-toast';
import { multipleUploadFiles, uploadFile } from '../../features/files';
import Multiselect from 'multiselect-react-dropdown';

const EditProject = ({ id, onClose, onSubmit, upShift, setUpShift, isSubmitting }) => {
  const dispatch = useDispatch();
  const fileUrls = useSelector((state) => state.file.fileUrls);
  const MultiFiles = useSelector((state) => state.file.multifiles);

  // Local States
  const [registrationDate, setRegistrationDate] = useState('');
  const [permissionDates, setPermissionDates] = useState({});
  const [numBuildings, setNumBuildings] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [developmentType, setDevelopmentType] = useState('');
  const [city, setCity] = useState('Select');
  const [state, setState] = useState('-');
  const [district, setDistrict] = useState('Select');
  const [tehsil, setTehsil] = useState('Select');
  const [cityDropdownOptions, setCityDropdownOptions] = useState([]);
  const [districtDropdownOptions, setDistrictDropdownOptions] = useState([]);
  const [tehsilDropdownOptions, setTehsilDropdownOptions] = useState([]);

  // React Hook Form
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors }
  } = useForm();

  const currentDevelopmentType = watch('development_type');

  // Fetch Project Data
  const { data: project, isLoading: isProjectLoading } = useQuery({
    queryKey: ['project', id],
    queryFn: () => getProjectByID(id),
    enabled: !!id,
    onError: (error) => {
      console.error('Error fetching project:', error);
      setIsLoading(false);
    }
  });

  // Fetch All Lands
  const { data: lands, isLoading: isLandsLoading } = useQuery({
    queryKey: ['lands:-project'],
    queryFn: getAllLands,
    onError: (error) => {
      console.error('Error fetching lands:', error);
    }
  });

  // Helper Functions
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const convertToISODateWithCurrentTime = (dateString) => {
    if (!dateString || dateString.trim() === '') {
      return null;
    }
    const now = new Date();
    const hours = now.getUTCHours().toString().padStart(2, '0');
    const minutes = now.getUTCMinutes().toString().padStart(2, '0');
    const seconds = now.getUTCSeconds().toString().padStart(2, '0');
    const milliseconds = now.getUTCMilliseconds().toString().padStart(3, '0');
    const dateTimeString = `${dateString}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    return new Date(dateTimeString).toISOString();
  };

  const formatFiles = (files) => {
    if (!Array.isArray(files) || files.length === 0) {
      return [];
    }
    return files.map((file) => ({
      name: file.name || 'default_name',
      url: file.url || file
    }));
  };

  // Extract All Khasra Numbers from Land's Khasra Array


  // Load Project Data and Prefill Form
  useEffect(() => {
    if (project) {
      // Basic Project Fields
      setValue('name', project.name);
      setValue('tncp_registration_number', project.tncp_registration_number);
      setValue('tncp_registration_date', formatDateForInput(project.tncp_registration_date));
      setValue('start_date', formatDateForInput(project.start_date));
      setValue('tncp_permission_end_date', formatDateForInput(project.tncp_permission_end_date));
      setValue('project_type', project.project_type);
      setValue('land_id', project.land_id);
      setValue('joint_registered_city', project.joint_registered_city);
      setValue('joint_registered_date', formatDateForInput(project.joint_registered_date));
      setValue('joint_registered_pincode', project.joint_registered_pincode);
      setValue('joint_registered_state', project.joint_registered_state);
      setValue('joint_registration_no', project.joint_registration_no);
      setValue('development_type', project.development_type);
      setValue('price_list_share', project.price_list_share);
      setValue('unit_of_measurement', project.measurement_type);
      setValue('google_location_url', project.google_location_link);

      // Joint Development Fields
      if (project.development_type === 'joint') {
        setValue('joint_registered_city', project.joint_registered_city);
        setValue('joint_registered_date', formatDateForInput(project.joint_registered_date));
        setValue('joint_registered_pincode', project.joint_registered_pincode);
        setValue('joint_registered_state', project.joint_registered_state);
        setValue('joint_registration_no', project.joint_registration_no);
      }

      // Buildings
      if (project.buildings && project.buildings.length > 0) {
        setNumBuildings(project.buildings.length);
        project.buildings.forEach((building, index) => {
          // Building-specific fields
          setValue(`permission_no_${index}`, building.permissionNo);
          setValue(`permission_date_${index}`, formatDateForInput(building.permissionDate));
          setValue(`permission_end_date_${index}`, formatDateForInput(building.permissionEndDate));
          setValue(`rera_registration_no_${index}`, building.rera_registration_no);
          setValue(`rera_approval_date_${index}`, formatDateForInput(building.rera_approval_date));
          setValue(`rera_permission_tenure_${index}`, building.rera_permission_tenure);
          setValue(`site_address_${index}`, building.site_address);
          setValue(`estimated_budget_${index}`, building.estimated_budget);

          // Prefill Khasra Numbers from Building's Khasra Building Mapping
          const selectedKhasras =
            building.khasra_building_mapping?.map((mapping) => ({
              id: mapping.khasra.id,
              name: mapping.khasra.number
            })) || [];
          console.log({ selectedKhasras });
          setValue(`khasra_number_${index}`, selectedKhasras);

          // File-related fields
          if (building.layoutPlans) {
            dispatch(
              multipleUploadFiles({
                fileType: `layout_plan_${index}`,
                urls: building.layoutPlans.map((doc) => ({ name: doc.name, url: doc.url }))
              })
            );
          }
          if (building.developmentOrders) {
            dispatch(
              multipleUploadFiles({
                fileType: `development_order_${index}`,
                urls: building.developmentOrders.map((doc) => ({ name: doc.name, url: doc.url }))
              })
            );
          }
          if (building.uploadLetterheads) {
            dispatch(
              multipleUploadFiles({
                fileType: `upload_letterhead_${index}`,
                urls: building.uploadLetterheads.map((doc) => ({
                  name: doc.name,
                  url: doc.url
                }))
              })
            );
          }
          if (building.googleMapImages) {
            setValue(`google_map_image_${index}`, building.googleMapImages);
          }
          if (building.additionalDocuments) {
            dispatch(
              multipleUploadFiles({
                fileType: `additional_documents_${index}`,
                urls: building.additionalDocuments.map((doc) => ({
                  name: doc.name,
                  url: doc.url
                }))
              })
            );
          }
        });
      }

      // Project-level File Fields
      dispatch(
        multipleUploadFiles({
          fileType: 'project_images',
          urls: project.projectImages.map((doc) => ({ name: doc.name, url: doc.url })) || []
        })
      );
      dispatch(
        multipleUploadFiles({
          fileType: 'google_images',
          urls: project.googleImage.map((doc) => ({ name: doc.name, url: doc.url })) || []
        })
      );
      dispatch(
        uploadFile({
          fileType: 'project_brochure',
          url: project.brochures.map((doc) => ({ name: doc.name, url: doc.url })) || []
        })
      );
      dispatch(
        uploadFile({
          fileType: 'tncp_approved_plan',
          url: project.tncp_approved_plan.map((doc) => ({ name: doc.name, url: doc.url })) || []
        })
      );
      dispatch(
        uploadFile({
          fileType: 'tncp_sanctioned_layout_plan',
          url:
            project.tncp_sanctioned_layout_plan.map((doc) => ({
              name: doc.name,
              url: doc.url
            })) || []
        })
      );
      dispatch(
        uploadFile({
          fileType: 'projectSpecification',
          url: project.projectSpecification.map((doc) => ({
            name: doc.name,
            url: doc.url
          }))
        })
      );

      setIsLoading(false);
    }
  }, [project, setValue, dispatch]);

  const selectedLandId = useWatch({ control, name: 'land_id', defaultValue: project?.land_id });
  const selectedLand = lands?.find((land) => land.id === parseInt(selectedLandId));
  const selectedLandState = selectedLand?.state?.toLowerCase();

  // Use a memo for khasra
  const allKhasraNumbers = React.useMemo(() => {
    if (!selectedLand?.ownerLandMappings) return [];
    const khasraSet = new Set();
    selectedLand.ownerLandMappings.forEach((mapping) => {
      if (mapping?.owner?.khasra?.length) {
        mapping.owner.khasra.forEach((k) => {
          if (k?.id && k?.number) {
            khasraSet.add(JSON.stringify({ id: k.id, name: k.number }));
          }
        });
      }
    });
    return Array.from(khasraSet).map((str) => JSON.parse(str));
  }, [selectedLand]);

  // Event Handlers
  const handleAddBuilding = useCallback(() => {
    setNumBuildings((prev) => prev + 1);
    setUpShift(upShift + 6);
    // Initialize khasra_number for the new building
    setValue(`khasra_number_${numBuildings}`, []);
  }, [setUpShift, upShift, numBuildings, setValue]);

  const handleRemoveBuilding = useCallback(() => {
    setNumBuildings((prev) => prev - 1);
    setUpShift(upShift - 6);
  }, [setUpShift, upShift]);

  const handleDevelopmentType = (value) => {
    setDevelopmentType(value);
    setValue('development_type', value);

    // Clear joint development fields if switching away from joint
    if (value !== 'joint') {
      setValue('joint_registration_no', '');
      setValue('joint_registered_date', '');
      setValue('joint_registered_pincode', '');
      setValue('joint_registered_state', '');
      setValue('joint_registered_city', '');
    }
  };

  const handlePincodeChange = useCallback(
    async (pincode) => {
      const cleanedPincode = pincode.replace(/[^0-9]/g, '');
      setValue('joint_registered_pincode', cleanedPincode);
      if (cleanedPincode.length === 6) {
        try {
          const pincodeList = await getStateAndCity(cleanedPincode);
          const postOfficeList = pincodeList[0]?.PostOffice || [];
          if (postOfficeList.length > 0) {
            setState(postOfficeList[0].State);

            const uniqueCircles = [...new Set(postOfficeList.map((office) => office.Name))];
            const uniqueDistricts = [...new Set(postOfficeList.map((office) => office.District))];
            const uniqueTehsils = [...new Set(postOfficeList.map((office) => office.Block))];

            setCityDropdownOptions(
              uniqueCircles.map((circle) => ({ value: circle, label: circle }))
            );
            setDistrictDropdownOptions(
              uniqueDistricts.map((dist) => ({ value: dist, label: dist }))
            );
            setTehsilDropdownOptions(uniqueTehsils.map((t) => ({ value: t, label: t })));

            setValue('joint_registered_state', postOfficeList[0].State);
            setValue('joint_registered_city', uniqueCircles[0] || 'Select');
            setValue('district', uniqueDistricts[0] || 'Select');
            setValue('tehsil', uniqueTehsils[0] || 'Select');

            setCity(uniqueCircles[0] || 'Select');
            setDistrict(uniqueDistricts[0] || 'Select');
            setTehsil(uniqueTehsils[0] || 'Select');
          } else {
            // If no post office found, reset fields
            setValue('joint_registered_state', '-');
            setValue('joint_registered_city', 'Select');
            setValue('district', 'Select');
            setValue('tehsil', 'Select');

            setState('-');
            setCity('Select');
            setDistrict('Select');
            setTehsil('Select');
            setCityDropdownOptions([]);
          }
        } catch (error) {
          toast.error('The entered pincode is invalid. Please enter a valid pincode.');
          setValue('joint_registered_state', '-');
          setValue('joint_registered_city', 'Select');
          setValue('district', 'Select');
          setValue('tehsil', 'Select');

          setState('-');
          setCity('Select');
          setDistrict('Select');
          setTehsil('Select');
          setCityDropdownOptions([]);
        }
      }
    },
    [setValue]
  );

  // Registration date & building permission date for "next day" logic
  const getNextDay = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate.toISOString().split('T')[0];
  };

  const handleRegistrationDateChange = (date) => {
    const endDate = getNextDay(date);
    setRegistrationDate(endDate);
  };

  const handleBuildingPermissionDateChange = (index, date) => {
    const newDate = getNextDay(date);
    setPermissionDates((prev) => ({
      ...prev,
      [index]: newDate
    }));
  };

  // Prepare Payload for Submission
  const preparePayload = (data) => {
    const newPayload = {
      land_id: parseInt(data?.land_id),
      name: data.name,
      tncp_registration_number: data.tncp_registration_number,
      tncp_registration_date: convertToISODateWithCurrentTime(data.tncp_registration_date),
      start_date: convertToISODateWithCurrentTime(data.start_date),
      tncp_permission_end_date: convertToISODateWithCurrentTime(data.tncp_permission_end_date),
      project_type: data.project_type,
      project_images: formatFiles(MultiFiles.project_images || project?.projectImages || []),
      tncp_approved_plan: formatFiles(
        fileUrls.tncp_approved_plan || project?.tncp_approved_plan || []
      ),
      brochure_files: formatFiles(fileUrls.project_brochure || project?.project_brochure || []),
      google_images: formatFiles(MultiFiles.google_images || []),
      google_location_url: data.google_location_url,
      development_type: data.development_type,
      joint_registered_date: convertToISODateWithCurrentTime(data.joint_registered_date),
      joint_registered_pincode: data.joint_registered_pincode,
      joint_registered_city: data.joint_registered_city,
      joint_registered_state: data.joint_registered_state,
      tncp_sanctioned_layout_plan: formatFiles(
        fileUrls.tncp_sanctioned_layout_plan || project?.tncp_sanctioned_layout_plan || []
      ),
      projectSpecification: formatFiles(
        fileUrls.projectSpecification || project?.projectSpecification || []
      ),
      price_list_share: data.price_list_share,
      measurement_type: data.unit_of_measurement,
      buildings: Array.from({ length: numBuildings }, (_, index) => ({
        permission_no: data[`permission_no_${index}`],
        permission_date: convertToISODateWithCurrentTime(data[`permission_date_${index}`]),
        permission_end_date: convertToISODateWithCurrentTime(data[`permission_end_date_${index}`]),
        layout_plan: formatFiles(
          MultiFiles[`layout_plan_${index}`] || project?.buildings?.[index]?.layoutPlans || []
        ),
        development_order: formatFiles(
          MultiFiles[`development_order_${index}`] ||
            project?.buildings?.[index]?.developmentOrders ||
            []
        ),
        upload_letterhead: formatFiles(
          MultiFiles[`upload_letterhead_${index}`] ||
            project?.buildings?.[index]?.uploadLetterheads ||
            []
        ),
        rera_registration_no: data[`rera_registration_no_${index}`],
        rera_approval_date: convertToISODateWithCurrentTime(data[`rera_approval_date_${index}`]),
        rera_permission_tenure: data[`rera_permission_tenure_${index}`],
        google_map_image: formatFiles(
          fileUrls[`google_map_image_${index}`] ||
            project?.buildings?.[index]?.googleMapImages ||
            []
        ),
        additional_documents: formatFiles(
          MultiFiles[`additional_documents_${index}`] ||
            project?.buildings?.[index]?.additionalDocuments ||
            []
        ),
        site_address: data[`site_address_${index}`],
        estimated_budget: data[`estimated_budget_${index}`],
        khasra_number: data[`khasra_number_${index}`] || []
      }))
    };

    return newPayload;
  };

  const onFormSubmit = (data) => {
    // Example validation (optional)
    // if (new Date(data.tncp_registration_date) <= new Date(data.start_date)) {
    //   toast.error('TNCP Registration Date must be greater than Project Start Date.');
    //   return;
    // }

    const payload = preparePayload(data);
    console.log({ payload });
    onSubmit(payload);
  };

  // Display Loading Indicator if Data is Being Fetched
  useEffect(() => {
    if (!isProjectLoading && !isLandsLoading) {
      setIsLoading(false);
    }
  }, [isProjectLoading, isLandsLoading]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onFormSubmit)}>
      {/* ----------------------- Project Details ----------------------- */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Project Details</span>

        <div className="flex flex-col w-full flex-1 gap-4">
          <div className="flex space-x-8 justify-between items-start w-full">
            {/* Project Image Preview */}
            {MultiFiles.project_images && MultiFiles.project_images[0]?.url ? (
              <img
                src={MultiFiles.project_images[0].url}
                alt="project_images"
                className="w-[10rem] h-[11rem] bg-center bg-cover object-cover object-center rounded-lg"
              />
            ) : (
              <div className="bg-gray-300 rounded-xl w-[10rem] h-[10.8rem] flex justify-center items-center">
                <img
                  src={user}
                  alt="user_image"
                  className="w-[6rem] bg-center bg-cover object-cover object-center"
                />
              </div>
            )}

            <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
              {/* Row 1 */}
              <div className="w-full flex flex-col gap-4 flex-1">
                <div className="flex justify-between space-x-4">
                  <FormInput
                    width={24}
                    register={register}
                    register_key={'land_id'}
                    label={'Land Name'}
                    type={'dropdown'}
                    options={lands?.map((item) => ({
                      value: parseInt(item.id),
                      label: item.name
                    }))}
                    isRequired={true}
                    maxLength={150}
                    minLength={6}
                  />
                  <FormInput
                    width={24}
                    register={register}
                    register_key={'name'}
                    label={'Project Name'}
                    type={'text'}
                    isRequired={true}
                  />
                  <FormInput
                    width={24}
                    register={register}
                    register_key={'tncp_registration_number'}
                    label={'TNCP Registration No'}
                    isRequired={true}
                  />
                  <FormInput
                    width={24}
                    register={register}
                    register_key={'tncp_registration_date'}
                    label={'TNCP Registration Date'}
                    type={'date'}
                    isRequired={true}
                    onType={handleRegistrationDateChange}
                  />
                </div>
              </div>

              {/* Row 2 */}
              <div className="w-full flex justify-between items-center">
                <FormInput
                  width={24}
                  register={register}
                  register_key={'start_date'}
                  label={'Project Start Date'}
                  type={'date'}
                  isRequired={true}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'project_images'}
                  label={'Project Images'}
                  type={'file-secondary'}
                  prefilledFiles={MultiFiles['project_images'] || []}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'tncp_permission_end_date'}
                  label={'TNCP Permission End Date'}
                  type={'date'}
                  isRequired={true}
                  CustomFeature={{
                    min: registrationDate
                  }}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'tncp_approved_plan'}
                  label={'TNCP Permission Document'}
                  type={'file-btn'}
                  prefilledFiles={fileUrls['tncp_approved_plan'] || []}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
              </div>

              {/* Row 3 */}
              <div className="w-full flex justify-between items-center">
                <FormInput
                  width={24}
                  register={register}
                  register_key={'project_type'}
                  label={'Project Type'}
                  type={'dropdown'}
                  isRequired={true}
                  options={[
                    { value: '', label: 'Select' },
                    { value: 'Residential', label: 'Residential' },
                    { value: 'Commercial', label: 'Commercial' },
                    { value: 'Hybrid', label: 'Hybrid' },
                    { value: 'Industrial', label: 'Industrial' }
                  ]}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'project_brochure'}
                  label={'Project Brochure'}
                  type={'file-btn'}
                  prefilledFiles={fileUrls['project_brochure'] || []}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'google_images'}
                  label={'Google Images'}
                  type={'file-secondary'}
                  prefilledFiles={MultiFiles['google_images'] || []}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={`google_location_url`}
                  label={'Google Location URL'}
                  type={'text'}
                />
              </div>

              {/* Row 4 */}
              <div className="w-full flex justify-start gap-4 items-center">
                <FormInput
                  width={24}
                  register={register}
                  register_key={`tncp_sanctioned_layout_plan`}
                  label={'TNCP Sanctioned Layout'}
                  type={'file-btn'}
                  maxFileSize={25 * 1024 * 1024}
                  prefilledFiles={fileUrls['tncp_sanctioned_layout_plan'] || []}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={`projectSpecification`}
                  label={'Project Specification'}
                  type={'file-btn'}
                  maxFileSize={25 * 1024 * 1024}
                  prefilledFiles={fileUrls['projectSpecification'] || []}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'development_type'}
                  label={'Development Type'}
                  type={'dropdown'}
                  options={[
                    { label: 'Select', value: '' },
                    { label: 'Joint', value: 'joint' },
                    { label: 'Company', value: 'company' }
                  ]}
                  onDropdownChange={handleDevelopmentType}
                  isRequired={true}
                />

                {currentDevelopmentType === 'joint' && (
                  <>
                    <div className="w-full flex justify-start gap-4 items-center">
                      <FormInput
                        width={45}
                        register={register}
                        register_key={'joint_registration_no'}
                        label={'Joint Registration No'}
                        type={'text'}
                        isRequired={true}
                      />
                      <FormInput
                        width={48}
                        register={register}
                        register_key={'joint_registered_date'}
                        label={'Joint Registration Date'}
                        type={'date'}
                        isRequired={true}
                      />
                    </div>
                  </>
                )}
              </div>

              {/* Joint Development Fields (Pincode/City) */}
              {currentDevelopmentType === 'joint' && (
                <>
                  <div className="w-full flex justify-start gap-4 items-center">
                    <FormInput
                      width={32}
                      register={register}
                      register_key={'joint_registered_pincode'}
                      label={'Pincode'}
                      type={'text'}
                      maxLength={6}
                      onType={handlePincodeChange}
                    />
                    <FormInput
                      width={32}
                      register={register}
                      isRequired
                      register_key={'joint_registered_state'}
                      label={'State'}
                      errors={errors.state}
                      CustomStyle={{ pointerEvents: 'none' }}
                    />
                    <FormInput
                      width={32}
                      register={register}
                      register_key={'joint_registered_city'}
                      label={'City'}
                      type={'dropdown'}
                      options={cityDropdownOptions}
                      isRequired={true}
                    />
                  </div>
                </>
              )}

              {/* Price List & Unit of Measurement */}
              <div className="w-full flex justify-start gap-4 items-center">
                <FormInput
                  width={24}
                  register={register}
                  register_key={'price_list_share'}
                  label={'Price List'}
                  type={'dropdown'}
                  options={[
                    { label: 'Select', value: '', disabled: true },
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  isRequired={true}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'unit_of_measurement'}
                  label={'Unit of Measurement'}
                  type={'dropdown'}
                  options={[
                    { label: 'Select', value: '', disabled: true },
                    { label: 'sq/ft', value: 'sq/ft' },
                    { label: 'sq/mt', value: 'sq/mt' }
                  ]}
                  isRequired={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------- Buildings Loop ----------------------- */}
      <div className="flex flex-col gap-12">
        {Array.from({ length: numBuildings }, (_, index) => (
          <div
            className={`flex flex-col gap-8 ${
              index < numBuildings - 1 ? 'border-b-[2px]' : ''
            } border-b-gray-200 pb-8`}
            key={index}>
            {/* {numBuildings > 1 && index > 0 && (
              <div className="w-full flex justify-end pb-2 mt-[-1.6rem]">
                <span
                  className="text-stone-700 underline underline-offset-2 transition-all duration-300 hover:scale-[102%] cursor-pointer"
                  onClick={handleRemoveBuilding}>
                  - Remove building details
                </span>
              </div>
            )} */}

            {/* Building Permission Details */}
            <div className="flex flex-col justify-between items-start gap-4">
              <div className="w-full flex flex-col gap-4">
                <div className="flex justify-between space-x-4">
                  <FormInput
                    width={32}
                    register={register}
                    register_key={`permission_no_${index}`}
                    label={'Building Permission No'}
                    type={'text'}
                    isRequired={true}
                  />
                  <FormInput
                    width={32}
                    register={register}
                    register_key={`permission_date_${index}`}
                    type={'date'}
                    isRequired={true}
                    label={'Building Permission Date'}
                    onType={(date) => handleBuildingPermissionDateChange(index, date)}
                  />
                  <FormInput
                    width={30}
                    register={register}
                    register_key={`permission_end_date_${index}`}
                    label={'Building Permission End Date'}
                    type={'date'}
                    isRequired={true}
                    CustomFeature={{
                      min: permissionDates[index] || ''
                    }}
                  />
                </div>

                <div className="flex justify-between space-x-4">
                  <FormInput
                    width={32}
                    register={register}
                    register_key={`layout_plan_${index}`}
                    label={`${
                      selectedLandState === 'karnataka' ? 'Layout Plan' : 'Building Layout Plan'
                    }`}
                    type={'file-secondary'}
                    prefilledFiles={MultiFiles[`layout_plan_${index}`] || []}
                    accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                  />
                  <FormInput
                    width={32}
                    register={register}
                    register_key={`development_order_${index}`}
                    label={'Development Order'}
                    type={'file-secondary'}
                    prefilledFiles={MultiFiles[`development_order_${index}`] || []}
                    accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                  />
                  <FormInput
                    width={30}
                    register={register}
                    register_key={`upload_letterhead_${index}`}
                    label={'Upload Letterhead'}
                    type={'file-secondary'}
                    prefilledFiles={MultiFiles[`upload_letterhead_${index}`] || []}
                    accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                  />
                </div>
              </div>
            </div>

            {/* RERA Details */}
            <div className="flex flex-col justify-between items-start gap-4">
              <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
                RERA Details
              </span>

              <div className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-col gap-4">
                  <div className="flex justify-between space-x-4">
                    <FormInput
                      width={32}
                      register={register}
                      register_key={`rera_registration_no_${index}`}
                      label={'RERA Registration No'}
                      type={'text'}
                      isRequired={true}
                    />
                    <FormInput
                      width={32}
                      register={register}
                      register_key={`rera_approval_date_${index}`}
                      label={'RERA Approval Date'}
                      type={'date'}
                      isRequired={true}
                    />
                    <div className="relative w-[32%]">
                      <FormInput
                        width={100}
                        register={register}
                        register_key={`rera_permission_tenure_${index}`}
                        label={'RERA Permission Tenure'}
                        type={'text'}
                        isRequired={true}
                      />
                      <span className="p-1 px-2 absolute right-2 top-[52%] bg-gray-100 rounded-md text-sm text-gray-600">
                        Years
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-between space-x-4">
                    <FormInput
                      width={32}
                      register={register}
                      register_key={`additional_documents_${index}`}
                      label={'Additional Documents'}
                      type={'file-secondary'}
                      prefilledFiles={MultiFiles[`additional_documents_${index}`] || []}
                      accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                    />

                    {/* Khasra MultiSelect */}
                    <Controller
                      name={`khasra_number_${index}`}
                      control={control}
                      rules={{ required: 'At least one Khasra number is required' }}
                      render={({ field }) => (
                        <div className="w-[30rem] mt-2">
                          <label className="block text-sm font-medium text-gray-700">
                            {selectedLandState === 'karnataka'
                              ? 'Survey Numbers'
                              : 'Khasra Numbers'}
                          </label>
                          <Multiselect
                            options={allKhasraNumbers} // All possible Khasra from land
                            selectedValues={field.value} // Building's selected Khasra
                            onSelect={(selectedList) => field.onChange(selectedList)}
                            onRemove={(selectedList) => field.onChange(selectedList)}
                            displayValue="name"
                            placeholder="Select Khasra"
                            style={{
                              optionContainer: {
                                border: '1px solid #ccc',
                                backgroundColor: 'white',
                                maxHeight: '200px',
                                overflowY: 'auto'
                              },
                              option: {
                                color: 'black',
                                backgroundColor: 'white'
                              },
                              chips: {
                                background: '#2A565E',
                                color: 'white'
                              },
                              searchBox: {
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '5px'
                              }
                            }}
                          />
                          {errors[`khasra_number_${index}`] && (
                            <span className="text-red-500 text-sm">
                              {errors[`khasra_number_${index}`].message}
                            </span>
                          )}
                        </div>
                      )}
                    />

                    <FormInput
                      width={32}
                      register={register}
                      register_key={`site_address_${index}`}
                      label={'Site Address'}
                      type={'text'}
                      isRequired={true}
                    />
                  </div>

                  <div className="flex gap-6">
                    <div className="relative w-[32%]">
                      <FormInput
                        width={100}
                        register={register}
                        register_key={`estimated_budget_${index}`}
                        label={'Estimated Budget (₹)'}
                        type={'text'}
                        isRequired={true}
                      />
                      <span className="p-1 px-2 absolute right-2 top-[52%] bg-gray-100 rounded-md text-sm text-gray-600">
                        Lakhs
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* + Add more Building Button */}
        <div className="w-full flex justify-end border-t-[1px] border-stone-200 pb-2 mt-4">
          <span
            className="text-stone-700 underline underline-offset-2 transition-all duration-300 hover:scale-[102%] cursor-pointer mt-4"
            onClick={handleAddBuilding}>
            + Add more building details
          </span>
        </div>
      </div>

      {/* Footer Buttons */}
      <div className="w-full flex justify-end items-center space-x-4">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </form>
  );
};

export default EditProject;
