// components/PendingBills.jsx
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import Papa from 'papaparse'; // Import papaparse for CSV generation

import Table from '../../shared/components/layout/Table';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Search from '../../shared/components/ui/Search';
import FileDownload from '../../../modules/shared/assets/svg/download.svg';
import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';
import { transformData } from '../../super-admin/utils/transformData';
import {
  getAccountPendingBillsReports,
  updateOrdersStatus
} from '../services/api/implementation/impl';
import Button from '../../shared/components/ui/Button';
import Modal from '../../shared/components/ui/Modal';
import { toast } from 'react-hot-toast';

const PendingBills = () => {
  /*
   * -------------------------------------------------
   * Component State
   * -------------------------------------------------
   */
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Items per page
  const [isDownloading, setIsDownloading] = useState(false); // State for download status
  const [dotCount, setDotCount] = useState(0); // State for dots animation
  const [openSetStatusModal, setOpenSetStatusModal] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState(null); // To track which bill is being updated

  /*
   * -------------------------------------------------
   * Fetching Pending Bills Reports from the API (Server-Side Pagination)
   * -------------------------------------------------
   */
  const {
    data: pendingBillsResponse,
    isFetching: isFetchingPendingBills,
    refetch: refetchPendingBills,
    isError,
    error
  } = useQuery({
    queryKey: ['pendingBillsReports', page, limit],
    queryFn: () => getAccountPendingBillsReports(page, limit),
    keepPreviousData: true
  });

  // If your API returns { data: [...], meta: {...} }
  const allPendingBills = pendingBillsResponse?.data || [];
  const totalItems = pendingBillsResponse?.meta?.total || 0;
  const totalPages = Math.ceil(totalItems / limit);

  /*
   * -------------------------------------------------
   * Transform the Data for Table
   * -------------------------------------------------
   */
  const pendingBillsDataFormat = ['id', 'VendorName', 'BillAmount', 'TDS', 'Status', 'actions'];

  const processedPendingBills = allPendingBills.map((bill) => ({
    id: bill.id || 0,
    VendorName: bill.vendor?.name || 'N/A',
    BillAmount:
      bill.total_amount !== undefined ? `₹ ${bill.total_amount}` : '₹ 0',
    TDS: bill.tds !== undefined ? `₹ ${bill.tds}` : '₹ 0',
    Status: bill.paid || 'N/A'
  }));

  const pendingBillsData = processedPendingBills.map((row) =>
    transformData(row, pendingBillsDataFormat)
  );

  /*
   * -------------------------------------------------
   * Handlers
   * -------------------------------------------------
   */
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleToggleStatusModal = (billId) => {
    console.log('Update status for ID:', billId);
    setSelectedBillId(billId);
    setOpenSetStatusModal(!openSetStatusModal);
  };

  const { mutate: handleStatusUpdateFn } = useMutation({
    mutationFn: (data) => updateOrdersStatus(parseInt(selectedBillId), data),
    onSuccess: (res) => {
      toast.success('Status Updated Successfully');
      setOpenSetStatusModal(false);
      refetchPendingBills();
    },
    onError: (err) => {
      toast.error('Error in Status Update');
    }
  });

  const handleConfirmStatusUpdate = () => {
    handleStatusUpdateFn({ status: 'Payment Done' });
  };

  /**
   * Handle Download CSV using papaparse
   */
  const handleDownload = async () => {
    if (isDownloading) return; // Prevent multiple clicks

    setIsDownloading(true);
    try {
      // Fetch all data with page=1 and limit=1000
      const response = await getAccountPendingBillsReports(1, 1000);
      const pendingBills = response?.data || [];

      if (pendingBills.length === 0) {
        toast.error('No data available to download.');
        return;
      }

      // Define CSV headers
      const headers = ['Vendor Name', 'Bill Amount', 'TDS', 'Status'];

      // Map data to CSV rows as objects without the currency symbol
      const rows = pendingBills.map((bill) => ({
        'Vendor Name': bill.vendor?.name || 'N/A',
        'Bill Amount': bill.total_amount !== undefined ? bill.total_amount : 0,
        'TDS': bill.tds !== undefined ? bill.tds : 0,
        'Status': bill.paid || 'N/A',
      }));

      // Convert to CSV using papaparse
      const csv = Papa.unparse({
        fields: headers,
        data: rows,
      });

      // Create a Blob from the CSV content
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', `pending_bills_reports.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (downloadError) {
      console.error('Download failed:', downloadError);
      toast.error('Failed to download the file. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  /*
   * -------------------------------------------------
   * Dots Animation Effect
   * -------------------------------------------------
   */
  useEffect(() => {
    let interval;
    if (isDownloading) {
      interval = setInterval(() => {
        setDotCount((prev) => (prev < 3 ? prev + 1 : 0));
      }, 500);
    } else {
      setDotCount(0);
    }

    return () => clearInterval(interval);
  }, [isDownloading]);

  const renderDownloadingText = () => {
    return `Downloading${'.'.repeat(dotCount)}`;
  };

  /*
   * -------------------------------------------------
   * Render
   * -------------------------------------------------
   */
  return (
    <div className="w-full flex flex-col relative z-5 bg-gray-100 pb-10 overflow-hidden">
      {/* Status Update Modal */}
      {openSetStatusModal && (
        <Modal isDirty={true} confirmOnClose={true} width={30} name="Update Status" onClose={() => setOpenSetStatusModal(false)}>
          <div className="flex justify-center flex-col items-center">
            <h1 className="font-bold text-lg border-b w-full text-center pb-2 text-[#333333]">
              Payment Status
            </h1>
            <p className="font-bold w-full text-center pt-7 text-[#666666]">
              Do you want to mark this bill as Paid?
            </p>
            <div className="flex justify-end gap-4 p-6">
              <Button displayType="secondary" onClick={() => setOpenSetStatusModal(false)}>
                No
              </Button>
              <Button onClick={handleConfirmStatusUpdate}>Yes</Button>
            </div>
          </div>
        </Modal>
      )}

      {/* Top Bar: Search, Download */}
      <div className="flex items-center justify-between px-4 py-4 bg-white fixed top-[9.5rem] border-t-2 border-stone-100 left-[18vw] w-[82vw] shadow-sm">
        {/* Uncomment the Search component if needed */}
        {/* <Search placeholder="Search here..." /> */}
        <div></div>
        <div className="flex items-center gap-3 px-4">
          <button
            className={`relative flex items-center justify-center ${
              isDownloading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
            onClick={handleDownload}
            disabled={isDownloading}
            aria-label="Download CSV"
          >
            <img
              height={35}
              width={35}
              src={FileDownload}
              alt="File Download Icon"
            />
            {isDownloading && (
              <span className="absolute top-10 left-0 text-sm text-gray-700">
                {renderDownloadingText()}
              </span>
            )}
          </button>
        </div>
      </div>

      {/* Pending Bills Reports Table */}
      <div className="mt-24 w-full h-full">
        {isFetchingPendingBills ? (
          <div className="pt-12 text-center">
            <p>Loading...</p>
          </div>
        ) : isError ? (
          <div className="pt-12 text-center text-red-500">
            <p>Error: {error.message}</p>
          </div>
        ) : pendingBillsData && pendingBillsData.length > 0 ? (
          <GridWrapper width={100}>
            <div className="mt-[10rem] h-[28rem] relative">
              <Table
                headers={['Vendor Name', 'Bill Amount', 'TDS', 'Status', 'Action']}
                data={pendingBillsData}
                identifier="id"
                isChangeStatusEnable={true}
                onChangeStatus={(id) => handleToggleStatusModal(id)}
                isViewEnable={false}
                isDeleteEnable={false}
                isEditEnable={false}
              />

              <PaginationComponent
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                isFetching={isFetchingPendingBills}
              />
            </div>
          </GridWrapper>
        ) : (
          <div className="pt-12">
            <NoDataFound disabledBtn screenHeight={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingBills;
