import React, { useEffect, useState } from 'react';
import Table from '../../shared/components/layout/Table';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Header from '../../shared/components/layout/Header';
import Search from '../../shared/components/ui/Search';
import { transformData } from '../../super-admin/utils/transformData';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import Modal from '../../shared/components/ui/Modal';
import { CreateRateList } from '../components/forms/CreateRateList';
import { CreatePLCRateList } from '../components/forms/CreatePLCRateList';
import { get, set, useForm } from 'react-hook-form';
import EditPricing from '../components/forms/EditPricing';
import Cookies from 'js-cookie';
import {
  getAllPricingByProjectId,
  getSinglePricingById,
  getPlc,
  editPricing
} from '../services/api/implementation/impl';
import toast from 'react-hot-toast';
import FormInput from '../../shared/components/ui/FormInput';

export const RateList = ({ projectName }) => {
  const { id: projectId } = useParams();
  const { register } = useForm();

  const rawMeasurementUnit = Cookies.get('measurement_type');
  const measurementType =
    !rawMeasurementUnit || rawMeasurementUnit === 'null' || rawMeasurementUnit === 'undefined'
      ? 'sq/ft'
      : rawMeasurementUnit;
  const measurementTypeWithoutSlash = measurementType.replace('/', '');

  // 1. Local state for selected accommodation type, default = "GROUP"
  const [selectedAccommodationType, setSelectedAccommodationType] = useState('GROUP');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [unitPricingList, setUnitPricingList] = useState([]);
  const [accommodationPricingList, setAccommodationPricingList] = useState([]);
  const [allPricingList, setAllPricingList] = useState([]);
  const [currentPriceingId, setCurrentPriceingId] = useState(null);

  const [isOpenRateList, setIsOpenRateList] = useState(false);
  const [selectedTableType, setSelectedTableType] = useState('');
  const [isEditingRateList, setIsEditingRateList] = useState(false);

  const [blockDropdownOptions, setBlockDropdownOptions] = useState([]);
  const [selectedBlockId, setSelectedBlockId] = useState('');
  const [currentBlockWithPLC, setCurrentBlockWithPLC] = useState([]);
  const [selectedBlockName, setSelectedBlockName] = useState('');

  const [isPricingEmpty, setIsPricingEmpty] = useState(false);
  const [isOpenPLCRateList, setIsOpenPLCRateList] = useState(false);
  const [pricingType, setPricingType] = useState('all');

  // ------------------ Query Calls ------------------
  const { data: getAllPricingList, refetch: refetchAllPricingList } = useQuery({
    queryKey: ['getAllPricingList', projectId],
    queryFn: () => getAllPricingByProjectId(projectId),
    enabled: !!projectId
  });

  const { data: pricingData, refetch: refetchPricingData } = useQuery({
    queryKey: ['pricingData', currentPriceingId],
    queryFn: () => getSinglePricingById(parseInt(currentPriceingId), projectId),
    enabled: !!currentPriceingId
  });

  const { data: PLCByProjectId, refetch: refetchPLCProject } = useQuery({
    queryKey: ['PLCByProjectId'],
    queryFn: () => getPlc(),
    enabled: !!projectId
  });

  // ------------------ Effects ------------------
  useEffect(() => {
    if (getAllPricingList && getAllPricingList.length === 0) {
      setIsPricingEmpty(true);
    } else {
      setIsPricingEmpty(false);
    }
  }, [getAllPricingList]);

  useEffect(() => {
    // If we have no pricing records => 'all'
    // Otherwise, set based on the mode of the first record
    if (isPricingEmpty) {
      setPricingType('all');
    } else if (getAllPricingList && getAllPricingList.length > 0) {
      setPricingType(getAllPricingList[0].mode);
      setSelectedTableType(getAllPricingList[0].mode);
    }
  }, [isPricingEmpty, getAllPricingList, refetchAllPricingList]);

  // **Filter** pricing data by selectedAccommodationType when it changes (or pricing data changes)
  useEffect(() => {
    if (getAllPricingList) {
      // 2. Filter to keep only items matching the selectedAccommodationType
      const filteredPricingList = getAllPricingList.filter((item) => {
        return (
          item.accommodation?.accomodationType === selectedAccommodationType.toUpperCase() ||
          // If your backend field is spelled differently or has a different shape,
          // adjust the condition accordingly. E.g. item.accommodation.accommodationType
          false
        );
      });

      // Now from the filtered list, separate 'accommodation' mode vs 'sqft' mode
      const accommodationData = filteredPricingList.filter((item) => item.mode === 'accommodation');
      const sqftData = filteredPricingList.filter((item) => item.mode === 'sqft');
      console.log({ accommodationData, sqftData });

      // Format for table
      setUnitPricingList(
        sqftData.map((el) =>
          transformData(handleUnit(el, selectedAccommodationType), unitRateListFormat)
        )
      );
      setAccommodationPricingList(
        accommodationData.map((el) =>
          transformData(handleAcc(el, selectedAccommodationType), accRatelistFormat)
        )
      );
      // If you also need all:
      setAllPricingList(
        filteredPricingList.map((el) =>
          transformData(handleUnit(el, selectedAccommodationType), allRateListFormat)
        )
      );
    }
  }, [getAllPricingList, selectedAccommodationType]);

  useEffect(() => {
    refetchAllPricingList();
  }, [isOpenRateList]);

  useEffect(() => {
    if (currentPriceingId) {
      refetchPricingData();
    }
  }, [currentPriceingId]);

  // --------------- PLC / Blocks Setup ---------------
  useEffect(() => {
    if (PLCByProjectId && PLCByProjectId.length > 0) {
      const filteredPLCs = PLCByProjectId.filter((plc) =>
        plc.plc_block_mapping.some((block) => block.block.projectId === parseInt(projectId))
      );

      const blocks = filteredPLCs.flatMap((plc) => plc.plc_block_mapping);
      const uniqueBlocks = Array.from(new Set(blocks?.map((block) => block.block_id)))?.map((id) =>
        blocks.find((block) => block.block_id === id)
      );

      const options = uniqueBlocks?.map((item) => ({
        value: item.block.id,
        label: item.block.name
      }));
      setBlockDropdownOptions(options);

      if (options.length > 0) {
        setSelectedBlockId(options[0].value);
      }
    }
  }, [PLCByProjectId, projectId]);

  useEffect(() => {
    if (PLCByProjectId && selectedBlockId) {
      const filteredBlock = PLCByProjectId.flatMap((plc) =>
        plc.plc_block_mapping.filter((block) => block.block.id === parseInt(selectedBlockId))
      );

      if (filteredBlock.length > 0) {
        const block = filteredBlock[0];
        const blockName = block.name;

        const PLCList = PLCByProjectId.flatMap((plc) => {
          return plc.plc_block_mapping
            .filter((blockMapping) => blockMapping.block.id === parseInt(selectedBlockId))
            .map((blockMapping) => ({
              id: plc.id,
              name: plc.name,
              rate: plc.price || null
            }));
        });

        const uniqueData = Object.values(
          PLCList.reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          }, {})
        );

        const transformedPLCList = uniqueData.map((item) =>
          transformData(item, ['name', 'rate', 'id'])
        );

        setCurrentBlockWithPLC(transformedPLCList);
        setSelectedBlockName(blockName);
      }
    }
  }, [selectedBlockId, PLCByProjectId]);

  // ------------------ Handlers ------------------
  const formattedNumberToIndian = (number) => {
    return new Intl.NumberFormat('en-IN').format(number);
  };

  const handleAcc = (item, selectedAccommodationType) => {
    if (selectedAccommodationType === 'INDEPENDENT') {
      // Return separate plot_price, construction_price
      return {
        id: item.id,
        accommodation: item.accommodation.name,
        plote_price: `₹${formattedNumberToIndian(item.plote_price)}`,
        construction_price: `₹${formattedNumberToIndian(item.construction_price)}`
        // No loading factor for "accommodation" mode, if that's how your data works
      };
    } else {
      // GROUP => show a single "price"
      return {
        id: item.id,
        accommodation: item.accommodation.name,
        price: `₹${formattedNumberToIndian(item.price)}`
      };
    }
  };

  // handleUnit
  const handleUnit = (item, selectedAccommodationType) => {
    if (selectedAccommodationType === 'INDEPENDENT') {
      // Return plot_price, construction_price, plus loading_factor
      return {
        id: item.id,
        accommodation: item.accommodation.name,
        plote_price: `₹${formattedNumberToIndian(item.plote_price)}`,
        construction_price: `₹${formattedNumberToIndian(item.construction_price)}`,
        loading_factor: item.loading_factor
      };
    } else {
      // GROUP => show a single "price" plus loading_factor
      return {
        id: item.id,
        accommodation: item.accommodation.name,
        price: `₹${formattedNumberToIndian(item.price)}`,
        loading_factor: item.loading_factor
      };
    }
  };

  const unitRateListFormat = [
    'accommodation',
    'price',
    'plote_price',
    'construction_price',
    'loading_factor',
    'actions',
    'id'
  ];
  const accRatelistFormat = [
    'accommodation',
    'price',
    'plote_price',
    'construction_price',
    'loading_factor',
    'actions',
    'id'
  ];
  const allRateListFormat = [
    'accommodation',
    'price',
    'plote_price',
    'construction_price',
    'loading_factor',
    'actions',
    'id'
  ];

  const { mutate: editPricingData } = useMutation({
    mutationFn: (payload) => editPricing(currentPriceingId, payload),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: () => {
      setIsSubmitting(false);
      setCurrentPriceingId(null);
      setIsEditingRateList(false);
      refetchAllPricingList();
      toast.success('Pricing edited successfully');
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error editing pricing', err);
      toast.error('Error editing pricing', err);
    }
  });

  const pricingEditHandler = (data) => {
    if (Object.prototype.hasOwnProperty.call(data, 'rate')) {
      data.rate = parseInt(data.rate);
    }
    setIsSubmitting(true);
    editPricingData(data);
  };

  const onEditClick = (id) => {
    setCurrentPriceingId(id);
    setIsEditingRateList(true);
  };

  const handleRateList = () => {
    setIsOpenRateList(!isOpenRateList);
  };

  const handleTableTypeChange = (e) => {
    setSelectedTableType(e.target.value);
  };

  const handleBlockNameChange = (e) => {
    setSelectedBlockId(e.target.value);
  };

  // 3. Handle changes for the “Select Accommodation Type” dropdown
  const handleAccommodationTypeChange = (e) => {
    // This will re-trigger the useEffect that filters getAllPricingList
    setSelectedAccommodationType(e.target.value);
  };

  const onPlCUpdateSuccess = () => {
    setIsOpenPLCRateList(false);
    refetchPLCProject();
  };

  const onCreateSuccess = () => {
    setIsOpenRateList(false);
    refetchAllPricingList(projectId);
  };

  let tableHeadersSqFt = [];
  if (selectedAccommodationType === 'INDEPENDENT') {
    tableHeadersSqFt = [
      'Accommodation Name',
      'Plot Price',
      'Construction Price',
      'Loading factor',
      'Action'
    ];
  } else {
    // GROUP
    tableHeadersSqFt = ['Accommodation Name', 'Rates', 'Loading factor', 'Action'];
  }

  let tableHeadersAccommodation = [];
  if (selectedAccommodationType === 'INDEPENDENT') {
    tableHeadersAccommodation = [
      'Accommodation Name',
      'Plot Price',
      'Construction Price',
      'Action'
    ];
  } else {
    // GROUP
    tableHeadersAccommodation = ['Accommodation Name', 'Rates', 'Action'];
  }

  return (
    <div className="w-full relative">
      {isOpenRateList && (
        <Modal isDirty={true} confirmOnClose={true} name={'Create Rate List'} onClose={() => setIsOpenRateList(false)} width={80}>
          <CreateRateList
            projectName={projectName}
            createSuccess={onCreateSuccess}
            pricingType={pricingType}
            onClose={() => setIsOpenRateList(false)}
          />
        </Modal>
      )}
      {isOpenPLCRateList && (
        <Modal isDirty={true} confirmOnClose={true} name={'Create PLC Rate List'} onClose={() => setIsOpenPLCRateList(false)} width={80}>
          <CreatePLCRateList
            projectName={projectName}
            createSuccess={onPlCUpdateSuccess}
            plcList={currentBlockWithPLC}
            blockName={selectedBlockName}
            selectedBlockId={selectedBlockId}
            onClose={() => setIsOpenPLCRateList(false)}
          />
        </Modal>
      )}
      {isEditingRateList && (
        <Modal isDirty={true} confirmOnClose={true}
          name={'Edit Rate List'}
          onClose={() => {
            setIsEditingRateList(false);
            setCurrentPriceingId(null);
          }}
          width={80}>
          <EditPricing
            isSubmitting={isSubmitting}
            handleEdit={pricingEditHandler}
            selectedTableType={selectedTableType}
            singlePricingData={pricingData}
            selectedAccommodationType={selectedAccommodationType}
            pricingType={pricingType}
            onClose={() => {
              setIsEditingRateList(false);
            }}
          />
        </Modal>
      )}

      {/* Header */}
      <div className="fixed top-0 z-10 w-full">
        <Header data={{ name: 'Master Rates' }} />
      </div>

      {/* Main content */}
      <div className="flex z-10 items-center justify-between px-6 py-4 bg-white fixed top-[15rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
        <Search />
        <div className="flex gap-5 cursor-pointer">
          <Button onClick={handleRateList}>Create</Button>
        </div>
      </div>

      {/* Grid */}
      <GridWrapper width={100}>
        <div className="mt-[12rem] h-[48rem] absolute top-[8rem] left-0 w-full px-6 overflow-clip bg-gray-100">
          <div className={`flex-shrink-0 flex gap-2 text-gray-600 mb-8`} style={{ width: '40%' }}>
            {/* Pricing Type (read-only) */}
            <FormInput
              width={50}
              register={register}
              register_key={'pricingType'}
              label={'Pricing Type'}
              type={'text'}
              valuePre={
                measurementTypeWithoutSlash === 'sqmt' && pricingType && pricingType !== 'all'
                  ? 'sqmt'
                  : pricingType && pricingType !== 'all'
                    ? pricingType
                    : ''
              }
              disabled
            />

            {/* 4. Accommodation Type (GROUP / INDEPENDENT) */}

            <div className="flex flex-col gap-2 w-[50%]">
              <label htmlFor="accommodation_type" className="text-[16px] font-[400] text-gray-600">
                Select Accommodation Type
              </label>
              <select
                id="accommodation_type"
                value={selectedAccommodationType}
                onChange={handleAccommodationTypeChange}
                className="border border-gray-300 rounded p-2 focus:outline-none">
                <option value="GROUP">GROUP</option>
                <option value="INDEPENDENT">INDEPENDENT</option>
              </select>
            </div>
          </div>

          {/* Tables */}
          {pricingType === 'sqft' && (
            <div className="flex flex-col gap-10 hide-scrollbar">
              <div className="overflow-y-auto hide-scrollbar h-[16rem]">
                <Table
                  headers={tableHeadersSqFt}
                  data={unitPricingList}
                  onEdit={(id) => onEditClick(id)}
                  identifier={'id'}
                />
              </div>
            </div>
          )}

          {/* If "all", or "accommodation", or anything not 'sqft' => show accommodationPricingList */}
          {(pricingType === 'accommodation' || pricingType !== 'sqft') && (
            <div className="flex flex-col gap-10 hide-scrollbar">
              <div className="overflow-y-auto hide-scrollbar h-[16rem]">
                <Table
                  headers={tableHeadersAccommodation}
                  data={accommodationPricingList}
                  onEdit={(id) => onEditClick(id)}
                  identifier={'id'}
                />
              </div>
            </div>
          )}

          {/* PLC & block section */}
          <div className="flex justify-between items-center">
            <div
              className={`flex-shrink-0 flex flex-col gap-2 text-gray-600 mb-8 mt-5`}
              style={{ width: '20%' }}>
              <div className="flex justify-between items-start space-x-2 w-fit">
                <span className="text-gray-600">{`Select Block`}</span>
              </div>
              <select
                className={`w-full p-2 border-[1px] border-stone-300 rounded `}
                {...register(`block_name`)}
                value={selectedBlockId}
                onChange={handleBlockNameChange}>
                {blockDropdownOptions?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <Button onClick={() => setIsOpenPLCRateList(true)}>Update Pricing</Button>
            </div>
          </div>

          <div className="overflow-y-scroll hide-scrollbar h-[16rem]">
            <Table headers={['PLC', 'Rate']} data={currentBlockWithPLC} identifier={'id'} />
          </div>
        </div>
      </GridWrapper>
    </div>
  );
};
