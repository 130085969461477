import React, { useEffect, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import {
  getBlockByID,
  getAllAccommodationsByProject
} from '../../services/api/implementation/impl';

const UpdateUnit = ({
  blockId,
  levelId,
  activeLevelType,
  onClose,
  handleUpdateUnit,
 isSubmitting
}) => {
  const { id: projectId } = useParams();

  // React Hook Form
  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      units: []
    }
  });

  // Queries
  const {
    data: blockData,
    isLoading: isBlockLoading,
    isError: isBlockError
  } = useQuery({
    queryKey: ['block', blockId],
    queryFn: () => getBlockByID(blockId),
    enabled: !!blockId
  });

  const {
    data: accommodationsData,
    isLoading: isAccommodationsLoading,
    isError: isAccommodationsError
  } = useQuery({
    queryKey: ['accommodations', projectId],
    queryFn: () => getAllAccommodationsByProject(projectId),
    enabled: !!projectId
  });

  // Filter accommodations for new units
  const filteredAccommodations = useMemo(() => {
    if (!accommodationsData || !blockData) return [];
    const blockType = blockData.type?.toLowerCase() || '';

    if (activeLevelType === 'PARKING') {
      return accommodationsData.filter(
        (acc) => acc.accomodationType?.toLowerCase() === 'parking'
      );
    } else {
      const filtered = accommodationsData.filter(
        (acc) => acc.accomodationType?.toLowerCase() === blockType
      );
      return filtered.length ? filtered : accommodationsData;
    }
  }, [accommodationsData, blockData, activeLevelType]);

  // Identify level data
  const levelData = useMemo(() => {
    if (!blockData || !blockData.levels) return null;
    return blockData.levels.find(
      (lvl) => String(lvl.id) === String(levelId)
    );
  }, [blockData, levelId]);

  // Handle loading & errors
  if (isBlockLoading || isAccommodationsLoading) {
    return <div>Loading...</div>;
  }
  if (isBlockError || isAccommodationsError) {
    return <div>Error loading data. Please try again.</div>;
  }
  if (!blockData || !levelData) {
    return <div>No data found.</div>;
  }

  // Prepare existing units
  const initialUnitsComputed = useMemo(() => {
    if (!levelData.units) return [];
    return levelData.units.map((u) => ({
      unit_name: u.unitName,
      accommodation_id: u.accommodation ? String(u.accommodation.id) : '',
      accommodation_name: u.accommodation?.name || ''
    }));
  }, [levelData]);

  // Field array
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'units'
  });

  // Load initial fields
  useEffect(() => {
    if (initialUnitsComputed.length) {
      replace(initialUnitsComputed);
    }
  }, [initialUnitsComputed, replace]);

  const initialUnitsCount = initialUnitsComputed.length;

  // Submit → only new units
  const onSubmit = (data) => {
    const newUnits = data.units.slice(initialUnitsCount).map((unit) => ({
      unit_name: unit.unit_name,
      accomodation_id: parseInt(unit.accommodation_id, 10) || null
    }));

    const payload = {
      project_id: parseInt(projectId, 10),
      block_id: blockId,
      level_id: levelId,
      units: newUnits
    };

    handleUpdateUnit(payload);
  };

  const levelName = levelData?.name || levelId;

  return (
    <div className="w-full p-4">
      <h2 className="text-lg font-bold mb-4">Update Units</h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Block & Level (read-only) */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <FormInput
            label="Block"
            type="text"
            disabled
            register={register}
            register_key="blockName"
            valuePre={blockData.name || blockId}
          />
          <FormInput
            label="Level"
            type="text"
            disabled
            register={register}
            register_key="levelName"
            valuePre={levelName}
          />
        </div>

        {/* Units */}
        <div className="space-y-4">
          {fields.map((field, index) => {
            const isExisting = index < initialUnitsCount;

            return (
              <div key={field.id} className="grid grid-cols-12 gap-4 items-end">
                {/* Unit Name */}
                <div className="col-span-5">
                  {isExisting ? (
                    <FormInput
                      label="Unit Name"
                      type="text"
                      disabled
                      register={register}
                      register_key={`units[${index}].unit_name`}
                      valuePre={field.unit_name}
                    />
                  ) : (
                    <FormInput
                      label="Unit Name"
                      type="text"
                      register={register}
                      register_key={`units[${index}].unit_name`}
                      placeholder="Enter unit name"
                    />
                  )}
                </div>

                {/* Accommodation */}
                <div className="col-span-5">
                  {isExisting ? (
                    <>
                      <FormInput
                        label="Accommodation"
                        type="text"
                        disabled
                        register={register}
                        register_key={`units[${index}].accommodation_name`}
                        valuePre={field.accommodation_name}
                      />
                      {/* Hidden ID field */}
                      <FormInput
                        type="hidden"
                        register={register}
                        register_key={`units[${index}].accommodation_id`}
                        valuePre={field.accommodation_id}
                      />
                    </>
                  ) : (
                    <FormInput
                      label="Accommodation"
                      type="dropdown"
                      register={register}
                      register_key={`units[${index}].accommodation_id`}
                      options={filteredAccommodations.map((acc) => ({
                        value: String(acc.id),
                        label: acc.name
                      }))}
                    />
                  )}
                </div>

                {/* Remove button for new units */}
                <div className="col-span-2">
                  {!isExisting && (
                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      displayType="secondary"
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* Add More Units */}
        <div className="mt-4">
          <button
            type="button"
            onClick={() =>
              // Default to the first filteredAccommodations item if you want
              append({
                unit_name: '',
                // Auto-select the first item in the dropdown (optional).
                // If you want the user to pick explicitly, set this to ''.
                accommodation_id: filteredAccommodations.length
                  ? String(filteredAccommodations[0].id)
                  : '',
                accommodation_name: ''
              })
            }
            className="text-primary underline"
          >
            Add More Units
          </button>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpdateUnit;
