import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import store from './store';
import PageNotAccessible from './modules/shared/pages/PageNotAccessible';

Sentry.init({
  dsn: "https://77038894211c10612bc91128cc96d202@o4508657584832512.ingest.us.sentry.io/4508657587978240",
  integrations: [
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [
        "localhost",
        "https://dev.housingram.com/api", 
        "https://application.housingram.com/api"
      ],
    }),
    // Replace new Sentry.Replay() with the replayIntegration() function
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const RootComponent = () => {
  const [isAccessible, setIsAccessible] = useState(window.innerWidth >= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsAccessible(window.innerWidth >= 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isAccessible ? (
    <Provider store={store}>
      <App />
    </Provider>
  ) : (
    <PageNotAccessible />
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);
