import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import {
  createUser,
  deleteUser,
  getAllUsers,
  updateUser,
  // If you're using resend invites
  resendEmailInvites
} from '../../services/api/implementation/impl';

import Layout from './AppLayout';
import Header from '../../../shared/components/layout/Header';
// import filter from '../../../shared/assets/svg/filter.svg'; // if needed
import Button from '../../../shared/components/ui/Button';
import GridWrapper from '../../../shared/components/layout/GridWrapper';
import Table from '../../../shared/components/layout/Table';
import Modal from '../../../shared/components/ui/Modal';
import Search from '../../../shared/components/ui/Search';
import AddBuilder from '../forms/AddBuilder';
import EditBuilder from '../forms/EditBuilder';
import '../../../shared/styles/modal.scss';
import toast from 'react-hot-toast';
import { transformData } from '../../utils/transformData';
import PaginationComponent from '../../../shared/components/PaginationComponent';

const UserAdmin = () => {
  const [openAddBuilderModal, setOpenAddBuilderModal] = useState(false);
  const [openEditBuilderModal, setOpenEditBuilderModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState('');
  const [usersToastShown, setUsersToastShown] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  // NEW - track isSubmitting for the resend-invite button if needed
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Query: fetch all users
  const {
    data: users,
    refetch: refetchUsers,
    isFetching
  } = useQuery({
    queryKey: ['users', page],
    queryFn: () => getAllUsers(page, limit),
    keepPreviousData: true
  });

  // total pages
  const totalPages = users?.meta ? Math.ceil(users?.meta.total / limit) : 1;

  // Form methods
  const { reset } = useForm();

  // CREATE user mutation
  const { mutate: createUserMutation, isLoading: isLoadingNewUserMutation } = useMutation({
    mutationFn: (newUser) => createUser(newUser),
    onSuccess: async () => {
      toast.success('User created successfully');
      reset();
      setOpenAddBuilderModal(false);
      await refetchUsers();
    },
    onError: (err) => {
      toast.error('Error creating user');
      console.error('Error creating new user', err);
    }
  });

  // DELETE user mutation
  const { mutate: onDeleteUser } = useMutation({
    mutationFn: (id) => deleteUser(id),
    onSuccess: async () => {
      toast.success('User deleted successfully');
      await refetchUsers();
    },
    onError: (err) => {
      toast.error('Error deleting user');
      console.error('Error deleting user', err);
    }
  });

  // "RESEND INVITE" mutation (if you have such functionality)
  const { mutate: resendInvite } = useMutation({
    mutationFn: (payload) => resendEmailInvites(payload),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Invite sent successfully');
      await refetchUsers();
    },
    onError: (err) => {
      setIsSubmitting(false);
      toast.error('Error sending invite');
      console.error('Error sending invite', err);
    }
  });

  // Handler to open/close add-builder modal
  const handleToggleAddBuilderModal = () => {
    setOpenAddBuilderModal(!openAddBuilderModal);
  };

  // Handler to open/close edit-builder modal
  const handleToggleEditBuilderModal = (id) => {
    setCurrentUserId(id);
    setOpenEditBuilderModal(!openEditBuilderModal);
  };

  // Handler to create a new user
  const onSubmit = (data) => {
    createUserMutation(data);
  };

  // Handler to "Resend Invite"
  const handleResendInvite = (id) => {
    console.log('Resend invite for user with id:', id);
    const user = filteredData.find((u) => parseInt(u.id) === parseInt(id));
    if (!user) return;

    // Call the mutation with the user's email
    resendInvite({ email: user.primary_email });
  };

  // Build the columns for your table
  const usersDataFormat = [
    'id',
    'primary_username',
    'primary_email',
    'primary_phone_number',
    'type',
    'is_active',
    'actions',
    // Column for "Invite"
    'invite'
  ];

  // On first load, optionally show a toast
  useEffect(() => {
    if (users?.data && !usersToastShown) {
      // Example: toast.success(`Successfully fetched ${users?.data?.length} users`);
      setUsersToastShown(true);
    }
  }, [users?.data, usersToastShown]);

  // --------------------------------------------
  // 1. Omit the secondary user if it doesn't exist
  // --------------------------------------------
  useEffect(() => {
    if (users?.data) {
      const formattedData = users?.data.reduce((acc, user) => {
        // Always add the primary user
        const primaryUser = {
          id: user?.id,
          primary_username: user?.primery_user?.name,
          primary_email: user?.primery_user?.email,
          primary_phone_number: user?.primery_user?.phone_number,
          is_active: user?.primery_user?.is_active,
          type: 'Primary'
        };
        acc.push(primaryUser);

        // Only add the secondary user if it exists
        if (user.secondry_user) {
          const secondaryUser = {
            id: user?.id,
            primary_username: user?.secondry_user?.name,
            primary_email: user?.secondry_user?.email,
            primary_phone_number: user?.secondry_user?.phone_number,
            is_active: user?.secondry_user?.is_active,
            type: 'Secondary'
          };
          acc.push(secondaryUser);
        }

        return acc;
      }, []);

      setFilteredData(formattedData);
    }
  }, [users?.data]);

  // --------------------------------------------
  // 2. Omit the secondary user if it doesn't exist in the search
  // --------------------------------------------
  const handleSearchChange = useCallback(
    (incomingQuery) => {
      setSearchQuery(incomingQuery);

      if (incomingQuery) {
        const lowerCaseQuery = incomingQuery.toLowerCase();

        const filteredUsers = users?.data?.reduce((acc, user) => {
          // Build the primary user object
          const primaryUser = {
            id: user?.id,
            primary_username: user?.primery_user?.name,
            primary_email: user?.primery_user?.email,
            primary_phone_number: user?.primery_user?.phone_number,
            is_active: user?.primery_user?.is_active,
            type: 'Primary'
          };

          // Build the secondary user object only if it exists
          let secondaryUser = null;
          if (user.secondry_user) {
            secondaryUser = {
              id: user?.id,
              primary_username: user?.secondry_user?.name,
              primary_email: user?.secondry_user?.email,
              primary_phone_number: user?.secondry_user?.phone_number,
              is_active: user?.secondry_user?.is_active,
              type: 'Secondary'
            };
          }

          // Check matches for primary
          const matchesPrimary =
            primaryUser.primary_username?.toLowerCase().includes(lowerCaseQuery) ||
            primaryUser.primary_email?.toLowerCase().includes(lowerCaseQuery) ||
            primaryUser.primary_phone_number?.toLowerCase().includes(lowerCaseQuery) ||
            primaryUser.type?.toLowerCase().includes(lowerCaseQuery) ||
            primaryUser.is_active?.toString()?.toLowerCase().includes(lowerCaseQuery);

          if (matchesPrimary) acc.push(primaryUser);

          // Check matches for secondary if it exists
          if (secondaryUser) {
            const matchesSecondary =
              secondaryUser.primary_username?.toLowerCase().includes(lowerCaseQuery) ||
              secondaryUser.primary_email?.toLowerCase().includes(lowerCaseQuery) ||
              secondaryUser.primary_phone_number?.toLowerCase().includes(lowerCaseQuery) ||
              secondaryUser.type?.toLowerCase().includes(lowerCaseQuery) ||
              secondaryUser.is_active?.toString()?.toLowerCase().includes(lowerCaseQuery);

            if (matchesSecondary) acc.push(secondaryUser);
          }

          return acc;
        }, []);

        setFilteredData(filteredUsers || []);
      } else {
        // Reset to all users if query is empty
        const allUsers = users?.data?.reduce((acc, user) => {
          // Primary
          const primaryUser = {
            id: user?.id,
            primary_username: user?.primery_user?.name,
            primary_email: user?.primery_user?.email,
            primary_phone_number: user?.primery_user?.phone_number,
            is_active: user?.primery_user?.is_active,
            type: 'Primary'
          };
          acc.push(primaryUser);

          // Secondary (only if it exists)
          if (user.secondry_user) {
            const secondaryUser = {
              id: user?.id,
              primary_username: user?.secondry_user?.name,
              primary_email: user?.secondry_user?.email,
              primary_phone_number: user?.secondry_user?.phone_number,
              is_active: user?.secondry_user?.is_active,
              type: 'Secondary'
            };
            acc.push(secondaryUser);
          }

          return acc;
        }, []);

        setFilteredData(allUsers || []);
      }
    },
    [users?.data]
  );

  // Transform for table consumption
  const transformedData = filteredData?.map((el) =>
    transformData(
      {
        ...el,
        // Provide a label for the invite column (optional)
        invite: `Invite ${el?.is_active}`
      },
      usersDataFormat
    )
  );

  // Handle pagination
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Modal lock scroll
  useEffect(() => {
    if (openAddBuilderModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [openAddBuilderModal]);

  return (
    <>
      {/* Add Builder Modal */}
      {openAddBuilderModal && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Add builder" onClose={handleToggleAddBuilderModal}>
          <AddBuilder onSubmit={onSubmit} onClose={handleToggleAddBuilderModal} />
        </Modal>
      )}

      {/* Edit Builder Modal */}
      {openEditBuilderModal && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Edit builder" onClose={handleToggleEditBuilderModal}>
          <EditBuilder
            id={currentUserId}
            isDisabled={isLoadingNewUserMutation}
            onClose={handleToggleEditBuilderModal}
            onSuccess={refetchUsers}
          />
        </Modal>
      )}

      <Layout>
        {/* Top Header */}
        <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
          <Header data={{ name: 'Users' }} />
        </div>

        {/* Search + Add */}
        <div className="flex items-center justify-between px-6 py-4 bg-white fixed top-[4rem] z-20 border-t-[2px] border-stone-100 w-[82vw] shadow-sm">
          <Search query={searchQuery} onChange={handleSearchChange} />
          <div className="flex gap-5 cursor-pointer">
            {/* If you have a filter icon: <img className="" src={filter} alt="" /> */}
            <Button onClick={handleToggleAddBuilderModal}>Add Builder</Button>
          </div>
        </div>

        {/* Main Content - Table + Pagination */}
        <GridWrapper width={100}>
          <div className="mt-[12rem] h-[28rem] relative">
            <Table
              headers={[
                'S.No',
                'Name',
                'Email',
                'Phone',
                'Type',
                'Status',
                'Action',
                'Invite'
              ]}
              data={transformedData?.map((item, index) => ({
                serialNumber: index + 1,
                ...item
              }))}
              identifier={'id'}
              onEdit={handleToggleEditBuilderModal}
              onDelete={onDeleteUser}
              onResendInvite={handleResendInvite}
            />

            <PaginationComponent
              page={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              isFetching={isFetching}
            />
          </div>
        </GridWrapper>
      </Layout>
    </>
  );
};

export default UserAdmin;
