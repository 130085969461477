export const transformPayload = (payload, format) => {
  let newPayload = { ...payload };

  format?.forEach((item) => {
    if (item?.type === 'integer') {
      newPayload[item.label] = parseInt(payload[item.label]);
    } else if (item?.type === 'boolean') {
      newPayload[item.label] =
        payload[item.label] === 'true' || payload[item.label] === 'Yes' ? true : false;
    } else if (item?.type === 'float') {
      newPayload[item.label] = Number(payload[item.label]);
    }
  });

  return newPayload;
};
