import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import {
  getBlockByID,
  getAllAccommodationsByProject
} from '../../services/api/implementation/impl';

export const EditUnit = ({
  handleEditUnit,
  unitId,
  activeLevelType,
  data,
  onClose,
  isSubmitting
}) => {
  const [filteredAccommodations, setFilteredAccommodations] = useState([]);

  // --- 1. Queries for block info & accommodations ---
  const {
    data: blockDyna,
    refetch: refetchBlockDyna,
    isLoading: isBlockDynaLoading
  } = useQuery({
    queryKey: ['block', data?.[0]?.block_id],
    queryFn: () => getBlockByID(data?.[0]?.block_id),
    enabled: !!data?.[0]?.block_id
  });

  const { data: accommodations } = useQuery({
    queryKey: ['accommodations', data?.[0]?.project_id],
    queryFn: () => getAllAccommodationsByProject(data?.[0]?.project_id),
    enabled: !!data?.[0]?.project_id
  });

  // --- 2. Filter the target unit based on unitId ---
  const filteredData = useMemo(() => {
    if (!data) return [];
    return data.filter((item) => parseInt(item.id, 10) === parseInt(unitId, 10));
  }, [data, unitId]);

  console.log(filteredData, 'filteredData');

  // --- 3. React Hook Form with `reset` ---
  const { register, handleSubmit, reset } = useForm({
    // (Optional) provide some default values
    defaultValues: {
      unit_name: '',
      accomodation_id: '',
      carpetArea: 0,
      buildUpArea: 0,
      area: '',
      loadingFactor: '',
      superBuildUpArea: '',
      electrified: 'No'
    }
  });

  // --- 4. Single effect to filter accommodations & reset form once ready ---
  useEffect(() => {
    if (!filteredData.length || !accommodations || !blockDyna || isBlockDynaLoading) {
      return;
    }

    // Filter accommodations for this block / level type
    let filtered = [];
    const blockType = blockDyna?.type;
    const currentUnit = filteredData[0] || {};
    const accom = currentUnit?.accommodation || {};

    if (activeLevelType === 'PARKING') {
      // Show accommodations where accomodationType = 'parking'
      filtered = accommodations.filter((acc) => acc.accomodationType?.toLowerCase() === 'parking');
    } else {
      // Use either the unit's own type or fallback to activeLevelType
      const unitType = accom?.unitType || activeLevelType;

      filtered = accommodations.filter(
        (acc) =>
          acc.accomodationType?.toLowerCase() === blockType?.toLowerCase() &&
          acc.unitType?.toLowerCase() === unitType?.toLowerCase()
      );

      // fallback if none match
      if (!blockType || filtered.length === 0) {
        filtered = accommodations.filter(
          (acc) => acc.unitType?.toLowerCase() === unitType?.toLowerCase()
        );
      }
    }

    setFilteredAccommodations(filtered);

    // Now that we have the correct unit data & filtered accommodations,
    // reset the form in one shot with the correct default values
    reset({
      unit_name: currentUnit.unitName || '',
      accomodation_id: accom?.id ? String(accom.id) : '',
      carpetArea: accom.carpetArea ?? 0,
      buildUpArea: accom.buildUpArea ?? 0,
      area: accom.buildUpArea ?? '',
      loadingFactor: accom.loadingFactor ?? '',
      superBuildUpArea: accom.superBuildUpArea ?? '',
      electrified: accom.electrified ? 'Yes' : 'No'
    });
  }, [filteredData, accommodations, blockDyna, isBlockDynaLoading, activeLevelType, reset]);

  // --- 5. Refetch block if block_id changes (optional if needed) ---
  useEffect(() => {
    if (data?.[0]?.block_id) {
      refetchBlockDyna(data[0].block_id);
    }
  }, [data, refetchBlockDyna]);

  // --- 6. Submit Handler ---
  const onSubmit = (formData) => {
    // Convert values to correct types
    formData.accomodation_id = parseInt(formData.accomodation_id, 10) || null;
    formData.carpetArea = parseFloat(formData.carpetArea) || 0;
    formData.buildUpArea = parseFloat(formData.buildUpArea) || 0;
    formData.electrified = formData.electrified === 'Yes';

    handleEditUnit(formData);
  };

  // --- 7. Render ---
  return (
    <div className="w-full">
      <div className="grid grid-cols-2 gap-5">
        {/*  Unit Name  */}
        <FormInput
          width={100}
          register={register}
          register_key="unit_name"
          label="Unit Name"
          type="text"
          isRequired
        />

        {/*  Accommodation / Parking dropdown  */}
        <FormInput
          width={100}
          register={register}
          register_key="accomodation_id"
          label={activeLevelType === 'PARKING' ? 'Parking Type' : 'Accommodation Name'}
          type="dropdown"
          options={filteredAccommodations.map((acc) => ({
            value: String(acc.id),
            label: acc.name
          }))}
        />

        {activeLevelType !== 'PARKING' && (
          <FormInput
            width={100}
            register={register}
            register_key="carpetArea"
            label="Carpet Area"
            type="text"
            disabled
            valuePre={filteredData[0]?.accommodation?.carpetArea || 0}
          />
        )}

        {activeLevelType !== 'PARKING' && (
          <FormInput
            width={100}
            register={register}
            register_key="buildUpArea"
            label="Build up Area"
            type="text"
            disabled
            valuePre={filteredData[0]?.accommodation?.buildUpArea || 0}
          />
        )}

        {activeLevelType === 'PARKING' && (
          <FormInput
            key={`area-${filteredData[0]?.area || ''}-${unitId}`} // use a key that changes when the area changes
            width={100}
            register={register}
            register_key="area"
            label="Area"
            type="text"
            disabled
            valuePre={filteredData[0]?.accommodation?.buildUpArea || ''}
          />
        )}

        {activeLevelType === 'COMMERCIAL' && (
          <FormInput
            width={100}
            register={register}
            register_key="loadingFactor"
            label="Loading Factor"
            type="text"
            disabled
            valuePre={filteredData[0]?.accommodation?.loadingFactor || ''}
          />
        )}

        {activeLevelType === 'COMMERCIAL' && (
          <FormInput
            width={100}
            register={register}
            register_key="superBuildUpArea"
            label="Super Build Up"
            type="text"
            disabled
            valuePre={filteredData[0]?.accommodation?.superBuildUpArea || 0}
          />
        )}

        {activeLevelType === 'PARKING' && (
          <FormInput
            width={100}
            register={register}
            register_key="electrified"
            label="Electrified"
            type="dropdown"
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
          />
        )}
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </div>
  );
};
