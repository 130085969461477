import React, { useCallback, useEffect, useState } from 'react';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Search from '../../shared/components/ui/Search';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Table from '../../shared/components/layout/Table';
import { transformData } from '../../super-admin/utils/transformData';
import Modal from '../../shared/components/ui/Modal';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { filterData } from '../../shared/utils/filterData';
import { useNavigate } from 'react-router-dom';
import RequestQuotation from '../components/forms/RequestQuotation';
import { useQuery } from '@tanstack/react-query';
import { getRequirements } from '../services/api/implementation/impl';
import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';

// Dummy data for item requirements
const initialRequirements = [
  {
    id: 1,
    project_name: 'Suman Vihar',
    raised_by: 'John Doe',
    requirements: 'White Cement',
    task: 'Task 1',
    is_active: true,
    status: 'Pending'
  },
  {
    id: 2,
    project_name: 'Sky Life',
    raised_by: 'Jane Doe',
    requirements: 'Brick',
    task: 'Task 2',
    is_active: true,
    status: 'Accepted'
  }
];

const RequirementList = () => {
  const navigate = useNavigate();
  const [isOpenAddRequirement, setIsOpenAddRequirement] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState();
  const [openRequestQuotation, setOpenRequestQuotation] = useState(false);

  // pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  const {
    data: getAllrequiremnt,
    refetch: refetchRequiremnt,
    isFetching
  } = useQuery({
    queryKey: ['requirement', page],
    queryFn: () => getRequirements(page, limit),
    keepPreviousData: true
  });

  const totalPages = getAllrequiremnt?.meta ? Math.ceil(getAllrequiremnt?.meta.total / limit) : 1;

  const handlePageChange = (newPage) => {
    setFilteredData([]);
    setSearchQuery('');
    setPage(newPage);
  };

  useEffect(() => {
    refetchRequiremnt();
  }, [refetchRequiremnt]);

  console.log({ getAllrequiremnt });

  // Form handling using react-hook-form
  const { reset } = useForm();

  const handleSearch = (data) => {
    setSearchQuery(data);
  };

  // const handleOnClickSearch = () => {
  //   const filtered = getAllrequiremnt.filter((item) =>
  //     item?.project?.name.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  //   setFilteredData(filtered);
  //   setSearchQuery('');
  // };

  const handleToggleAddRequirement = () => {
    setIsOpenAddRequirement(!isOpenAddRequirement);
  };

  const handleNavigateRequirement = (id) => {
    navigate(`/builder/operations/requirements/${id}`);
  };

  const handleDeleteRequirement = (requirementId) => {
    const updatedRequirements = filteredData.filter(
      (requirement) => requirement.id !== requirementId
    );
    setFilteredData(updatedRequirements);
    toast.success('Requirement deleted successfully');
  };

  const requirementsDataFormat = [
    'id',
    'project_name',
    'raised_by',
    'task',
    'requirements',
    'status',
    'actions'
  ];

  const onSubmit_Requirement = (data) => {
    // Add logic to handle form submission (e.g., API call)
    setIsOpenAddRequirement(false);
    toast.success('Requirement created successfully');
    reset();
  };

  const handleSearchChange = useCallback((incomingQuery) => {
    if (incomingQuery) {
      const filteredRequirements = filterData('name', getAllrequiremnt?.data, incomingQuery);
      setFilteredData(filteredRequirements);
    } else {
      setFilteredData(initialRequirements);
    }
  }, []);

  const handleRequirementFormat = (item) => {
    return {
      id: item.id,
      project_name: item?.project?.name || ' ',
      raised_by: item?.user?.name || ' ',
      task: item?.task?.jobTitle || ' ',
      requirements: item?.vendorRequirmentMappingItems?.map((item) => item?.items?.name).toString(),
      status: item.status || ' '
    };
  };

  const transformedData = getAllrequiremnt?.data?.map((item) =>
    transformData(handleRequirementFormat(item), requirementsDataFormat)
  );

  return (
    <>
      {openRequestQuotation && (
        <Modal isDirty={true} confirmOnClose={true} width={40} name="Update Quotation" onClose={() => setOpenRequestQuotation(false)}>
          <RequestQuotation onClose={() => setOpenRequestQuotation(false)} />
        </Modal>
      )}
      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18vw] z-5 w-[82vw]">
          <Header data={{ name: 'Requirements' }} />
        </div>

        {transformedData && transformedData?.length > 0 ? (
          <>
            {/* Main content */}
            <div className="flex z-5 items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
              <Search onChange={handleSearchChange} query={searchQuery} />
            </div>

            {/* Grid */}
            <GridWrapper width={100}>
              <div className="mt-[12rem] h-[28rem] relative">
                <Table
                  headers={['Project Name', 'Raised By', 'Task', 'No of Items', 'Status', 'Action']}
                  data={transformedData}
                  identifier="id"
                  onView={handleNavigateRequirement}
                  // onAccept={() => setOpenRequestQuotation(true)}
                  // onCancel={handleNavigateRequirement}
                  isEditEnable={false}
                  isViewEnable={true}
                  isViewByName={true}
                  onViewByName={handleNavigateRequirement}
                />
                <PaginationComponent
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  isFetching={isFetching}
                />
              </div>
            </GridWrapper>
          </>
        ) : (
          <NoDataFound disabledBtn={true} />
        )}
      </Layout>
    </>
  );
};

export default RequirementList;
