import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../shared/components/ui/Button';
import Table from '../../shared/components/layout/Table';
import PaginationComponent from '../../shared/components/PaginationComponent';
import { transformData } from '../../super-admin/utils/transformData';
import NewPropertyDocumentModal from '../components/forms/NewPropertyDocumentModal';
import Modal from '../../shared/components/ui/Modal';
import NewDocumentTemplate from './NewDocumentTemplate';
import {
  getTemplate,
  getDocumentsByTemplateId,
  downloadDocument,
  uploadDocument,
  downloadSignedDocument
} from '../services/api/implementation/impl';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { act } from 'react';
import EditDocumentTemplate from '../components/forms/EditDocumentTemplate';

const ProjectDocuments = () => {
  const queryClient = useQueryClient();

  const { id: projectId } = useParams();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);

  const {
    data: getAllTemplate,
    isLoading: isLoadingTemplate,
    isError: isErrorTemplate
  } = useQuery({
    queryKey: ['template'],
    queryFn: () => getTemplate(projectId),
    enabled: !!projectId
  });

  //write api call for get all documents by template id
  const {
    data: getAllDocumentsByTemplateId,
    isLoading: isLoadingDocuments,
    isError: isErrorDocuments
  } = useQuery({
    queryKey: ['documents', selectedTemplateId],
    queryFn: () => getDocumentsByTemplateId(selectedTemplateId),
    enabled: !!selectedTemplateId
  });

  console.log({ getAllDocumentsByTemplateId });

  console.log({ getAllTemplate });
  const navigate = useNavigate();

  // -- State to track if the user is viewing the second table --
  const [isViewing, setIsViewing] = useState(false);

  // -- State for showing/hiding the "Create New Document" modal --
  const [showNewDocModal, setShowNewDocModal] = useState(false);

  //write a function to create 2025-02-06T09:45:34.277Z to proper date and time format in Jan 10, 2025 and time in 10:45 AM
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  // -- Dummy data for the first table (Documents) --
  const documentsDummyData = [
    {
      id: 1,
      documentName: 'Allotment Letter',
      description: 'Document Template for releasing allotment Letters',
      dateCreated: '25 January 2025',
      lastModifiedOn: '25 January 2025'
    },
    {
      id: 2,
      documentName: 'Property Agreement',
      description: 'Property Agreement',
      dateCreated: '25 January 2025',
      lastModifiedOn: '25 January 2025'
    }
  ];

  // -- Format + transform for Table 1 --
  const DocumentDataFormat = [
    'id',
    'documentName',
    'description',
    'dateCreated',
    'lastModifiedOn',
    'actions'
  ];

  const RequiredDocumentsData = getAllTemplate?.map((doc) =>
    transformData(
      {
        ...doc,
        documentName: doc.document_name || '-',
        description: doc.description || '-',
        dateCreated: formatDate(doc.created_at) || '-',
        lastModifiedOn: formatDate(doc.updated_at) || '-'
      },
      DocumentDataFormat
    )
  );

  const tableHeaders = [
    'Document Name',
    'Description',
    'Date Created',
    'Last Modified On',
    'Action'
  ];

  // -- Dummy data for the second table (Records) --
  const secondTableData = [
    {
      id: 100,
      customerName: 'Suresh Kumar',
      unitOrOther: '102',
      notes: 'Sent for',
      dateCreated: '25 January 2025',
      sentOn: '25 January 2025'
    },
    {
      id: 101,
      customerName: 'Pankaj Aluhwaliya',
      unitOrOther: '103',
      notes: 'Sent for',
      dateCreated: '25 January 2025',
      sentOn: '25 January 2025'
    },
    {
      id: 102,
      customerName: 'Sneha Kapoor',
      unitOrOther: 'A-201',
      notes: 'Awaiting signature',
      dateCreated: '25 January 2025',
      sentOn: '26 January 2025'
    }
  ];

  // -- Format + transform for Table 2 --
  const SecondTableDataFormat = ['id', 'customerName', 'unitName', 'dateCreated', 'actions'];

  const secondTableHeaders = ['Customer Name', 'Unit Name', 'Date Created', 'Action'];

  const RequiredSecondTableData = getAllDocumentsByTemplateId?.map((doc) =>
    transformData(
      {
        ...doc,
        customerName: doc.customer_name.join(',') || '-',
        unitName: doc.unit.unitName || '-',
        dateCreated: formatDate(doc.created_at) || '-',
        actions: `Actions-${doc.is_uploaded}---${doc.id}` 
      },
      SecondTableDataFormat
    )
  );

  // -- Pagination (dummy) --
  const [page, setPage] = useState(1);
  const limit = 10;
  const totalPages = 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // -- When user clicks View on the first table --
  const handleView = (id) => {
    setSelectedTemplateId(id);
    queryClient.invalidateQueries(['documents', selectedTemplateId]);
    setIsViewing(true);
  };

  // -- Go back to the first table --
  const handleBackToFirstTable = () => {
    setIsViewing(false);
  };

  // -- Create Template => navigate to new template route
  const handleCreateTemplate = () => {
    navigate(`/builder/management/company/projects/project-documents/template/new/${projectId}`);
  };

  // -- Create New Document => open the modal
  const handleCreateNewDocument = () => {
    setShowNewDocModal(true);
  };


  const { mutate: downloadDochandler } = useMutation({
    mutationFn: async (id) => {
      return await downloadDocument(id);
    },
    onSuccess: (data, variables) => {
      const id = variables;
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url; // <-- Set the href attribute to the created URL
      a.download = `document_${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success('Document Downloaded');
    },    
    onError: () => {
      toast.error('Error in downloading document');
    }
  });

  const { mutate: downloadSignedDocumentHandler } = useMutation({
    mutationFn: async (id) => {
      return await downloadSignedDocument(id);
    },
    onSuccess: (data, variables) => {
      const id = variables;
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url; // <-- Set the href attribute to the created URL
      a.download = `signed_document.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success('Document Downloaded');
    },
    onError: () => {
      toast.error('Error in downloading document');
    }
  });



  const handleDownloadDocument = (id) => {
    // console.log('Downloading document with ID:', id);
    // const filteredDoc = getAllDocumentsByTemplateId.find((doc) => parseInt(doc.id) === parseInt(id));
    // const dealId = filteredDoc.deal_id;
    downloadDochandler(id);
  };

  const handleDownloadSignDocument = (id) => {
    // console.log('Downloading document with ID:', id);
    // const filteredDoc = getAllDocumentsByTemplateId.find((doc) => parseInt(doc.id) === parseInt(id));
    // const dealId = filteredDoc.deal_id;
    downloadSignedDocumentHandler(id);
  };


  const handleUploadDocument = async ({dataNew, payload}) => {
    try {
      await uploadDocument(dataNew, payload);
      toast.success('Document uploaded successfully');
      queryClient.invalidateQueries(['documents', selectedTemplateId]);
    } catch (error) {
      console.error('Error uploading document:', error);
      toast.error('Error uploading document');
    }
  };

const uploadDocumentHandler = (dataNew, payload) => {
  // console.log(dataNew, payload, "uploaded data")
  // const filteredDoc = getAllDocumentsByTemplateId.find((doc) => parseInt(doc.id) === parseInt(dataNew));
  // console.log(filteredDoc, "filtered doc")
  // const dealId = filteredDoc?.deal_id;
  handleUploadDocument({dataNew, payload});
}
const onNewDocSuccess = () => {
  setShowNewDocModal(false);
  queryClient.invalidateQueries(['documents', selectedTemplateId]);
  // setSelectedTemplateId(null);
  // setIsViewing(false);

}

const templateEditHandler = (id) => {
  setSelectedTemplateId(id);
  setIsEditTemplateModalOpen(true);
}


  return (
    <div className="w-full flex flex-col absolute top-[16rem] z-10 pl-5 pr-5 bg-gray-100 pb-10 overflow-hidden">
      {/* Modal for creating a new document */}
      {showNewDocModal && (
        <Modal
          name="New Property Document"
          isDirty={true}
          confirmOnClose={false}
          onClose={() => setShowNewDocModal(false)}
          width={50}>
          <NewPropertyDocumentModal
            onClose={() => setShowNewDocModal(false)}
            templateId={selectedTemplateId}
            onSuccess={onNewDocSuccess}
          />
        </Modal>
      )}
      {
        isEditTemplateModalOpen && (
          <Modal 
            name="Edit Document Template"
            isDirty={true}
            confirmOnClose={false}
            onClose={() => setIsEditTemplateModalOpen(false)}
            width={50}
          >
            <EditDocumentTemplate
              templateid={selectedTemplateId}
              onClose={() => setIsEditTemplateModalOpen(false)}
              onSuccess={() => {
                setIsEditTemplateModalOpen(false);
                queryClient.invalidateQueries(['template']);
              }}
            />
          </Modal>
        )
      }

      {/* Header row: back icon (if viewing second table) + top-right button(s) */}
      <div className="w-full flex justify-between items-center py-4">
        {/* Back icon only when second table is visible */}
        {isViewing ? (
          <div className="cursor-pointer text-xl font-bold" onClick={handleBackToFirstTable}>
            &lt;
          </div>
        ) : (
          <div />
        )}

        {/* Top-right button(s) */}
        <div className="flex gap-2">
          {isViewing ? (
            /* If second table is active, show "Create New Document" only */
            <Button onClick={handleCreateNewDocument} displayType="secondary">
              Create New Document
            </Button>
          ) : (
            /* If first table is active, show "Create Template" only */
            <Button onClick={handleCreateTemplate} displayType="secondary">
              Create Template
            </Button>
          )}
        </div>
      </div>

      {/* If NOT viewing second table, show first table; else show second table */}
      {!isViewing ? (
        // ---------------- FIRST TABLE ----------------
        <div className="h-[25rem] hide-scrollbar overflow-y-scroll relative">
          <Table
            headers={tableHeaders}
            data={RequiredDocumentsData}
            identifier={'id'}
            isViewEnable={true}
            onView={handleView}
            isEditEnable={true}
            onEdit={templateEditHandler}
          />
          {/* <PaginationComponent
            page={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isFetching={false}
            isEditEnable={false}
          /> */}
        </div>
      ) : (
        // ---------------- SECOND TABLE ----------------
        <div className="h-[25rem] hide-scrollbar overflow-y-scroll relative">
          <Table
            headers={secondTableHeaders}
            data={RequiredSecondTableData}
            identifier={'id'}
            isEditEnable={false}
            isDownloadDocEnable={true}
            onDownloadDoc={handleDownloadDocument}
            onUploadDoc={uploadDocumentHandler}
            onDownloadSignDoc={handleDownloadSignDocument}
          />
          {/* <PaginationComponent
            page={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isFetching={false}
          /> */}
        </div>
      )}
    </div>
  );
};

export default ProjectDocuments;
