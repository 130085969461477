import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from './modules/super-admin/components/layout/Dashboard';
import UserAdmin from './modules/super-admin/components/layout/UserAdmin';
import CompaniesList from './modules/builder/components/layout/CompaniesList';
import BillingPayments from './modules/super-admin/components/layout/BillingsPayments/BillingPayment';
import CompanyDetails from './modules/builder/pages/CompanyDetails';
import ProjectsList from './modules/builder/pages/ProjectsList';
import ProjectDetails from './modules/builder/pages/ProjectDetails';
import { Toaster } from 'react-hot-toast';
import UsersList from './modules/builder/pages/UsersList';
import RolePermission from './modules/builder/pages/RolePermission';
import UserDetails from './modules/builder/pages/UserDetails';
import LandDetails from './modules/builder/pages/LandDetails';
import LandsList from './modules/builder/pages/LandsList';
import Register from './modules/shared/pages/Register';
import Login from './modules/shared/pages/Login';
import ForgotPassword from './modules/shared/pages/ForgotPassword';
import VerifyEmail from './modules/shared/pages/VerifyEmail';
// import { SalesList } from './modules/builder/pages/SalesList';
// import { RateList } from './modules/builder/pages/RateList';
import SaleDetails from './modules/builder/pages/SaleDetails';
import VendorList from './modules/builder/pages/VendorList';
import VendorDetails from './modules/builder/pages/VendorDetails';
import ServiceProviderList from './modules/builder/pages/ServiceProviderList';
import MaterialList from './modules/builder/pages/MaterialList';
import Orders from './modules/builder/pages/Orders';
import QuotationDetails from './modules/builder/pages/QuotationDetails';
import RequirementList from './modules/builder/pages/RequirementList';
import RequirementDetails from './modules/builder/pages/RequirementDetails';
import SalesMain from './modules/builder/pages/SalesMain';
import DealsList from './modules/builder/pages/DealsList';
import PageNotFound from './modules/shared/pages/PageNotFound'; // Import the PageNotFound component
import OrdersRequirement from './modules/builder/pages/OrdersRequirement';
import LiveFeedList from './modules/builder/pages/LiveFeedList';
import AgreementFormNew from './modules/builder/pages/AgreementFormNew';
import LoginPhone from './modules/shared/pages/LoginPhone';
import CreatePasswordPage from './modules/shared/pages/CreatePassword';
import AgreementFormHandler from './modules/builder/pages/AgreementFormHandler';
import Modal from './modules/shared/components/ui/Modal';
import AddCompany from './modules/builder/components/forms/AddCompany';
import Layout from './modules/builder/components/layout/AppLayout';
import Analytics from './modules/builder/components/forms/Analytics';
import SuperAdminAnalytics from './modules/super-admin/components/forms/SuperAdminAnalytics';

import SalesReports from './modules/builder/pages/SalesReport';
import AccountsReport from './modules/builder/pages/AccountsReport';
import Integration from './modules/builder/pages/Integration';
import InventoryList from './modules/builder/pages/InventoryList';
import OrderActivityList from './modules/builder/pages/OrderActivityList';
import SentryTestPage from './modules/builder/pages/SentryTestPage';
import NewDocumentTemplate from './modules/builder/pages/NewDocumentTemplate';
import HomeRedirect from './modules/shared/components/layout/HomeRedirect';

/* 
  Router
*/
const router = createBrowserRouter([
  {
    path: '/',
    element: <HomeRedirect />,
    errorElement: <PageNotFound homePath="/" />
  },
  {
    path: '/super-admin',
    children: [
      {
        path: '/super-admin/home',
        element: <Dashboard />
      },
      {
        path: '/super-admin/user-management',
        element: <UserAdmin />
      },
      {
        path: '/super-admin/billings-payment',
        element: <BillingPayments />
      },
      {
        path: '/super-admin/analytics',
        element: <SuperAdminAnalytics />
      },
      {
        path: '*',
        element: <PageNotFound homePath="/super-admin/home" /> // Catch-all route for undefined paths
      }
    ]
  },
  /* 
    Builder routes
  */
  {
    path: '/builder',
    children: [
      {
        path: '/builder/feed',
        element: <LiveFeedList />
      },
      {
        path: '/builder/management',
        element: <CompaniesList />
      },
      {
        path: '/builder/management/company',
        element: <CompaniesList />
      },
      {
        path: '/builder/management/company/:id',
        element: <CompanyDetails />
      },
      {
        path: '/builder/billings-payment',
        element: <div>{`Builder module > Billings`}</div>
      },
      {
        path: '/builder/analytics',
        element: <Analytics />
      },
      {
        path: '/builder/management/land/:id',
        element: <LandDetails />
      },
      {
        path: '/builder/management/land',
        element: <LandsList />
      },
      {
        path: '/builder/management/company/:id/projects',
        element: <ProjectsList />
      },
      {
        path: '/builder/management/company/projects/:id',
        element: <ProjectDetails />
      },
      {
        path: '/builder/operations',
        element: <VendorList />
      },
      {
        path: '/builder/operations/vendors',
        element: <VendorList />
      },
      {
        path: '/builder/operations/service-providers',
        element: <ServiceProviderList />
      },
      {
        path: '/builder/operations/materials',
        element: <MaterialList />
      },
      {
        path: '/builder/operations/requirements',
        element: <RequirementList />
      },
      {
        path: '/builder/operations/requirements/:id',
        element: <RequirementDetails />
      },
      {
        path: '/builder/operations/orders',
        element: <Orders />
      },
      {
        path: '/builder/operations/orders/requirement/:requirementId/list/:id',
        element: <QuotationDetails />
      },
      {
        path: '/builder/users',
        element: <UsersList />
      },
      {
        path: '/builder/users/roles',
        element: <RolePermission />
      },
      {
        path: '/builder/users/:id',
        element: <UserDetails />
      },
      {
        path: '/builder/vendors/:id',
        element: <VendorDetails />
      },
      {
        path: '/builder/sales',
        element: <SalesMain />
      },
      {
        path: '/builder/sales/:id',
        element: <SaleDetails />
      },
      {
        path: '/builder/deals/:id',
        element: <DealsList />
      },
      {
        path: '/builder/operations/orders/requirement/:id',
        element: <OrdersRequirement />
      },
      {
        path: '/builder/Agreement/:id',
        element: <AgreementFormHandler />
      },
      {
        path: '/builder/Allotment/:id',
        element: <AgreementFormNew />
      },
      {
        path: '/builder/reports',
        element: <SalesReports />
      },
      {
        path: '/builder/reports/sales',
        element: <SalesReports />
      },
      {
        path: '/builder/reports/accounts',
        element: <AccountsReport />
      },
      {
        path: '/builder/integration',
        element: <Integration />
      },
      {
        path: '/builder/operations/inventory',
        element: <InventoryList />
      },
      {
        path: '/builder/operations/orders/activity/:id',
        element: <OrderActivityList />
      },
      {
        path: '/builder/sentry',
        element: <SentryTestPage />
      },
      {
        path: '/builder/management/company/projects/project-documents/template/new/:id',
        element: <NewDocumentTemplate/>
      },
      {
        path: '*',
        element: <PageNotFound homePath="/builder/feed" /> // Catch-all route for undefined paths
      }
    ]
  },
  /* 
    Auth routes
  */
  {
    path: '/auth',
    children: [
      {
        path: '/auth/register',
        element: <Register />
      },
      {
        path: '/auth/login',
        element: <Login />
      },
      {
        path: '/auth/login-phone',
        element: <LoginPhone />
      },
      {
        path: '/auth/verify-email',
        element: <VerifyEmail />
      },
      {
        path: '/auth/create-password',
        element: <CreatePasswordPage />
      },
      {
        path: '/auth/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: '*',
        element: <PageNotFound homePath="/auth/login" />
      }
    ]
  },
  {
    path: '*',
    element: <PageNotFound homePath="/" />
  }
]);

/* 
  React query client
*/
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 6000 * 1000
    }
  }
});

const App = () => {
  // const user = 'admin';
  return (
    <>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools /> */}
        <Toaster position="top-right" />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
};

export default App;
