import React from 'react';
import TableData from './TableData';

function TableRow({
  data,
  width,
  identifier,
  onEdit,
  onDelete,
  onView,
  isDeleteEnable,
  isViewEnable,
  isChangeStatusEnable,
  onChangeStatus,
  isEditEnable,
  handleRowClick,
  onAccept,
  isAcceptEnable,
  onCancel,
  isCancelEnable,
  onViewBrands,
  onViewItems,
  isStatusUpdateEnable,
  onStatusUpdate,
  isMarkEnable,
  onMark,
  isDollerEnable,
  onDollor,
  onViewByName,
  isViewByName,
  rowName,
  isBtnEnable,
  onBtn,
  datashowbtn,
  onViewNote,
  isDownloadInvoiceEnable,
  onDownloadInvoice,
  isDownloadBillEnable,
  onDownloadBill,
  onResendInvite,
  isInventoryActionEnabled,
  onInventoryPurchase,
  onInventoryUpdate,
  onDownloadDoc,
  isDownloadDocEnable,
  onUploadDoc,
  uploadDocId,
  onDownloadSignDoc,
}) {
  return (
    <div
      className="flex flex-wrap max-w-full justify-between border-b-[1px] p-4 cursor-pointer"
      onClick={() => handleRowClick(data)}>
      {Object.keys(data)?.map((item, index) =>
        data?.[item] === 'Actions' ||
        data?.[item] === 'BrandList' ||
        data?.[item] === 'ItemList' ||
        data?.[item] === 'notes' ||
        data?.[item] === 'ShowPaidButton' ||
        data?.[item] === 'Invite false' ||
        data?.[item] === 'Invite true' ? (
          <TableData
            key={Math.random()}
            data={`${data[item]}---${data?.[identifier]}`}
            completeItem={data}
            identifier={identifier}
            width={width}
            alignLeft={index === 0}
            alignRight={index === Object.keys(data).length - 1}
            onEdit={onEdit}
            onDelete={onDelete}
            onView={onView}
            isDeleteEnable={isDeleteEnable}
            isViewEnable={isViewEnable}
            isEditEnable={isEditEnable}
            onAccept={onAccept}
            isAcceptEnable={isAcceptEnable}
            onChangeStatus={onChangeStatus}
            isChangeStatusEnable={isChangeStatusEnable}
            onCancel={onCancel}
            isCancelEnable={isCancelEnable}
            onViewBrands={onViewBrands}
            onViewItems={onViewItems}
            isStatusUpdateEnable={isStatusUpdateEnable}
            onStatusUpdate={onStatusUpdate}
            isMarkEnable={isMarkEnable}
            onMark={onMark}
            isDollerEnable={isDollerEnable}
            onDollor={onDollor}
            onViewByName={onViewByName}
            isViewByName={isViewByName}
            rowName={rowName}
            isBtnEnable={isBtnEnable}
            onBtn={onBtn}
            showPaidButton={data.actions === 'ShowPaidButton'}
            rowId={data?.[identifier]} //
            onViewNote={onViewNote}
            isDownloadInvoiceEnable={isDownloadInvoiceEnable}
            onDownloadInvoice={onDownloadInvoice}
            isDownloadBillEnable={isDownloadBillEnable}
            onDownloadBill={onDownloadBill}
            onResendInvite={onResendInvite}
            isInventoryActionEnabled={isInventoryActionEnabled}
            onInventoryPurchase={onInventoryPurchase}
            onInventoryUpdate={onInventoryUpdate}
            onDownloadDoc={onDownloadDoc}
            isDownloadDocEnable={isDownloadDocEnable}
            onUploadDoc={onUploadDoc}
            uploadDocId={uploadDocId}
            onDownloadSignDoc={onDownloadSignDoc}
          />
        ) : (
          item !== identifier && (
            <TableData
              key={Math.random()}
              data={data[item]}
              completeItem={data}
              identifier={identifier}
              width={width}
              alignLeft={index === 0}
              alignRight={index === Object.keys(data).length - 1}
              onMark={onMark}
              onEdit={onEdit}
              onDelete={onDelete}
              onView={onView}
              isDeleteEnable={isDeleteEnable}
              isViewEnable={isViewEnable}
              isEditEnable={isEditEnable}
              onChangeStatus={onChangeStatus}
              isChangeStatusEnable={isChangeStatusEnable}
              onAccept={onAccept}
              isAcceptEnable={isAcceptEnable}
              onCancel={onCancel}
              isCancelEnable={isCancelEnable}
              onViewBrands={onViewBrands}
              onViewItems={onViewItems}
              isStatusUpdateEnable={isStatusUpdateEnable}
              onStatusUpdate={onStatusUpdate}
              isMarkEnable={isMarkEnable}
              isDollerEnable={isDollerEnable}
              onDollor={onDollor}
              onViewByName={onViewByName}
              isViewByName={isViewByName}
              rowName={rowName}
              isBtnEnable={isBtnEnable}
              onBtn={onBtn}
              onViewNote={onViewNote}
              isDownloadInvoiceEnable={isDownloadInvoiceEnable}
              onDownloadInvoice={onDownloadInvoice}
              isDownloadBillEnable={isDownloadBillEnable}
              onDownloadBill={onDownloadBill}
              onResendInvite={onResendInvite}
              isInventoryActionEnabled={isInventoryActionEnabled}
              onInventoryPurchase={onInventoryPurchase}
              onInventoryUpdate={onInventoryUpdate}
              onDownloadDoc={onDownloadDoc}
              isDownloadDocEnable={isDownloadDocEnable}
              onUploadDoc={onUploadDoc}
              uploadDocId={uploadDocId}
              onDownloadSignDoc={onDownloadSignDoc}
            />
          )
        )
      )}
    </div>
  );
}

export default TableRow;
