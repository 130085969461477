import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import { getAllCategoriesForServiceProvider, getStateAndCity } from '../../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

// Validation schema including address fields
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Full name is required')
    .max(50, 'Full name should be at most 50 characters'),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .matches(/^[0-9]{10}$/, 'Invalid phone number'),
  email: yup.string().required('Email is required').email('Invalid email format'),
  category_id: yup.number().required('User role is required'),
  type_id: yup.string().required('User type is required'),
  zip_code: yup
    .string()
    .required('Zip/Pin code is required')
    .matches(/^[0-9]{6}$/, 'Zip/Pin code should be exactly 6 digits'),
  country: yup
    .string()
    .required('Country is required')
    .oneOf(['India'], 'Country must be India'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required')
});

export const AddServiceProvider = ({ onClose, onSubmit, isSubmitting }) => {
  // Initialize react-hook-form with Yup validation
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  // Local state for address fields
  const [city, setCity] = useState('Select');
  const [state, setState] = useState('-');
  const [cityDropdownOptions, setCityDropdownOptions] = useState([]);

  // Local state for service provider categories
  const [categoriesForDropdown, setCategoriesForDropdown] = useState([]);

  // Fetch all service provider categories
  const { data: allCategories } = useQuery({
    queryKey: ['getAllCategoriesForServiceProvider'],
    queryFn: getAllCategoriesForServiceProvider
  });

  useEffect(() => {
    if (Array.isArray(allCategories)) {
      setCategoriesForDropdown(allCategories);
    }
  }, [allCategories]);

  // Form submission handler
  const onSubmitForm = (data) => {
    const formattedData = {
      phone_number: data.phone_number,
      email: data.email,
      name: data.name,
      category_id: data.category_id,
      type: data.type_id,
      is_active: false,
      pincode: data.zip_code,
      country: data.country,
      state: data.state,
      city: data.city
    };
    console.log(formattedData, 'formattedData');
    onSubmit(formattedData);
  };

  // Handler to sanitize the phone number input
  const handlePhoneNumber = useCallback(
    (val) => {
      const newVal = val.replace(/[^0-9]/g, '');
      setValue('phone_number', newVal);
    },
    [setValue]
  );

  // Handler to sanitize the name input (only alphabets and spaces allowed)
  const handleName = useCallback(
    (val) => {
      const newVal = val.replace(/[^a-zA-Z\s]/g, '');
      setValue('name', newVal);
    },
    [setValue]
  );

  // Handler for email validation; sets the value if valid
  const handleEmailValidation = useCallback(
    (val) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(val)) {
        setValue('email', val);
      } else {
        console.log('Invalid email format');
      }
    },
    [setValue]
  );

  // Handler for pincode changes
  const handlePincodeChange = useCallback(
    async (pincode) => {
      const cleanedPincode = pincode.replace(/[^0-9]/g, '');
      setValue('zip_code', cleanedPincode);

      if (cleanedPincode.length === 6) {
        try {
          const pincodeList = await getStateAndCity(cleanedPincode);
          const postOfficeList = pincodeList[0].PostOffice;

          if (postOfficeList.length > 0) {
            const newState = postOfficeList[0].State;
            // Determine unique circles and tehsils from the post office data
            const uniqueCircles = [
              ...new Set(
                postOfficeList
                  .map((office) => {
                    const circle =
                      office.Name && office.Name !== 'NA' && office.Name.trim() !== ''
                        ? office.Name
                        : office.Block;
                    return circle && circle !== 'NA' && circle.trim() !== '' ? circle : null;
                  })
                  .filter(Boolean)
              )
            ];
            const uniqueTehsils = [...new Set(postOfficeList.map((office) => office.Block))];

            setCityDropdownOptions(
              uniqueTehsils.map((tehsil) => ({ value: tehsil, label: tehsil }))
            );
            const newCity = uniqueCircles[0];

            setValue('state', newState);
            setValue('city', uniqueTehsils[0]);

            setState(newState);
            setCity(newCity);
          } else {
            setValue('state', '-');
            setValue('city', 'Select');
            setCityDropdownOptions([]);
            setState('-');
            setCity('Select');
          }
        } catch (error) {
          toast.error('The entered pincode is invalid. Please enter a valid pincode.');
          setValue('state', '-');
          setValue('city', 'Select');
          setCityDropdownOptions([]);
          setState('-');
          setCity('Select');
        }
      }
    },
    [setValue]
  );

  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Service Provider Details
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
        {/* Basic Details */}
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={100}
            label={'Full Name'}
            isRequired={true}
            register_key={'name'}
            register={register}
            type={'text'}
            errors={errors.name}
            onType={handleName}
          />
        </div>
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Phone Number'}
            register={register}
            isRequired={true}
            register_key={'phone_number'}
            type={'text'}
            maxLength={10}
            errors={errors.phone_number}
            onType={handlePhoneNumber}
          />
          <FormInput
            width={48}
            label={'Email Address'}
            register={register}
            isRequired={true}
            register_key={'email'}
            type={'text'}
            errors={errors.email}
            onType={handleEmailValidation}
          />
        </div>
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Service Provider Category'}
            register={register}
            isRequired={true}
            register_key={'category_id'}
            type={'dropdown'}
            options={[
              { value: '', label: 'Select Category', disabled: true, selected: true },
              ...categoriesForDropdown.map((item) => ({
                value: item.id,
                label: item.name.trim()
              }))
            ]}
            errors={errors.category_id}
          />
          <FormInput
            width={48}
            label={'Service Provider Type'}
            register={register}
            isRequired={true}
            register_key={'type_id'}
            type={'dropdown'}
            options={[
              { value: '', label: 'Select Type' },
              { value: 'individual', label: 'Individual' },
              { value: 'company', label: 'Company' }
            ]}
            errors={errors.type_id}
          />
        </div>

        {/* Address Details */}
        <div className="flex flex-col justify-between items-start gap-4">
          <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
            Address Details
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between space-x-4">
            <FormInput
              width={48}
              register={register}
              isRequired
              register_key={'zip_code'}
              label={'Zip/Pin Code'}
              type={'text'}
              maxLength={6}
              errors={errors.zip_code}
              onType={handlePincodeChange}
            />
            <FormInput
              width={48}
              register={register}
              isRequired
              register_key={'country'}
              label={'Select Country'}
              valuePre={'India'}
              errors={errors.country}
              CustomStyle={{ pointerEvents: 'none' }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between space-x-4">
            <FormInput
              width={48}
              register={register}
              isRequired
              register_key={'state'}
              label={'State'}
              errors={errors.state}
              valuePre={state}
              CustomStyle={{ pointerEvents: 'none' }}
            />
            <FormInput
              width={48}
              register={register}
              isRequired
              register_key={'city'}
              label={'City'}
              defaultValuePre={city}
              type={'dropdown'}
              options={cityDropdownOptions}
              errors={errors.city}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddServiceProvider;
