import { io } from 'socket.io-client';
import Cookies from 'js-cookie';
// 
// Socket Ip 'http://65.1.235.13:3000'

// const SOCKET_URL = 'http://13.203.57.170:3000';
const SOCKET_URL = 'https://livefeed.housingram.com';

const socket = io(SOCKET_URL, {
  reconnectionDelay: 0,
  auth: {
    token: Cookies.get('token')
  },
  transports: ['websocket']
});

// Log on successful connection
socket.on('connect', () => {
  console.log(`Connected to socket server with ID: ${socket.id}`);
});

// Log on connection error
socket.on('connect_error', (error) => {
  console.error('Connection error:', error.message);
});

export default socket;
