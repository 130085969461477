import React, { useCallback, useEffect, useState } from 'react';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Search from '../../shared/components/ui/Search';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import filter from '../../../modules/shared/assets/svg/filter.svg';
import Table from '../../shared/components/layout/Table';
import { transformData } from '../../super-admin/utils/transformData';
import { AddServiceProvider } from '../components/forms/AddServiceProvider';
import Modal from '../../shared/components/ui/Modal';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { filterData } from '../../shared/utils/filterData';
import {
  getAllServiceProvider,
  createServiceProvider,
  deleteServiceProvider
} from '../services/api/implementation/impl';
import { useMutation, useQuery } from '@tanstack/react-query';
import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';

// Dummy data for service providers
const initialServiceProviders = [
  {
    id: 1,
    name: 'John Doe',
    email: 'johndoe@example.com',
    phone_number: '1234567890',
    role_name: 'Contractor',
    is_active: true
  },
  {
    id: 2,
    name: 'Jane Doe',
    email: 'janedoe@example.com',
    phone_number: '0987654321',
    role_name: 'Painter',
    is_active: true
  },
  {
    id: 3,
    name: 'Alice Smith',
    email: 'alice@example.com',
    phone_number: '1112223333',
    role_name: 'Plumber',
    is_active: false
  }
];

const ServiceProviderList = () => {
  const [OpenAddUser, setOpenAddUser] = useState(false);
  const [isOpenEditUser, setIsOpenEditUser] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [serviceProviders, setServiceProviders] = useState(initialServiceProviders);
  const [filteredData, setFilteredData] = useState(initialServiceProviders);
  // pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  const {
    data: allServiceProvider,
    refetch: refetchServiceProviders,
    isFetching
  } = useQuery({
    queryKey: ['serviceProvider', page],
    queryFn: () => getAllServiceProvider(page, limit),
    keepPreviousData: true
  });

  const totalPages = allServiceProvider?.meta
    ? Math.ceil(allServiceProvider?.meta.total / limit)
    : 1;

  console.log('allServiceProvider', allServiceProvider);

  useEffect(() => {
    if (allServiceProvider?.data) {
      const flattedArray = allServiceProvider?.data?.map((item) => {
        const { user, ...rest } = item;
        const flatUser = user
          ? Object.entries(user).reduce((acc, [key, value]) => {
              acc[`user_${key}`] = value;
              return acc;
            }, {})
          : {};
        return { ...rest, ...flatUser };
      });
      console.log(flattedArray, 'flattedArray');
      setServiceProviders(flattedArray);
      setFilteredData(flattedArray);
    }
  }, [allServiceProvider?.data]);

  console.log(serviceProviders, 'allServiceProvider');

  const { mutate: handleCreateServiceProvider } = useMutation({
    mutationFn: (payload) => createServiceProvider(payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (data) => {
      setIsSubmitting(false);
      toast.success('Service Provider created successfully');
      setServiceProviders([...serviceProviders, data]);
      setFilteredData([...serviceProviders, data]);
      setOpenAddUser(false);
      refetchServiceProviders();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new service provider', err);
      toast.error('Error creating new service provider', err);
    }
  });

  //write the delete api call here
  const { mutate: handleDeleteServiceProvider } = useMutation({
    mutationFn: (id) => deleteServiceProvider(id),
    onSuccess: async (data) => {
      toast.success('Service Provider deleted successfully');
      refetchServiceProviders();
    },
    onError: (err) => {
      console.error('Error deleting service provider', err);
      toast.error('Error deleting service provider', err);
    }
  });

  const { reset } = useForm();

  const handleSearch = (data) => {
    setSearchQuery(data);
  };

  const handleOnClickSearch = () => {
    const filtered = serviceProviders.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    setSearchQuery('');
  };

  const handleToggleAddUser = () => {
    setOpenAddUser(!OpenAddUser);
  };

  const handleToggleEditUser = (id) => {
    setIsOpenEditUser(!isOpenEditUser);
  };

  const handleNavigateUser = (id) => {
    // Navigate logic here if needed
  };

  const handleDeleteUser = (userId) => {
    // const updatedUsers = filteredData.filter(user => user.id !== userId);
    // setServiceProviders(updatedUsers);
    // setFilteredData(updatedUsers);
    handleDeleteServiceProvider(userId);
    toast.success('User deleted successfully');
  };

  const usersDataFormat = [
    'id',
    'user_name',
    'user_email',
    'user_phone_number',
    // 'category',
    'type',
    // 'actions'
  ];

  const onSubmit_User = (data) => {
    console.log(data, 'test data');
    handleCreateServiceProvider(data);
    refetchServiceProviders();
  };

  const handleSearchChange = useCallback(
    (incomingQuery) => {
      setSearchQuery(incomingQuery); // Update the search query state
      if (incomingQuery) {
        const lowercasedQuery = incomingQuery.toLowerCase(); // Convert the query to lowercase for case-insensitive comparison
        const filteredUsers = serviceProviders.filter((item) => {
          return (
            item.user_name?.toLowerCase().includes(lowercasedQuery) ||
            item.user_email?.toLowerCase().includes(lowercasedQuery) ||
            item.user_phone_number?.toLowerCase().includes(lowercasedQuery) ||
            item.type?.toLowerCase().includes(lowercasedQuery)
          );
        });
        setFilteredData(filteredUsers);
      } else {
        setFilteredData(serviceProviders); // Reset to all data when query is empty
      }
    },
    [serviceProviders]
  );

  const Data = filteredData
    ?.map((item) => {
      if (!item || typeof item !== 'object') {
        console.warn('Invalid item in filteredData:', item);
        return null;
      }
      console.log(item, 'item');

      // Use transformData with the original item and usersDataFormat
      return transformData(item, usersDataFormat);
    })
    .filter(Boolean); // Remove any undefined items
  const handlePageChange = (newPage) => {
    setServiceProviders(initialServiceProviders);
    setFilteredData(initialServiceProviders);
    setSearchQuery('');
    setPage(newPage);
  };

  return (
    <>
      {/* Add service provider form */}
      {OpenAddUser && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Add Service Provider" onClose={handleToggleAddUser}>
          <AddServiceProvider
            isSubmitting={isSubmitting}
            onSubmit={onSubmit_User}
            onClose={handleToggleAddUser}
          />
        </Modal>
      )}

      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18vw] z-40 w-[82vw]">
          <Header data={{ name: 'Service Providers' }} />
        </div>

        {serviceProviders && serviceProviders?.length > 0 ? (
          <>
            {/* Main content */}
            <div className="flex z-20 items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
              {/* Pass searchQuery and handleSearchChange as props to Search component */}
              <Search
                onChange={handleSearchChange}
                query={searchQuery}
                onClick={handleOnClickSearch}
              />
              <div className="flex gap-5 cursor-pointer">
                <div>{/* <img src={filter} alt="filter" /> */}</div>
                <Button onClick={handleToggleAddUser}>Add Service Provider</Button>
              </div>
            </div>

            {/* Grid */}
            <GridWrapper width={100}>
              <div className="mt-[12rem] h-[28rem] relative">
                <Table
                  headers={['Name', 'Email', 'Phone', 'Type']}
                  data={Data}
                  identifier="id"
                  onEdit={handleToggleEditUser}
                  onView={handleNavigateUser}
                  onDelete={handleDeleteUser}
                  isDeleteEnable={true}
                  isEditEnable={false}
                />
                <PaginationComponent
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  isFetching={isFetching}
                />
              </div>
            </GridWrapper>
          </>
        ) : (
          <NoDataFound buttonText="Add Service Provider" onButtonClick={handleToggleAddUser} />
        )}
      </Layout>
    </>
  );
};

export default ServiceProviderList;
