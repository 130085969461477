import React, { useCallback, useEffect, useState } from 'react';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Search from '../../shared/components/ui/Search';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import filter from '../../../modules/shared/assets/svg/filter.svg';
import Table from '../../shared/components/layout/Table';
import { transformData } from '../../super-admin/utils/transformData';
import { AddVendor } from '../components/forms/AddVendor';
import Modal from '../../shared/components/ui/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { filterData } from '../../shared/utils/filterData';
import {
  createVendor,
  getVendorById,
  getVendorDeleteId
} from '../services/api/implementation/impl';
import { useMutation, useQuery } from '@tanstack/react-query';

import { getAllVendor } from '../services/api/implementation/impl';
import PaginationComponent from '../../shared/components/PaginationComponent';

// Dummy data for vendors
const initialVendors = [
  {
    id: 1,
    name: 'Vendor A',
    contact_person_name: 'Rahul',
    email: 'vendorA@example.com',
    phone_number: '1234567890',
    category: 'Category 1',
    status: 'Active'
  },
  {
    id: 2,
    name: 'Vendor B',
    contact_person_name: 'Rakesh',
    email: 'vendorB@example.com',
    phone_number: '0987654321',
    category: 'Category 2',
    status: 'Inactive'
  },
  {
    id: 3,
    name: 'Vendor C',
    contact_person_name: 'Prakesh',
    email: 'vendorC@example.com',
    phone_number: '1112223333',
    category: 'Category 3',
    status: 'Active'
  }
];

const VendorList = () => {
  /* 
    Hooks
  */
  const navigate = useNavigate();

  // State for pagination, search, modal control, and vendors data
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page
  const [searchQuery, setSearchQuery] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [OpenAddVendor, setOpenAddVendor] = useState(false);
  const [isOpenEditVendor, setIsOpenEditVendor] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // const { data: allVendors, refetch: refetchVendors } = useQuery({
  //   queryKey: ['vendor'],
  //   queryFn: getAllVendor
  // });

  // const { data: DeleteVendorById, refetch: refetchDeleteById } = useQuery({
  //   queryKey: ['deletebyId'],
  //   queryFn: getVendorById
  // });

  // Fetch vendors data with pagination
  const {
    data: allVendors,
    refetch: refetchVendors,
    isFetching
  } = useQuery({
    queryKey: ['vendor', page],
    queryFn: () => getAllVendor(page, limit),
    keepPreviousData: true
  });

  // Calculate total pages based on the total number of items and the limit
  const totalPages = allVendors?.meta ? Math.ceil(allVendors.meta.total / limit) : 1;

  //refetch vendors when component mounts
  useEffect(() => {
    refetchVendors();
  }, []);

  console.log('vendors', allVendors);

  //stare the all vendors in the vendors state
  useEffect(() => {
    if (allVendors?.data) {
      setVendors(allVendors?.data);
      setFilteredData(allVendors?.data);
    }
  }, [allVendors?.data]);

  const { mutate: handleCreateVendor } = useMutation({
    mutationFn: (payload) => createVendor(payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (data) => {
      setIsSubmitting(false);
      toast.success('Vendor created successfully');
      refetchVendors();
      setVendors([...vendors, data]);
      setFilteredData([...vendors, data]);
      setOpenAddVendor(false);
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new vendor', err);
      toast.error('Error creating new vendor', err);
    }
  });

  const handleSearch = (data) => {
    setSearchQuery(data);
  };

  const handleOnClickSearch = () => {
    const filtered = vendors.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    setSearchQuery('');
  };

  /* 
    Event handlers
  */
  const handleToggleAddVendor = () => {
    setOpenAddVendor(!OpenAddVendor);
  };

  const handleToggleEditVendor = (id) => {
    setIsOpenEditVendor(!isOpenEditVendor);
  };

  const handleNavigateVendor = (id) => {
    navigate(`/builder/vendors/${id}`);
  };

  const handleDeleteVendor = (vendorId) => {
    console.log({ vendorId });
    getVendorDeleteId(parseInt(vendorId))
      .then((res) => {
        console.log({ res });
        // setVendors(res)
        setFilteredData(res);
        toast.success('Vendor deleted successfully');
        refetchVendors();
      })
      .catch((error) => {
        toast.success('Vendor', error);
        console.log('delete the vendor', error);
      });
    //  const updatedVendors = filteredData.filter(vendor => vendor.id !== vendorId);
    // setVendors(updatedVendors);
  };

  const vendorsDataFormat = [
    'id',
    'businessName',
    'name',
    'email',
    'phone_number',
    'status',
    'actions'
  ];

  const { reset } = useForm();

  useEffect(() => {
    setFilteredData(vendors);
  }, [vendors]);

  const handleSearchChange = useCallback(
    (incomingQuery) => {
      const lowerCaseQuery = incomingQuery.toLowerCase();

      if (incomingQuery) {
        const filteredVendors = vendors.filter((vendor) => {
          // Search across multiple fields
          return (
            vendor.businessName?.toLowerCase().includes(lowerCaseQuery) ||
            vendor.user?.name?.toLowerCase().includes(lowerCaseQuery) ||
            vendor.user?.email?.toLowerCase().includes(lowerCaseQuery) ||
            vendor.user?.phone_number?.toLowerCase().includes(lowerCaseQuery)
          );
        });

        setFilteredData(filteredVendors);
      } else {
        // Reset to all vendors if query is empty
        setFilteredData(vendors);
      }
    },
    [vendors]
  );

  const handleVendorFormat = (item) => {
    return {
      id: item.id,
      name: item?.user?.name,
      businessName: item?.businessName,
      email: item?.user?.email,
      phone_number: item?.user?.phone_number,
      status: item?.is_onboarded == true ? 'Active' : 'Inactive'
    };
  };

  // Transformation of the vendor data
  const transformedData = filteredData?.map((item) =>
    transformData(handleVendorFormat(item), vendorsDataFormat)
  );

  // onSubmit button function for adding vendor
  const onSubmit_Vendor = (data) => {
    const newVendor = { ...data, id: vendors.length + 1 };
    setVendors([...vendors, newVendor]);
    setFilteredData([...vendors, newVendor]);
    setOpenAddVendor(false);
    toast.success('Vendor created successfully');
    reset();
  };

  const handlePageChange = (newPage) => {
    setVendors([]);
    setFilteredData([]);
    setSearchQuery('');
    setPage(newPage);
  };

  return (
    <>
      {/* Add vendor form */}
      {OpenAddVendor && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Add Vendor" onClose={handleToggleAddVendor}>
          <AddVendor
            isSubmitting={isSubmitting}
            onSubmit={handleCreateVendor}
            onClose={handleToggleAddVendor}
          />
        </Modal>
      )}

      {/* Edit vendor form */}
      {isOpenEditVendor && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Edit Vendor" onClose={() => setIsOpenEditVendor(false)}>
          {/* Add your EditVendor form here */}
        </Modal>
      )}

      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18vw] z-40 w-[82vw]">
          <Header data={{ name: 'Vendors' }} />
        </div>

        {/* Main content */}
        <div className="flex z-20 items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
          {/* Pass searchQuery and handleSearchChange as props to Search component */}
          <Search onChange={handleSearchChange} query={searchQuery} onClick={handleOnClickSearch} />
          <div className="flex gap-5 cursor-pointer">
            <div>{/* <img className="" src={filter} alt="filter" /> */}</div>
            <Button onClick={handleToggleAddVendor}>Add Vendor</Button>
          </div>
        </div>

        {/* Grid */}
        <GridWrapper width={100}>
          <div className="mt-[12rem] h-[28rem] relative">
            <Table
              headers={[
                'Business Name',
                'Contact Person Name',
                'Email',
                'Phone',
                'Status',
                'Action'
              ]}
              data={transformedData}
              identifier={'id'}
              onEdit={handleToggleEditVendor}
              onView={handleNavigateVendor}
              // onDelete={handleDeleteVendor}
              // isDeleteEnable={false}
              isViewEnable={true}
              isEditEnable={false}
            />

            <PaginationComponent
              page={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              isFetching={isFetching}
            />
          </div>
        </GridWrapper>
      </Layout>
    </>
  );
};

export default VendorList;
