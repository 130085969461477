import React, { useCallback, useState, useEffect } from 'react';
import {
  getAllCompanies,
  getStateAndCity,
  getLandById
} from '../../services/api/implementation/impl';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import { useForm, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { uploadFile, multipleUploadFiles } from '../../../builder/features/files';

const EditLand = ({ onClose, onSubmit, id, isSubmitting }) => {
  const dispatch = useDispatch();

  // Fetch land data
  const { data: land, isLoading } = useQuery({
    queryKey: ['land', id],
    queryFn: () => getLandById(id),
    enabled: !!id
  });

  // Fetch companies data
  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: () => getAllCompanies()
  });

  // Initialize form methods
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      total_mainAreaType: 'sq/hectare',
      total_mainArea: 0,
      total_subArea: 0
    }
  });

  // Local state
  const [owners, setOwners] = useState([]);
  const [state, setState] = useState('-');
  const [district, setDistrict] = useState('Select');
  const [tehsil, setTehsil] = useState('Select');
  const [city, setCity] = useState('Select');
  const [cityDropdownOptions, setCityDropdownOptions] = useState([]);
  const [districtDropdownOptions, setDistrictDropdownOptions] = useState([]);
  const [tehsilDropdownOptions, setTehsilDropdownOptions] = useState([]);
  const [totalMainArea, setTotalMainArea] = useState(0);
  const [totalSubArea, setTotalSubArea] = useState(0);
  const [totalMainAreaType, setTotalMainAreaType] = useState('sq/hectare'); // Default unit
  const totalSubAreaType = 'sq/gunta'; // Fixed unit

  // Redux selectors for file URLs
  const fileUrls = useSelector((state) => state.file.fileUrls);
  const MultipleFiles = useSelector((state) => state.file.multifiles);

  // Initialize owners and form values when land data is fetched
  useEffect(() => {
    if (land) {
      const initialOwners = land.ownerLandMappings.map((ownerMapping, ownerIndex) => ({
        ownerId: Date.now() + ownerIndex, // Unique ID
        
        khasras: ownerMapping.owner.khasra.map((khasra, khasraIndex) => ({
          khasraId: `${ownerIndex}-${khasraIndex}-${Date.now()}`, // Unique ID
          khasra_number: khasra.number,
          mainArea: khasra.mainArea,
          subArea: khasra.subArea || 0, // Assuming subArea exists
          khasra_map: khasra.khasra_map_link,
          khasra_p1b2: khasra.khasra_p1b2_link,
          land_use_map: khasra.land_use_map
        }))
      }));

      setOwners(initialOwners);

      // Set form field values for owners and khasras
      initialOwners.forEach((owner, ownerIndex) => {
        const ownerData = land.ownerLandMappings[ownerIndex].owner;
        setValue(`name-${owner.ownerId}`, ownerData.name);
        setValue(`email-${owner.ownerId}`, ownerData.email);
        setValue(`phone_number-${owner.ownerId}`, ownerData.phone_number);
        setValue(`aadhar-${owner.ownerId}`, ownerData.aadhar);
        setValue(`pan-${owner.ownerId}`, ownerData.pan_number);
        if (ownerData.diversionOrderFiles) {
          // Extract URLs from diversionOrderFiles
          const diversionOrderURLs = ownerData.diversionOrderFiles.map(file => ({
            name: file.name,
            url: file.url
          }));
          dispatch(
            multipleUploadFiles({
              fileType: `diversion_order-${owner.ownerId}`,
              urls: diversionOrderURLs
            })
          );
        }
        owner.khasras.forEach((khasra) => {
          setValue(`khasra_number-${khasra.khasraId}`, khasra.khasra_number);
          setValue(`mainArea-${khasra.khasraId}`, khasra.mainArea);
          setValue(`subArea-${khasra.khasraId}`, khasra.subArea);

          if (khasra.khasra_map) {
            dispatch(
              uploadFile({ fileType: `khasra_map-${khasra.khasraId}`, url: khasra.khasra_map })
            );
          }

          if (khasra.khasra_p1b2) {
            dispatch(
              uploadFile({ fileType: `khasra_p1b2-${khasra.khasraId}`, url: khasra.khasra_p1b2 })
            );
          }

          if (khasra.land_use_map) {
            dispatch(
              uploadFile({ fileType: `land_use_map-${khasra.khasraId}`, url: khasra.land_use_map })
            );
          }
        });
      });

      // Set other land details
      Object.entries(land).forEach(([key, value]) => {
        setValue(key, value);
      });

      if (land.pincode) {
        handlePincodeChange(land.pincode);
      }

      if (land.company_id && companies?.length > 0) {
        const selectedCompany = companies.find(
          (item) => parseInt(item.id) === parseInt(land.company_id)
        );
        setValue('company_id', selectedCompany?.id || '');
      }

      // Pre-fill additional document files
      dispatch(
        multipleUploadFiles({
          fileType: 'registry_document',
          urls: land.registryDocs?.map((doc) => ({ name: doc.name, url: doc.url })) || []
        })
      );
      dispatch(
        multipleUploadFiles({
          fileType: 'advocate_document',
          urls: land.advocateSearches?.map((doc) => ({ name: doc.name, url: doc.url })) || []
        })
      );
      dispatch(
        multipleUploadFiles({
          fileType: 'land_map',
          urls: land.additionalDocuments?.map((doc) => ({ name: doc.name, url: doc.url })) || []
        })
      );
    }
  }, [land, setValue, companies, dispatch]);

  // Pincode change handler
  const handlePincodeChange = useCallback(
    async (pincode) => {
      const cleanedPincode = pincode.toString().replace(/[^0-9]/g, '');
      setValue('pincode', cleanedPincode);
      if (cleanedPincode.length === 6) {
        try {
          const pincodeList = await getStateAndCity(cleanedPincode);
          const postOfficeList = pincodeList[0]?.PostOffice || [];
          if (postOfficeList.length > 0) {
            const fetchedState = postOfficeList[0].State;
            const uniqueDistricts = [...new Set(postOfficeList.map((office) => office.District))];
            const uniqueTehsils = [...new Set(postOfficeList.map((office) => office.Block))];
            const uniqueCities = [...new Set(postOfficeList.map((office) => office.Name))];

            setState(fetchedState);
            setDistrict(uniqueDistricts[0]);
            setTehsil(uniqueTehsils[0]);
            setCity(uniqueCities[0]);

            setDistrictDropdownOptions(
              uniqueDistricts.map((district) => ({
                value: district,
                label: district
              }))
            );
            setTehsilDropdownOptions(
              uniqueTehsils.map((tehsil) => ({
                value: tehsil,
                label: tehsil
              }))
            );
            setCityDropdownOptions(
              uniqueCities.map((city) => ({
                value: city,
                label: city
              }))
            );

            setValue('state', fetchedState);
            setValue('district', uniqueDistricts[0]);
            setValue('tehsil', uniqueTehsils[0]);
            setValue('city', uniqueCities[0]);
          } else {
            resetLocationFields();
          }
        } catch (error) {
          toast.error('Invalid pincode. Please enter a valid pincode.');
          resetLocationFields();
        }
      }
    },
    [setValue]
  );

  const resetLocationFields = () => {
    setValue('state', '-');
    setValue('district', 'Select');
    setValue('tehsil', 'Select');
    setValue('city', 'Select');
    setState('-');
    setDistrict('Select');
    setTehsil('Select');
    setCity('Select');
    setDistrictDropdownOptions([]);
    setTehsilDropdownOptions([]);
    setCityDropdownOptions([]);
  };

  // Handlers for area changes
  const handleMainAreaChange = useCallback(
    (ownerId, khasraId, value) => {
      const cleanedValue = value.replace(/[^0-9.]/g, '').slice(0, 8);
      setOwners((prevOwners) =>
        prevOwners.map((owner) =>
          owner.ownerId === ownerId
            ? {
                ...owner,
                khasras: owner.khasras.map((khasra) =>
                  khasra.khasraId === khasraId
                    ? { ...khasra, mainArea: parseFloat(cleanedValue) || 0 }
                    : khasra
                )
              }
            : owner
        )
      );
      setValue(`mainArea-${khasraId}`, cleanedValue);
    },
    [setValue]
  );

  const handleSubAreaChange = useCallback(
    (ownerId, khasraId, value) => {
      const cleanedValue = value.replace(/[^0-9]/g, '').slice(0, 5);
      setOwners((prevOwners) =>
        prevOwners.map((owner) =>
          owner.ownerId === ownerId
            ? {
                ...owner,
                khasras: owner.khasras.map((khasra) =>
                  khasra.khasraId === khasraId
                    ? { ...khasra, subArea: parseInt(cleanedValue) || 0 }
                    : khasra
                )
              }
            : owner
        )
      );
      setValue(`subArea-${khasraId}`, cleanedValue);
    },
    [setValue]
  );

  // Update total main and sub areas whenever owners state changes
  useEffect(() => {
    const totalMain = owners.reduce(
      (sum, owner) =>
        sum + owner.khasras.reduce((kSum, khasra) => kSum + (parseFloat(khasra.mainArea) || 0), 0),
      0
    );
    const totalSub = owners.reduce(
      (sum, owner) =>
        sum + owner.khasras.reduce((kSum, khasra) => kSum + (parseInt(khasra.subArea) || 0), 0),
      0
    );
    setTotalMainArea(totalMain);
    setTotalSubArea(totalSub);
    setValue('total_main_area', totalMain);
    setValue('total_sub_area', totalSub);
  }, [owners, setValue]);

  // Handlers to add/remove owners and khasras
  const handleAddKhasraBlock = useCallback((ownerId) => {
    setOwners((prevOwners) =>
      prevOwners.map((owner) =>
        owner.ownerId === ownerId
          ? {
              ...owner,
              khasras: [
                ...owner.khasras,
                {
                  khasraId: `${ownerId}-${Date.now()}`,
                  khasra_number: '',
                  mainArea: '',
                  subArea: '',
                  khasra_map: '',
                  khasra_p1b2: '',
                  land_use_map: ''
                }
              ]
            }
          : owner
      )
    );
  }, []);

  const handleRemoveKhasraBlock = useCallback((ownerId, khasraId) => {
    setOwners((prevOwners) =>
      prevOwners.map((owner) =>
        owner.ownerId === ownerId
          ? {
              ...owner,
              khasras: owner.khasras.filter((khasra) => khasra.khasraId !== khasraId)
            }
          : owner
      )
    );
  }, []);

  const handleAddOwnerBlock = useCallback(() => {
    setOwners((prevOwners) => [
      ...prevOwners,
      {
        ownerId: Date.now(),
        khasras: [
          {
            khasraId: `${Date.now()}`,
            khasra_number: '',
            mainArea: '',
            subArea: '',
            khasra_map: '',
            khasra_p1b2: '',
            land_use_map: ''
          }
        ]
      }
    ]);
  }, []);

  const handleRemoveOwnerBlock = useCallback((ownerId) => {
    setOwners((prevOwners) => prevOwners.filter((owner) => owner.ownerId !== ownerId));
  }, []);

  // Handlers for other input changes
  const handleAadharChange = useCallback(
    (aadhar, key) => {
      const cleanedAadhar = aadhar.replace(/[^0-9]/g, '').slice(0, 12); // Aadhar card is 12 digits
      setValue(key, cleanedAadhar);
    },
    [setValue]
  );

  const handlePhoneNumber = useCallback(
    (val, key) => {
      const newVal = val.replace(/[^0-9]/g, '');
      setValue(key, newVal);
    },
    [setValue]
  );

  const handleLandName = useCallback(
    (val) => {
      const newVal = val.replace(/[^a-zA-Z\s]/g, ''); // Only allow alphabets and spaces
      setValue('name', newVal);
    },
    [setValue]
  );

  const handleOwnerName = useCallback(
    (val, key) => {
      const newVal = val.replace(/[^a-zA-Z\s]/g, ''); // Only allow alphabets and spaces
      setValue(key, newVal);
    },
    [setValue]
  );

  const handlePanChange = useCallback(
    (pan, key) => {
      const cleanedPan = pan.toUpperCase().replace(/[^A-Z0-9]/g, '');
      setValue(key, cleanedPan);
    },
    [setValue]
  );

  const handleLocalityChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^a-zA-Z\s]/g, '').slice(0, 50);
      setValue('area', cleanedValue);
    },
    [setValue]
  );

  // Watchers
  const watchedMeasuringUnit = useWatch({
    control,
    name: 'total_mainAreaType',
    defaultValue: 'sq/hectare'
  });

  const watchedStateChange = useWatch({
    control,
    name: 'state'
  });

  const [watchState, setWatchState] = useState(null);

  useEffect(() => {
    setTotalMainAreaType(watchedMeasuringUnit);
  }, [watchedMeasuringUnit]);

  useEffect(() => {
    setWatchState(watchedStateChange?.toLowerCase());
  }, [watchedStateChange]);

  // Form submission handler
  const handleOnSubmitEditLand = (formData) => {
    const transformedData = {
      owner: owners.map((owner) => ({
        name: formData[`name-${owner.ownerId}`],
        email: formData[`email-${owner.ownerId}`],
        phone_number: formData[`phone_number-${owner.ownerId}`],
        aadhar: formData[`aadhar-${owner.ownerId}`],
        pan_number: formData[`pan-${owner.ownerId}`],
        // diversionOrderFiles: fileUrls[`diversion_order-${owner.ownerId}`] || [],
        diversion_order_link: MultipleFiles[`diversion_order-${owner.ownerId}`] || [],
        khasra: owner.khasras.map((khasra) => ({
          number: formData[`khasra_number-${khasra.khasraId}`],
          mainArea: parseFloat(formData[`mainArea-${khasra.khasraId}`]),
          subArea: parseInt(formData[`subArea-${khasra.khasraId}`]),
          mainAreaType: formData.total_mainAreaType,
          subAreaType: totalSubAreaType,
          khasra_map_link: fileUrls[`khasra_map-${khasra.khasraId}`] || [],
          khasra_p1b2_link: fileUrls[`khasra_p1b2-${khasra.khasraId}`] || [],
          land_use_map: fileUrls[`land_use_map-${khasra.khasraId}`] || []
        }))
      })),
      name: formData.name,
      company_id: parseInt(formData.company_id),
      registry_doc_link: MultipleFiles?.registry_document || [],
      advocate_search_link: MultipleFiles?.advocate_document || [],
      measuringUnit: formData.total_mainAreaType,
      total_mainArea: parseFloat(formData.total_main_area),
      total_subArea: parseInt(formData.total_sub_area),
      total_mainAreaType: formData.total_mainAreaType,
      total_subAreaType: totalSubAreaType,
      pincode: parseInt(formData.pincode),
      state: formData.state,
      district: formData.district,
      tehsil: formData.tehsil,
      city: formData.city,
      area: formData.area,
      google_map_link: formData.google_map_link,
      additional_documents_link: MultipleFiles?.land_map || []
    };

    // Call the onSubmit function passed as a prop
    // console.log({transformedData})
    onSubmit(transformedData);
  };

  const formatAreaType = (areaType) => {
    return areaType?.replace('sq/', '');
  };

  if (isLoading) {
    return <div>Loading...</div>; // You can replace this with a spinner
  }

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(handleOnSubmitEditLand)}>
      {/* Land Details Section */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Land Details</span>

        <div className="flex flex-col w-full flex-1 gap-4">
          <div className="flex space-x-8 justify-between items-start w-full">
            <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
              <div className="w-full flex flex-col gap-4 flex-1">
                <div className="flex justify-between space-x-4">
                  <FormInput
                    width={32}
                    register={register}
                    isRequired={true}
                    register_key={'name'}
                    label={'Land Name'}
                    type={'text'}
                    onType={handleLandName}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    register_key={'company_id'}
                    label={'Company name'}
                    type={'dropdown'}
                    options={[
                      { value: '', label: 'Select Company' },
                      ...(companies
                        ? companies.map((item) => ({
                            value: item.id,
                            label: item.company_name.trim()
                          }))
                        : [])
                    ]}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    register_key={'registry_document'}
                    label={'Registry Document'}
                    type={'file-secondary'}
                    prefilledFiles={MultipleFiles['registry_document'] || []}
                  />
                </div>
              </div>

              <div className="w-full flex justify-between space-x-4 items-center pb-6">
                <FormInput
                  width={32}
                  register={register}
                  register_key={'advocate_document'}
                  label={'Advocate Search'}
                  type={'file-secondary'}
                  prefilledFiles={MultipleFiles['advocate_document'] || []}
                />


                <div className="flex flex-col w-[32%]">
                  <label className="text-md text-gray-600 mb-2">Khasra Area Measurement Type</label>

                  <div className="flex items-center justify-between gap-4">
                    {/* Main area measuring unit */}
                    <select
                      className="border border-gray-300 rounded-md px-3 py-2 w-1/2"
                      {...register('total_mainAreaType', { required: true })}>
                      <option value="sq/hectare">
                        Hectares
                      </option>
                      <option value="sq/acres">Acres</option>
                    </select>

                    {/* Sub area measuring unit */}
                    <input
                      className="border border-gray-300 rounded-md px-3 py-2 bg-[#e7e5e4] w-1/2"
                      type="text"
                      placeholder="Guntha"
                      // {...register('sub_area_total_mainAreaType', { required: true })}
                      value={'guntha'}
                      disabled={true}
                    />
                  </div>

                </div>
                <div className="flex w-[32%] gap-4 items-center">
                  <FormInput
                    width={55}
                    register={register}
                    isRequired={true}
                    register_key={'total_main_area'}
                    label={`Total ${watchState === 'karnataka' ? 'Survey' : 'Khasra'} area (${formatAreaType(watchedMeasuringUnit)})`}
                    type={'text'}
                    valuePre={totalMainArea || 0}
                    CustomStyle={{ pointerEvents: 'none', backgroundColor: '#E7E5E4' }}
                  />
                  <FormInput
                    width={40}
                    register={register}
                    isRequired={true}
                    register_key={'total_sub_area'}
                    label={`Total (${formatAreaType(totalSubAreaType) === 'gunta' ? 'guntha' : 'guntha'})`}
                    type={'text'}
                    valuePre={totalSubArea || 0}
                    CustomStyle={{ pointerEvents: 'none', backgroundColor: '#E7E5E4' }}
                  />
                </div>
              </div>


              <div className="w-full flex justify-between space-x-4 items-center">
                <FormInput
                  width={32}
                  register={register}
                  isRequired={true}
                  register_key={'pincode'}
                  label={'Pincode'}
                  type={'text'}
                  maxLength={6}
                  onType={handlePincodeChange}
                />

                <FormInput
                  width={32}
                  register={register}
                  register_key={'land_use_map'}
                  label={'Land use map'}
                  type={'hidden'}
                  prefilledFiles={fileUrls['land_use_map'] || []}
                />

                <FormInput
                  width={32}
                  register={register}
                  isRequired={true}
                  register_key={'state'}
                  label={`State`}
                  errors={errors.state}
                  valuePre={state}
                  CustomStyle={{ pointerEvents: 'none', backgroundColor: '#E7E5E4' }}
                />

                <FormInput
                  width={32}
                  register={register}
                  register_key={'district'}
                  label={'District'}
                  type={'dropdown'}
                  defaultValuePre={district}
                  options={districtDropdownOptions}
                />
              </div>

              <div className="w-full flex justify-start space-x-4 items-center pb-6">
                <FormInput
                  width={32}
                  register={register}
                  register_key={'tehsil'}
                  label={`${watchState === 'karnataka' ? 'Taluk' : 'Tehsil'}`}
                  type={'dropdown'}
                  defaultValuePre={tehsil}
                  options={tehsilDropdownOptions}
                />

                {/* <FormInput
                  width={32}
                  register={register}
                  register_key={'city'}
                  label={'City'}
                  type={'dropdown'}
                  defaultValuePre={city}
                  options={cityDropdownOptions}
                /> */}

                <FormInput
                  width={32}
                  register={register}
                  register_key={'area'}
                  label={'Locality'}
                  type={'text'}
                  onType={handleLocalityChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Owner Details Section */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg w-full pb-2">Owner Details</span>

        {owners.map((owner, ownerIndex) => (
          <div
            className={`flex flex-col w-full flex-1 gap-4 ${
              ownerIndex > 0 ? 'pt-6' : 'pb-6'
            } border-[1px] rounded-md p-3`}
            key={owner.ownerId}>
            <div className="flex space-x-8 justify-between items-start w-full">
              <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
                <div className="w-full flex flex-col gap-4 flex-1">
                  <div className="flex justify-between space-x-4">
                    <FormInput
                      width={32}
                      register={register}
                      isRequired={true}
                      register_key={`name-${owner.ownerId}`}
                      label={'Owner Name'}
                      type={'text'}
                      onType={(val) => handleOwnerName(val, `name-${owner.ownerId}`)}
                    />

                    <FormInput
                      width={32}
                      register={register}
                      isRequired={true}
                      register_key={`email-${owner.ownerId}`}
                      label={'Email'}
                      type={'email'}
                    />

                    <FormInput
                      width={32}
                      register={register}
                      isRequired={true}
                      register_key={`phone_number-${owner.ownerId}`}
                      label={'Mobile Number'}
                      type={'text'}
                      onType={(val) => handlePhoneNumber(val, `phone_number-${owner.ownerId}`)}
                    />
                  </div>
                </div>

                <div className="w-full flex justify-start space-x-6 items-center">
                  <FormInput
                    width={32}
                    register={register}
                    isRequired={true}
                    register_key={`aadhar-${owner.ownerId}`}
                    label={'Aadhar number'}
                    type={'text'}
                    onType={(val) => handleAadharChange(val, `aadhar-${owner.ownerId}`)}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    isRequired={true}
                    register_key={`pan-${owner.ownerId}`}
                    label={'PAN number'}
                    type={'text'}
                    onType={(val) => handlePanChange(val, `pan-${owner.ownerId}`)}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    register_key={`diversion_order-${owner.ownerId}`}
                    label={`${watchState === 'karnataka' ? 'Conversion Order' : 'Diversion Order'}`}
                    type={'file-secondary'}
                    prefilledFiles={MultipleFiles[`diversion_order-${owner.ownerId}`] || []}
                  />
                </div>
              </div>
            </div>

            {/* Khasra Details */}
            {owner.khasras.map((khasra, khasraIndex) => (
              <div key={khasra.khasraId} className="flex flex-col gap-6 bg-gray-50 rounded-md p-3">
                <div className="w-full flex justify-between space-x-4 items-center pt-6">
                  <FormInput
                    width={20}
                    register={register}
                    register_key={`khasra_map-${khasra.khasraId}`}
                    label={`${watchState === 'karnataka' ? 'Survey Map' : 'Khasra Map'}`}
                    type={'file-btn'}
                    prefilledFiles={fileUrls[`khasra_map-${khasra.khasraId}`] || []}
                  />

                  <FormInput
                    width={20}
                    register={register}
                    register_key={`khasra_p1b2-${khasra.khasraId}`}
                    label={`${watchState === 'karnataka' ? 'Podi / Hissas ' : 'Khasra P1B2'}`}
                    type={'file-btn'}
                    accept={'.pdf'}
                    prefilledFiles={fileUrls[`khasra_p1b2-${khasra.khasraId}`] || []}
                  />

                  <FormInput
                    width={20}
                    register={register}
                    register_key={`land_use_map-${khasra.khasraId}`}
                    label={'Land Use Certificate'}
                    type={'file-btn'}
                    accept={'.pdf'}
                    prefilledFiles={fileUrls[`land_use_map-${khasra.khasraId}`] || []}
                  />

                  <FormInput
                    width={30}
                    register={register}
                    isRequired={true}
                    register_key={`khasra_number-${khasra.khasraId}`}
                    label={`${watchState === 'karnataka' ? 'Survey Number' : 'Khasra Number'}`}
                    type={'text'}
                  />
                </div>

                {/* Khasra Area Inputs */}
                <div className="w-full flex justify-start space-x-4 items-center">
                  {/* Main Area Input */}
                  <FormInput
                    width={30}
                    register={register}
                    isRequired={true}
                    register_key={`mainArea-${khasra.khasraId}`}
                    label={`${watchState === 'karnataka' ? 'Survey Area' : 'Khasra Area'} (${formatAreaType(totalMainAreaType)})`}
                    type={'textOnType'}
                    onType={(e) =>
                      handleMainAreaChange(owner.ownerId, khasra.khasraId, e.target.value)
                    }
                  />

                  {/* Sub Area Input (Guntha) */}
                  <FormInput
                    width={30}
                    register={register}
                    isRequired={true}
                    register_key={`subArea-${khasra.khasraId}`}
                    label={`(${formatAreaType(totalSubAreaType) === 'gunta' ? 'guntha' : 'guntha'})`}
                    type={'textOnType'}
                    onType={(e) =>
                      handleSubAreaChange(owner.ownerId, khasra.khasraId, e.target.value)
                    }
                  />
                </div>

                {/* Remove Khasra Button */}
                {owner.khasras.length > 1 && (
                  <div className="w-full flex justify-end mt-4 self-end">
                    <span
                      className="text-stone-700 underline underline-offset-2 transition-all duration-300 hover:scale-[102%] cursor-pointer"
                      onClick={() => handleRemoveKhasraBlock(owner.ownerId, khasra.khasraId)}>
                      - Remove khasra details
                    </span>
                  </div>
                )}
              </div>
            ))}

            {/* Add Khasra Button */}
            <div className="w-full flex justify-end self-end">
              <span
                className="text-stone-700 underline underline-offset-2 transition-all duration-300 hover:scale-[102%] cursor-pointer"
                onClick={() => handleAddKhasraBlock(owner.ownerId)}>
                + Add more khasra details
              </span>
            </div>

            {/* Remove Owner Button */}
            {owners.length > 1 && (
              <div className="w-full flex justify-end mt-4 self-end">
                <span
                  className="text-stone-700 border-customBlue border py-2 px-4 rounded-lg transition-all duration-300 hover:scale-[102%] cursor-pointer"
                  onClick={() => handleRemoveOwnerBlock(owner.ownerId)}>
                  - Remove owner details
                </span>
              </div>
            )}
          </div>
        ))}

        {/* Add Owner Button */}
        <div className="w-full flex justify-end mt-4 self-end">
          <span
            className="text-stone-700 border-customBlue border py-2 px-4 rounded-lg transition-all duration-300 hover:scale-[102%] cursor-pointer"
            onClick={handleAddOwnerBlock}>
            + Add more owner details
          </span>
        </div>
      </div>

      {/* Additional Details Section */}
      <div className="flex flex-col justify-between items-start gap-4 pt-6">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Additional Details
        </span>

        <div className="flex flex-col w-full flex-1 gap-4">
          <div className="flex space-x-8 justify-between items-start w-full">
            <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
              <div className="w-full flex flex-col gap-4 flex-1">
                <div className="flex justify-start space-x-4">
                  <FormInput
                    width={32}
                    register={register}
                    register_key={'google_map_link'}
                    errors={errors.google_map_link}
                    label={'Google Map Link'}
                    type={'textOnType'}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    register_key={'land_map'}
                    label={'Additional Documents'}
                    type={'file-secondary'}
                    prefilledFiles={MultipleFiles['land_map'] || []}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form Actions */}
      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default EditLand;
