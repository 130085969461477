import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GridWrapper from '../../shared/components/layout/GridWrapper';

import Button from '../../shared/components/ui/Button';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';

import company_image from '../../shared/assets/png/company_image.png';
import toast from 'react-hot-toast';
import {
  getAllVendor,
  getVendorById,
  getVendorDeleteId,
  deactivateVendor,
  updateVendor
} from '../services/api/implementation/impl';
import { useMutation, useQuery } from '@tanstack/react-query';
import Modal from '../../shared/components/ui/Modal';
import EditVendor from '../components/forms/EditVendor';
import { set } from 'react-hook-form';

const VendorDetails = () => {
  /* 
    Hooks
  */
  const navigate = useNavigate();
  const { id: userId } = useParams();

  /* 
    Local state
  */
  const [isEnabled, setIsEnabled] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedPermissions, setEditedPermissions] = useState({});
  const [hasEdited, setHasEdited] = useState(false);
  const [user, setUser] = useState(null);
  const [openEditVendor, setOpenEditVendor] = useState(false);

  const { id } = useParams();

  const { data: vendorsbyId, refetch: refetchVendorsById } = useQuery({
    queryKey: ['vendorById'],
    queryFn: () => getVendorById(parseInt(id)),
    enabled: !!id
  });

  const { data: allVendors, refetch: refetchVendors } = useQuery({
    queryKey: ['vendor'],
    queryFn: getAllVendor
  });

  console.log(vendorsbyId, 'vendorsbyId');

  const { mutate: handleToggleStatusVendor } = useMutation({
    mutationFn: (data) => deactivateVendor(data),
    onSuccess: () => {
      toast.success('Status updated successfully');
      navigate(-1);
    },
    onError: (error) => {
      console.error('Error updating status:', error);
      toast.error('Failed to update status!');
    }
  });

  useEffect(() => {
    refetchVendorsById();
  }, [id]);

  /* 
    Demo data fetching
  */
  useEffect(() => {
    // Replace this with your actual user fetching logic
    const fetchUser = () => {
      // Simulated user data
      const demoUser = {
        id: userId,
        name: 'John Doe',
        phone_number: '123-456-7890',
        email: 'john.doe@example.com',
        vendor_category: 'Electrical',
        permissions: {
          view: true,
          edit: false,
          delete: false
        },
        is_active: true
      };
      setUser(demoUser);
    };

    fetchUser();
  }, [userId, hasEdited]);

  /* 
    Event handlers
  */
  const handleRadioChange = (value) => {
    setIsEnabled(value === 'enable');
  };

  const handleToggleEditing = () => {
    // setIsEditing(!isEditing);
    setOpenEditVendor(true);
  };

  const handlePermissionChange = (section, action, value) => {
    setEditedPermissions((prevPermissions) => ({
      ...prevPermissions,
      [section]: {
        ...prevPermissions[section],
        [action]: value
      }
    }));
  };

  const handleSubmit = () => {
    // Simulate API call to update user permissions
    const updatedUser = { ...user, permissions: editedPermissions };
    setUser(updatedUser);
    setHasEdited(!hasEdited);
    setIsEditing(false);
    toast.success('Permissions updated successfully');
  };

  const { mutate: handleEditVendor } = useMutation({
    mutationFn: (payload) => updateVendor(id, payload),
    onSuccess: async (data) => {
      toast.success('Vendor updated successfully');
      setOpenEditVendor(false);
      refetchVendorsById();
    },
    onError: (err) => {
      console.error('Error updating vendor', err);
      toast.error('Error updating vendor', err);
    }
  });

  const handleDeleteUser = () => {
    getVendorDeleteId(parseInt(id))
      .then((res) => {
        console.log({ res });

        toast.success('Vendor deleted successfully');
        refetchVendors();
        navigate(-1);
      })
      .catch((error) => {
        toast.success('Vendor', error);
        console.log('delete the vendor', error);
      });
  };

  const handleDeactivateUser = () => {
    // Simulate API call to deactivate user
    console.log(vendorsbyId, 'vendorsbyId');
    const statusPayload = {
      id: vendorsbyId.id,
      status: vendorsbyId.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    };
    handleToggleStatusVendor(statusPayload);
  };

  /* 
    Header data
  */
  const headerData = [
    {
      _id: 1,
      name: 'Phone number',
      value: vendorsbyId?.user?.phone_number
    },
    {
      _id: 2,
      name: 'Email address',
      value: vendorsbyId?.user?.email
    },
    {
      _id: 3,
      name: 'Vendor Category',
      value: vendorsbyId?.category?.name
    }
  ];

  console.log({ vendorsbyId });

  /* 
    JSX
  */
  return (
    <>
      <Layout>
        {/* Header */}
        <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
          <Header data={{ name: `Vendors > ${vendorsbyId?.user?.name}` }} backArrow={true} />
        </div>

        {openEditVendor && (
          <Modal isDirty={true} confirmOnClose={true} width={56} name="Add Vendor" onClose={() => setOpenEditVendor(false)}>
            <EditVendor
              vendor={vendorsbyId}
              onSubmit={handleEditVendor}
              onClose={() => setOpenEditVendor(false)}
            />
          </Modal>
        )}

        {/* Grid */}
        <GridWrapper width={100}>
          <div className="w-full flex flex-col justify-between items-center gap-8 mt-[6rem]">
            {/* Details card component */}
            <div className="p-8 rounded-lg bg-white w-full shadow-md flex items-center justify-between gap-6 space-x-10">
              {company_image ? (
                <img
                  src={company_image}
                  alt="company_image"
                  className="w-[12rem] bg-center bg-cover !object-cover !object-center"
                />
              ) : (
                <div className="bg-gray-300 w-[12rem] h-[12rem] rounded-full flex justify-center items-center">
                  <span className="text-[8rem] text-white bg-center bg-cover !object-cover !object-center">
                    {vendorsbyId?.user?.name}
                  </span>
                </div>
              )}

              <div className="flex-1 flex flex-col gap-8">
                <div className="flex justify-between items-start">
                  <div className="flex flex-col gap-4">
                    <span className="font-semibold text-xl">{vendorsbyId?.name}</span>
                    <span className="text-gray-400 text-sm">{`Business Name : ${vendorsbyId?.businessName}`}</span>
                    <span className="text-gray-400 text-sm">{`Vendor Type : ${vendorsbyId?.type}`}</span>
                  </div>
                </div>

                <div className="w-[88%] flex justify-between items-center gap-4 flex-wrap">
                  {headerData?.map((item) => (
                    <div className="flex flex-col gap-4  flex-shrink-0 min-w-[30%]" key={item?._id}>
                      <span className="">{item?.name}</span>
                      <span className="text-sm">{item?.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {!isEditing && (
            <div className="w-full flex justify-end items-center space-x-4 pr-4 mt-4">
              {vendorsbyId?.status === 'ACTIVE' && (
                <Button displayType={'secondary'} onClick={handleToggleEditing}>
                  Edit
                </Button>
              )}

              {vendorsbyId?.status === 'ACTIVE' && (
                <Button displayType={'secondary-red'} onClick={handleDeactivateUser}>
                  Deactivate
                </Button>
              )}
              {vendorsbyId?.status === 'INACTIVE' && (
                <Button displayType={'secondary'} onClick={handleDeactivateUser}>
                  Activate
                </Button>
              )}
              {/* <Button displayType={'primary-red'} onClick={handleDeleteUser}>
                Delete
              </Button> */}
            </div>
          )}

          {isEditing && (
            <div className="w-full flex justify-end items-center space-x-4 pr-4 mt-4">
              <Button displayType={'secondary'} onClick={handleToggleEditing}>
                Cancel
              </Button>
              <Button displayType={'primary'} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          )}
        </GridWrapper>
      </Layout>
    </>
  );
};

export default VendorDetails;
