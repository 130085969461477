import React, { useEffect, useState, useMemo } from 'react';
import Button from '../../shared/components/ui/Button';
import Table from '../../shared/components/layout/Table';
import { useForm } from 'react-hook-form';
import { transformData } from '../../super-admin/utils/transformData';
import Modal from '../../shared/components/ui/Modal';
import { CreatePLC } from '../components/forms/CreatePLC';
import { AddPLCUnit } from '../components/forms/AddPLCUnit';
import {
  createPLCList,
  createPLCUnit,
  getAllBlocks,
  getPlcbyBlockId,
  getPlc,
  getAllAccommodationsByProject,
  updateplc
} from '../services/api/implementation/impl';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { EditPlcList } from '../components/forms/EditPlcList';
import { EditPlcUnit } from './EditPlcUnit';

export const PLCsDetails = () => {
  const { register } = useForm();
  const [isOpenPLCList, setIsOpenPLCList] = useState(false);
  const [isOpenPLCUnit, setIsOpenPLCUnit] = useState(false);
  const [newBlockId, setNewBlockId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blocksDynaWithLevels, setBlocksDynaWithLevels] = useState([]);
  const { id: projectId } = useParams();
  const [level_id, setLevelId] = useState();
  const [isOpenEditPlcList, setIsOpenEditPlcList] = useState(false);
  const [plcid, setplcId] = useState('');
  const [iseditplcUnits, setiseditplcUnits] = useState(false);
  const [plcUnitId, setplcUnitId] = useState('');
  const [showCreateBtn, setShowCreateBtn] = useState(true);

  const { data: blocksDyna, refetch: refetchBlocks } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId
  });

  console.log('blockdyna', blocksDyna);

  const { data: getAllplc, refetch: refetchPLCProject } = useQuery({
    queryKey: ['getAllplc'],
    queryFn: getPlc
  });

  const { data: plc_block_id, refetch: refetchplc_blockid } = useQuery({
    queryKey: ['plc_blockbyid', newBlockId],
    queryFn: () => getPlcbyBlockId(newBlockId),
    enabled: !!newBlockId
  });

  const { data: getAllAccommodation, refetch: refetchaccommodation } = useQuery({
    queryKey: ['getAllAccommodation', projectId],
    queryFn: () => getAllAccommodationsByProject(projectId),
    enabled: !!projectId
  });

  console.log({ plc_block_id });

  const { mutate: AddListPLC } = useMutation({
    mutationFn: createPLCList,
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('PLC created successfully');
      setIsOpenPLCList(false);
      refetchPLCProject();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating PLC', err);
      toast.error('Error creating PLC');
    }
  });

  const { mutate: UnitPLC } = useMutation({
    mutationFn: (payload) => createPLCUnit(payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('PLC unit created successfully');
      setIsOpenPLCUnit(false);
      refetchplc_blockid();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating PLC unit', err);
      toast.error('Error creating PLC unit');
    }
  });

  console.log({ plc_block_id });

  // Edit Plc in Units

  const { mutate: handleEditPlcUnits } = useMutation({
    mutationFn: (payload) => createPLCUnit(payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('PLC in Units updated successfully');
      setiseditplcUnits(false);
      refetchplc_blockid();
    },
    onError: () => {
      setIsSubmitting(false);
      toast.error('Error in PLC update');
    }
  });

  const { mutate: handleEditplcSubmit } = useMutation({
    mutationFn: (data) => updateplc(plcid, data),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('PLC is updated successfully');
      setIsOpenEditPlcList(false);
      refetchPLCProject();
    },
    onError: () => {
      setIsSubmitting(false);
      toast.error('Error in PLC update');
    }
  });

  // const ListDataFormat = ['id', 'name', 'description', 'blockName', 'actions'];

  const filteredPLCs = useMemo(() => {
    if (!getAllplc) return [];
    return getAllplc.filter((plc) =>
      plc.plc_block_mapping.some((mapping) => mapping.block.projectId === parseInt(projectId))
    );
  }, [getAllplc, projectId]);

  const ListDataFormat = ['id', 'name', 'description', 'blockName', 'actions'];

  const ListData = useMemo(() => {
    if (!filteredPLCs) return [];

    return filteredPLCs?.map((plc) => {
      const { id, name, description } = plc;
      const blockNames = new Set();

      plc.plc_block_mapping?.forEach((mapping) => {
        blockNames.add(mapping.block.name);
      });

      return transformData(
        {
          id,
          name,
          description,
          blockName: Array.from(blockNames).join(', '),
          actions: 'Actions'
        },
        ListDataFormat
      );
    });
  }, [filteredPLCs]);

  console.log({ ListData });

  const UnitDataFormat = [
    'id',
    'unitName',
    'accommodationName',
    'north',
    'south',
    'east',
    'west',
    'other',
    'actions'
  ];

  const getAccommodationName = (id) => {
    return getAllAccommodation?.find((item) => item.id === id)?.name || '';
  };

  const formatDirectionData = (direction, unit) => {
    const plcNames = unit.unitPlcs
      .filter((plc) => plc.direction === direction)
      ?.map((plc) => plc.plc.name)
      .join(', ');

    const entityNames = unit.entityMappings
      .filter((entity) => entity.direction === direction)
      ?.map((entity) => entity.entity.name)
      .join(', ');

    return [entityNames, plcNames].filter(Boolean).join(' | ');
  };
  console.log("plc_block_id", plc_block_id)

  const UnitData = useMemo(() => {
    if (!plc_block_id) return [];
  
    const filteredUnits = plc_block_id.filter(
      (unit) =>
        !unit?.accommodation?.accomodationType ||
        unit.accommodation.accomodationType?.toLowerCase() !== 'parking'
    );
  
    // 2) Transform the filtered units
    return filteredUnits.map((unit) =>
      transformData(
        {
          id: unit.id,
          unitName: unit.unitName,
          accommodationName: getAccommodationName(unit.accomodation_id),
          north: formatDirectionData('north', unit),
          south: formatDirectionData('south', unit),
          east: formatDirectionData('east', unit),
          west: formatDirectionData('west', unit),
          other: formatDirectionData('other', unit)
        },
        UnitDataFormat
      )
    );
  }, [plc_block_id, getAllAccommodation]);
  
  console.log({ blocksDyna });
  useEffect(() => {
    if (Array.isArray(blocksDyna)) {
      const blocksWithLevels = blocksDyna.filter((block) => block?.levels?.length > 0);
      const sorted = blocksWithLevels.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      if (sorted.length > 0) {
        setBlocksDynaWithLevels(sorted);
        setNewBlockId(sorted[0].id);
      }
    }
  }, [blocksDyna]);

  const handleOpenPLCList = () => setIsOpenPLCList(!isOpenPLCList);
  const handleOpenPLCUnit = () => setIsOpenPLCUnit(!isOpenPLCUnit);

  const handleSelectBlockChange = (event) => {
    const selectedBlockId = event.target.value;
    setNewBlockId(selectedBlockId);
  };

  const AddUnitPLC = (data) => {
    setLevelId(data.level_id);
    UnitPLC(data);
  };

  const handleEditplc = (id) => {
    setplcId(id);
    setIsOpenEditPlcList(true);
  };

  const unitData = UnitData.sort((a, b) =>
    a.unitName.localeCompare(b.unitName, undefined, { numeric: true, sensitivity: 'base' })
  );

  const handleEditUnit = (id) => {
    setplcUnitId(id);
    setiseditplcUnits(!iseditplcUnits);
  };

  useEffect(() => {
    const allEmpty = plc_block_id?.every(
      (item) => Array.isArray(item.unitPlcs) && item.unitPlcs.length === 0
    );
    setShowCreateBtn(allEmpty);
  }, [plc_block_id]);

  return (
    <>
      {isOpenPLCList && (
        <Modal isDirty={true} confirmOnClose={true} name={'Create PLC'} width={70} onClose={() => setIsOpenPLCList(false)}>
          <CreatePLC
            isSubmitting={isSubmitting}
            handleListPLC={AddListPLC}
            onClose={() => setIsOpenPLCList(false)}
          />
        </Modal>
      )}
      {isOpenPLCUnit && (
        <Modal isDirty={true} confirmOnClose={true} name={'Add PLC to Units'} width={70} onClose={() => setIsOpenPLCUnit(false)}>
          <AddPLCUnit
            isSubmitting={isSubmitting}
            handleUnitPLC={AddUnitPLC}
            onClose={() => setIsOpenPLCUnit(false)}
          />
        </Modal>
      )}
      {isOpenEditPlcList && (
        <Modal isDirty={true} confirmOnClose={true} name={'Edit PLC'} width={70} onClose={() => setIsOpenEditPlcList(false)}>
          <EditPlcList
            id={plcid}
            isSubmitting={isSubmitting}
            handleEditplcSubmit={handleEditplcSubmit}
            onClose={() => setIsOpenEditPlcList(false)}
          />
        </Modal>
      )}

      {iseditplcUnits && (
        <Modal isDirty={true} confirmOnClose={true} name={'Edit Plc in Unit'} width={70} onClose={() => setiseditplcUnits(false)}>
          <EditPlcUnit
            id={plcUnitId}
            isSubmitting={isSubmitting}
            handleEditPlcUnits={handleEditPlcUnits}
            blockId={newBlockId}
            onClose={() => setiseditplcUnits(false)}
          />
        </Modal>
      )}
      <div className="w-full flex flex-col absolute top-[16rem] z-10 pl-5 pr-5 bg-gray-100 pb-10 overflow-hidden">
        <div className="flex p-3 bg-white rounded-md items-center justify-between">
          <h3 className="text-xl font-semibold">PLC List</h3>
          <div className="flex gap-3">
            <Button onClick={handleOpenPLCList}>Create New</Button>
          </div>
        </div>
        <div className="h-[20rem] mt-10">
          <Table
            headers={['PLC Type Name', 'PLC Description', 'Associated Block', 'Action']}
            data={ListData}
            identifier={'id'}
            onEdit={(id) => handleEditplc(id)}
          />
        </div>
        <div className="flex p-3 mt-[8%] bg-white rounded-md items-center justify-between">
          <h3 className="text-xl font-semibold">PLC in Unit</h3>
          {showCreateBtn && (
            <div className="flex gap-3">
              <Button onClick={handleOpenPLCUnit}>Create New</Button>
            </div>
          )}
        </div>
        <div className="mt-5 flex flex-col gap-2">
          <label htmlFor="">Select Block</label>
          {blocksDynaWithLevels && blocksDynaWithLevels.length > 0 && (
            <select
              className="w-[10rem] p-2 border-[1px] border-stone-300 rounded"
              {...register(`table_type`)}
              value={newBlockId}
              onChange={handleSelectBlockChange}>
              {blocksDynaWithLevels?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="h-[20rem] mt-10">
          <Table
            headers={[
              'Unit Name',
              'Accommodation',
              'North',
              'South',
              'East',
              'West',
              'Others',
              'Action'
            ]}
            data={unitData}
            identifier={'id'}
            isEditEnable={true}
            onEdit={handleEditUnit}
          />
        </div>
      </div>
    </>
  );
};
