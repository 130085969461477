import React, { useCallback, useEffect, useState } from 'react';
import Search from '../../shared/components/ui/Search';
import Table from '../../shared/components/layout/Table';
import filter from '../../../modules/shared/assets/svg/filter.svg';
import { transformData } from '../../super-admin/utils/transformData';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getAllBlocks,
  getInvoicesList,
  updateInvoiceStatus
} from '../services/api/implementation/impl';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Modal from '../../shared/components/ui/Modal';
import Button from '../../shared/components/ui/Button';
import DateFormat from '../../super-admin/utils/DateFormat';
import toast from 'react-hot-toast';
import FormInput from '../../shared/components/ui/FormInput';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';
const InvoicesList = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control
  } = useForm();
  const { id: projectId } = useParams();
  const navigate = useNavigate();
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selctedStage, setSelctedStage] = useState('');

  const { data: blocksDyna, refetch: refetchBlocks } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId
  });

  // pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  const {
    data: Invoices,
    refetch: refetchInvoices,
    isFetching
  } = useQuery({
    queryKey: ['invoices', page],
    queryFn: () => getInvoicesList(page, limit),
    keepPreviousData: true
  });

  const totalPages = Invoices?.meta ? Math.ceil(Invoices?.meta.total / limit) : 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const [newBlockId, setNewBlockId] = useState(null);
  const [blocksDynaWithLevels, setBlocksDynaWithLevels] = useState([]);

  const [isPaid, setIsPaid] = useState(false);
  const [StatusId, setStatusId] = useState();

  useEffect(() => {
    refetchInvoices();
  }, [Invoices?.data]);

  const InvoiceFormat = [
    'id',
    'customer_name',
    'invoice_number',
    'due_date',
    'status',
    'stage',
    'pending_interest',
    'paid_amount',
    'paid_on',
    'amount',
    'due_amount',
    'actions'
  ];

  const InvoiceData = Invoices?.data?.map((item) =>
    transformData(
      {
        ...item,
        id: item.id,
        customer_name: item?.closedDeal?.lead?.user?.name,
        invoice_number: item.id,
        due_date: DateFormat(item.due_date),
        status: item.status,
        amount: parseInt(item?.amount) + parseInt(item?.prev_amount),
        stage: item.source,
        pending_interest: item?.pending_interest || '-',
        paid_amount: item?.paid_amount || '-',
        paid_on: item?.paid_on || '-',
        due_amount:
          Number(item?.amount || 0) +
            Number(item?.prev_amount || 0) -
            Number(item?.paid_amount || 0) || 0,

        actions: item.status === 'Unpaid' ? 'ShowPaidButton' : 'Actions'
      },
      InvoiceFormat
    )
  );

  console.log({ InvoiceData });

  const { mutate: handleUpdateStatus } = useMutation({
    mutationFn: (formData) => {
      let valueamount = removeRupeeSymbol(formData.payingAmount);
      console.log({ valueamount });

      updateInvoiceStatus(StatusId, {
        status: 'Paid',
        deal_id: InvoiceData[0]?.deal_id,
        amount: parseInt(removeRupeeSymbol(formData.payingAmount))
      });
      console.log(formData);
    },

    onSuccess: () => {
      toast.success('Invoice is Updated');
      setIsPaid(false);
      refetchInvoices();
    },
    onError: () => {
      toast.error('Error to update Invoice');
    }
  });

  //js dunction to remove rupee symbole from "₹ 500000" to "500000"
  const removeRupeeSymbol = (amount) => {
    console.log({ amount });
    // Remove rupee symbol and commas
    return amount.replace(/₹\s?|,/g, '');
  };

  const handleStatus = (id) => {
    setStatusId(id);
    console.log(id);
    console.log(InvoiceData);
    const filteredData = InvoiceData.filter((item) => parseInt(item.id) === parseInt(id));
    console.log(filteredData);
    setSelectedAmount(filteredData[0]?.amount);
    setSelctedStage(filteredData[0]?.stage);
    setIsPaid(true);
  };

  useEffect(() => {
    setFilteredData(InvoiceData);
  }, [Invoices?.data]);

  const handleSelectBlockChange = (event) => {
    const selectedBlockId = event.target.value;
    setNewBlockId(selectedBlockId);
  };
  useEffect(() => {
    if (blocksDyna?.blocks) {
      const blocksWithLevels = blocksDyna.blocks.filter((block) => block?.levels?.length > 0);
      const sorted = blocksWithLevels.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      if (sorted.length > 0) {
        setBlocksDynaWithLevels(sorted);
        setNewBlockId(sorted[0].id);
      }
    }
  }, [blocksDyna]);

  const resetPaidModal = () => {
    setIsPaid(false);
    setSelctedStage('');
    setSelectedAmount(0);
    reset();
  };

  const [searchQuery, setSearchQuery] = useState('');

  const [filteredData, setFilteredData] = useState(InvoiceData);

  const handleOnClickSearch = () => {
    const filtered = InvoiceData?.user?.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    setSearchQuery('');
  };

  const handleSearchChange = useCallback(
    (incomingQuery) => {
      if (incomingQuery) {
        const lowerCaseQuery = incomingQuery.toLowerCase();

        const filteredInvoice = InvoiceData?.filter((invoice) => {
          return (
            invoice.customer_name?.toLowerCase().includes(lowerCaseQuery) ||
            invoice.due_date?.toLowerCase().includes(lowerCaseQuery) ||
            invoice.status?.toLowerCase().includes(lowerCaseQuery) ||
            invoice.stage?.toLowerCase().includes(lowerCaseQuery)
          );
        });
        setFilteredData(filteredInvoice);
      } else {
        setFilteredData(InvoiceData);
      }
    },
    [InvoiceData]
  );
  return (
    <>
      {/* {isPaid && (
        <Modal isDirty={true} confirmOnClose={true} width={55} onClose={() => setIsPaid(!isPaid)}>
          <div>
            <div className="flex justify-center mb-8 text-xl font-semibold">
              <p>Do you want to mark this paid ?</p>
            </div>
            <div className="flex gap-8 justify-center">
              <Button displayType={'secondary'}>No</Button>
              <Button onClick={handleUpdateStatus}>Yes</Button>
            </div>
          </div>
        </Modal>
      )} */}
      {isPaid && (
        <Modal isDirty={true} confirmOnClose={true} width={55} onClose={resetPaidModal}>
          <div>
            <div className="flex justify-center flex-col mb-8">
              <p>Pending invoice amount - {selectedAmount}</p>
              {/* input field with label how much are you paying  */}
              {/* <div className="flex flex-col gap-4">
                <label htmlFor="amount">How much are you paying</label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  ref={register}
                  className="border border-stone-100 rounded-md p-2"
                />
              </div> */}

              <form
                onSubmit={handleSubmit(handleUpdateStatus)}
                className="flex flex-col gap-4 pt-6">
                <div className="flex justify-between items-center w-full">
                  {selctedStage === 'Advance' ? (
                    <FormInput
                      width={48}
                      label={'How much are you paying'}
                      isRequired
                      register_key={'payingAmount'}
                      register={register}
                      type={'text'}
                      valuePre={selctedStage === 'Advance' ? selectedAmount : '0'}
                      errors={errors.name}
                    />
                  ) : (
                    <FormInput
                      width={48}
                      label={'How much are you paying'}
                      isRequired
                      register_key={'payingAmount'}
                      register={register}
                      type={'text'}
                      defaultValuePre={selctedStage === 'Advance' ? selectedAmount : '0'}
                      errors={errors.name}
                    />
                  )}
                </div>

                <div className="w-full flex justify-end items-center space-x-4 mt-8">
                  <Button type="button" onClick={resetPaidModal} displayType="secondary">
                    Cancel
                  </Button>
                  <Button type="submit">Add</Button>
                </div>
              </form>
            </div>
            {/* <div className="flex gap-8 justify-center">
              <Button displayType={'secondary'}>No</Button>
              <Button onClick={handleUpdateStatus}>Yes</Button>
            </div> */}
          </div>
        </Modal>
      )}

      <div className="w-full flex flex-col absolute bg-gray-100 pb-10 overflow-hidden hide-scrollbar">
        {InvoiceData && InvoiceData?.length > 0 ? (
          <>
            <div className="flex z-10 items-center justify-between px-6 py-4 bg-white fixed top-[9rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
              {/* Pass searchQuery and handleSearchChange as props to Search component */}
              <Search
                onChange={handleSearchChange}
                query={searchQuery}
                onClick={handleOnClickSearch}
              />{' '}
              <div className="flex gap-5 cursor-pointer">
                <div></div>
              </div>
            </div>

            {/* Grid */}

            <GridWrapper width={100}>
              <div className="mt-[15rem] h-[28rem] overflow-scroll hide-scrollbar relative">
                <Table
                  headers={[
                    'Customer Name',
                    'Invoice',
                    'Due Date',
                    'Status',
                    'Stage',
                    'Pending Interest',
                    'Paid Amount',
                    'Paid On',
                    'Amount',
                    'Due Amount',
                    'Action'
                  ]}
                  data={filteredData}
                  identifier={'id'}
                  isViewEnable={false}
                  isEditEnable={false}
                  onBtn={(id) => handleStatus(id)}
                  onEdit={(id) => console.log(id)}
                  onView={() => navigate('/builder/deals')}
                />
                <PaginationComponent
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  isFetching={isFetching}
                />
              </div>
            </GridWrapper>
          </>
        ) : (
          <div className="pt-[3rem]">
            <NoDataFound disabledBtn screenHeight={true} />
          </div>
        )}
      </div>
    </>
  );
};

export default InvoicesList;
