import React, { useState, useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import {
  createApplication,
  getCloseDealsById,
  getApplication,
  getStateAndCity
} from '../../services/api/implementation/impl';

// ===================== Validation Schemas =====================
const firstApplicantSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required').email('Invalid email'),
  mobile: yup.string().required('Mobile is required'),
  relationship: yup.string().required('Relationship is required'),
  gender: yup.string().required('Gender is required'),
  dob: yup.string().required('Date of Birth is required'),
  profession: yup.string().required('Profession is required'),
  aadhar: yup.string().required('Aadhar number is required'),
  pan: yup.string().required('PAN is required'),
  company_name: yup.string().required('Company name is required'),
  address: yup.string().required('Address is required'),
  pinCode: yup.string().required('Pincode is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  officeAddress: yup.string().required('Office Address is required')
});

const secondApplicantSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required').email('Invalid email'),
  mobile: yup.string().required('Mobile is required'),
  relationship: yup.string().required('Relationship is required'),
  gender: yup.string().required('Gender is required'),
  dob: yup.string().required('Date of Birth is required'),
  profession: yup.string().required('Profession is required'),
  aadhar: yup.string().required('Aadhar number is required'),
  pan: yup.string().required('PAN is required'),
  company_name: yup.string().required('Company name is required'),
  address: yup.string().required('Address is required'),
  pinCode: yup.string().required('Pincode is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  officeAddress: yup.string().required('Office Address is required')
});

export const ApplicantModal = ({ onClose = () => {}, dealId, isSubmitting = false }) => {
  // 1) Fetch closeDeal (for lead user info) & existing application data
  const { data: closeDeals } = useQuery({
    queryKey: ['closeDeals', dealId],
    queryFn: () => getCloseDealsById(dealId),
    enabled: !!dealId
  });

  const {
    data: applicationData,
    refetch: refetchApplicationData
  } = useQuery({
    queryKey: ['application', dealId],
    queryFn: () => getApplication(dealId),
    enabled: !!dealId
  });

  // 2) Find pre-existing records for first & second applicant
  const firstApplicantRecord = applicationData?.find(
    (item) => item.deal_id === dealId && item.applicant_number === 1
  );
  const secondApplicantRecord = applicationData?.find(
    (item) => item.deal_id === dealId && item.applicant_number === 2
  );

  // Manage which tab is active
  const [activeTab, setActiveTab] = useState('first');

  // Are these forms editable or read-only?
  const [isFirstApplicantEditable, setIsFirstApplicantEditable] = useState(!firstApplicantRecord);
  const [isSecondApplicantEditable, setIsSecondApplicantEditable] = useState(!secondApplicantRecord);

  // --------- HELPER: normalize gender to "Male", "Female", or "Non Binary" ---------
  const normalizeGender = (rawGender) => {
    if (!rawGender) return '';
    const g = rawGender.toLowerCase();
    if (g === 'male') return 'Male';
    if (g === 'female') return 'Female';
    if (g === 'non binary') return 'Non Binary';
    return rawGender; // fallback if doesn't match
  };

  // --------- HELPER: add city to dropdown if needed (case-insensitive) ---------
  function ensureCityInDropdown(cityValue, currentDropdown) {
    if (!cityValue) return currentDropdown;
    const found = currentDropdown.find(
      (c) => c.value.toLowerCase() === cityValue.toLowerCase()
    );
    if (!found) {
      return [...currentDropdown, { value: cityValue, label: cityValue }];
    }
    return currentDropdown;
  }

  // =========================== FIRST APPLICANT FORM ============================
  const {
    register: registerFirst,
    handleSubmit: handleSubmitFirst,
    formState: { errors: errorsFirst },
    setValue: setValueFirst,
    watch: watchFirst,
    reset: resetFirst
  } = useForm({
    resolver: yupResolver(firstApplicantSchema),
    defaultValues: {
      name: '',
      email: '',
      mobile: '',
      relationship: '',
      gender: '',
      dob: '',
      profession: '',
      aadhar: '',
      pan: '',
      company_name: '',
      address: '',
      pinCode: '',
      city: '',
      state: '',
      officeAddress: ''
    }
  });

  // Once we know if there's an existing record, prefill or enable editing
  useEffect(() => {
    if (firstApplicantRecord) {
      setIsFirstApplicantEditable(false);
      const finalGender = normalizeGender(firstApplicantRecord.gender);
      resetFirst({
        name: firstApplicantRecord.name || '',
        email: firstApplicantRecord.email || '',
        mobile: firstApplicantRecord.mobile || '',
        relationship: firstApplicantRecord.relationship || '',
        gender: finalGender,
        dob: firstApplicantRecord.dob || '',
        profession: firstApplicantRecord.profession || '',
        aadhar: firstApplicantRecord.aadhar || '',
        pan: firstApplicantRecord.pan?.toUpperCase() || '',
        company_name: firstApplicantRecord.company_name || '',
        address: firstApplicantRecord.address || '',
        pinCode: firstApplicantRecord.pinCode?.toString() || '',
        city: firstApplicantRecord.city || '',
        state: firstApplicantRecord.state || '',
        officeAddress: firstApplicantRecord.officeAddress || ''
      });
    } else {
      // No record => user can fill
      setIsFirstApplicantEditable(true);
      // Pre-fill from closeDeals lead user if present
      if (closeDeals?.lead?.user) {
        setValueFirst('name', closeDeals.lead.user.name || '');
        setValueFirst('email', closeDeals.lead.user.email || '');
        setValueFirst('mobile', closeDeals.lead.user.phone_number || '');
      }
    }
  }, [firstApplicantRecord, closeDeals, resetFirst, setValueFirst]);

  // City dropdown (first applicant)
  const [cityDropdownOptionsFirst, setCityDropdownOptionsFirst] = useState([]);
  const watchFirstFields = watchFirst();

  // Pincode => city/state logic
  const handlePincodeChangeFirst = useCallback(
    async (val) => {
      if (!isFirstApplicantEditable) return;
      const cleaned = val.replace(/\D/g, '');
      setValueFirst('pinCode', cleaned);

      if (cleaned.length === 6) {
        try {
          const res = await getStateAndCity(cleaned);
          const postOfficeList = res[0]?.PostOffice || [];

          if (postOfficeList.length > 0) {
            const newState = postOfficeList[0].State;
            let uniqueTehsils = [
              ...new Set(postOfficeList.map((office) => office.Block).filter(Boolean))
            ];
            let cityOptions = uniqueTehsils.map((th) => ({
              value: th,
              label: th
            }));

            const currentCity = watchFirstFields.city;
            cityOptions = ensureCityInDropdown(currentCity, cityOptions);

            setCityDropdownOptionsFirst(cityOptions);

            if (!currentCity && cityOptions[0]) {
              setValueFirst('city', cityOptions[0].value);
            }
            setValueFirst('state', newState);
          } else {
            toast.error('No city/state found for this pincode.');
            setCityDropdownOptionsFirst([]);
            setValueFirst('city', '');
            setValueFirst('state', '');
          }
        } catch (err) {
          console.error(err);
          toast.error('Error fetching city/state from pincode.');
          setCityDropdownOptionsFirst([]);
          setValueFirst('city', '');
          setValueFirst('state', '');
        }
      } else {
        // Reset city + state
        setCityDropdownOptionsFirst([]);
        setValueFirst('city', '');
        setValueFirst('state', '');
      }
    },
    [isFirstApplicantEditable, setValueFirst, watchFirstFields.city]
  );

  // Aadhar
  const handleAadharChangeFirst = useCallback(
    (val) => {
      if (!isFirstApplicantEditable) return;
      let cleaned = val.replace(/\D/g, '');
      if (cleaned.length > 12) cleaned = cleaned.slice(0, 12);
      setValueFirst('aadhar', cleaned);
    },
    [isFirstApplicantEditable, setValueFirst]
  );

  // PAN
  const handlePanChangeFirst = useCallback(
    (val) => {
      if (!isFirstApplicantEditable) return;
      let cleaned = val.toUpperCase().replace(/[^A-Z0-9]/g, '');
      if (cleaned.length > 10) cleaned = cleaned.slice(0, 10);
      setValueFirst('pan', cleaned);
    },
    [isFirstApplicantEditable, setValueFirst]
  );

  // "Same as above" for office address
  const [sameAsAddressFirst, setSameAsAddressFirst] = useState(false);
  const handleSameAsAddressFirstChange = () => {
    if (!isFirstApplicantEditable) return;
    const newValue = !sameAsAddressFirst;
    setSameAsAddressFirst(newValue);
    if (newValue) {
      setValueFirst('officeAddress', watchFirstFields.address);
    } else {
      setValueFirst('officeAddress', '');
    }
  };

  // Submit (first applicant)
  const onSubmitFirst = (data) => {
    const payload = [
      {
        ...data,
        applicant_number: 1,
        deal_id: dealId
      }
    ];
    createApplication(payload)
      .then(() => {
        toast.success('First applicant data saved!');
        refetchApplicationData();
      })
      .catch((err) => {
        console.error('Error saving first applicant => ', err);
        toast.error('Error saving first applicant data.');
      });
  };

  // ========================== SECOND APPLICANT FORM ============================
  const {
    register: registerSecond,
    handleSubmit: handleSubmitSecond,
    formState: { errors: errorsSecond },
    setValue: setValueSecond,
    watch: watchSecond,
    reset: resetSecond
  } = useForm({
    resolver: yupResolver(secondApplicantSchema),
    defaultValues: {
      name: '',
      email: '',
      mobile: '',
      relationship: '',
      gender: '',
      dob: '',
      profession: '',
      aadhar: '',
      pan: '',
      company_name: '',
      address: '',
      pinCode: '',
      city: '',
      state: '',
      officeAddress: ''
    }
  });

  // If second applicant record is found
  useEffect(() => {
    if (secondApplicantRecord) {
      setIsSecondApplicantEditable(false);
      const finalGender = normalizeGender(secondApplicantRecord.gender);
      resetSecond({
        name: secondApplicantRecord.name || '',
        email: secondApplicantRecord.email || '',
        mobile: secondApplicantRecord.mobile || '',
        relationship: secondApplicantRecord.relationship || '',
        gender: finalGender,
        dob: secondApplicantRecord.dob || '',
        profession: secondApplicantRecord.profession || '',
        aadhar: secondApplicantRecord.aadhar || '',
        pan: secondApplicantRecord.pan?.toUpperCase() || '',
        company_name: secondApplicantRecord.company_name || '',
        address: secondApplicantRecord.address || '',
        pinCode: secondApplicantRecord.pinCode?.toString() || '',
        city: secondApplicantRecord.city || '',
        state: secondApplicantRecord.state || '',
        officeAddress: secondApplicantRecord.officeAddress || ''
      });
    } else {
      setIsSecondApplicantEditable(true);
    }
  }, [secondApplicantRecord, resetSecond]);

  const [cityDropdownOptionsSecond, setCityDropdownOptionsSecond] = useState([]);
  const watchSecondFields = watchSecond();

  // Pincode => city/state logic (second applicant)
  const handlePincodeChangeSecond = useCallback(
    async (val) => {
      if (!isSecondApplicantEditable) return;
      let cleaned = val.replace(/\D/g, '');
      setValueSecond('pinCode', cleaned);

      if (cleaned.length === 6) {
        try {
          const res = await getStateAndCity(cleaned);
          const postOfficeList = res[0]?.PostOffice || [];

          if (postOfficeList.length > 0) {
            const newState = postOfficeList[0].State;
            let uniqueTehsils = [
              ...new Set(postOfficeList.map((office) => office.Block).filter(Boolean))
            ];
            let cityOptions = uniqueTehsils.map((th) => ({
              value: th,
              label: th
            }));

            const currentCity = watchSecondFields.city;
            cityOptions = ensureCityInDropdown(currentCity, cityOptions);

            if (!currentCity && cityOptions[0]) {
              setValueSecond('city', cityOptions[0].value);
            }
            setValueSecond('state', newState);
            setCityDropdownOptionsSecond(cityOptions);
          } else {
            toast.error('No city/state found for this pincode.');
            setCityDropdownOptionsSecond([]);
            setValueSecond('city', '');
            setValueSecond('state', '');
          }
        } catch (err) {
          console.error(err);
          toast.error('Error fetching city/state from pincode.');
          setCityDropdownOptionsSecond([]);
          setValueSecond('city', '');
          setValueSecond('state', '');
        }
      } else {
        setCityDropdownOptionsSecond([]);
        setValueSecond('city', '');
        setValueSecond('state', '');
      }
    },
    [isSecondApplicantEditable, setValueSecond, watchSecondFields.city]
  );

  // Aadhar
  const handleAadharChangeSecond = useCallback(
    (val) => {
      if (!isSecondApplicantEditable) return;
      let cleaned = val.replace(/\D/g, '');
      if (cleaned.length > 12) cleaned = cleaned.slice(0, 12);
      setValueSecond('aadhar', cleaned);
    },
    [isSecondApplicantEditable, setValueSecond]
  );

  // Phone
  const handlePhoneNumberSecond = useCallback(
    (val) => {
      if (!isSecondApplicantEditable) return;
      let cleaned = val.replace(/\D/g, '');
      if (cleaned.length > 10) cleaned = cleaned.slice(0, 10);
      setValueSecond('mobile', cleaned);
    },
    [isSecondApplicantEditable, setValueSecond]
  );

  // PAN
  const handlePanChangeSecond = useCallback(
    (val) => {
      if (!isSecondApplicantEditable) return;
      let cleaned = val.toUpperCase().replace(/[^A-Z0-9]/g, '');
      if (cleaned.length > 10) cleaned = cleaned.slice(0, 10);
      setValueSecond('pan', cleaned);
    },
    [isSecondApplicantEditable, setValueSecond]
  );

  // "Same as above"
  const [sameAsAddressSecond, setSameAsAddressSecond] = useState(false);
  const handleSameAsAddressSecondChange = () => {
    if (!isSecondApplicantEditable) return;
    const newValue = !sameAsAddressSecond;
    setSameAsAddressSecond(newValue);
    if (newValue) {
      setValueSecond('officeAddress', watchSecondFields.address);
    } else {
      setValueSecond('officeAddress', '');
    }
  };

  const onSubmitSecond = (data) => {
    const payload = [
      {
        ...data,
        applicant_number: 2,
        deal_id: dealId
      }
    ];
    createApplication(payload)
      .then(() => {
        toast.success('Second applicant data saved!');
        refetchApplicationData();
      })
      .catch((err) => {
        console.error('Error saving second applicant => ', err);
        toast.error('Error saving second applicant data.');
      });
  };

  return (
    <div className="flex flex-col gap-4 w-[90vw] max-w-2xl p-6">
      {/* Tabs */}
      <div className="flex border-b border-gray-200">
        <button
          className={`px-4 py-2 ${
            activeTab === 'first' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('first')}
        >
          First Applicant
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === 'second' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('second')}
        >
          Second Applicant
        </button>
      </div>

      {/* --------------------- FIRST APPLICANT FORM --------------------- */}
      {activeTab === 'first' && (
        <form onSubmit={handleSubmitFirst(onSubmitFirst)} className="flex flex-col gap-4 pt-4">
          {/* Row 1: Name / Email */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Name"
              isRequired
              register={registerFirst}
              register_key="name"
              type="text"
              readOnly={!isFirstApplicantEditable}
              valuePre={watchFirstFields.name}
              errors={errorsFirst.name}
            />
            <FormInput
              width={48}
              label="Email"
              isRequired
              register={registerFirst}
              register_key="email"
              type="text"
              readOnly={!isFirstApplicantEditable}
              valuePre={watchFirstFields.email}
              errors={errorsFirst.email}
            />
          </div>

          {/* Row 2: Phone / Relationship */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Phone Number"
              isRequired
              register={registerFirst}
              register_key="mobile"
              type="text"
              readOnly={!isFirstApplicantEditable}
              valuePre={watchFirstFields.mobile}
              errors={errorsFirst.mobile}
            />
            <FormInput
              width={48}
              label="Son/Wife/Daughter Name"
              isRequired
              register_key="relationship"
              register={registerFirst}
              type="text"
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.relationship}
            />
          </div>

          {/* Row 3: Gender / DOB */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Gender"
              isRequired
              register_key="gender"
              register={registerFirst}
              type="dropdown"
              options={[
                { value: '', label: 'Select Gender', disabled: true, selected: true },
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' },
                { value: 'Non Binary', label: 'Non Binary' }
              ]}
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.gender}
            />
            <FormInput
              width={48}
              label="Date of Birth"
              isRequired
              register_key="dob"
              register={registerFirst}
              type="date"
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.dob}
            />
          </div>

          {/* Row 4: Profession / Aadhar */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Profession"
              isRequired
              register_key="profession"
              register={registerFirst}
              type="text"
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.profession}
            />
            <FormInput
              width={48}
              label="Aadhar Number"
              isRequired
              register_key="aadhar"
              register={registerFirst}
              type="text"
              maxLength={12}
              onType={handleAadharChangeFirst}
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.aadhar}
            />
          </div>

          {/* Row 5: PAN / Company Name */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="PAN"
              isRequired
              register_key="pan"
              register={registerFirst}
              type="text"
              onType={handlePanChangeFirst}
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.pan}
            />
            <FormInput
              width={48}
              label="Company/Firm Name"
              isRequired
              register_key="company_name"
              register={registerFirst}
              type="text"
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.company_name}
            />
          </div>

          {/* Row 6: Address (Full Width) */}
          <div className="flex w-full">
            <FormInput
              width={100}
              label="Address"
              isRequired
              register_key="address"
              register={registerFirst}
              type="textarea"
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.address}
            />
          </div>

          {/* Row 7: Pincode / City */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Pincode"
              isRequired
              register_key="pinCode"
              register={registerFirst}
              type="text"
              maxLength={6}
              onType={handlePincodeChangeFirst}
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.pinCode}
            />
            {/* City: Dropdown if editable, else read-only text */}
            {isFirstApplicantEditable ? (
              <FormInput
                width={48}
                label="City"
                isRequired
                register_key="city"
                register={registerFirst}
                type="dropdown"
                options={cityDropdownOptionsFirst}
                readOnly={!isFirstApplicantEditable}
                errors={errorsFirst.city}
              />
            ) : (
              <FormInput
                width={48}
                label="City"
                isRequired
                register_key="city"
                register={registerFirst}
                type="text"
                readOnly
                valuePre={watchFirstFields.city}
                errors={errorsFirst.city}
              />
            )}
          </div>

          {/* Row 8: State (Always read-only in UI) */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="State"
              isRequired
              register_key="state"
              register={registerFirst}
              type="text"
              readOnly
              CustomStyle={{ backgroundColor: '#e7e5e4' }}
              errors={errorsFirst.state}
            />
          </div>

          {/* Office Address + Same as above */}
          <div className="flex flex-col gap-4 w-full">
            <FormInput
              width={100}
              label="Office Address"
              register={registerFirst}
              register_key="officeAddress"
              type="textarea"
              readOnly={!isFirstApplicantEditable}
              errors={errorsFirst.officeAddress}
            />
            <div className="flex items-center mb-1">
              <input
                type="checkbox"
                checked={sameAsAddressFirst}
                onChange={handleSameAsAddressFirstChange}
                className="mr-2 h-4 w-4"
                disabled={!isFirstApplicantEditable}
              />
              <span className="text-sm text-gray-600">Same as above</span>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="w-full flex justify-end items-center space-x-4 mt-8">
            <Button type="button" onClick={onClose} displayType="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting || !isFirstApplicantEditable}
            >
              Save
            </Button>
          </div>
        </form>
      )}

      {/* --------------------- SECOND APPLICANT FORM --------------------- */}
      {activeTab === 'second' && (
        <form onSubmit={handleSubmitSecond(onSubmitSecond)} className="flex flex-col gap-4 pt-4">
          {/* Row 1: Name / Email */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Name"
              isRequired
              register_key="name"
              register={registerSecond}
              type="text"
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.name}
            />
            <FormInput
              width={48}
              label="Email"
              isRequired
              register_key="email"
              register={registerSecond}
              type="text"
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.email}
            />
          </div>

          {/* Row 2: Phone / Relationship */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Phone Number"
              isRequired
              register_key="mobile"
              register={registerSecond}
              type="text"
              onType={handlePhoneNumberSecond}
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.mobile}
            />
            <FormInput
              width={48}
              label="Son/Wife/Daughter Name"
              isRequired
              register_key="relationship"
              register={registerSecond}
              type="text"
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.relationship}
            />
          </div>

          {/* Row 3: Gender / DOB */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Gender"
              isRequired
              register_key="gender"
              register={registerSecond}
              type="dropdown"
              options={[
                { value: '', label: 'Select Gender', disabled: true, selected: true },
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' },
                { value: 'Non Binary', label: 'Non Binary' }
              ]}
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.gender}
            />
            <FormInput
              width={48}
              label="Date of Birth"
              isRequired
              register_key="dob"
              register={registerSecond}
              type="date"
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.dob}
            />
          </div>

          {/* Row 4: Profession / Aadhar */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Profession"
              isRequired
              register_key="profession"
              register={registerSecond}
              type="text"
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.profession}
            />
            <FormInput
              width={48}
              label="Aadhar Number"
              isRequired
              register_key="aadhar"
              register={registerSecond}
              type="text"
              maxLength={12}
              onType={handleAadharChangeSecond}
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.aadhar}
            />
          </div>

          {/* Row 5: PAN / Company Name */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="PAN"
              isRequired
              register_key="pan"
              register={registerSecond}
              type="text"
              onType={handlePanChangeSecond}
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.pan}
            />
            <FormInput
              width={48}
              label="Company/Firm Name"
              isRequired
              register_key="company_name"
              register={registerSecond}
              type="text"
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.company_name}
            />
          </div>

          {/* Row 6: Address (Full Width) */}
          <div className="flex w-full">
            <FormInput
              width={100}
              label="Address"
              isRequired
              register_key="address"
              register={registerSecond}
              type="textarea"
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.address}
            />
          </div>

          {/* Row 7: Pincode / City */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="Pincode"
              isRequired
              register_key="pinCode"
              register={registerSecond}
              type="text"
              maxLength={6}
              onType={handlePincodeChangeSecond}
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.pinCode}
            />

            {/* City: if editable => dropdown, else read-only text */}
            {isSecondApplicantEditable ? (
              <FormInput
                width={48}
                label="City"
                isRequired
                register_key="city"
                register={registerSecond}
                type="dropdown"
                options={cityDropdownOptionsSecond}
                readOnly={!isSecondApplicantEditable}
                errors={errorsSecond.city}
              />
            ) : (
              <FormInput
                width={48}
                label="City"
                isRequired
                register_key="city"
                register={registerSecond}
                type="text"
                readOnly
                valuePre={watchSecondFields.city}
                errors={errorsSecond.city}
              />
            )}
          </div>

          {/* Row 8: State (Read-only) + Office Address */}
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label="State"
              isRequired
              register_key="state"
              register={registerSecond}
              type="text"
              readOnly
              CustomStyle={{ backgroundColor: '#e7e5e4' }}
              errors={errorsSecond.state}
            />
          </div>

          <div className="flex flex-col gap-4 w-full">
            <FormInput
              width={100}
              label="Office Address"
              register={registerSecond}
              register_key="officeAddress"
              type="textarea"
              readOnly={!isSecondApplicantEditable}
              errors={errorsSecond.officeAddress}
            />
            <div className="flex items-center mb-1">
              <input
                type="checkbox"
                checked={sameAsAddressSecond}
                onChange={handleSameAsAddressSecondChange}
                className="mr-2 h-4 w-4"
                disabled={!isSecondApplicantEditable}
              />
              <span className="text-sm text-gray-600">Same as above</span>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="w-full flex justify-end items-center space-x-4 mt-8">
            <Button type="button" onClick={onClose} displayType="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting || !isSecondApplicantEditable}
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
