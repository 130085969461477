import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import HeaderCard from '../components/ui/HeaderCard';
import Modal from '../../shared/components/ui/Modal';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Search from '../../shared/components/ui/Search';
import Button from '../../shared/components/ui/Button';

import filter from '../../shared/assets/svg/filter.svg';

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Table from '../../shared/components/layout/Table';
import { transformData } from '../../super-admin/utils/transformData';
import AddProject from '../components/forms/AddProject';
import projects from '../static/data/projects.json';
import {
  createProject,
  editProject,
  getProjectsByCompanyId,
  getProjectStats
} from '../services/api/implementation/impl';
import EditProject from '../components/forms/EditProject';
import { useMutation, useQuery } from '@tanstack/react-query';
import generateProjectPayload from '../utils/generateProjectPayload';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { filterData } from '../../shared/utils/filterData';
import DateFormat from '../../super-admin/utils/DateFormat';

const ProjectsList = () => {
  /* 
    Hooks
  */
  const navigate = useNavigate();

  /* 
    Global state
  */
  const fileUrls = useSelector((state) => state.file.fileUrls);

  const companyid = useParams();
  const companyIdInt = parseInt(companyid.id, 10);

  /* 
    Local state
  */
  const [isOpenAddProject, setIsOpenAddProject] = useState(false);
  const [isOpenEditProject, setisOpenEditProject] = useState(false);
  const [upShift, setUpShift] = useState(6);
  const [projectsStatic, setProjectsStatic] = useState(projects);
  const [ProjectStaticId, setProjectStaticId] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [ProjectFilter, setProjectFilter] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  /* 
    Data fetching
  */
  const { data, refetch: refetchProjects } = useQuery({
    queryKey: ['project_byId', companyIdInt],
    queryFn: () => getProjectsByCompanyId(companyIdInt),
    enabled: !!companyIdInt // Ensure the query only runs if companyIdInt is valid
  });

  console.log({ data });

  const { data: projectStats, refetch: refetchProjectStats } = useQuery({
    queryKey: ['project_stats', companyIdInt],
    queryFn: () => getProjectStats(companyIdInt),
    enabled: !!companyIdInt // Ensure the query only runs if companyIdInt is valid
  });

  /* 
    Meta data
  */
  const metaData = [
    {
      _id: 1,
      type: 'information',
      name: 'Total Projects',
      data: projectStats?.totalProjects || 0
    },
    // {
    //   _id: 2,
    //   type: 'information',
    //   name: 'Total Land Area',
    //   data: `${projectStats?.totalLandArea || 0} Hector`
    // },
    {
      _id: 3,
      type: 'completed',
      name: 'Completed Projects',
      data: projectStats?.completedProjects || 0
    },
    {
      _id: 4,
      type: 'ongoing',
      name: 'Ongoing Projects',
      data: projectStats?.incompleteProjects || 0
    }
  ];

  // Updated projects array with estimated_budget
  const updatedProjects = projects?.map((project) => {
    return {
      ...project,
      estimated_budget: `$${Math.floor(Math.random() * 500000) + 100000}` // Random value between $100,000 and $600,000
    };
  });

  /* 
    Event handlers
  */
  const handleToggleAddProjectModal = useCallback(() => {
    setIsOpenAddProject(!isOpenAddProject);
  }, [isOpenAddProject]);

  const handleToggleEditProjectModal = useCallback(
    (id) => {
      setProjectStaticId(id);
      setisOpenEditProject(!isOpenEditProject);
    },
    [isOpenEditProject]
  );

  /* 
    Data mutation
  */
  const { mutate: handleOnSubmitAddProject, isLoading: isLoadingNewUserMutation } = useMutation({
    mutationFn: (newProject) => createProject(newProject),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (res) => {
      toast.success('Project created successfully');
      setIsOpenAddProject(false);
      setIsSubmitting(false);
      await refetchProjects(); // UI data update
      navigate(`/builder/management/company/projects/${res.id}`);
      location.reload();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new project');
      toast.error('Error creating project');
    }
  });

  const { mutate: handleEditProject, isLoading: isEditingProject } = useMutation({
    mutationFn: (updatedProject) => {
      // console.log('Entering mutationFn');
      // console.log('updatedProject:', JSON.stringify(updatedProject, null, 2));
      // console.log('ProjectStaticId:', ProjectStaticId);

      if (!updatedProject) {
        throw new Error('updatedProject is null or undefined');
      }

      if (ProjectStaticId == null) {
        throw new Error('ProjectStaticId is null or undefined');
      }

      let parsedLandId;
      try {
        parsedLandId = updatedProject.land_id != null ? parseInt(updatedProject.land_id, 10) : null;
        console.log('Parsed land_id:', parsedLandId);
      } catch (error) {
        console.error('Error parsing land_id:', error);
        throw new Error(`Failed to parse land_id: ${error.message}`);
      }

      const projectToEdit = {
        ...updatedProject,
        land_id: parsedLandId
      };

      console.log('Project to edit:', JSON.stringify(projectToEdit, null, 2));

      return editProject(ProjectStaticId, projectToEdit);
    },
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      console.log('Edit project succeeded');
      setIsSubmitting(false);
      toast.success('Project edited successfully');
      setisOpenEditProject(false);
      await refetchProjects(); // UI data update
      location.reload();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error editing project:', err);
      toast.error(`Error editing project: ${err.message}`);
    }
  });

  useEffect(() => {
    setProjectFilter(data);
  }, [data]);
  const handleSearchChange = useCallback(
    (incomingQuery) => {
      if (incomingQuery) {
        const filterProject = filterData('name', data, incomingQuery);
        setProjectFilter(filterProject);
      } else {
        setProjectFilter(data);
      }
    },
    [data]
  );

  /* 
    Meta data
  */
  const ProjectDataFormat = [
    'id',
    'name',
    'estimated_budget',
    'start_date',
    'completion_date',
    'actions'
  ];

  /* 
    Helper method calls
  */
    const FilterProjectData = ProjectFilter?.sort((a, b) => {
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
    
      // Compare by year, then by month and day
      return dateA - dateB;
    });
      console.log(FilterProjectData);
  const transformedData = FilterProjectData?.map((item) =>
    transformData(
      {
        ...item,
        start_date: DateFormat(item.start_date),
        estimated_budget: item?.buildings[0]?.estimated_budget
      },
      ProjectDataFormat
    )
  );

  // console.log(ProjectFilter, data);

  /* 
    Event handlers
  */
  const handleNavigateProject = useCallback(
    (id) => {
      navigate(`/builder/management/company/projects/${id}`);
    },
    [navigate]
  );

  /* 
    Effects
  */
  useEffect(() => {
    refetchProjects();
    refetchProjectStats();
  }, [isOpenAddProject, isOpenEditProject, refetchProjects, refetchProjectStats]);

  /* 
    JSX
  */
  return (
    <div className="bg-gray-100">
      {isOpenAddProject && (
        <Modal isDirty={true} confirmOnClose={true}
          width={93}
          name="Add Project"
          onClose={handleToggleAddProjectModal}
          upShift={upShift * 10}>
          <AddProject
            onSubmit={handleOnSubmitAddProject}
            upShift={upShift}
            isSubmitting={isSubmitting}
            setUpShift={setUpShift}
            onClose={handleToggleAddProjectModal}
          />
        </Modal>
      )}

      {/* Edit Project Modal */}

      {isOpenEditProject && (
        <Modal isDirty={true} confirmOnClose={true}
          width={88}
          name="Edit project"
          onClose={handleToggleEditProjectModal}
          upShift={upShift * 10}>
          <EditProject
            id={ProjectStaticId}
            ProjectData={transformedData}
            upShift={upShift}
            isSubmitting={isSubmitting}
            setUpShift={setUpShift}
            onSubmit={handleEditProject}
            onClose={handleToggleEditProjectModal}
          />
        </Modal>
      )}
      <div className="overflow-y-hidden">
        <Layout>
          {/* Header */}
          <div className="w-full">
            <Header data={{ name: 'Company > Projects' }} backArrow={true} />
          </div>

          {/* Cards */}
          <div className="w-full flex justify-between items-center px-6 py-4 bg-stone-100 space-x-4">
            {metaData?.map((item) => (
              <HeaderCard
                data={{ key: item?.name, value: item.data, type: item.type }}
                key={item?._id}
              />
            ))}
          </div>

          {/* Main content */}
          <div className="w-full flex items-center justify-between px-6 py-4 bg-white border-t-[2px] border-stone-100 shadow-sm">
            <Search query={searchQuery} onChange={handleSearchChange} />
            <div className="flex gap-5 cursor-pointer">
              <div>{/* <img className="" src={filter} alt="" /> */}</div>
              <Button onClick={handleToggleAddProjectModal}>Add Project</Button>
            </div>
          </div>

          {/* Table content */}
          <GridWrapper width={100}>
            <div className="h-[22rem] hide-scrollbar ">
              <Table
                headers={[
                  'Project',
                  'Estimated budget (Lakhs)',
                  'Start Date',
                  'Completion Date',
                  'Actions'
                ]}
                data={transformedData}
                identifier={'id'}
                onEdit={(id) => handleToggleEditProjectModal(id)}
                // isDeleteEnable={true}
                isViewEnable={true}
                onView={(id) => handleNavigateProject(id)}
              />
            </div>
          </GridWrapper>
        </Layout>
      </div>
    </div>
  );
};

export default ProjectsList;
