import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import {
  addInvoice,
  addInvoiceStatic,
  addPaymentHistory,
  addPaymentHistoryAPI,
  addPaymentStatic,
  createUser,
  editPaymentAPI,
  getAllBillings,
  getAllInvoices,
  getAllPaymentHistory,
  getAllUsers,
  getDashboardHeaderData,
  postBillings,
  updateInvoiceByIdStatic
} from '../../../services/api/implementation/impl';
import Layout from '../AppLayout';
import Header from '../../../../shared/components/layout/Header';
import search from '../../../../shared/assets/svg/search.svg';
import filter from '../../../../shared/assets/svg/filter.svg';
import Button from '../../../../shared/components/ui/Button';
import GridWrapper from '../../../../shared/components/layout/GridWrapper';
import Table from '../../../../shared/components/layout/Table';
import Modal from '../../../../shared/components/ui/Modal';
import { transformData } from '../../../utils/transformData';
import '../../../../shared/styles/modal.scss';
import Overview from './Overview';
import BillingSummary from './BillingSummary';
import InvoiceManagement from './InvoiceManagement';
import PaymentHistory from './PaymentHistory';
import { BillingSummaryForm } from '../../forms/BillingSummaryForm';
import { InvoiceForm } from '../../forms/InvoiceForm';
import { ImportSummaryForm } from '../../forms/ImportSummaryForm';
import { ImportHistoryForm } from '../../forms/ImportHistoryForm';
import { BillingHistoryForm } from '../../forms/BillingHistoryForm';
import invoicesStatic from '../../../static/invoices.json';
import paymentsStatic from '../../../static/payments.json';
import { EditInvoice } from '../../forms/EditInvoice';

import { editInvoice } from '../../../services/api/implementation/impl';
import { EditPayment } from '../../forms/EditPayment';
import toast from 'react-hot-toast';

const BillingPayments = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [openBillingModal, setOpenBillingModal] = useState(false);
  const [openInvoiceModal, setopenInvoiceModal] = useState(false);
  const [openEditInvoiceModal, setOpenEditInvoiceModal] = useState(false);
  const [openBillingHistoryModal, setopenBillingHistoryModal] = useState(false);
  const [uploadType, setuploadType] = useState('single');
  const [inVoicesStaticState, setInVoicesStaticState] = useState(invoicesStatic);
  const [newAddedInvoice, setNewAddedInvoice] = useState(false);
  const [newEditedInvoice, setNewEditedInvoice] = useState(false);
  const [activeInvoiceId, setActiveInvoiceId] = useState();
  const [paymentsDataStatic, setPaymentsDataStatic] = useState(paymentsStatic);
  const [isOpenEditBillingModal, setisOpenEditBillingModal] = useState(false);
  const [activePaymentId, setActivePaymentId] = useState();
  const { register, handleSubmit, reset } = useForm();
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleToggleBillingModal = () => {
    setuploadType('single');
    setOpenBillingModal(!openBillingModal);
  };

  const handleToggleInvoiceModal = () => {
    setopenInvoiceModal(!openInvoiceModal);
  };

  const handleToggleEditInvoiceModal = () => {
    setOpenEditInvoiceModal(!openEditInvoiceModal);
  };

  const handleToggleBillingSummaryModal = () => {
    setuploadType('multiple');
    setOpenBillingModal(!openBillingModal);
  };

  const handleToggleAddBillingHistoryModal = () => {
    setuploadType('single');
    setOpenBillingModal(!openBillingModal);
  };

  const handleToggleImportBillingHistoryModal = () => {
    setuploadType('multiple');
    console.log('Toggling billing modal: ', selectedFilter);
    setopenBillingHistoryModal(!openBillingHistoryModal);
  };

  // const { data: billings, refetch: refetchbillings } = useQuery({
  //   queryKey: ['billings'],
  //   queryFn: () => getAllBillings()
  // });

  const { data: invoices, refetch: refetchinvoice } = useQuery({
    queryKey: ['invoices'],
    queryFn: getAllInvoices()
  });

  console.log('invoices', invoices);
  const { data: payments, refetch: refetchPaymentHistory } = useQuery({
    queryKey: ['payment'],
    queryFn: getAllPaymentHistory
  });

  const { mutate, isLoading: isLoadingNewUserMutation } = useMutation({
    mutationFn: (postdata) => postBillings(postdata),
    onSuccess: async () => {
      console.log('Billing created successfully');
      reset();
      setOpenBillingModal(!openBillingModal);
    },
    onError: (err) => {
      alert('Error creating user');
      console.error('Error creating new user');
    }
  });

  const { mutate: mutate_invoice, isLoading: isLoadingInvoice } = useMutation({
    mutationFn: async (postdata) => await addInvoice(postdata),
    onSuccess: () => {
      console.log('Invoice created successfully');
      reset();
      setopenInvoiceModal(false);
      setNewAddedInvoice(!newAddedInvoice);
      toast.success('Invoice created successfully');
    },
    onError: (err) => {
      toast.error('Error creating new invoice');
      console.error('Error creating new invoice');
    }
  });

  const { mutate: editInvoiceMutation } = useMutation({
    mutationFn: (putdata) => editInvoice(activeInvoiceId, putdata),
    onSuccess: async () => {
      console.log('Invoice update successfully');
      toast.success('Invoice Updated Successfully');
      reset();
      setopenInvoiceModal(false);
      await refetchinvoice();
    },
    onError: (err) => {
      toast.error('Error to update invoice');
      console.error('Error to update invoice');
    }
  });

  function onSubmit(data) {
    mutate(data);
  }

  async function onSubmit_Invoice(data) {
    // Data sanitisation
    if (data?.amount && typeof data.amount === 'string')
      data = { ...data, amount: parseInt(data?.amount) };
    if (data?.builder_id && typeof data.builder_id === 'string')
      data = { ...data, builder_id: parseInt(data?.builder_id) };
    setopenInvoiceModal(false);
    mutate_invoice(data);
    await refetchinvoice();
  }

  async function onEditInvoice(data) {
    // Data sanitisation
    setNewEditedInvoice(!newEditedInvoice);
    if (data?.amount && typeof data.amount === 'string')
      data = { ...data, amount: parseInt(data?.amount) };
    if (data?.due_amount && typeof data?.due_amount === 'string')
      data = { ...data, due_amount: parseInt(data?.due_amount) };

    // editInvoice(id, data)
    const updatedInvoices = await updateInvoiceByIdStatic(invoicesStatic, data?.id, data);
    setInVoicesStaticState(updatedInvoices);
    setOpenEditInvoiceModal(!openEditInvoiceModal);

    // After API created, integrate here
    editInvoiceMutation(data);
  }

  const { mutate: mutate_paymentHistory, isLoading: isLoadingEditPaymentHistory } = useMutation({
    mutationFn: (postdata) => addPaymentHistoryAPI(postdata),
    onSuccess: async () => {
      console.log('Payment History created successfully');
      toast.success('Payment History created successfully');
      reset();
      setOpenBillingModal(false);
      await refetchPaymentHistory();
    },
    onError: (err) => {
      toast.error('Error creating Payment History');
      console.error('Error creating new invoice');
    }
  });

  const { mutate: mutate_EditPaymentHistory, isLoading: isLoadingpaymentHistory } = useMutation({
    mutationFn: (postdata) => editPaymentAPI(activePaymentId, postdata),
    onSuccess: async () => {
      console.log('Payment History updated successfully');
      toast.success('Payment History updated successfully');
      reset();
      setisOpenEditBillingModal(false);
      await refetchPaymentHistory();
    },
    onError: (err) => {
      toast.error('Error creating Payment History');
      console.error('Error creating new invoice');
    }
  });

  function onSubmitPaymentStatic(data) {
    if (data?.amount_paid && typeof data.amount_paid === 'string')
      data = { ...data, amount_paid: parseInt(data?.amount_paid) };
    if (data?.invoice_id && typeof data.invoice_id === 'string')
      data = { ...data, invoice_id: parseInt(data?.invoice_id) };
    if (data?.builder_id && typeof data.builder_id === 'string')
      data = { ...data, builder_id: parseInt(data?.builder_id) };
    if (data?.project_id && typeof data.project_id === 'string')
      data = { ...data, project_id: parseInt(data?.project_id) };
    addPaymentStatic(paymentsDataStatic, setPaymentsDataStatic, data);
    setOpenBillingModal(false);
    mutate_paymentHistory(data);
  }

  function onSubmitEditPayment(data) {
    if (data?.amount_paid && typeof data.amount_paid === 'string')
      data = { ...data, amount_paid: parseInt(data?.amount_paid) };
    if (data?.invoice_id && typeof data.invoice_id === 'string')
      data = { ...data, invoice_id: parseInt(data?.invoice_id) };
    if (data?.builder_id && typeof data.builder_id === 'string')
      data = { ...data, builder_id: parseInt(data?.builder_id) };
    if (data?.project_id && typeof data.project_id === 'string')
      data = { ...data, project_id: parseInt(data?.project_id) };
    mutate_EditPaymentHistory(data);
  }
  function handleActiveUserChange(id) {
    console.log('bab invoice id', id);
    setActiveInvoiceId(id);
    setOpenEditInvoiceModal(!openEditInvoiceModal);
  }

  function handleToggleOpenEditBillingModal(id) {
    console.log('bab payment id', id);
    setActivePaymentId(id);
    setisOpenEditBillingModal(!isOpenEditBillingModal);
  }

  const [activeFilter, setActiveFilter] = useState('overview');
  const [selectedFilter, setSelectedFilter] = useState('overview');

  const handleButtonClick = (filter) => {
    setSelectedFilter(filter);
  };

  /* 
    Effects
  */
  useEffect(() => {
    refetchinvoice();
  }, [openInvoiceModal, refetchinvoice, editInvoiceMutation]);

  return (
    <>
      {selectedFilter === 'payment-history'
        ? openBillingModal &&
          (uploadType === 'single' ? (
            <Modal isDirty={true} confirmOnClose={true} width={56} name="Add Manually" onClose={handleToggleBillingModal}>
              <BillingHistoryForm
                onSubmit={onSubmitPaymentStatic}
                onClose={handleToggleBillingModal}
              />
            </Modal>
          ) : (
            <Modal isDirty={true} confirmOnClose={true} width={53} name="Import Summary" onClose={handleToggleBillingModal}>
              <ImportSummaryForm onSubmit={onSubmit} />
            </Modal>
          ))
        : selectedFilter === 'invoice-management' &&
          (openInvoiceModal ? (
            <Modal isDirty={true} confirmOnClose={true} width={72} name="Add Invoice" upShift={5} onClose={handleToggleInvoiceModal}>
              <InvoiceForm onSubmit={onSubmit_Invoice} onClose={handleToggleInvoiceModal} />
            </Modal>
          ) : (
            openEditInvoiceModal && (
              <Modal isDirty={true} confirmOnClose={true}
                width={72}
                name="Edit Invoice"
                upShift={5}
                onClose={handleToggleEditInvoiceModal}>
                <EditInvoice
                  onSubmit={onEditInvoice}
                  id={activeInvoiceId}
                  staticInvoices={invoices}
                  //in the onClose button it was a buit eariler nnd the or tho be donwe
                  onClose={handleToggleEditInvoiceModal}
                />
              </Modal>
            )
          ))}

      {selectedFilter === 'payment-history' && isOpenEditBillingModal && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Edit Manually" onClose={handleToggleOpenEditBillingModal}>
          <EditPayment
            onSubmit={onSubmitEditPayment}
            id={activePaymentId}
            staticPayments={payments}
            onClose={handleToggleOpenEditBillingModal}
          />
        </Modal>
      )}
      <div className='overflow-hidden'>
      <Layout>
        <div className="w-full">
          <Header data={{ name: 'Billings & Payment' }} />
        </div>
        <div className="flex items-center justify-between bg-white p-4">
          <div className="bg-white flex items-center justify-between w-full rounded-lg">
            <div className="button-group flex rounded-lg ">
              <div
                className={`border border-x-1 px-2 py-1 font-helvetica cursor-pointer button ${selectedFilter === 'overview' ? `bg-customBlue text-white` : 'text-textC'} rounded-tl rounded-bl`}
                onClick={() => handleButtonClick('overview')}>
                Overview
              </div>
              <div
                className={`border  border-x-1 px-2 py-1  cursor-pointer font-helvetica button ${selectedFilter === 'invoice-management' ? `bg-customBlue text-white` : 'text-textC'}`}
                onClick={() => handleButtonClick('invoice-management')}>
                Invoice Management
              </div>
              <div
                className={`border border-x-1 px-2 py-1 cursor-pointer font-helvetica button ${selectedFilter === 'payment-history' ? `bg-customBlue text-white` : 'text-textC'} rounded-tr rounded-br`}
                onClick={() => handleButtonClick('payment-history')}>
                Payment History
              </div>
            </div>
            {selectedFilter === 'billing-summary' ? (
              <div className="flex gap-3 mb-10">
                <Button displayType={'secondary'} onClick={() => handleToggleBillingSummaryModal()}>
                  Import Summary
                </Button>
                <Button onClick={() => handleToggleBillingModal()}>Add Payment</Button>
              </div>
            ) : selectedFilter === 'invoice-management' ? (
              <div className="">
                <Button onClick={() => handleToggleInvoiceModal()}>Add Invoice</Button>
              </div>
            ) : selectedFilter === 'payment-history' ? (
              <div className="">
                {/* <Button
                  displayType={'secondary'}
                  onClick={() => handleToggleImportBillingHistoryModal()}>
                  Import History
                </Button> */}
                <Button onClick={() => handleToggleAddBillingHistoryModal()}>Add Manually</Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className=''>
        <GridWrapper  width={100} childrenCustomStyle='m-0'>
          {selectedFilter === 'overview' ? (
            <Overview />
          ) : selectedFilter === 'billing-summary' ? (
            <BillingSummary />
          ) : selectedFilter === 'invoice-management' ? (
            <InvoiceManagement
              newAddedInvoice={newAddedInvoice}
              newEditedInvoice={newEditedInvoice}
              onEdit={handleToggleEditInvoiceModal}
              onChangeActiveInvoiceId={(id) => handleActiveUserChange(id)}
            />
          ) : selectedFilter === 'payment-history' ? (
            <PaymentHistory onChangePaymentId={(id) => handleToggleOpenEditBillingModal(id)} />
          ) : (
            <Overview />
          )}
        </GridWrapper>
        </div>

      </Layout>
      </div>
     
    </>
  );
};

export default BillingPayments;
