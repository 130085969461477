// AddAccommodation.jsx
import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

export const AddAccommodation = ({
  onSubmitIndependent,
  onSubmitGroup,
  onSubmitParking,
  independentData,
  groupData,
  parkingData,
  isSubmitting,
  ShowValue,
  onClose
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      northLength: '',
      southLength: '',
      eastLength: '',
      westLength: '',
      plotType: 'regular',
      measurementUnit: Cookies.get('measurement_type') || 'sq/ft'
    }
  });

  const rawMeasurementUnit = Cookies.get('measurement_type');
  const measurementType =
    !rawMeasurementUnit || rawMeasurementUnit === 'null' || rawMeasurementUnit === 'undefined'
      ? 'sq/ft'
      : rawMeasurementUnit;

  const [isShow, setisShow] = useState('');
  const [plotArea, setPlotArea] = useState('');

  // Watch relevant fields
  const watchedPlotType = watch('plotType', 'regular');
  const watchedWidth = watch('width', '');
  const watchedDepth = watch('length', '');

  //write a useeffect to reset form on on watchedPlotType change
  useEffect(() => {
    setValue('width', '');
    setValue('length', '');
    setValue('eastLength', '');
    setValue('westLength', '');
    setValue('northLength', '');
    setValue('southLength', '');
    setValue('plotArea', '');
  }, [watchedPlotType, setValue]);

  // irregular plot fields
  const watchedEast = watch('eastLength', '');
  const watchedWest = watch('westLength', '');
  const watchedNorth = watch('northLength', '');
  const watchedSouth = watch('southLength', '');

  // Handle Accommodation Type Change
  const handleOnChangeType = (e) => {
    setisShow(e.target.value);
    setValue('accomodationType', e.target.value);
  };

  // Calculate Plot Area for regular Plots
  useEffect(() => {
    if (isShow === 'INDEPENDENT' && watchedPlotType === 'regular') {
      const width = parseFloat(watchedWidth);
      const length = parseFloat(watchedDepth);
      if (!isNaN(width) && !isNaN(length)) {
        const calculatedPlotArea = (width * length).toFixed(2);
        setPlotArea(calculatedPlotArea);
        setValue('plotArea', calculatedPlotArea);
      } else {
        setPlotArea('');
        setValue('plotArea', '');
      }
    }
    // Reset plot area for irregular plots
    if (isShow === 'INDEPENDENT' && watchedPlotType === 'irregular') {
      setPlotArea('');
      setValue('plotArea', '');
    }
  }, [watchedWidth, watchedDepth, watchedPlotType, isShow, setValue]);

  const handleCarpetAreaChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^0-9.]/g, '').slice(0, 8);
      setValue('carpetArea', cleanedValue);
    },
    [setValue]
  );

  const handleBuildUpAreaChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^0-9.]/g, '').slice(0, 8);
      setValue('buildUpArea', cleanedValue);
    },
    [setValue]
  );

  const handlePlotAreaChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^0-9.]/g, '').slice(0, 8);
      setValue('plotArea', cleanedValue);
      setPlotArea(cleanedValue);
    },
    [setValue]
  );

  const MultipleFiles = useSelector((state) => state.file.multifiles);

  console.log('This is the File State');
  console.log(MultipleFiles);

  const onSubmitHandler = (data) => {
    if (isShow === 'INDEPENDENT') {
      const transformedData = {
        ...data,
        plotArea: watchedPlotType === 'regular' ? plotArea : data.plotArea,
        plotType: watchedPlotType,
        ...(watchedPlotType === 'regular'
          ? { width: data.width, length: data.length }
          : {
              eastLength: data.eastLength,
              westLength: data.westLength,
              northLength: data.northLength,
              southLength: data.southLength
            }),
        floorPlanLink: MultipleFiles?.floorPlanLink || [],
        unitSpecification: MultipleFiles?.unitSpecification || [],
        materialListLink: MultipleFiles?.materialListLink || []
      };
      onSubmitIndependent(transformedData);
    } else if (isShow === 'GROUP') {
      const transformedData = {
        ...data,
        floorPlanLink: MultipleFiles?.floorPlanLink || [],
        unitSpecification: MultipleFiles?.unitSpecification || [],
        materialListLink: MultipleFiles?.materialListLink || []
      };
      onSubmitGroup(transformedData);
    } else if (isShow === 'PARKING') {
      onSubmitParking(data);
    }
  };

  return (
    <div>
      <div>
        <div className="flex flex-col justify-between items-start gap-4">
          <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
            Accommodation Details
          </span>
        </div>
        <div className="flex flex-col gap-6 pt-6">
          <div className="flex gap-10 justify-between items-center w-full">
            <div className="text-md flex flex-col gap-2 w-[48%]">
              <span className="text-gray-600">Accommodation Type</span>
              <select
                onChange={handleOnChangeType}
                className="w-full p-2 border-[1px] border-stone-300 rounded"
                defaultValue="">
                <option value="" disabled>
                  Select Accommodation Type
                </option>
                <option value="INDEPENDENT">Independent</option>
                <option value="GROUP">Group</option>
                <option value="PARKING">Parking</option>
              </select>
            </div>

            <FormInput
              width={48}
              label={'Accommodation Name'}
              register={register}
              register_key={'name'}
              isRequired={true}
              type={'text'}
              validation={{ required: 'Accommodation name is required' }}
              errors={errors}
            />

            <input type="hidden" name="accomodationType" {...register('accomodationType')} />
          </div>

          {/* Render form based on Accommodation Type */}
          {isShow === 'INDEPENDENT' && (
            <div className="flex flex-col gap-6">
              {/* Unit Type and Plot Type Fields */}
              <div className="flex justify-between items-center w-full">
                <FormInput
                  width={48}
                  label={'Unit Type'}
                  register={register}
                  register_key={'unitType'}
                  isRequired={true}
                  type={'dropdown'}
                  options={[
                    { value: '', label: 'Select Unit Type', disabled: true },
                    { value: 'Residential', label: 'Residential' },
                    { value: 'Commercial', label: 'Commercial' }
                  ]}
                  validation={{ required: 'Unit Type is required' }}
                  errors={errors}
                />

                <FormInput
                  width={48}
                  label={'Plot Type'}
                  register={register}
                  register_key={'plotType'}
                  isRequired={true}
                  type={'dropdown'}
                  options={[
                    { value: 'regular', label: 'Regular' },
                    { value: 'irregular', label: 'Irregular' }
                  ]}
                  validation={{ required: 'Plot Type is required' }}
                  errors={errors}
                />
              </div>

              {/* Conditionally render fields based on Plot Type */}
              {watchedPlotType === 'regular' ? (
                <div className="flex justify-between items-center w-full">
                  <FormInput
                    key={'width'}
                    width={32}
                    label={`Width (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register={register}
                    register_key={'width'}
                    isRequired={true}
                    type={'text'}
                    onType={(val) => setValue('width', val)}
                    validation={{
                      required: 'Width is required',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Width must be a number'
                      }
                    }}
                    errors={errors}
                  />
                  <FormInput
                    key={'length'}
                    width={32}
                    label={`Depth (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register={register}
                    register_key={'length'}
                    isRequired={true}
                    type={'text'}
                    onType={(val) => setValue('length', val)}
                    validation={{
                      required: 'Depth is required',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Depth must be a number'
                      }
                    }}
                    errors={errors}
                  />

                  <FormInput
                    key={'measurementUnit'}
                    width={32}
                    label={'Measurement Unit'}
                    register={register}
                    register_key={'measurementUnit'}
                    isRequired={true}
                    type={'text'}
                    valuePre={measurementType ? measurementType : 'sq/ft'}
                    readOnly={true} // Read-only as it's predefined
                    validation={{ required: 'Measurement Unit is required' }}
                    errors={errors}
                  />
                </div>
              ) : (
                <div className="flex justify-between gap-2 flex-nowrap">
                  {/* East, West, North, South Fields */}
                  <FormInput
                    key={'eastLength'}
                    width={18}
                    label={`East (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register={register}
                    register_key={'eastLength'}
                    isRequired={true}
                    type={'text'}
                    onType={(val) => setValue('eastLength', val)}
                    validation={{
                      required: 'East is required',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'East must be a number'
                      }
                    }}
                    errors={errors}
                  />
                  <FormInput
                    key={'westLength'}
                    width={18}
                    label={`West (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register={register}
                    register_key={'westLength'}
                    isRequired={true}
                    type={'text'}
                    onType={(val) => setValue('westLength', val)}
                    validation={{
                      required: 'West is required',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'West must be a number'
                      }
                    }}
                    errors={errors}
                  />
                  <FormInput
                    key={'northLength'}
                    width={18}
                    label={`North (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register={register}
                    register_key={'northLength'}
                    isRequired={true}
                    type={'text'}
                    onType={(val) => setValue('northLength', val)}
                    validation={{
                      required: 'North is required',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'North must be a number'
                      }
                    }}
                    errors={errors}
                  />
                  <FormInput
                    key={'southLength'}
                    width={18}
                    label={`South (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register={register}
                    register_key={'southLength'}
                    isRequired={true}
                    type={'text'}
                    onType={(val) => setValue('southLength', val)}
                    validation={{
                      required: 'South is required',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'South must be a number'
                      }
                    }}
                    errors={errors}
                  />

                  <FormInput
                    key={'measurementUnitirregular'}
                    width={16}
                    label={'Measurement Unit'}
                    register={register}
                    register_key={'measurementUnit'}
                    isRequired={true}
                    type={'text'}
                    valuePre={measurementType ? measurementType : 'sq/ft'}
                    readOnly={true} // Read-only as it's predefined
                    validation={{ required: 'Measurement Unit is required' }}
                    errors={errors}
                  />
                </div>
              )}

              {/* Build Up Area and Carpet Area */}
              <div className="flex justify-between items-center w-full">
                {watchedPlotType === 'regular' ? (
                  <FormInput
                    width={32}
                    label={`Plot Area (${measurementType ? measurementType : 'sq/ft'})`}
                    register={register}
                    register_key={'plotArea'}
                    isRequired={true}
                    type={'text'}
                    valuePre={plotArea}
                    readOnly={true}
                    validation={{
                      required: 'Plot Area is required',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Plot Area must be a number'
                      }
                    }}
                    errors={errors}
                  />
                ) : (
                  <FormInput
                    width={32}
                    label={`Plot Area (${measurementType ? measurementType : 'sq/ft'})`}
                    register={register}
                    register_key={'plotArea'}
                    isRequired={true}
                    type={'text'}
                    onType={handlePlotAreaChange}
                    placeholder="Enter plot area manually"
                    validation={{
                      required: 'Plot Area is required for Irregular plots',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Plot Area must be a number'
                      }
                    }}
                    errors={errors}
                  />
                )}
                <FormInput
                  width={32}
                  label={`Build Up Area (${measurementType ? measurementType : 'sq/ft'})`}
                  register={register}
                  register_key={'buildUpArea'}
                  isRequired={true}
                  type={'text'}
                  onType={handleBuildUpAreaChange}
                  validation={{
                    required: 'Build Up Area is required',
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Build Up Area must be a number'
                    }
                  }}
                  errors={errors}
                />

                <FormInput
                  width={32}
                  label={`Carpet Area (${measurementType ? measurementType : 'sq/ft'})`}
                  register={register}
                  register_key={'carpetArea'}
                  isRequired={true}
                  type={'text'}
                  onType={handleCarpetAreaChange}
                  validation={{
                    required: 'Carpet Area is required',
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Carpet Area must be a number'
                    }
                  }}
                  errors={errors}
                />
              </div>

              {/* File Uploads */}
              <div className="flex justify-start space-x-4 items-center w-full">
                <FormInput
                  width={32}
                  register={register}
                  register_key={'floorPlanLink'}
                  isRequired={false}
                  type={'file-btn'}
                  valuePre={'dummy-link'}
                  label={'Add Floor Plan'}
                  errors={errors}
                />
                <FormInput
                  width={32}
                  register={register}
                  register_key={'unitSpecification'}
                  isRequired={false}
                  type={'file-btn'}
                  valuePre={'dummy-link'}
                  label={'Unit Specification'}
                  errors={errors}
                />

                {/* <FormInput
                  width={32}
                  register={register}
                  register_key={'materialListLink'}
                  isRequired={false}
                  type={'file-btn'}
                  valuePre={'dummy-link'}
                  label={'Material List'}
                  errors={errors}
                /> */}
              </div>
              {/* Submit Buttons */}
              <div className="w-full flex justify-end items-center space-x-4 mt-8">
                <Button type="reset" onClick={onClose} displayType="secondary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmitHandler)} type="submit">
                  Add
                </Button>
              </div>
            </div>
          )}
          {/* GROUP Accommodation Type */}
          {isShow === 'GROUP' && (
            <div className="flex flex-col gap-6">
              <div className="flex justify-between items-center w-full">
                <FormInput
                  width={48}
                  label={`Build Up Area (${measurementType ? measurementType : 'sq/ft'})`}
                  register={register}
                  register_key={'buildUpArea'}
                  isRequired={true}
                  type={'text'}
                  onType={handleBuildUpAreaChange}
                  validation={{
                    required: 'Build Up Area is required',
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Build Up Area must be a number'
                    }
                  }}
                  errors={errors}
                />

                <FormInput
                  width={48}
                  label={`Carpet Area (${measurementType ? measurementType : 'sq/ft'})`}
                  register={register}
                  register_key={'carpetArea'}
                  isRequired={true}
                  type={'text'}
                  onType={handleCarpetAreaChange}
                  validation={{
                    required: 'Carpet Area is required',
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Carpet Area must be a number'
                    }
                  }}
                  errors={errors}
                />
              </div>
              <div className="flex justify-between items-center w-full">
                <FormInput
                  width={48}
                  label={'Unit Type'}
                  register={register}
                  register_key={'unitType'}
                  isRequired={true}
                  type={'dropdown'}
                  options={[
                    {
                      value: '',
                      label: 'Select type',
                      disabled: true
                    },
                    { value: 'Residential', label: 'Residential' },
                    { value: 'Commercial', label: 'Commercial' }
                  ]}
                  validation={{ required: 'Unit Type is required' }}
                  errors={errors}
                />

                <FormInput
                  width={48}
                  register={register}
                  register_key={'floorPlanLink'}
                  isRequired={false}
                  type={'file-btn'}
                  label={'Add Floor Plan'}
                  errors={errors}
                />
                <FormInput
                  width={48}
                  register={register}
                  register_key={'unitSpecification'}
                  isRequired={false}
                  type={'file-btn'}
                  label={'Unit Specification'}
                  errors={errors}
                />
              </div>
              {/* <div className="flex justify-between items-center w-full">
                <FormInput
                  width={46}
                  register={register}
                  register_key={'materialListLink'}
                  isRequired={false}
                  type={'file-btn'}
                  label={'Material List'}
                  errors={errors}
                />
              </div> */}

              {/* Submit Buttons */}
              <div className="w-full flex justify-end items-center space-x-4 mt-8">
                <Button type="reset" onClick={onClose} displayType="secondary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmitHandler)} type="submit">
                  Add
                </Button>
              </div>
            </div>
          )}

          {/* PARKING Accommodation Type */}
          {isShow === 'PARKING' && (
            <div className="flex flex-col gap-6">
              <div className="flex justify-between items-center w-full space-x-8">
                <FormInput
                  width={32}
                  label={'Unit Type'}
                  register={register}
                  register_key={'unitType'}
                  isRequired={true}
                  type={'dropdown'}
                  options={[
                    {
                      value: '',
                      label: 'Select type',
                      disabled: true
                    },
                    { value: '2-wheeler', label: '2-wheeler' },
                    { value: '4-wheeler', label: '4-wheeler' }
                  ]}
                  validation={{ required: 'Unit Type is required' }}
                  errors={errors}
                />

                <FormInput
                  width={32}
                  label={`Parking Space Type (${measurementType ? measurementType : 'sq/ft'})`}
                  register={register}
                  register_key={'buildUpArea'}
                  isRequired={true}
                  type={'text'}
                  onType={handleBuildUpAreaChange}
                  validation={{
                    required: 'Parking Space Type is required',
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Parking Space Type must be a number'
                    }
                  }}
                  errors={errors}
                />

                <input
                  type="hidden"
                  name="accomodationType"
                  value="PARKING"
                  {...register('accomodationType')}
                />

                <div className="text-md flex flex-col justify-start gap-2 w-[48%] pl-6">
                  <span className="text-gray-600">Electrified</span>
                  <div className="w-fit flex justify-start space-x-6">
                    <div className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="electrified"
                        id="electrifiedYes"
                        value="Yes"
                        {...register('electrified', { required: 'Electrified is required' })}
                      />
                      <label htmlFor="electrifiedYes">Yes</label>
                    </div>

                    <div className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="electrified"
                        id="electrifiedNo"
                        value="No"
                        {...register('electrified', { required: 'Electrified is required' })}
                      />
                      <label htmlFor="electrifiedNo">No</label>
                    </div>
                  </div>
                  {errors.electrified && (
                    <p className="text-red-500 text-xs mt-1">{errors.electrified.message}</p>
                  )}
                </div>
              </div>

              {/* Submit Buttons */}
              <div className="w-full flex justify-end items-center space-x-4 mt-8">
                <Button type="reset" onClick={onClose} displayType="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit(onSubmitHandler)}
                  disabled={isSubmitting}
                  type="submit">
                  Add
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
