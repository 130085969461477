import React, { useEffect, useState } from 'react'
import Button from '../../../shared/components/ui/Button'
import Layout from '../layout/AppLayout'
import Modal from '../../../shared/components/ui/Modal'
import { ReportBuilder } from './ReportBuilder'
import { useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom'

const Analytics = () => {
    const [ReportModal, setReportModal] = useState(false)
    const Params = useLocation();
    const navigation = useNavigate()
    useEffect(() => {
        if (Params.pathname == "/builder/analytics") {
            setReportModal(true)
        }
    }, [])

    const handleOnClose = () => {
        setReportModal(false)
        navigation('/builder/management/company')

    }
    return (
        <>
            <Layout>
                {
                    ReportModal && (<Modal isDirty={true} confirmOnClose={true} name={"Reports"} width={60} onClose={handleOnClose}>
                        <ReportBuilder />
                    </Modal>)
                }
            </Layout>
        </>
    )
}

export default Analytics