import React, { useCallback, useEffect, useState } from 'react';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Search from '../../shared/components/ui/Search';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import filter from '../../../modules/shared/assets/svg/filter.svg';
import Table from '../../shared/components/layout/Table';
import usersjson from '../static/data/Users.json';
import { transformData } from '../../super-admin/utils/transformData';
import { AddUser } from '../components/forms/AddUser';
import Modal from '../../shared/components/ui/Modal';
import {
  addUserStatic,
  createUser,
  deleteUserById,
  getAllDesignations,
  getAllRoles,
  getAllUsers,
  getUserById,
  resendEmailInvites
} from '../services/api/implementation/impl';
import { useNavigate } from 'react-router-dom';
import { EditUser } from '../components/forms/EditUser';
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { Sales } from './SalesList';
import { RateList } from './RateList';
import { filterData } from '../../shared/utils/filterData';

import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';

const UsersList = () => {
  /* 
    Hooks
  */
  const navigate = useNavigate();

  /* 
    Local state
  */
  const [OpenAddUser, setOpenAddUser] = useState(false);
  const [isOpenEditUser, setIsOpenEditUser] = useState(false);
  const [userStatic, setuserStatic] = useState(usersjson);
  const [searchQuery, setSearchQuery] = useState('');
  const [FilteredData, setFilteredData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  /* 
    Event handlers
  */
  const handleToggleAddUser = () => {
    setOpenAddUser(!OpenAddUser);
  };

  const handleToggleEditUser = (id) => {
    setIsOpenEditUser(!isOpenEditUser);
  };

  const handleNavigateUser = (id) => {
    getUserbyid(id);
  };
  const handleResendInvite = (id) => {
    const user = users?.data?.filter((user) => parseInt(user.id) === parseInt(id));
    const email = user[0]?.email;
    resendInvite({ email });
  };

  const handleDeleteUser = (userId) => {
    deleteUser(userId);
  };

  const usersDataFormat = [
    'id',
    'name',
    'email',
    'phone_number',
    'designation_name',
    'is_active',
    'actions',
    'invite'
  ];

  /* 
    Hook form
  */
  const { reset } = useForm();

  /* 
    Data fetching
   */

  const {
    data: users,
    refetch: refetchUsers,
    isFetching
  } = useQuery({
    queryKey: ['users', page],
    queryFn: () => getAllUsers(page, limit),
    keepPreviousData: true
  });

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: () => getAllRoles()
  });

  const { data: designations } = useQuery({
    queryKey: ['designations'],
    queryFn: () => getAllDesignations()
  });

  console.log({ roles });

  const totalPages = users?.meta ? Math.ceil(users?.meta.total / limit) : 1;

  const handlePageChange = (newPage) => {
    setFilteredData([]);
    setSearchQuery('');
    setPage(newPage);
  };

  /* 
    Data mutation
  */
  const { mutate: deleteUser } = useMutation({
    mutationFn: (userId) => deleteUserById(userId),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (res) => {
      setIsSubmitting(false);
      console.log({ res });
      toast.success('User deleted successfully');
      console.log('User deleted successfully');
      await refetchUsers(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error deleting user');
    }
  });

  const { mutate: getUserbyid, isLoading: isLoadingUserbyIdMutation } = useMutation({
    mutationFn: (id) => getUserById(parseInt(id)),
    onSuccess: async (res) => {
      // Add toast here
      navigate(`/builder/users/${res.id}`);
      reset();
      await refetchUsers(); // UI data update
    },
    onError: (err) => {
      toast.error('Error creating get user by Id');
      console.error('Error creating new user by Id');
    }
  });
  useEffect(() => {
    setFilteredData(users?.data);
  }, [users?.data]);

  //wriet useMutation for resendEmailInvites
  const { mutate: resendInvite } = useMutation({
    mutationFn: (payload) => resendEmailInvites(payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (res) => {
      setIsSubmitting(false);
      console.log({ res });
      toast.success('Invite sent successfully');
      console.log('Invite sent successfully');
      await refetchUsers(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error sending invite');
    }
  });

  const handleSearchChange = useCallback(
    (incomingQuery) => {
      console.log({ incomingQuery });
      if (incomingQuery) {
        const filteredUsers = users?.data?.filter((user) => {
          const lowerQuery = incomingQuery.toLowerCase();
          return (
            user.name?.toLowerCase().includes(lowerQuery) ||
            user.email?.toLowerCase().includes(lowerQuery) ||
            user.phone_number?.toLowerCase().includes(lowerQuery)
          );
        });
        setFilteredData(filteredUsers);
      } else {
        setFilteredData(users?.data);
      }
    },
    [users?.data]
  );

  /* 
    Data mutation
  */
  const { mutate, isLoading: isLoadingNewUserMutation } = useMutation({
    mutationFn: (newUser) => createUser(newUser),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      // Add toast here
      toast.success('User created successfully');
      reset();
      await refetchUsers(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      toast.error('Error creating user');
      console.error('Error creating new user');
    }
  });

  // Tranformation of the user data
  const transformedData = FilteredData?.map((item) =>
    transformData(
      {
        ...item,
        designation_name: designations?.filter((ele) => ele.id == item?.desgignation_id)?.map((ele) => ele.name),
        invite: `Invite ${item?.is_active}`
      },
      usersDataFormat
    )
  );

  // onSubmit button function  for the Add to user
  const onSubmit_User = (data) => {
    mutate({ ...data });
    setOpenAddUser(false);
  };

  console.log({ users, getUserbyid });
  /* 
    Effects
  */
  useEffect(() => {
    refetchUsers();
  }, [refetchUsers]);

  return (
    <>
      {/* Add builder form */}
      {OpenAddUser && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Add User" onClose={handleToggleAddUser}>
          <AddUser
            isSubmitting={isSubmitting}
            onSubmit={onSubmit_User}
            users={userStatic}
            onClose={handleToggleAddUser}
          />
        </Modal>
      )}

      {/* Edit user form */}
      {isOpenEditUser && (
        <Modal isDirty={true} confirmOnClose={true} width={56} name="Edit builder" onClose={() => setIsOpenEditUser(false)}>
          <EditUser
            isSubmitting={isSubmitting}
            onSubmit={onSubmit_User}
            onClick={searchQuery}
            onClose={() => setIsOpenEditUser(false)}
          />
        </Modal>
      )}

      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18vw] z-40 w-[82vw]">
          <Header data={{ name: 'Users' }} />
        </div>

        {/* No data found */}
        {isFetching ? (
          <div className="flex items-center justify-center h-full">
            <p>Loading...</p> {/* Replace this with a spinner or skeleton if needed */}
          </div>
        ) : users?.data && users?.data?.length > 0 ? (
          <>
            {/* Main content */}
            <div className="flex z-10 items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
              {/* Pass searchQuery and handleSearchChange as props to Search component */}
              <Search onChange={handleSearchChange} query={searchQuery} />
              <div className="flex gap-5 cursor-pointer">
                <div>{/* <img className="" src={filter} alt="" /> */}</div>
                {/* <Button displayType={"secondary"} onClick={()=>navigate("/builder/users/roles")} children={'Manage Roles'}  /> */}
                <Button onClick={handleToggleAddUser}>Add User</Button>
              </div>
            </div>

            {/* Grid */}
            <GridWrapper width={100}>
              <div className="mt-[12rem] h-[28rem] relative">
                <Table
                  headers={['Name', 'Email', 'Phone', 'Designation', 'Status', 'Action', 'Invite']}
                  data={transformedData}
                  identifier={'id'}
                  onEdit={handleToggleEditUser}
                  onView={handleNavigateUser}
                  onResendInvite={(id) => handleResendInvite(id)}
                  onDelete={(id) => deleteUser(id)}
                  // isDeleteEnable={true}
                  isViewEnable={true}
                  isEditEnable={false}
                />
                <PaginationComponent
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  isFetching={isFetching}
                />
              </div>
            </GridWrapper>
          </>
        ) : (
          <NoDataFound buttonText="Add User" onButtonClick={handleToggleAddUser} />
        )}
      </Layout>
    </>
  );
};

export default UsersList;
