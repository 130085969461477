import React, { useCallback, useEffect, useState } from 'react';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Search from '../../shared/components/ui/Search';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Table from '../../shared/components/layout/Table';
import { transformData } from '../../super-admin/utils/transformData';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { filterData } from '../../shared/utils/filterData';
import { UpdateStatus } from '../components/forms/UpdateStatus';
import Modal from '../../shared/components/ui/Modal';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getOrders,
  getRequirements,
  updateOrdersStatus,
  downloadInvoiceForOrder,
  downloadBillForOrder
} from '../services/api/implementation/impl';
import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';

const Orders = () => {
  const navigate = useNavigate();

  // pagination
  const [requirementPage, setRequirementPage] = useState(1);
  const [ordersPage, setOrdersPage] = useState(1);
  const [requirementLimit] = useState(10); // items per page
  const [ordersLimit] = useState(10); // items per page

  // Query to get requirements
  const {
    data: getAllrequiremnt,
    refetch: refetchRequiremnt,
    isFetching
  } = useQuery({
    queryKey: ['requirement', requirementPage],
    queryFn: () => getRequirements(requirementPage, requirementLimit),
    keepPreviousData: true
  });

  // Query to get orders
  const { data: getAllOrders, refetch: refetchgetAllOrders } = useQuery({
    queryKey: ['orders', ordersPage],
    queryFn: () => getOrders(ordersPage, ordersLimit),
    keepPreviousData: true
  });

  // Mutations to handle invoice download
  const { mutate: handleDownloadInvoice } = useMutation({
    mutationFn: async (id) => {
      return await downloadInvoiceForOrder(id);
    },
    onSuccess: (data, id) => {
      if (!data || data.byteLength === 0) {
        toast.error('Error: File data is empty or invalid.');
        return;
      }
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `Order_${id}_Invoice.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      toast.success('Invoice Downloaded');
    },
    onError: (error) => {
      console.error('Error downloading invoice:', error);
      toast.error('Error in downloading invoice');
    }
  });

  // Mutations to handle bill download
  const { mutate: handleDownloadBill } = useMutation({
    mutationFn: async (id) => {
      return await downloadBillForOrder(id);
    },
    onSuccess: (data, id) => {
      if (!data || data.byteLength === 0) {
        toast.error('Error: File data is empty or invalid.');
        return;
      }
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `Order_${id}_Bill.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      toast.success('Bill Downloaded');
    },
    onError: (error) => {
      console.error('Error downloading bill:', error);
      toast.error('Error in downloading bill');
    }
  });

  // total pagination pages
  const totalRequirementPages = getAllrequiremnt?.meta
    ? Math.ceil(getAllrequiremnt?.meta.total / requirementLimit)
    : 1;
  const totalOrdersPage = getAllOrders?.meta
    ? Math.ceil(getAllOrders?.meta.total / ordersLimit)
    : 1;

  // Local state
  const [requirements, setrequirements] = useState([]);
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Retrieve the tab from localStorage, fallback to 'requirements' if nothing is stored
  const [activeTab, setActiveTab] = useState(() => {
    const storedTab = localStorage.getItem('ordersActiveTab');
    return storedTab || 'requirements';
  });

  const [filteredData, setFilteredData] = useState([]);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // set data from API to local states
  useEffect(() => {
    if (getAllrequiremnt?.data) {
      setrequirements(getAllrequiremnt.data);
    }
  }, [getAllrequiremnt?.data]);

  useEffect(() => {
    if (getAllOrders?.data) {
      setOrders(getAllOrders.data);
    }
  }, [getAllOrders?.data]);

  // handle tab changes and persist in localStorage
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('ordersActiveTab', tab);
  };

  // handle searching
  const handleSearchChange = useCallback(
    (query) => {
      setSearchQuery(query);

      let currentData = [];
      if (activeTab === 'requirements') {
        currentData = [...requirements];
      } else if (activeTab === 'orders') {
        currentData = [...orders];
      }

      const filteredData = filterData('vendor', currentData, query);
      setFilteredData(filteredData);
    },
    [activeTab, requirements, orders]
  );

  // handle searching on button click
  const handleSearch = useCallback(() => {
    let currentData = [];
    if (activeTab === 'requirements') {
      currentData = [...requirements];
    } else if (activeTab === 'orders') {
      currentData = [...orders];
    }
    const filteredData = filterData('vendor', currentData, searchQuery);
    setFilteredData(filteredData);
  }, [activeTab, requirements, orders, searchQuery]);

  // handle delete
  const handleDelete = (id) => {
    let currentData;
    if (activeTab === 'requirements') {
      currentData = [...requirements];
      setrequirements(currentData.filter((item) => item.id !== id));
    } else {
      currentData = [...orders];
      setOrders(currentData.filter((item) => item.id !== id));
    }
    const updatedData = currentData.filter((item) => item.id !== id);
    setFilteredData(updatedData);
    toast.success('Deleted successfully');
  };

  // effect to set filtered data whenever activeTab changes or data changes
  useEffect(() => {
    let currentData = [];
    if (activeTab === 'requirements') {
      currentData = requirements;
    } else if (activeTab === 'orders') {
      currentData = orders;
    }
    setFilteredData(currentData);
  }, [activeTab, requirements, orders]);

  // handle formatting
  const handleRequirementFormant = (item) => {
    return {
      id: item.id,
      category: item?.category?.name,
      project: item?.project?.name || ' ',
      requirement: item?.task?.jobTitle,
      vendors: item?.vendorRequirmentMappingItems?.length || '0'
    };
  };

  const handleOrders = (item) => {
    return {
      id: item?.id,
      vendor: item?.vendor?.name,
      category: item?.category?.name,
      task: item?.vendorRequirement?.task?.jobTitle,
      status: item?.status,
      actions: 'Actions'
    };
  };

  // transform data for requirements
  const requirementData = getAllrequiremnt?.data?.length
    ? getAllrequiremnt?.data?.map((item) =>
        transformData(handleRequirementFormant(item), [
          'id',
          'requirement',
          'category',
          'project',
          'vendors',
          'actions'
        ])
      )
    : [];

  // transform data for orders
  const OrdersData = getAllOrders?.data?.length
    ? getAllOrders?.data?.map(
        (item) => handleOrders(item),
        ['id', 'vendor', 'category', 'task', 'status', 'actions']
      )
    : [];

  // Status Update for the Orders
  const UpdateStatusOptions = [
    {
      id: 1,
      name: 'Material received'
    },
    {
      id: 2,
      name: 'Payment Done'
    }
  ];

  const { mutate: handleStatusUpdateFn } = useMutation({
    mutationFn: (data) => updateOrdersStatus(parseInt(orderId), data),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Status Updated Successfully');
      setOpenUpdateStatus(false);
      refetchgetAllOrders();
    },
    onError: (err) => {
      setIsSubmitting(false);
      toast.error(err.message || 'Error in Status Update');
    }
  });

  // Open modal for status update
  const handleStatusUpdate = (id) => {
    setOrderId(id);
    setOpenUpdateStatus(true);
  };

  // handle pagination
  const handleRequirementPageChange = (newPage) => {
    setrequirements([]);
    setFilteredData([]);
    setSearchQuery('');
    setRequirementPage(newPage);
  };

  const handleOrdersPageChange = (newPage) => {
    setOrders([]);
    setFilteredData([]);
    setSearchQuery('');
    setOrdersPage(newPage);
  };

  // handle viewing details of requirements
  const handleViewDetails = (id) => {
    navigate(`/builder/operations/orders/requirement/${id}`);
  };

  return (
    <>
      {openUpdateStatus && (
        <Modal isDirty={true} confirmOnClose={true} width={40} name="Update Status" onClose={() => setOpenUpdateStatus(false)}>
          <UpdateStatus
            onSubmit={handleStatusUpdateFn}
            statuses={UpdateStatusOptions}
            isSubmitting={isSubmitting}
            onClose={() => setOpenUpdateStatus(false)}
          />
        </Modal>
      )}
      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18vw] z-40 w-[82vw]">
          <Header data={{ name: 'Orders' }} />
        </div>

        {/* Tabs & Search */}
        <div className="flex z-20 items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
          <div className="flex gap-8">
            <p
              className={`text-gray-500 py-2 cursor-pointer ${
                activeTab === 'requirements' ? 'border-b-2 border-primary text-primary' : ''
              }`}
              onClick={() => handleTabChange('requirements')}
            >
              Received Quotations
            </p>
            <p
              className={`text-gray-500 py-2 cursor-pointer ${
                activeTab === 'orders' ? 'border-b-2 border-primary text-primary' : ''
              }`}
              onClick={() => handleTabChange('orders')}
            >
              Orders
            </p>
          </div>
          <Search onChange={handleSearchChange} query={searchQuery} onClick={handleSearch} />
        </div>

        {/* Main content */}
        <GridWrapper width={100}>
          <div className="mt-[12rem] h-[28rem]">
            {activeTab === 'requirements' &&
              (requirementData && requirementData?.length > 0 ? (
                <>
                  <Table
                    headers={['Requirement', 'Category', 'Project', 'Vendor', 'Action']}
                    data={requirementData}
                    identifier={'id'}
                    isEditEnable={false}
                    onViewByName={handleViewDetails}
                    isViewByName={true}
                    rowName={'View Quotations'}
                  />
                  <PaginationComponent
                    page={requirementPage}
                    totalPages={totalRequirementPages}
                    onPageChange={handleRequirementPageChange}
                    isFetching={isFetching}
                  />
                </>
              ) : (
                <NoDataFound disabledBtn={true} screenHeight={false} />
              ))}

            {activeTab === 'orders' &&
              (OrdersData && OrdersData?.length > 0 ? (
                <>
                  <Table
                    headers={['Vendor', 'Category', 'Task', 'Status', 'Action']}
                    data={OrdersData}
                    identifier={'id'}
                    isEditEnable={false}
                    isStatusUpdateEnable={true}
                    onStatusUpdate={(id) => handleStatusUpdate(id)}
                    isDownloadInvoiceEnable={true}
                    onDownloadInvoice={(id) => handleDownloadInvoice(id)}
                    isDownloadBillEnable={true}
                    onDownloadBill={(id) => handleDownloadBill(id)}
                    isViewEnable={true}
                    onView={(id) => navigate(`/builder/operations/orders/activity/${id}`)}
                  />
                  <PaginationComponent
                    page={ordersPage}
                    totalPages={totalOrdersPage}
                    onPageChange={handleOrdersPageChange}
                    isFetching={isFetching}
                  />
                </>
              ) : (
                <NoDataFound disabledBtn={true} screenHeight={false} />
              ))}
          </div>
        </GridWrapper>
      </Layout>
    </>
  );
};

export default Orders;
