import React from 'react';
import Layout from '../components/layout/AppLayout';
import Button from '../../shared/components/ui/Button';

function SentryTestPage() {
  return (
    <Layout>
      <div>
        <Button
          onClick={() => {
            throw new Error('This is your first error!');
          }}>
          Break the world
        </Button>
      </div>
    </Layout>
  );
}

export default SentryTestPage;
