// components/SalesTargets.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Papa from 'papaparse'; // Import papaparse for CSV generation

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Table from '../../shared/components/layout/Table';
import Button from '../../shared/components/ui/Button';
import Search from '../../shared/components/ui/Search';
import Modal from '../../shared/components/ui/Modal';
import PaginationComponent from '../../shared/components/PaginationComponent';
import NoDataFound from '../components/layout/NoDataFound';

import filter from '../../../modules/shared/assets/svg/filter.svg';
import FileDownload from '../../../modules/shared/assets/svg/download.svg';

import { transformData } from '../../super-admin/utils/transformData';
import { getAllProjects, getTargetReports } from '../services/api/implementation/impl';
import AddSalesTarget from '../components/forms/AddSalesTarget';
import { toast } from 'react-hot-toast'; // Assuming you're using react-hot-toast for notifications

const SalesTargets = () => {
  /*
   * -------------------------------------------------
   * Table Format
   * -------------------------------------------------
   * Define the keys in the order expected by the Table component.
   */
  const targetsFormat = [
    'salesRep',
    'companyName',
    // 'projectName',
    'target',
    'achieved',
    'pending',
    'id'
  ];

  /*
   * -------------------------------------------------
   * Component State
   * -------------------------------------------------
   */
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Company');
  const applyFilterRef = useRef(null);
  const [checkedItems, setCheckedItems] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  // Modal
  const [openAddTargetModal, setOpenAddTargetModal] = useState(false);

  // Downloading State
  const [isDownloading, setIsDownloading] = useState(false);
  const [dotCount, setDotCount] = useState(0);

  // react-hook-form for Filters (Month & Year)
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-based
  const currentYear = currentDate.getFullYear();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      selected_month: currentMonth.toString(),
      selected_year: currentYear.toString()
    }
  });

  /*
   * -------------------------------------------------
   * Fetching Targets from the API (Server-Side Pagination)
   * -------------------------------------------------
   */
  // Watch selected_month and selected_year for changes
  const selectedMonth = watch('selected_month');
  const selectedYear = watch('selected_year');

  const {
    data: targetResponse,
    isFetching: isFetchingTargets,
    refetch: refetchTargets
  } = useQuery({
    // Include page, limit, selectedMonth & selectedYear so React Query re-fetches if they change
    queryKey: ['targets', page, limit, selectedMonth, selectedYear],
    queryFn: () => getTargetReports(page, limit, selectedMonth, selectedYear),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!selectedMonth && !!selectedYear // Ensure month and year are selected
  });

  // If your API returns { data: [...], meta: {...} }
  const allTargets = targetResponse?.data || [];
  const totalItems = targetResponse?.meta?.total || 0;
  const totalPages = Math.ceil(totalItems / limit);

  /*
   * -------------------------------------------------
   * Transform the Data for Table
   * -------------------------------------------------
   */
  const processedTargets = allTargets.map((t) => {
    // Ensure your API returns fields like t.name, t.companyName, t.projects, etc.
    const pendingAmount = (t?.target || 0) - (t?.achieved || 0);

    return {
      salesRep: t.name || 'N/A',
      companyName: t.companyName || 'N/A',
      projectName: t.projects || 'N/A',
      target: t?.target || 0,
      achieved: t?.achieved || 0,
      pending: pendingAmount,
      id: t?.id || 'no-id' // for row identification
    };
  });

  // Convert each row to the shape required by Table
  const targetsData = processedTargets.map((row) =>
    transformData(row, targetsFormat)
  );

  /*
   * -------------------------------------------------
   * Handlers
   * -------------------------------------------------
   */
  const handleToggleAddUser = () => {
    setOpenAddTargetModal(!openAddTargetModal);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Filter widget logic
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    handleClearAll();
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const handleCheckboxChange = (val) => {
    setCheckedItems((prev) =>
      prev.includes(val) ? prev.filter((item) => item !== val) : [...prev, val]
    );
  };

  const handleClearAll = () => {
    setCheckedItems([]);
    setSelectedFilter('Company');
  };

  // Close filter popup if user clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (applyFilterRef.current && !applyFilterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
        handleClearAll();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /*
   * -------------------------------------------------
   * Handle Filter Form Submission
   * -------------------------------------------------
   */
  const onFilterSubmit = (data) => {
    // Reset to first page whenever filters change
    setPage(1);
    // Refetch data with new filters
    refetchTargets();
  };

  /**
   * Handle Download CSV using papaparse
   */
  const handleDownload = async () => {
    if (isDownloading) return; // Prevent multiple clicks

    setIsDownloading(true);
    try {
      // Fetch all data with page=1 and limit=1000 based on current filters
      const response = await getTargetReports(1, 1000, selectedMonth, selectedYear);
      const targets = response?.data || [];

      if (targets.length === 0) {
        toast.error('No data available to download.');
        return;
      }

      // Define CSV headers
      const headers = ['Sales Rep', 'Company Name', 'Project Name', 'Target', 'Achieved', 'Pending'];

      // Map data to CSV rows
      const rows = targets.map((t) => ({
        'Sales Rep': t.name || 'N/A',
        'Company Name': t.companyName || 'N/A',
        // 'Project Name': t.projects || 'N/A',
        'Target': t.target !== undefined ? t.target : 0,
        'Achieved': t.achieved !== undefined ? t.achieved : 0,
        'Pending': t.target !== undefined && t.achieved !== undefined ? t.target - t.achieved : 0,
      }));

      // Convert to CSV using papaparse
      const csv = Papa.unparse({
        fields: headers,
        data: rows,
      });

      // Create a Blob from the CSV content
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', `sales_targets_reports.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success('Download initiated successfully!');
    } catch (downloadError) {
      console.error('Download failed:', downloadError);
      toast.error('Failed to download the file. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  /*
   * -------------------------------------------------
   * Dots Animation Effect
   * -------------------------------------------------
   */
  useEffect(() => {
    let interval;
    if (isDownloading) {
      interval = setInterval(() => {
        setDotCount((prev) => (prev < 3 ? prev + 1 : 0));
      }, 500);
    } else {
      setDotCount(0);
    }

    return () => clearInterval(interval);
  }, [isDownloading]);

  const renderDownloadingText = () => {
    return `Downloading${'.'.repeat(dotCount)}`;
  };

  /*
   * -------------------------------------------------
   * Render
   * -------------------------------------------------
   */
  return (
    <div className="w-full flex flex-col z-5 bg-gray-100 pb-10 overflow-hidden">
      {/* Add Target Modal */}
      {openAddTargetModal && (
        <Modal isDirty={true} confirmOnClose={true} width={40} name="Add Target" onClose={handleToggleAddUser}>
          <AddSalesTarget 
            onClose={handleToggleAddUser}
          />
        </Modal>
      )}

      {/* Top Bar: Search, Filters, Download, Add Target */}
      <div className="flex items-center justify-between px-6 py-4 bg-white  border-t-[2px] border-stone-100 shadow-sm">
        {/* Search Component */}
        {/* Uncomment the Search component if needed */}
        {/* <Search placeholder="Search here..." /> */}
        <form onSubmit={handleSubmit(onFilterSubmit)} className="flex gap-4 items-end">
          {/* Month Selector */}
          <div className="flex flex-col">
            <label htmlFor="selected_month" className="mb-1 text-sm font-medium text-gray-700">
              Select Month <span className="text-red-500">*</span>
            </label>
            <select
              id="selected_month"
              className={`w-40 p-2 border ${
                errors.selected_month ? 'border-red-500' : 'border-gray-300'
              } rounded`}
              {...register('selected_month', {
                required: 'Month is required',
                validate: (value) => {
                  if (Number(value) >= 1 && Number(value) <= 12) {
                    return true;
                  }
                  return 'Please select a valid month';
                }
              })}
            >
              <option value="">Select a Month</option>
              {[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
              ].map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
            {errors.selected_month && (
              <span className="text-red-500 text-xs mt-1">
                {errors.selected_month.message}
              </span>
            )}
          </div>

          {/* Year Selector */}
          <div className="flex flex-col">
            <label htmlFor="selected_year" className="mb-1 text-sm font-medium text-gray-700">
              Select Year <span className="text-red-500">*</span>
            </label>
            <select
              id="selected_year"
              className={`w-40 p-2 border ${
                errors.selected_year ? 'border-red-500' : 'border-gray-300'
              } rounded`}
              {...register('selected_year', {
                required: 'Year is required',
                validate: (value) => {
                  const year = Number(value);
                  const currentYear = new Date().getFullYear();
                  if (year >= currentYear - 10 && year <= currentYear + 10) {
                    return true;
                  }
                  return `Please select a year between ${currentYear - 10} and ${currentYear + 10}`;
                }
              })}
            >
              {/* Generate a range of years, e.g., currentYear ± 10 */}
              {Array.from({ length: 21 }, (_, i) => currentYear - 10 + i).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            {errors.selected_year && (
              <span className="text-red-500 text-xs mt-1">
                {errors.selected_year.message}
              </span>
            )}
          </div>

          {/* Apply Filters Button */}
          <Button type="submit">Apply Filters</Button>
        </form>

        {/* Filters and Actions */}
        <div className="flex items-center gap-3 px-0">
          {/* Download Button */}
          <button
            className={`relative flex items-center justify-center ${
              isDownloading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
            onClick={handleDownload}
            disabled={isDownloading}
            aria-label="Download CSV"
          >
            <img
              height={35}
              width={35}
              src={FileDownload}
              alt="File Download Icon"
            />
            {isDownloading && (
              <span className="absolute top-10 left-0 text-sm text-gray-700">
                {renderDownloadingText()}
              </span>
            )}
          </button>

          {/* Add Target Button */}
          <Button onClick={handleToggleAddUser}>Set Targets</Button>
        </div>
      </div>

      {/* Targets Table */}
      <div className="w-full h-full">
        {isFetchingTargets ? (
          <div className="pt-12 text-center">
            <p>Loading...</p>
          </div>
        ) : targetResponse?.data && targetResponse.data.length > 0 ? (
          <GridWrapper width={100}>
            <div className="h-[22rem] relative">
              <Table
                headers={[
                  'Sales Rep',
                  'Company Name',
                  // 'Project Name',
                  'Target',
                  'Achieved',
                  'Pending'
                ]}
                data={targetsData}
                identifier="id"
                isViewEnable={false}
                isDeleteEnable={false}
                isEditEnable={false}
              />

              <PaginationComponent
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                isFetching={isFetchingTargets}
              />
            </div>
          </GridWrapper>
        ) : (
          <div className="pt-[4rem]">
            <NoDataFound disabledBtn screenHeight={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesTargets;
