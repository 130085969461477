import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import Button from '../../../shared/components/ui/Button';
import { getCloseDeals, generateDocument } from '../../services/api/implementation/impl';

const NewPropertyDocumentModal = ({ onClose, templateId, onSuccess }) => {
  const { id: projectId } = useParams();
  const [selectedDealId, setSelectedDealId] = useState('');

  // 1. Fetch all closed deals
  const {
    data: closeDealsData,
    isLoading: isLoadingCloseDeals,
    isError: isErrorCloseDeals,
  } = useQuery({
    queryKey: ['closeDeals'],
    queryFn: getCloseDeals,
  });

  // 2. Filter deals by project ID
  const dealsForProject = closeDealsData?.data?.filter(
    (deal) => deal?.unit?.project_id === Number(projectId)
  ) || [];

  // 3. Setup mutation to create/generate document
  const {
    mutate: createDocument,
    isLoading: isGeneratingDoc,
  } = useMutation({
    mutationFn: (payload) =>
      generateDocument( payload ), // Adjust if your API signature differs
    onSuccess: () => {
      toast.success('Document created successfully');
      // onClose();
      onSuccess();
    },
    onError: (error) => {
      console.error('Error creating document:', error);
      toast.error('Error creating document');
    },
  });

  // 4. Handle Generate Document button
  const handleGenerateDocument = () => {
    if (!selectedDealId) {
      toast.error('Please select a deal first');
      return;
    }
    const payload = { 
      id: parseInt(templateId),
      deal_id: parseInt(selectedDealId)
    };
    createDocument(payload);
  };

  if (isLoadingCloseDeals) {
    return <div>Loading deals...</div>;
  }

  if (isErrorCloseDeals) {
    return <div>Failed to fetch deals. Please try again.</div>;
  }

  return (
    <div className="flex flex-col gap-6">
      {/* Deal Selection Dropdown */}
      <div>
        <label className="font-medium mb-2 block">Select a Deal</label>
        <select
          className="border rounded p-2 w-full"
          value={selectedDealId}
          onChange={(e) => setSelectedDealId(e.target.value)}
        >
          <option value="">-- Select Deal --</option>
          {dealsForProject.map((deal) => {
            const leadName = deal?.lead?.user?.name;
            const unitName = deal?.unit?.unitName;
            return (
              <option key={deal.id} value={deal.id}>
                {leadName} ({unitName})
              </option>
            );
          })}
        </select>
      </div>

      {/* Footer Buttons */}
      <div className="flex justify-end gap-4 mt-4">
        <Button onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button onClick={handleGenerateDocument} disabled={isGeneratingDoc}>
          {isGeneratingDoc ? 'Generating...' : 'Generate'}
        </Button>
      </div>
    </div>
  );
};

export default NewPropertyDocumentModal;
