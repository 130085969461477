import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

const axiosInstance = axios.create({
  baseURL: 'https://dev.housingram.com/api'
});

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      toast.error('Session expired. Please login again.');
      Object.keys(Cookies.get()).forEach((cookie) => {
        Cookies.remove(cookie);
      });
      console.log('Session expired. Redirecting to login page.');
      window.location.replace('/');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
