import React from 'react';

import { BsFiletypePdf } from 'react-icons/bs';
import image from '../../shared/assets/png/error404.png';
import { getProjectByID } from '../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { AiOutlineFileImage } from 'react-icons/ai';
import { FaFileAlt } from 'react-icons/fa';
import { FaRegFile } from 'react-icons/fa';

export const ProjectDocument = () => {
  const { id: projectId } = useParams();

  /* 
      Data fetching
    */
  const { data: project } = useQuery({
    queryKey: ['project'],
    queryFn: () => getProjectByID(projectId)
  });

  console.log({ project });

  const getFileIcon = (fileName) => {
    const extension = fileName?.split('.')?.pop()?.toLowerCase();

    switch (extension) {
      case 'pdf':
        return <BsFiletypePdf size={80} />;
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <AiOutlineFileImage size={90} />;
      default:
        return <FaRegFile size={80} />; // Default icon for other file types
    }
  };

  const truncateFileName = (fileName, maxLength = 15) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split('.').pop();
    const nameWithoutExtension = fileName.slice(0, -(extension.length + 1));
    return `${nameWithoutExtension.slice(0, maxLength - 3)}...${extension}`;
  };

  let newArrayFiles = [];
  let projectImages = project?.projectImages?.map((item) => item);
  let buildingFiles = project?.buildings?.map((item) => [
    ...item.additionalDocuments,
    ...item.layoutPlans,
    ...item.developmentOrders,
    ...item.uploadLetterheads,
    ...item.googleMapImages
  ]);
  let googleImage = project?.googleImage?.map((item) => item);
  let brochures = project?.brochures?.map((item) => item);
  let tncp_approved_plan = project?.tncp_approved_plan?.map((item) => item);
  let tncp_sanctioned_layout_plan = project?.tncp_sanctioned_layout_plan?.map((item) => item);
  let projectSpecification = project?.projectSpecification.map((item) => item);

  console.log(buildingFiles, projectImages);

  return (
    <>
      <div className="w-full flex flex-col absolute top-[13rem] z-10 pl-5 pr-5 bg-gray-100 pb-10 overflow-hidden">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-10">
            <h3 className="border w-[10rem] text-center font-semibold p-2 rounded-lg bg-slate-600 text-lg text-white">
              Project Images
            </h3>
            <div className="flex  gap-3">
              {projectImages?.map((item, i) => (
                <div
                  key={i}
                  className="  flex flex-col  border w-[12rem] p-5 rounded-xl  justify-center items-center">
                  <a href={item.url} target="_blank" rel="noreferrer" download>
                    <AiOutlineFileImage size={80} />
                  </a>
                  <p className="text-center">{truncateFileName(item.name)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-10">
            <h3 className="border w-[10rem] text-center font-semibold p-2 rounded-lg bg-slate-600 text-lg text-white">
              Project files
            </h3>
            <div className="flex gap-3">
              {buildingFiles && buildingFiles.length > 0
                ? buildingFiles[0]?.map((file, i) => (
                    <div
                      key={i}
                      className="flex border w-[12rem] p-5 rounded-xl flex-col justify-center items-center">
                      <a
                        href={file.url}
                        download={file.name}
                        target="_blank"
                        rel="noopener noreferrer">
                        {getFileIcon(file.name)}
                      </a>
                      <p className="text-center">{truncateFileName(file.name)}</p>
                    </div>
                  ))
                : null}
              {googleImage?.map((item, i) => (
                <div
                  key={i}
                  className="  flex flex-col  border w-[12rem] p-5 rounded-xl  justify-center items-center">
                  <a href={item.url} target="_blank" rel="noreferrer" download>
                    <AiOutlineFileImage size={80} />
                  </a>
                  <p className="text-center">{truncateFileName(item.name)}</p>
                </div>
              ))}
              {brochures?.map((item, i) => (
                <div
                  key={i}
                  className="  flex flex-col  border w-[12rem] p-5 rounded-xl  justify-center items-center">
                  <a href={item.url} target="_blank" rel="noreferrer" download>
                    <AiOutlineFileImage size={80} />
                  </a>
                  <p className="text-center">{truncateFileName(item.name)}</p>
                </div>
              ))}
              {tncp_approved_plan?.map((item, i) => (
                <div
                  key={i}
                  className="  flex flex-col  border w-[12rem] p-5 rounded-xl  justify-center items-center">
                  <a href={item.url} target="_blank" rel="noreferrer" download>
                    <AiOutlineFileImage size={80} />
                  </a>
                  <p className="text-center">{truncateFileName(item.name)}</p>
                </div>
              ))}
              {tncp_sanctioned_layout_plan?.map((item, i) => (
                <div
                  key={i}
                  className="  flex flex-col  border w-[12rem] p-5 rounded-xl  justify-center items-center">
                  <a href={item.url} target="_blank" rel="noreferrer" download>
                    <AiOutlineFileImage size={80} />
                  </a>
                  <p className="text-center">{truncateFileName(item.name)}</p>
                </div>
              ))}
              {projectSpecification?.map((item, i) => (
                <div
                  key={i}
                  className="  flex flex-col  border w-[12rem] p-5 rounded-xl  justify-center items-center">
                  <a href={item.url} target="_blank" rel="noreferrer" download>
                    <AiOutlineFileImage size={80} />
                  </a>
                  <p className="text-center">{truncateFileName(item.name)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
