import React, { useEffect, useState } from 'react';

import editIcon from '../../assets/svg/edit.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import viewIcon from '../../assets/svg/view.svg';
import acceptTick from '../../assets/svg/acceptTick.svg';
import cancelCross from '../../assets/svg/cancelCross.svg';
import mark from '../../assets/svg/mark.svg';
import doller from '../../assets/svg/doller.svg';
import Status from '../../assets/svg/acceptCircle.svg';
// import DownloadIcon from '../../assets/svg/download.svg';
import DownloadIcon from '../../assets/svg/download-s.svg';
import UpdateIcon from '../../assets/svg/updateIcon.svg';
import FormInput from '../../../shared/components/ui/FormInput';

import DateFormat from '../../../super-admin/utils/DateFormat';
import { boolean } from 'yup';
import Button from './Button';

function TableData({
  data,
  width,
  identifier,
  onEdit,
  onDelete,
  onView,
  isDeleteEnable,
  isViewEnable,
  isEditEnable = true,
  onAccept,
  isAcceptEnable,
  isChangeStatusEnable,
  onChangeStatus,
  onCancel,
  isCancelEnable,
  onViewBrands,
  onViewItems,
  isStatusUpdateEnable,
  onStatusUpdate,
  isMarkEnable,
  onMark,
  isDollerEnable,
  onDoller,
  onViewByName,
  isViewByName,
  rowName,
  isBtnEnable,
  onBtn,
  showPaidButton,
  rowId,
  onViewNote,
  isDownloadInvoiceEnable,
  onDownloadInvoice,
  isDownloadBillEnable,
  onDownloadBill,
  completeItem,
  onResendInvite,
  isInventoryActionEnabled,
  onInventoryPurchase,
  onInventoryUpdate,
  onDownloadDoc,
  isDownloadDocEnable,
  onUploadDoc,
  uploadDocId,
  onDownloadSignDoc,
}) {


  const containerStyle = {
    width: `${Math.floor(width)}%`
  };
  console.log('TableData -> data', data);
  console.log('completeItem', completeItem);

  if (typeof data === 'string' && data?.includes('ShowPaidButton')) {
    const rowId = data?.split('---')?.[1];
    return (
      <div className="flex justify-start items-center space-x-3 px-3" style={containerStyle}>
        <Button onClick={(e) => (onBtn ? onBtn(rowId) : alert('No action assigned'))}>Paid</Button>
      </div>
    );
  }

  if (data === '2024-04-25T00:00:00Z') {
    return <DateFormat dateString={data} />;
  }

  if (typeof data === 'string' && data?.includes('BrandList')) {
    const rowId = data?.split('---')?.[1];
    console.log('TableData -> rowId', data);

    return (
      <div className="flex justify-start items-center space-x-3 px-3" style={containerStyle}>
        <p
          onClick={(e) => (onViewBrands ? onViewBrands(rowId) : alert('No view action assigned'))}
          className="text-primary">
          Multiple Brands
        </p>
      </div>
    );
  }
  if (typeof data === 'string' && data?.includes('ItemList')) {
    const rowId = data?.split('---')?.[1];
    console.log('TableData -> rowId', data);

    return (
      <div className="flex justify-start items-center space-x-3 px-3" style={containerStyle}>
        <p
          onClick={(e) => (onViewItems ? onViewItems(rowId) : alert('No view action assigned'))}
          className="text-primary">
          Multiple Items
        </p>
      </div>
    );
  }
  if (typeof data === 'string' && data?.includes('notes')) {
    const rowId = data?.split('---')?.[1];
    console.log('TableData -> rowId', data);

    return (
      <div className="flex justify-start items-center space-x-3 px-3" style={containerStyle}>
        <p
          onClick={(e) => (onViewNote ? onViewNote(rowId) : alert('No view action assigned'))}
          className="text-primary">
          Notes
        </p>
      </div>
    );
  }
  if (typeof data === 'string' && data?.includes('Invite')) {
    const rowId = data?.split('---')?.[1];
    console.log('TableData -> invite', { data });

    return (
      <div className="flex justify-start items-center space-x-3 px-3" style={containerStyle}>
        {data.includes('Invite true') ? (
          <p className="text-primary">Success</p>
        ) : (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) =>
              onResendInvite ? onResendInvite(rowId) : alert('No view action assigned')
            }>
            <Button customStyle={{ fontSize: '12px' }}>Resend Invite</Button>
          </div>
        )}
      </div>
    );
  }
  
  if (typeof data === 'string' && data?.includes('Actions')) {
    // const rowId = data?.split('---')?.[1];
    // if data is Actions-${doc.is_uploaded}---${doc.id} then split it and get the id and also the is_uploaded value
    const isUploaded = data?.split('---')?.[0].split('-')[1];
    const rowId = data?.split('---')?.[1];


   

    console.log('TableData new -> rowId', { data });

    return (
      <div className="flex justify-start items-center space-x-3 px-3" style={containerStyle}>
        {/* View button */}
        {isViewEnable && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) => (onView ? onView(rowId) : alert('No view action assigned'))}>
            <img src={viewIcon} alt="delete__icon" />
          </div>
        )}

        {/* {Mark as complete} */}
        {isMarkEnable && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300 flex gap-3"
            onClick={(e) => (onMark ? onMark(rowId) : alert('No Mark action assigned'))}>
            <p className="text-[#476369]">Mark As Completed</p>
            <img src={mark} alt="mark_icon" />
          </div>
        )}

        {/* Edit button */}
        {isEditEnable && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) => (onEdit ? onEdit(rowId) : alert('No edit action assigned'))}>
            <img src={editIcon} alt="edit__icon" />
          </div>
        )}

        {/* Delete button */}
        {isDeleteEnable && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) => (onDelete ? onDelete(rowId) : alert('No delete action assigned'))}>
            <img src={deleteIcon} alt="delete__icon" />
          </div>
        )}
        {/* Accept button */}
        {isAcceptEnable && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) => (onAccept ? onAccept(rowId) : alert('No accept action assigned'))}>
            <img src={acceptTick} alt="acceptTick_icon" />
          </div>
        )}

        {/* ChangeStatus button */}
        {isChangeStatusEnable && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) =>
              onChangeStatus ? onChangeStatus(rowId) : alert('No change action assigned')
            }>
            <img src={Status} alt="Status_icon" />
          </div>
        )}

        {/* Cancel button */}
        {isCancelEnable && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) => (onCancel ? onCancel(rowId) : alert('No cancel action assigned'))}>
            <img src={cancelCross} alt="cancelCross_icon" />
          </div>
        )}
        {/* Download Invoice button */}
        {isDownloadInvoiceEnable && completeItem?.status === 'Order Confirm' && (
          <Button
            type={'button'}
            customStyle={{
              padding: '0.5rem 1rem',
              fontSize: '13px',
              display: 'flex',
              gap: '5px',
              alignItem: 'center'
            }}
            displayType={'downloadButton'}
            onClick={(e) =>
              onDownloadInvoice ? onDownloadInvoice(rowId) : alert('No download action assigned')
            }>
            Invoice <img src={DownloadIcon} style={{ width: '17px', filter: 'invert(1)' }} alt="" />
          </Button>
        )}
        {/* Download Bill button */}
        {isDownloadBillEnable && completeItem?.status === 'Payment Done' && (
          <Button
            type={'button'}
            customStyle={{
              padding: '0.5rem 1rem',
              fontSize: '13px',
              display: 'flex',
              gap: '5px',
              alignItems: 'center'
            }}
            displayType={'downloadButton'}
            onClick={(e) =>
              onDownloadBill ? onDownloadBill(rowId) : alert('No download action assigned')
            }>
            Bill <img src={DownloadIcon} style={{ width: '17px', filter: 'invert(1)' }} alt="" />
          </Button>
        )}
        {isStatusUpdateEnable && (
          <Button
            type="button"
            customStyle={{
              padding: '0.5rem 1rem',
              fontSize: '13px',
              display: 'flex',
              gap: '5px',
              alignItems: 'center'
            }}
            displayType={'downloadButton'}
            onClick={(e) =>
              onStatusUpdate ? onStatusUpdate(rowId) : alert('No onStatusUpdate action assigned')
            }>
            Status <img src={UpdateIcon} style={{ width: '17px' }} alt="" />
          </Button>
        )}
        {isDownloadDocEnable && (
          <Button
            type={'button'}
            customStyle={{
              padding: '0.5rem 1rem',
              fontSize: '13px',
              display: 'flex',
              gap: '5px',
              alignItems: 'center'
            }}
            displayType={'downloadButton'}
            onClick={(e) =>
              onDownloadDoc ? onDownloadDoc(rowId) : alert('No download action assigned')
            }>
            Doc <img src={DownloadIcon} style={{ width: '17px', filter: 'invert(1)' }} alt="" />
          </Button>
        )}
        {isDownloadDocEnable && isUploaded === "false" &&  (
          <FormInput
            type={'file-icon-btn'}
            label={'Upload Signed Document'}
            register_key={'allotment_signed_doc'}
            accept={'.pdf'}
            uploadFunctionId={rowId}
            uploadFunction={onUploadDoc}
          />
        )}

        {isDownloadDocEnable &&  isUploaded === "true" &&(
          <Button
            type={'button'}
            customStyle={{
              padding: '0.5rem 1rem',
              fontSize: '13px',
              display: 'flex',
              gap: '5px',
              alignItems: 'center'
            }}
            displayType={'downloadButton'}
            onClick={(e) =>
              onDownloadSignDoc ? onDownloadSignDoc(rowId) : alert('No download action assigned')
            }>
            Sign <img src={DownloadIcon} style={{ width: '17px', filter: 'invert(1)' }} alt="" />
          </Button>
        )}

        {/* Doller button */}
        {isDollerEnable && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) => (onDoller ? onDoller(rowId) : alert('No Doller action assigned'))}>
            <img src={doller} alt="doller_icon" />
          </div>
        )}

        {isViewByName && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) =>
              onViewByName ? onViewByName(rowId) : alert('No view action assigned')
            }>
            <p className="text-blue-400">{rowName}</p>
          </div>
        )}

        {isBtnEnable && (
          <div
            className="cursor-pointer hover:scale-110 transition-all duration-300"
            onClick={(e) => (onBtn ? onBtn(rowId) : alert('No view action assigned'))}>
            <Button>Paid</Button>
          </div>
        )}
        {isInventoryActionEnabled && (
          <div>
            {/* <Button
              type="button"
              customStyle={{ padding: '0.5rem 1rem', fontSize: '13px' }}
              onClick={(e) =>
                onInventoryPurchase ? onInventoryPurchase(rowId) : alert('No purchase action assigned')
              }>
              Purchase
            </Button> */}
            <Button
              type="button"
              customStyle={{ padding: '0.5rem 1rem', fontSize: '13px' }}
              onClick={(e) =>
                onInventoryUpdate ? onInventoryUpdate(rowId) : alert('No update action assigned')
              }>
              Update
            </Button>
          </div>
        )}
      </div>
    );
  }
  if (typeof data === 'string' && data?.includes('stockStatus')) {
    const dataParts = data.split('-');
    const color = dataParts[1];
    const percentage = dataParts[2];
    return (
      <div className="flex justify-start items-center space-x-3 px-3" style={containerStyle}>
        <div
          className="cursor-pointer hover:scale-110 transition-all duration-300"
          style={{
            color: '#fff',
            padding: '0.3rem 0.5rem',
            borderRadius: '4px',
            width: '65px',
            textAlign: 'center',
            backgroundColor: `#${color}`
          }}>
          {percentage}%
        </div>
      </div>
    );
  }

  // Payment history status will be paid by default
  if (data === undefined) {
    return (
      <div
        className={`text-sm flex px-3 overflow-hidden text-stone-400 text-center justify-center items-center translate-x-[-25%]`}
        style={containerStyle}>
        {`___`}
      </div>
    );
  }

  /* 
    1. Upaid || Unapproved || Inactive- Red
    2. Paid || Approved || Active - Green
    3. Invite sent - Orange ** default
  */
  if (
    [
      'unpaid',
      'Unpaid',
      'unapproved',
      'Unapproved',
      'inactive',
      'Inactive',
      'INACTIVE',
      'Low',
      false,
      'No',
      'no',
      'Delivery Pending'
    ].includes(data)
  ) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="flex pl-6 overflow-hidden text-sm">{data ? data : 'Inactive'}</span>

        <div
          className={`w-[5px] h-[100%] bg-[#C2281B] rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  if (['Medium', 'Confirm Delivery'].includes(data)) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="flex pl-6 overflow-hidden text-sm">{data ? data : 'Inactive'}</span>

        <div
          className={`w-[5px] h-[100%] bg-orange-400 rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  if (
    [
      'paid',
      'Paid',
      'approved',
      'Approved',
      'active',
      'Active',
      'ACTIVE',
      'High',
      'Yes',
      'yes',
      'Completed',
      'Accepted',
      'Material received',
      'Sold'
    ].includes(data)
  ) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="flex pl-6 overflow-hidden text-sm">{data ? data : 'Active'}</span>

        <div
          className={`w-[5px] h-[100%] bg-[#3F8D78] rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  if (data === true) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="flex pl-6 overflow-hidden text-sm">{'Active'}</span>

        <div
          className={`w-[5px] h-[100%] bg-[#3F8D78] rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  if (
    [
      'pending',
      'Pending',
      'invite-sent',
      'Not Completed',
      'Material Dispatch',
      'Available'
    ].includes(data)
  ) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="flex pl-6 overflow-hidden text-sm">{data}</span>

        <div
          className={`w-[5px] h-[100%] bg-[#E97B37] rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`flex px-3 overflow-hidden text-sm`}
        style={{
          ...containerStyle,
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
          overflowWrap: 'anywhere'
        }}>
        {data}
      </div>
    </>
  );
}

export default TableData;
