import React, { useEffect, useState } from 'react';
import Button from '../../shared/components/ui/Button';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getAllBlocks,
  getUnitPricingByBlockId,
  updateUnitStatus,
  getUnitStatus
} from '../services/api/implementation/impl';
import { useParams } from 'react-router-dom';
import Table from '../../shared/components/layout/Table';
import { useForm } from 'react-hook-form';
import { transformData } from '../../super-admin/utils/transformData';
import toast from 'react-hot-toast';
import { UpdateStatus } from '../components/forms/UpdateStatus';
import Modal from '../../shared/components/ui/Modal';
import PaginationComponent from '../../shared/components/PaginationComponent';

/**
 * Define all possible transitions in a single map:
 * currentStatus => array of allowed next statuses
 */
const statusTransitions = {
  Vacant: ['Blocked', 'Blocked_for_Broker'],

  // If current status is "Blocked", it can transition to "Vacant" or "Blocked_for_Broker".
  Blocked: ['Vacant', 'Blocked_for_Broker'],

  // If current status is "Blocked_for_Broker", it can go to "Vacant" or "Blocked".
  Blocked_for_Broker: ['Vacant', 'Blocked'],

  // If current status is "Booked", it can transition to the following four statuses.
  Booked: [
    'Temporary_Booked',
    'Sales_Deed_Completed',
    'Sales_Deed_with_Pending_Payments',
    'No_Dues'
  ],

  // If current status is "Temporary_Booked", it can move to these four.
  Temporary_Booked: [
    'Sales_Deed_Completed',
    'Sales_Deed_with_Pending_Payments',
    'No_Dues',
    'Booked'
  ],

  // If current status is "Sales_Deed_Completed", it can move to these four.
  Sales_Deed_Completed: [
    'Temporary_Booked',
    'Sales_Deed_with_Pending_Payments',
    'No_Dues',
    'Booked'
  ],

  // If current status is "Sales_Deed_with_Pending_Payments", it can move to these four.
  Sales_Deed_with_Pending_Payments: [
    'Temporary_Booked',
    'Sales_Deed_Completed',
    'No_Dues',
    'Booked'
  ],

  // If current status is "No_Dues", it can move to these four.
  No_Dues: [
    'Temporary_Booked',
    'Sales_Deed_Completed',
    'Sales_Deed_with_Pending_Payments',
    'Booked'
  ]
};


const UnitPricingList = () => {
  const { id: projectId } = useParams();
  const { register } = useForm();
  const [newBlockId, setNewBlockId] = useState('');
  const [blocksDynaWithLevels, setBlocksDynaWithLevels] = useState([]);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [updateStatusOptions, setUpdateStatusOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Pagination states
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  // === Queries ===
  const { data: blocksDyna } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId
  });

  const {
    data: unitPricingData,
    refetch: refetchUnitPricingData,
    isFetching
  } = useQuery({
    queryKey: ['unitPricing', newBlockId, page],
    queryFn: () => getUnitPricingByBlockId(newBlockId, page, limit),
    keepPreviousData: true,
    enabled: !!newBlockId // Only fetch if we have a valid block ID
  });

  const { data: unitStatus } = useQuery({
    queryKey: ['unitStatus'],
    queryFn: () => getUnitStatus(),
    enabled: true
  });

  // === Mutations ===
  const { mutate: handleUnitStatusUpdate } = useMutation({
    mutationFn: (payload) => updateUnitStatus(selectedUnitId, payload),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Unit status updated successfully');
      setOpenUpdateStatus(false);
      await refetchUnitPricingData();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error updating unit status', err);
      toast.error('Error updating unit status');
    }
  });

  // === Effects ===
  useEffect(() => {
    // Filter out only blocks that have levels, then set the first one as default
    if (Array.isArray(blocksDyna) && blocksDyna.length > 0) {
      const blocksWithLevels = blocksDyna.filter(
        (block) => block?.levels?.length > 0
      );
      const sorted = blocksWithLevels.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      if (sorted.length > 0) {
        setBlocksDynaWithLevels(sorted);
        // If there's already a block selected, don't overwrite it;
        // otherwise default to the newest block
        setNewBlockId((prev) => (prev ? prev : sorted[0].id));
      }
    }
  }, [blocksDyna]);

  // Whenever block changes, refetch units
  useEffect(() => {
    if (newBlockId) {
      refetchUnitPricingData();
    }
  }, [newBlockId, refetchUnitPricingData]);

  // For debugging: log the statuses we fetched
  useEffect(() => {
    if (unitStatus) {
      console.log('Fetched unitStatus from API:', unitStatus);
    }
  }, [unitStatus]);

  // === Data transformations ===
  const UnitPricingFormat = [
    'id',
    'unitName',
    'cost',
    'plc_price',
    'gst',
    'total_price',
    'accommodationName',
    'status',
    'actions'
  ];

  const RequiredUnitPricingData =
  unitPricingData?.data && unitPricingData?.data.length > 0
    ? unitPricingData.data
        .map((item) =>
          transformData(
            {
              ...item,
              cost: parseInt(item?.cost) || 0,
              plc_price: parseInt(item?.plc_price) || 0,
              gst: `₹${(parseInt(item?.gst) || 0).toLocaleString('en-IN')}.00`,
              total_price: `₹${(
                (parseInt(item?.cost) || 0) +
                (parseInt(item?.plc_price) || 0) +
                (parseInt(item?.gst) || 0)
              ).toLocaleString('en-IN')}.00`,
              accommodationName: item?.accommodation?.name,
              status: item?.status
            },
            UnitPricingFormat
          )
        )
        // Sort by id in ascending order
        .sort((a, b) => a.id - b.id)
    : [];


  // === Handlers ===
  const handleSelectBlockChange = (event) => {
    const selectedBlockId = event.target.value;
    setNewBlockId(selectedBlockId);
    setPage(1); // Reset to first page on block change if needed
  };

  /**
   * Dynamically filter possible status updates based on current unit status,
   * using the `statusTransitions` map above.
   */
  const handleStatusUpdate = (unitId) => {
    setOpenUpdateStatus(true);
    setSelectedUnitId(unitId);
    console.log('Selected unit:', unitId);

    // Find the *transformed* unit in RequiredUnitPricingData
    // so we can detect the current status
    const currentUnit = RequiredUnitPricingData.find((u) => parseInt(u.id) === parseInt(unitId));
    console.log('Current unit:', currentUnit);
    if (!currentUnit || !unitStatus) {
      setUpdateStatusOptions([]);
      return;
    }

    const currentStatus = currentUnit.status; // e.g. "Vacant" or "Booked"
    const allowedNextStatuses =
      statusTransitions[currentStatus] || []; // fallback to empty if not defined

    // Filter those allowed statuses by what's actually available in the API
    const newOptions = unitStatus.filter((s) => allowedNextStatuses.includes(s));
    console.log('Allowed status updates:', newOptions);
    setUpdateStatusOptions(newOptions);
  };

  // Pagination
  const totalPages = unitPricingData?.meta
    ? Math.ceil(unitPricingData?.meta.total / limit)
    : 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {openUpdateStatus && (
        <Modal
          isDirty={true}
          confirmOnClose={true}
          width={40}
          name="Update Status"
          onClose={() => setOpenUpdateStatus(false)}
        >
          <UpdateStatus
            onClose={() => setOpenUpdateStatus(false)}
            statuses={updateStatusOptions}
            isSubmitting={isSubmitting}
            onSubmit={handleUnitStatusUpdate}
            type={'unitPricing'}
          />
        </Modal>
      )}

      <div className="w-full flex flex-col absolute top-[16rem] z-10 pl-5 pr-5 bg-gray-100 pb-10 overflow-hidden">
        <div className="flex justify-between items-center">
          <div className="mt-5 flex flex-col gap-2">
            <label htmlFor="blockSelector">Select Block</label>
            {blocksDynaWithLevels && blocksDynaWithLevels.length > 0 && (
              <select
                id="blockSelector"
                className="w-[10rem] p-2 border-[1px] border-stone-300 rounded"
                {...register('table_type')}
                value={newBlockId}
                onChange={handleSelectBlockChange}
              >
                {blocksDynaWithLevels.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        <div className="h-[25rem] mt-8 hide-scrollbar overflow-y-scroll relative">
          <Table
            headers={[
              'Unit Name',
              'Unit Price',
              'Total Plc Price',
              'GST',
              'Total Price',
              'Acc Name',
              'Status',
              'Action'
            ]}
            data={RequiredUnitPricingData}
            identifier={'id'}
            isEditEnable={false}
            isStatusUpdateEnable={true}
            // Pass the ID to our handler:
            onStatusUpdate={(id) => handleStatusUpdate(id)}
          />

          <PaginationComponent
            page={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isFetching={isFetching}
          />
        </div>
      </div>
    </>
  );
};

export default UnitPricingList;
