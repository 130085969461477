import React, { useCallback, useEffect, useState } from 'react';
import Search from '../../shared/components/ui/Search';
import Table from '../../shared/components/layout/Table';
import filter from '../../../modules/shared/assets/svg/filter.svg';
import { transformData } from '../../super-admin/utils/transformData';
import { useQuery } from '@tanstack/react-query';
import { getPaymentHistoryForDeals, getAllBlocks } from '../services/api/implementation/impl';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Modal from '../../shared/components/ui/Modal';
import Button from '../../shared/components/ui/Button';
import DateFormat from '../../super-admin/utils/DateFormat';
import toast from 'react-hot-toast';
import { UpdateStatus } from '../components/forms/UpdateStatus';
import { filterData } from '../../shared/utils/filterData';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';

const SalesPayment = () => {
  const { id: projectId } = useParams();
  const { register } = useForm();
  const navigate = useNavigate();

  // State Variables
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [newBlockId, setNewBlockId] = useState(null);
  const [blocksDynaWithLevels, setBlocksDynaWithLevels] = useState([]);
  const [isPaid, setIsPaid] = useState(false);
  const [StatusId, setStatusId] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [salesPaymentSearch, setsalesPaymentSearch] = useState([]);

  // Fetch blocks data
  // const { data: blocksDyna, refetch: refetchBlocks } = useQuery({
  //     queryKey: ['blocks', projectId],
  //     queryFn: () => getAllBlocks(projectId),
  //     enabled: !!projectId
  // });

  // pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  const {
    data: paymentHistory,
    refetch: refetchPaymentHistory,
    isFetching,
    isLoading,
    error
  } = useQuery({
    queryKey: ['paymentHistoryInvoive', page],
    queryFn: () => getPaymentHistoryForDeals(page, limit),
    keepPreviousData: true
  });

  const totalPages = paymentHistory?.meta ? Math.ceil(paymentHistory?.meta.total / limit) : 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    // refetchBlocks(projectId);
    refetchPaymentHistory();
  }, [projectId]);

  const UpdateStatusOptions = [
    { id: 1, name: 'Registry No due' },
    { id: 2, name: 'Registry With due' },
    { id: 3, name: 'No dues' }
  ];

  const ConsolidatedDataFormat = [
    'id',
    'customer_name',
    'customer_id',
    'payment_stage',
    'invoice_id',
    'deal_id',
    'amount_paid',
    'created_at'
  ];

  const handleStatusUpdateFn = () => {
    // Add your status update functionality here
  };

  const handleStatusUpdate = (id) => {
    setOpenUpdateStatus(true);
  };

  const handleSelectBlockChange = (event) => {
    const selectedBlockId = event.target.value;
    setNewBlockId(selectedBlockId);
  };

  useEffect(() => {
    setsalesPaymentSearch(paymentHistory?.data);
  }, [paymentHistory?.data]);
  const handleSearchChange = useCallback(
    (incomingQuery) => {
      if (incomingQuery) {
        const FilterSalesPaymentData = filterData(
          'customer_name',
          paymentHistory?.data,
          incomingQuery
        );
        setsalesPaymentSearch(FilterSalesPaymentData);
      } else {
        setsalesPaymentSearch(paymentHistory?.data);
      }
    },
    [paymentHistory?.data]
  );
  // Transform data to fit table format
  const ConsolidatedData =
    salesPaymentSearch?.map((item) => transformData(item, ConsolidatedDataFormat)) || [];

  // useEffect(() => {
  //     if (blocksDyna?.blocks) {
  //         const blocksWithLevels = blocksDyna.blocks.filter((block) => block?.levels?.length > 0);
  //         const sorted = blocksWithLevels.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  //         if (sorted.length > 0) {
  //             setBlocksDynaWithLevels(sorted);
  //             setNewBlockId(sorted[0].id);
  //         }
  //     }
  // }, [blocksDyna]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <>
      {openUpdateStatus && (
        <Modal isDirty={true} confirmOnClose={true} width={40} name="Update Status" onClose={() => setOpenUpdateStatus(false)}>
          <UpdateStatus
            onClose={() => setOpenUpdateStatus(false)}
            onSubmit={handleStatusUpdateFn}
            statuses={UpdateStatusOptions}
          />
        </Modal>
      )}

      <div className="w-full flex flex-col absolute z-5 bg-gray-100 pb-10 overflow-hidden hide-scrollbar">
        {
          paymentHistory?.data && paymentHistory?.data?.length && (
            <div className="flex z-40 items-center justify-between px-6 py-4 bg-white fixed top-[9rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
            <Search query={searchQuery} onChange={handleSearchChange} />
            <div className="flex gap-5 cursor-pointer">{/* Additional buttons or actions */}</div>
          </div>

          )
        }
        {paymentHistory?.data && paymentHistory?.data?.length > 0 ? (
          <>
           
            <div className="mt-[15rem]  left-0 w-full h-full overflow-clip bg-gray-100">
              <GridWrapper width={100}>
                <div className=" h-[30rem] overflow-scroll hide-scrollbar relative">
                  <Table
                    headers={[
                      'Customer Name',
                      'Customer Id',
                      'Payment Stage',
                      'Invoice Id',
                      'Deal Id',
                      'Amount Paid',
                      'Date'
                    ]}
                    data={ConsolidatedData}
                    identifier={'id'}
                    isEditEnable={false}
                    isStatusUpdateEnable={true}
                    onStatusUpdate={(id) => handleStatusUpdate(id)}
                  />

                  <PaginationComponent
                    page={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    isFetching={isFetching}
                  />
                </div>
              </GridWrapper>
            </div>
          </>
        ) : (
          <div className="pt-[3rem]">
            <NoDataFound disabledBtn screenHeight={true} />
          </div>
        )}
      </div>
    </>
  );
};

export default SalesPayment;
