import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Table from '../../shared/components/layout/Table';
import { useQuery } from '@tanstack/react-query';
import { getLandById } from '../services/api/implementation/impl';
import { transformData } from '../../super-admin/utils/transformData';
import { useParams } from 'react-router-dom';
import { BsFiletypePdf } from 'react-icons/bs';
import { AiOutlineFileImage } from 'react-icons/ai';
import { FaRegFile } from 'react-icons/fa';

/**
 * Parses area type strings like "sq/acres", "sq/hectares", "sq/gunta"
 * and normalizes them to "acre", "hectare", or "gunta".
 */
const parseAreaType = (areaType) => {
  if (!areaType) return '';
  let t = areaType.toLowerCase().trim();

  // Remove sq/
  t = t.replace('sq/', '');

  // Normalize "acres" to "acre"
  if (t.includes('acres')) t = t.replace('acres', 'acre');
  // Normalize "hectares" to "hectare"
  if (t.includes('hectares')) t = t.replace('hectares', 'hectare');
  // Normalize "guntha" to "gunta"
  if (t.includes('guntha')) t = t.replace('guntha', 'gunta');

  return t;
};
const formatAreaType = (areaType) => {
  return areaType?.replace('sq/', '');
};
/**
 * Converts a value in either "hectare", "acre", or "gunta"
 * into square meters. Returns null if value is falsy or type is unrecognized.
 */
const convertToSqMt = (value, type) => {
  if (!value || !type) return null;
  const val = parseFloat(value);
  if (Number.isNaN(val)) return null;

  switch (type) {
    case 'hectare':
      // 1 hectare = 10,000 sq. meters
      return (val * 10000).toFixed(2);
    case 'acres':
      // 1 acre ≈ 4,046.85642 sq. meters
      return (val * 4046.85642).toFixed(2);
    case 'gunta':
      // 1 gunta ≈ 101.17 sq. meters (approx)
      return (val * 101.17).toFixed(2);
    default:
      return '-';
  }
};

/**
 * Converts a value in either "hectare", "acre", or "gunta"
 * into square feet. Returns null if value is falsy or type is unrecognized.
 */
const convertToSqFt = (value, type) => {
  if (!value || !type) return null;
  const val = parseFloat(value);
  if (Number.isNaN(val)) return null;

  switch (type) {
    case 'hectare':
      // 1 hectare ≈ 107,639.104 sq. feet
      return (val * 107639.104).toFixed(2);
    case 'acres':
      // 1 acre = 43,560 sq. feet
      return (val * 43560).toFixed(2);
    case 'gunta':
      // 1 gunta ≈ 1,089 sq. feet (approx)
      return (val * 1089).toFixed(2);
    default:
      return '-';
  }
};

/*
  Helper Functions for Documents
*/
const getFileIcon = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return <BsFiletypePdf size={80} />;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <AiOutlineFileImage size={90} />;
    default:
      return <FaRegFile size={80} />; // Default icon
  }
};

const truncateFileName = (fileName, maxLength = 15) => {
  if (!fileName) return 'Unnamed File';
  if (fileName.length <= maxLength) return fileName;
  const extension = fileName.split('.').pop();
  const nameWithoutExtension = fileName.slice(0, -(extension.length + 1));
  return `${nameWithoutExtension.slice(0, maxLength - 3)}...${extension}`;
};

/*
  Document Renderer Component
*/
const DocumentRenderer = ({ title, documents }) => {
  if (!documents || (Array.isArray(documents) && documents.length === 0)) return null;

  // Normalize documents to an array of { name, url }
  let normalizedDocs = [];

  if (Array.isArray(documents)) {
    normalizedDocs = documents.flatMap((doc) => {
      if (typeof doc === 'string') {
        // If doc is just a URL
        return [{ name: doc.split('/').pop(), url: doc }];
      } else if (doc?.name && doc?.url) {
        return [{ name: doc.name, url: doc.url }];
      } else if (doc?.url) {
        return [{ name: doc.url.split('/').pop(), url: doc.url }];
      }
      return [];
    });
  } else if (typeof documents === 'object' && documents !== null) {
    // Single object
    if (documents.name && documents.url) {
      normalizedDocs.push({ name: documents.name, url: documents.url });
    } else if (documents.url) {
      normalizedDocs.push({ name: documents.url.split('/').pop(), url: documents.url });
    }
  }

  if (normalizedDocs.length === 0) return null;

  return (
    <div className="flex flex-col gap-10 border rounded-md border-gray-200 p-3">
      <h3 className="border w-full text-center font-semibold p-2 rounded-lg bg-slate-600 text-lg text-white">
        {title}
      </h3>
      <div className="flex flex-wrap gap-3">
        {normalizedDocs.map((file, i) => (
          <div
            key={i}
            className="flex flex-col border w-[12rem] p-5 rounded-xl justify-center items-center">
            <a href={file.url} target="_blank" rel="noreferrer" download>
              {getFileIcon(file.name)}
            </a>
            <p className="text-center">{truncateFileName(file.name)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const LandDetails = () => {
  const { id } = useParams();

  const { data, refetch: refetchLand } = useQuery({
    queryKey: ['land', id],
    queryFn: () => getLandById(id)
  });

  /*
    Effects
  */
  useEffect(() => {
    refetchLand();
  }, [refetchLand]);

  /*
    State
  */
  const [selectedFilter, setSelectedFilter] = useState('Land Details');
  const handleButtonClick = (filter) => {
    setSelectedFilter(filter);
  };

  /*
    For the table:
    We create 7 columns to display:
      1) Khasra Number (or Survey Number)
      2) Main area (whatever it is, called "Khasra Area")
      3) Guntha (the sub-area)
      4) Main area in SqMt
      5) Main area in SqFt
      6) Guntha in SqMt
      7) Guntha in SqFt
  */
  const khasraTableMetaData = [
    'id',
    'number',
    'area',
    'guntha',
    'area_sqmt', // main area → sq. mt
    'area_sqft', // main area → sq. ft
    'guntha_sqmt', // sub area → sq. mt
    'guntha_sqft' // sub area → sq. ft
  ];

  /**
   * We transform the data for the table, detecting the type of the mainArea
   * (acre/hectare/gunta) from `item.mainAreaType` and the subArea from `item.subAreaType`.
   */
  console.log(data?.ownerLandMappings?.[0]?.owner, 'testing data');
  console.log('data.total_mainAreaType', data?.total_mainAreaType);
  const khasraTransformedData = data?.ownerLandMappings?.[0]?.owner?.khasra?.map((item) => {
    // parse types (e.g., "sq/acres" -> "acre")
    const formatedmainLandType = formatAreaType(data?.total_mainAreaType);
    console.log({ formatedmainLandType }, 'y');
    const mainAreaType = parseAreaType(formatedmainLandType);
    const subAreaType = parseAreaType(item.subAreaType);
    console.log({ mainAreaType, subAreaType }, 'x');

    // Convert mainArea to sq. mt and sq. ft
    const area_sqmt = convertToSqMt(item.mainArea, formatedmainLandType);
    const area_sqft = convertToSqFt(item.mainArea, formatedmainLandType);

    // Convert subArea (guntha) to sq. mt and sq. ft
    const guntha_sqmt = convertToSqMt(item.subArea, subAreaType);
    const guntha_sqft = convertToSqFt(item.subArea, subAreaType);

    return transformData(
      {
        ...item,
        area: item.mainArea || 0, // main area in user units
        guntha: item.subArea || 0,
        area_sqmt,
        area_sqft,
        guntha_sqmt,
        guntha_sqft
      },
      khasraTableMetaData
    );
  });

  console.log({ khasraTransformedData });

  /*
    The second table: Owner Details
  */
  const ownerTableMetaData = ['name', 'email', 'phone_number', 'aadhar'];

  const ownerTransformedData = data?.ownerLandMappings?.map(({ owner }) =>
    transformData(
      {
        name: owner.name,
        email: owner.email,
        phone_number: owner.phone_number,
        aadhar: owner.aadhar
      },
      ownerTableMetaData
    )
  );

  /*
    Document sections to display
  */
  const landDocuments = [
    { title: 'Registry Documents', documents: data?.registryDocs || data?.registry_doc_link },
    { title: 'Advocate Searches', documents: data?.advocateSearches || data?.advocate_search_link },
    {
      title: 'Diversion Orders',
      documents: data?.diversionOrders || data?.diversion_order_link || data?.diversionOrderFiles
    },
    {
      title: 'Additional Documents',
      documents: data?.additionalDocuments || data?.additional_documents_link
    },
    {
      title: 'Khasra Map Links',
      documents: data?.ownerLandMappings?.[0]?.owner?.khasra?.flatMap((k) => k.khasra_map_link)
    },
    {
      title: 'Khasra P1B2 Links',
      documents: data?.ownerLandMappings?.[0]?.owner?.khasra?.flatMap((k) => k.khasra_p1b2_link)
    },
    {
      title: 'Land Use Maps',
      documents: data?.ownerLandMappings?.[0]?.owner?.khasra?.flatMap((k) => k.land_use_map)
    },
    {
      title: 'Google Location Images',
      documents: data?.google_map_image_link
    },
    {
      title: 'Google Maps',
      documents: data?.google_map_link ? [{ name: 'Google Map', url: data.google_map_link }] : null
    }
  ];

  return (
    <Layout>
      {/* Header */}
      <div className="fixed top-0 left-[18%] w-[82%] z-10">
        <Header data={{ name: `Lands > ${data?.name || 'Loading...'}` }} backArrow />
      </div>

      {/* Filter Buttons */}
      <div className="flex z-5 items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82%] shadow-sm">
        <div className="flex w-full justify-between items-center gap-5 cursor-pointer">
          <span className="font-semibold">{selectedFilter || 'Land Details'}</span>
          <div className="button-group flex rounded-lg">
            <div
              className={`border border-x-1 px-2 py-1 font-helvetica cursor-pointer button ${
                selectedFilter === 'Land Details' ? `bg-customBlue text-white` : 'text-textC'
              } rounded-tl rounded-bl`}
              onClick={() => handleButtonClick('Land Details')}>
              Land Details
            </div>
            <div
              className={`border border-x-1 px-2 py-1 font-helvetica cursor-pointer button ${
                selectedFilter === 'Documents' ? `bg-customBlue text-white` : 'text-textC'
              }`}
              onClick={() => handleButtonClick('Documents')}>
              Documents
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      {selectedFilter === 'Land Details' ? (
        <div className="w-full flex flex-col absolute top-[10rem] z-5 pl-5 pr-5 bg-gray-100 pb-10 overflow-auto">
          {/* Khasra Details */}
          <div>
            <h3 className="m-3 text-lg font-semibold">Khasra Details</h3>
            <div className="h-[20rem] overflow-auto hide-scrollbar">
              <Table
                headers={[
                  `${data?.state === 'Karnataka' ? 'Survey' : 'Khasra'} Number `,
                  `${data?.state === 'Karnataka' ? 'Survey' : 'Khasra'} Area (${formatAreaType(
                    data?.total_mainAreaType
                  )})`,
                  'Guntha',
                  'Area in Sq Mt', // main area in sq mt
                  'Area in Sq Ft', // main area in sq ft
                  'Guntha in Sq Mt', // sub area in sq mt
                  'Guntha in Sq Ft' // sub area in sq ft
                ]}
                identifier={'id'}
                data={khasraTransformedData}
              />
            </div>
          </div>

          {/* Owner Details */}
          <div className="mt-10">
            <h3 className="m-3 text-lg font-semibold">Owners</h3>
            <div className="h-[22rem] overflow-auto">
              <Table
                headers={['Name', 'Email', 'Number', 'Aadhar Number']}
                identifier={'id'}
                data={ownerTransformedData}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col absolute top-[10rem] z-5 pl-5 pr-5 bg-gray-100 pb-10 overflow-auto">
          {/* Documents Section */}
          <div className="flex flex-wrap gap-10">
            {landDocuments.map((docSection, index) => (
              <DocumentRenderer
                key={index}
                title={docSection.title}
                documents={docSection.documents}
              />
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default LandDetails;
