import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Search from '../../shared/components/ui/Search';
import Button from '../../shared/components/ui/Button';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import LiveFeedCard from './LiveFeedCard';
import { CreatePost } from '../components/forms/CreatePost';
import Modal from '../../shared/components/ui/Modal';
import { getPosts } from '../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';
import socket from '../../../socket';

const LiveFeedList = () => {
  const [isCreatePost, setisCreatePost] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // const dummyLiveFeedCard = [
  //   {
  //     id: 1,
  //     project_id: 2,
  //     created_by: 3,
  //     created_at: "2024-09-25T06:25:37.712Z",
  //     updated_at: "2024-09-25T06:25:37.712Z",
  //     creater_name: "Builder1",
  //     title: "Luxury Apartment Complex",
  //     post: "Introducing our latest luxury apartment complex located in the heart of the city. These high-end apartments offer state-of-the-art amenities including a rooftop pool, fitness center, and private parking. Each unit is designed with modern aesthetics and spacious layouts to provide a comfortable and stylish living experience.",
  //     is_public: true,
  //     attachments: [],
  //     comments: [null]
  //   },
  //   {
  //     id: 2,
  //     project_id: 1,
  //     created_by: 4,
  //     created_at: "2024-09-20T06:25:37.712Z",
  //     updated_at: "2024-09-20T06:25:37.712Z",
  //     creater_name: "Builder2",
  //     title: "Greenfield Residential Area",
  //     post: "Our Greenfield Residential Area is an eco-friendly housing project that emphasizes sustainable living. The community features energy-efficient homes, extensive green spaces, and a community garden. Designed for families and individuals seeking a peaceful and environmentally conscious lifestyle.",
  //     is_public: false,
  //     attachments: [],
  //     comments: [null]
  //   },
  //   {
  //     id: 3,
  //     project_id: 3,
  //     created_by: 5,
  //     created_at: "2024-09-22T06:25:37.712Z",
  //     updated_at: "2024-09-22T06:25:37.712Z",
  //     creater_name: "Builder3",
  //     title: "Downtown Office Spaces",
  //     post: "We are proud to present our new Downtown Office Spaces project, offering modern and flexible office solutions in the city's business district. These offices are equipped with the latest technology, high-speed internet, and ergonomic furniture to support a productive work environment.",
  //     is_public: true,
  //     attachments: [],
  //     comments: [null]
  //   },
  //   {
  //     id: 4,
  //     project_id: 4,
  //     created_by: 6,
  //     created_at: "2024-09-24T06:25:37.712Z",
  //     updated_at: "2024-09-24T06:25:37.712Z",
  //     creater_name: "Builder4",
  //     title: "Seaside Vacation Homes",
  //     post: "Discover our exclusive Seaside Vacation Homes, perfect for those looking to own a piece of paradise. These homes offer stunning ocean views, private beach access, and luxurious interiors. Ideal for vacation rentals or a serene personal retreat, each home is crafted to the highest standards of quality and comfort.",
  //     is_public: false,
  //     attachments: [],
  //     comments: [null]
  //   }
  // ];

  const { data: posts, refetch: refetchPosts } = useQuery({
    queryKey: ['posts'],
    queryFn: getPosts
  });

  socket.on('Post', () => {
    refetchPosts();
  });

  useEffect(() => {
    if (posts) {
      console.log(posts, 'posts');
      refetchPosts();
    }
  }, [posts]);

  const handleOnCreatePost = () => {
    setisCreatePost(!isCreatePost);
  };

  const onPostCreate = () => {
    setisCreatePost(false);
    refetchPosts();
  };
  useEffect(() => {
    if (isCreatePost) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isCreatePost]);

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = '';
  //   }

  //   return () => {
  //     document.body.style.overflow = '';
  //   };
  // }, [isOpen]);

  return (
    <>
      {/* Create Post Modal */}
      {isCreatePost && (
        <Modal isDirty={true} confirmOnClose={true} name={'Create New Post'} width={80} onClose={() => setisCreatePost(!isCreatePost)}>
          <CreatePost onSuccess={onPostCreate} />
        </Modal>
      )}

      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18%] z-50 w-[82%]">
          <Header data={{ name: `Live Feed` }} />
        </div>
        {/* Main content */}
        <div className="flex items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm z-10">
          <Search />
          <div className="flex gap-5 cursor-pointer">
            <Button children={'New Post'} onClick={handleOnCreatePost} />
          </div>
        </div>
        <GridWrapper>
          <div className="flex flex-col gap-8 mt-[11rem]">
            {/* Check if posts exist */}
            {posts && posts?.length > 0 ? (
              posts?.map((post) => (
                <div key={post.id} onClick={() => setIsOpen(!isOpen)}>
                  <LiveFeedCard data={post} onSuccess={() => refetchPosts()} />
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No posts to show</p>
            )}
          </div>
        </GridWrapper>
      </Layout>
    </>
  );
};

export default LiveFeedList;
