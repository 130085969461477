import React, { useEffect, useState } from 'react';
import Button from '../../shared/components/ui/Button';
import { useForm } from 'react-hook-form';
import Table from '../../shared/components/layout/Table';
import EditTable from '../../shared/components/layout/EditTable';
import { transformData } from '../../super-admin/utils/transformData';
import Modal from '../../shared/components/ui/Modal';
import { ImportBlock } from '../components/forms/ImportBlock';
import AddNewBlock from '../components/forms/AddNewBlock';
import EditBlock from '../components/forms/EditBlock';
import { EditBasement } from '../components/forms/EditBasement';
import { EditUnit } from '../components/forms/EditUnit';
import { EditGround } from '../components/forms/EditGround';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import UpdateLevel from '../components/forms/UpdateLevel';
import {
  createBLock,
  createLevel,
  cretaeUnit,
  editBlock,
  editLevel,
  editUnits,
  getAllAccommodationsByProject,
  getAllBlocks,
  getBlockByID,
  getLevelById
} from '../services/api/implementation/impl';
import toast from 'react-hot-toast';
import { EditFloor } from '../components/forms/EditFloor';
import { useParams } from 'react-router-dom';
import { EditLevel } from '../components/forms/EditLevel';
import UpdateUnit from '../components/forms/UpdateUnit';
import leftarrow from '../../shared/assets/svg/left_arrow.svg';

import BlocksStatic from '../../builder/static/data/BlockStatic.json';
import generateLevelPayload from '../utils/generateLevelPayload';
import backbtnIcon from '../../shared/assets/svg/backbtn.svg';

import { getAllAccommodations } from '../services/api/implementation/impl';

export const BlockDetails = () => {
  const queryClient = useQueryClient();

  const style = { width: `20%` };

  /* 
    Hooks
  */
  const { id: projectId } = useParams();

  /* 
    Hooks
  */
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm();

  /* 
    Local state
  */
  const [isOpenAddNewBlock, setIsOpenAddNewBlock] = useState(false);
  const [newBlockId, setNewBlockId] = useState(null);
  const [blockLevelStaticArray, setBlockLevelStaticArray] = useState(BlocksStatic);
  const [blocksArray, setBlocksArray] = useState([]);
  const [isEditBlockLevel, setIsEditBlockLevel] = useState(false);

  // For Level Unit Edit Modal
  const [levelsDyna, setLevelsDyna] = useState([]);
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
  const [isOpenEditLevelUnitModal, setisOpenEditLevelModal] = useState(false);

  //For Unit view
  const [activeBlock, setActiveBlock] = useState([]);
  const [viewUnits, setViewUnits] = useState(false);
  const [blocksDynaWithLevels, setBlocksDynaWithLevels] = useState([]);
  const [activeLevelType, setActiveLevelType] = useState('');
  const [HeaderByLevelName, setHeaderByLevelName] = useState('');
  const [isAllUnitDataSubmitted, setisAllUnitDataSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blockType, setBlockType] = useState('');
  const [type, setType] = useState();

  const [currentBlockName, setCurrentBlockName] = useState('');
  const [blockLevelNumber, setBlockLevelNumber] = useState(0);
  const [isSaved, setIsSaved] = useState(false);

  const [prevLevelData, setPrevLevelData] = useState([]);
  /*
    Data fetching
  */
  const { data: blocksDyna, refetch: refetchBlocks } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId
  });

  const { data: blockDyna, refetch: refetchBlock } = useQuery({
    queryKey: ['block', newBlockId],
    queryFn: () => getBlockByID(newBlockId),
    enabled: !!newBlockId
  });
  console.log('blockDyna', blockDyna);

  const { data: accommodations, refetch: refetchAccommodations } = useQuery({
    queryKey: ['accommodations', projectId],
    queryFn: () => getAllAccommodationsByProject(projectId)
  });



  // const blocksDynaWithLevels = blocksDyna?.blocks?.filter((block) => block?.levels?.length > 0);
  /* 
    Effects
  */
  useEffect(() => {
    if (newBlockId) {
      refetchBlock(newBlockId);
      setCurrentBlockName(blockDyna?.name);
    }
  }, [newBlockId, refetchBlock, currentLevelIndex, currentBlockName]);

  //setting block type

  useEffect(() => {
    if (!blockType && blockDyna?.type) {
      setBlockType(blockDyna.type);
      setBlockLevelNumber(blockDyna?.numberOfLevels);
      setCurrentBlockName(blockDyna?.name);
    }
  }, [blockDyna, blockType, blockLevelNumber, setCurrentBlockName]);

  useEffect(() => {
    setType(blockDyna?.type);
  }, [blockDyna]);

  // This useEffect runs only after blockType is set
  useEffect(() => {
    if (blockLevelNumber && blockType && blockDyna) {
      const newBlocksArray = Array.from({ length: blockLevelNumber }, (_, index) => { 
        const hasAccommodationUnitTypeResdential = accommodations.some(
          (acc) => acc.unitType.toLowerCase() === "residential"
        );
        console.log('hasAccommodationUnitTypeResdential', hasAccommodationUnitTypeResdential);

        const hasAccommodationUnitTypeCommercial = accommodations.some(
          (acc) => acc.unitType.toLowerCase() === "commercial"
        );
        console.log('hasAccommodationUnitTypeCommercial', hasAccommodationUnitTypeCommercial);
        console.log('accommodations',  { accommodations });
  
  
        const accommodationStr = blockLevelStaticArray.accommodation_type;
        const dropdownPattern = /dropdown-(.*)/;
        const matches = accommodationStr.match(dropdownPattern);
        let dropdownArrayStr = matches && matches[1] ? matches[1] : "[]";
  
        // Convert the extracted string into a JS array
        let accommodationOptions;
        try {
          accommodationOptions = JSON.parse(dropdownArrayStr);
        } catch (error) {
          // If there's a parse error, fallback to a default array
          accommodationOptions = [
            { value: 'RESIDENTIAL', label: 'RESIDENTIAL' },
            { value: 'COMMERCIAL', label: 'COMMERCIAL' },
            { value: 'PARKING', label: 'PARKING' },
            { value: 'SERVICES', label: 'SERVICES' }
          ];
        }
  
        // Conditionally remove RESIDENTIAL if not present in accommodations
        if (!hasAccommodationUnitTypeResdential) {
          accommodationOptions = accommodationOptions.filter(opt => opt.value !== 'RESIDENTIAL');
        }
  
        // Conditionally remove COMMERCIAL if not present in accommodations
        if (!hasAccommodationUnitTypeCommercial) {
          accommodationOptions = accommodationOptions.filter(opt => opt.value !== 'COMMERCIAL');
        }
  
        // Recreate the accommodation_type string
        const newAccommodationType = `dropdown-${JSON.stringify(accommodationOptions)}`;
  
        const newBlock = {
          ...blockLevelStaticArray,
          level_name: blockType === 'Independent' ? `Row` : `Level ${index}`,
          accommodation_type: newAccommodationType,
          num_units: ''
        };
  
        setValue(`Level Name-${index}`, newBlock.level_name);
        return newBlock;
      });
  
      setBlocksArray(newBlocksArray);
    }
  }, [blockLevelNumber, blockType, blockDyna, blockLevelStaticArray, setValue]);
  console.log(blocksArray, 'blocksArray');
  

  useEffect(() => {
    if (blocksDyna) {
      const blocksWithLevels =
        blocksDyna && blocksDyna.length > 0
          ? blocksDyna.filter((block) => block?.levels?.length > 0)
          : [];

      const sorted =
        blocksWithLevels && blocksWithLevels.length > 0
          ? blocksWithLevels.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          : [];

      if (sorted.length > 0) {
        setBlocksDynaWithLevels(sorted);
        setNewBlockId(sorted[0].id);
      }
    }
  }, [blocksDyna]);

  /*
    Data mutation
  */
  const { mutate: handleAddBlock } = useMutation({
    mutationFn: (payload) => createBLock(payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (data) => {
      setIsSubmitting(false);
      type;
      setNewBlockId(data?.id);
      setBlockType(data?.type);
      setBlockLevelNumber(data?.numberOfLevels);
      setIsEditBlockLevel(true);
      setIsOpenAddNewBlock(false);
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new block', err);
      toast.error('Error creating new block', err);
    }
  });
  const handlePreviewCreatedBlock = async (data) => {
    console.warn('handlePreviewCreatedBlockdata', data);

    // Filter the data to include only the relevant levels
    const filteredData = {};
    for (let i = 0; i < blockLevelNumber; i++) {
      filteredData[`Level Name-${i}`] = data[`Level Name-${i}`];
      filteredData[`No of Units-${i}`] = data[`No of Units-${i}`];
      filteredData[`Floor Type-${i}`] = data[`Floor Type-${i}`];
    }

    console.log('Filtered Data:', filteredData);

    const payload = generateLevelPayload({
      ...filteredData,
      block_id: blockDyna?.id,
      project_id: projectId
    });

    try {
      const res = await createLevel(payload);
      setisOpenEditLevelModal(true);
      setLevelsDyna(res);
    } catch (error) {
      console.error('Error creating level:', error);
      toast.error('Failed to create level. Please try again.');
    }
  };

  const handleEditLevel = (data) => {
    console.warn('Data: testing', data);
    setPrevLevelData((prev) => [...prev, data]);
    // Extracting unit names and accommodation names
    let units = Object.keys(data)
      .filter((key) => key.startsWith('Unit Name-'))
      ?.map((key, index) => {
        const unitName = data[key];
        const accommodationKey = `Accommodation Name-${index}`;
        const accommodationName = data[accommodationKey] || '';

        console.warn(accommodationName, 'accommodationName');

        return {
          unit_name: unitName,
          accomodation_id: parseInt(accommodationName)
        };
      });

    // Validate units
    const allFieldsFilled = units.every((unit) => unit.unit_name && unit.accomodation_id);
    if (!allFieldsFilled) {
      toast.error('Please fill n all the fields');
      return;
    }

    let payload = {
      project_id: projectId,
      block_id: blockDyna?.id,
      level_id: levelsDyna[currentLevelIndex].id, // Use the current level's ID
      units
    };

    if (currentLevelIndex < blockLevelNumber - 1) {
      cretaeUnit(payload);
      setCurrentLevelIndex(currentLevelIndex + 1);
      units = [];
      setisOpenEditLevelModal(true); // Reopen the modal with the next level
    } else {
      cretaeUnit(payload);
      setisOpenEditLevelModal(false); // Close the modal if it's the last level
      setisAllUnitDataSubmitted(true);
      setIsEditBlockLevel(false);
      units = [];
    }
  };

  const handleSaveAddBlock = async () => {
    setIsSaved(true);
    await refetchBlocks(projectId); // Refresh the block data from API
    await refetchBlock(newBlockId); // Refresh the specific block data

    // Resetting state variables
    setIsEditBlockLevel(false);
    setisAllUnitDataSubmitted(false);
    setLevelsDyna([]); // Reset levels data
    setBlocksArray([]); // Reset blocks data
    setBlockLevelNumber(0); // Reset 0the level number
    setBlockType(''); // Reset block type
    setCurrentBlockName(''); // Reset block name
    setNewBlockId(null); // Clear new block ID
    setIsSaved(false);
    setCurrentLevelIndex(0); // Reset the current level index

    // If you want to reset form fields as well
    reset(); // useForm's reset to clear all fields

    toast.success('Block saved successfully');
    window.location.reload();
    await refetchBlocks(projectId);
  };
  blockType;

  const blockDataByIdFormat = ['id', 'name', 'floorType', 'noOfUnits', 'actions'];

  const BlockDataId =
    blockDyna?.levels?.map((item) => transformData(item, blockDataByIdFormat)) || [];

    useEffect(() => {
      if (viewUnits && blockDyna?.levels && activeBlock?.[0]?.id) {
        // Use the ID of the currently active level to update the active block state
        const updatedActiveBlock = blockDyna.levels.filter(
          (item) => parseInt(item.id) === parseInt(activeBlock[0].id)
        );
        setActiveBlock(updatedActiveBlock);
      }
    }, [blockDyna]);
    

  const handleViewForType = (id) => {
    const filterType = blockDyna?.levels?.filter((item) => parseInt(item.id) === parseInt(id));
    setActiveBlock(filterType);
    setActiveLevelType(filterType[0]?.floorType);
    setHeaderByLevelName(filterType[0]?.name);
    setViewUnits(true);
  };

  const handleSelectBlockChange = (event) => {
    setNewBlockId(event.target.value);
  };

  const goBackToBlocks = () => {
    setViewUnits(false);
    setActiveBlock([]);
    setActiveLevelType('');
    setHeaderByLevelName('');
  };

  // Unit heders

  const commercialHeader = [
    'Unit Name',
    'Acc. Type',
    'Build Up Area',
    'Carpet Area',
    'Loading Factor',
    'Super Build Up',
    'Actions'
  ];

  const residentialHeader = [
    'Unit Name',
    'Acc. Type',
    'Acc. Name',
    ...(type === 'Independent' ? ['Plot Area'] : []),
    'Build Up Area',
    'Carpet Area',
    'Actions'
  ];

  console.log('Sdsdss', type);

  const parkingHeader = ['Unit Name', 'Acc. Type', 'Acc. Name', 'Area', 'Electrified', 'Actions'];

  const commercialDataFields = [
    'unitName',
    'accommodationType',
    'buildUpArea',
    'carpetArea',
    'loadingFactor',
    'superBuiltUpArea',
    'actions',
    'id'
  ];
  const residentialDataFields = [
    'unitName',
    'accommodationType',
    'accommodationName',
    ...(type === 'Independent' ? ['plotArea'] : []),
    'buildUpArea',
    'carpetArea',
    'actions',
    'id'
  ];

  const parkingDataFields = [
    'unitName',
    'accommodationType',
    'accommodationName',
    'buildUpArea',
    'electrified',
    'actions',
    'id'
  ];

  const headers =
    activeLevelType === 'PARKING'
      ? parkingHeader
      : activeLevelType === 'COMMERCIAL'
        ? commercialHeader
        : residentialHeader;
  const dataFields =
    activeLevelType === 'PARKING'
      ? parkingDataFields
      : activeLevelType === 'COMMERCIAL'
        ? commercialDataFields
        : residentialDataFields;

  const dataFieldUnits = activeBlock?.[0]?.units?.map((item) =>
    transformData(
      {
        ...item,
        accommodationName: item?.accommodation?.name,
        accommodationType: item?.accommodation?.accomodationType,
        carpetArea: item?.accommodation?.carpetArea,
        plotArea: item?.accommodation?.plotArea,
        buildUpArea: item?.accommodation?.buildUpArea,
        superBuiltUpArea: item?.accommodation?.superBuiltUpArea,
        loadingFactor: item?.accommodation?.loadingFactor,
        electrified: item?.accommodation?.electrified 
      },
      dataFields
    )
  );

  const unitDetailsForEdit = activeBlock?.[0]?.units;
  // const dataFieldUnits = activeBlock?.[0]?.units;

  // Edit Codes
  const [isEditingBlock, setIsEditingBlock] = useState(false);
  const [isEditUnit, setIsEditUnit] = useState(false);
  const [UnitId, setUnitId] = useState(null);

  const { mutate: handleEditBlock } = useMutation({
    mutationFn: (payload) => editBlock(newBlockId, payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (data) => {
      setIsSubmitting(false);
      toast.success('Block Updated successfully');
      setIsEditingBlock(false);
      await refetchBlocks(projectId);
      await refetchBlock(newBlockId);
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error  updating block', err);
      toast.error('Error Updating new block', err);
    }
  });

  const { mutate: handleEditUnits } = useMutation({
    mutationFn: (payload) => editUnits(UnitId, payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (data) => {
      setIsSubmitting(false);
      toast.success('UnitId Updated successfully');
      setIsEditUnit(false);
      queryClient.invalidateQueries(['block', newBlockId]);      
      await refetchBlock(newBlockId);
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error updating unit', err);
      toast.error('Error updating unit', err);
    }
  });

  const handleEditUnitById = (id) => {
    setUnitId(id);
    setIsEditUnit(!isEditUnit);
  };
  const handleCloseEditUnitModal = async () => {
    setIsEditUnit(false);
    await refetchBlock();
  };
  const isTypingNow = (value, index) => {
    const updatedBlocksArray = [...blocksArray];

    if (index === 0 && value.startsWith('Level ')) {
      // Extract the number from the first level name
      const levelNumber = parseInt(value.replace('Level ', ''), 10);

      if (!isNaN(levelNumber)) {
        // Update the first row
        updatedBlocksArray[0].level_name = value;

        // Propagate the change to subsequent rows
        for (let i = 1; i < updatedBlocksArray.length; i++) {
          updatedBlocksArray[i].level_name = `Level ${levelNumber + i}`;
        }

        // Update the form values dynamically using setValue for each field
        updatedBlocksArray.forEach((block, idx) => {
          setValue(`Level Name-${idx}`, block.level_name); // Update form state for each level name
        });

        // Update the state with the new array
        setBlocksArray(updatedBlocksArray);
      } else {
        toast.error('Please enter a valid number after "Level"');
      }
    } else if (value.startsWith('Level ')) {
      // Handle updates to other rows that start with "Level"
      const levelNumber = parseInt(value.replace('Level ', ''), 10);

      if (!isNaN(levelNumber)) {
        // Update the specific row
        updatedBlocksArray[index].level_name = value;
        setBlocksArray(updatedBlocksArray);
        setValue(`Level Name-${index}`, value); // Update form state for the specific level name
      } else {
        toast.error('Please enter a valid number after "Level"');
      }
    } else if (!value.startsWith('Level')) {
      // Handle updates to No of Units, Floor Type, or other fields
      updatedBlocksArray[index].num_units = value;
      setBlocksArray(updatedBlocksArray);
      setValue(`No of Units-${index}`, value);
    } else {
      // If none of the conditions match, show an error to prevent incorrect input
      toast.error('You can only edit the number after "Level"');
    }
  };

  // console.log("isTypingNow", isTypingNow);
  const [isUpdateUnitModalOpen, setIsUpdateUnitModalOpen] = useState(false);
  const [isUpdateLevelModalOpen, setIsUpdateLevelModalOpen] = useState(false);

  const addUnitHandler = () => {
    console.log('addUnitHandler');
    setIsUpdateUnitModalOpen(true);
  }
  const addLevelHandler = () => {
    console.log('addLevelHandler');
    setIsUpdateLevelModalOpen(true);
  }

  const handleUpdateUnit = async (payload) => {
    console.log('handleUpdateUnit', payload);
    await cretaeUnit(payload);
    await refetchBlock(newBlockId);
    // queryClient.invalidateQueries(['block', newBlockId]);    
    setIsUpdateUnitModalOpen(false);
    queryClient.invalidateQueries(['block', newBlockId]);

  };

  const handleUpdateLevel = async (payload) => {
    console.log('handleUpdateLevel', payload);
    await createLevel(payload);
    await refetchBlock(newBlockId);
    setIsUpdateLevelModalOpen(false);
    queryClient.invalidateQueries(['block', newBlockId]);
  }

  console.log(blocksArray, 'setBlocksArray[0]?.floorType');


  return (
    <>
    {
      isUpdateLevelModalOpen && (
        <Modal isDirty={true} confirmOnClose={true} name={'Update Levels'} width={55} onClose={() => setIsUpdateLevelModalOpen(false)}>
          <UpdateLevel
            blockId={newBlockId}
            levelId={activeBlock[0]?.id}
            floorTypeOptions={blocksArray[0]?.accommodation_type}
            onClose={() => setIsUpdateLevelModalOpen(false)}
            handleUpdateLevel={handleUpdateLevel}
          />
        </Modal>
      )
    }
    {isUpdateUnitModalOpen && (
      <Modal isDirty={true} confirmOnClose={true} name={'Update Units'} width={55} onClose={() => setIsUpdateUnitModalOpen(false)}>
        <UpdateUnit
          blockId={newBlockId}
          levelId={activeBlock[0]?.id}
          activeLevelType={activeLevelType}
          onClose={() => setIsUpdateUnitModalOpen(false)}
          handleUpdateUnit={handleUpdateUnit}
        />
      </Modal>        
    )}
      {/* EDITING CODES  */}
      {isEditingBlock && (
        <Modal isDirty={true} confirmOnClose={true} width={68} name={'Edit block'} onClose={() => setIsEditingBlock(!isEditingBlock)}>
          <EditBlock
            data={blockDyna}
            blockId={newBlockId}
            isSubmitting={isSubmitting}
            setIsEditingBlock={setIsEditingBlock}
            onProceed={handleEditBlock}
            onClose={() => setIsEditingBlock(!isEditingBlock)}
          />
        </Modal>
      )}

      {isEditUnit && (
        <Modal isDirty={true} confirmOnClose={true} name={'Edit Unit'} width={60} onClose={handleCloseEditUnitModal}>
          <EditUnit
            handleEditUnit={handleEditUnits}
            unitId={UnitId}
            isSubmitting={isSubmitting}
            activeLevelType={activeLevelType}
            data={unitDetailsForEdit}
            onClose={handleCloseEditUnitModal}
          />
        </Modal>
      )}
      {isOpenAddNewBlock && (
        <Modal isDirty={true} confirmOnClose={true}
          width={68}
          name={'Add New Block'}
          onClose={() => setIsOpenAddNewBlock(!isOpenAddNewBlock)}>
          <AddNewBlock
            setIsOpenAddNewBlock={setIsOpenAddNewBlock}
            onProceed={handleAddBlock}
            isSubmitting={isSubmitting}
            projectId={projectId}
            onClose={() => setIsOpenAddNewBlock(false)}
          />
        </Modal>
      )}

      {isOpenEditLevelUnitModal && (
        <Modal isDirty={true} confirmOnClose={true}
          name={'Unit Detailing'}
          width={55}
          onClose={() => setisOpenEditLevelModal(false)}
          hideClose={true}>
          <EditLevel
            handleEditLevel={handleEditLevel}
            data={levelsDyna}
            currentLevelIndex={currentLevelIndex}
            noOflevel={blockLevelNumber}
            setCurrentLevelIndex={setCurrentLevelIndex}
            allAccommodations={accommodations}
            onClose={() => setisOpenEditLevelModal(false)}
            blockType={blockType}
            blockName={currentBlockName}
            blockSaved={isSaved}
            prevLevelData={prevLevelData}
          />
        </Modal>
      )}

      <div className="w-full flex flex-col  absolute top-[16rem] z-5 pl-5 pr-5 bg-gray-100 pb-10 overflow-hidden ">
        <div className="flex  bg-white p-3 items-center justify-between rounded-md">
          <h3 className="text-xl font-semibold cursor-pointer flex gap-2">
            {viewUnits && <img src={backbtnIcon} onClick={goBackToBlocks} />}
            {/* {viewUnits ? HeaderByLevelName : `Block ${currentBlockName ? "-" + " " + currentBlockName : ''}`} */}
            {viewUnits ? HeaderByLevelName : `Block`}
          </h3>
          <div className="flex gap-3">
            {blocksDyna &&
              blocksDyna?.length > 0 &&
              !viewUnits &&
              !isEditBlockLevel &&
              !isAllUnitDataSubmitted && (
                <Button displayType="secondary" onClick={() => setIsEditingBlock(true)}>
                  Edit
                </Button>
              )}
            {!viewUnits && !isEditBlockLevel && !isAllUnitDataSubmitted && (
              <Button onClick={() => setIsOpenAddNewBlock(true)}>Create New</Button>
            )}
            {isEditBlockLevel && (
              <Button onClick={handleSubmit(handlePreviewCreatedBlock)} type="submit">
                Proceed
              </Button>
            )}
            {isAllUnitDataSubmitted && <Button onClick={handleSaveAddBlock}>Save</Button>}
            {viewUnits && <Button onClick={addUnitHandler}>Add Unit</Button>}
          </div>
        </div>

        {!viewUnits &&
          !isEditBlockLevel &&
          !isAllUnitDataSubmitted &&
          blocksDynaWithLevels &&
          blocksDynaWithLevels.length > 0 && (
            <div className='flex justify-between items-center w-full'>
            <div className={`flex-shrink-0 flex flex-col gap-2 text-gray-600 my-4`} style={style}>
              <div className="flex justify-between items-start space-x-2 w-fit">
                <span className="text-gray-600">{`Select Block`}</span>
              </div>
              {blocksDynaWithLevels && blocksDynaWithLevels.length > 0 && (
                <select
                  className={`w-full p-2 border-[1px] border-stone-300 rounded `}
                  {...register(`table_type`)}
                  value={newBlockId}
                  onChange={handleSelectBlockChange}>
                  {blocksDynaWithLevels
                    ?.map((item) => ({ label: item?.name, value: item?.id }))
                    ?.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>
              )}
            </div>
            <Button onClick={addLevelHandler}>Add Level</Button>

            </div>
          )}
        {blocksDyna &&
          blocksDyna?.length === 0 &&
          (isEditBlockLevel || viewUnits || isAllUnitDataSubmitted) && (
            <span className="block w-full text-center font-semibold text-gray-700">
              Start with creating a block first
            </span>
          )}

        <div className="h-[22rem] mt-5">
          {isEditBlockLevel && (
            // Usage in the EditTable component
            <EditTable
              headers={['Level Name', 'Floor Type', 'No of Units']}
              data={blocksArray}
              isEditEnable={false}
              onRegister={register}
              blockType={blockType}
              editTableType={'LevelEdit'}
              onType={(value, index) => isTypingNow(value, index)} // Pass the index here
              identifier={'id'}
            />
          )}
          {!viewUnits &&
            ((!isEditBlockLevel && blocksDyna && blocksDyna?.length > 0) ||
              isAllUnitDataSubmitted) && (
              <Table
                headers={['Level Name', 'Accommodation Type', 'No of Units', 'Action']}
                data={BlockDataId}
                isEditEnable={false}
                onView={(id) => handleViewForType(id)}
                isViewEnable={true}
                identifier={'id'}
              />
            )}

          {viewUnits && (
            <Table
              headers={isAllUnitDataSubmitted ? headers.filter((e) => e !== "Actions") : headers}
              data={dataFieldUnits}
              isEditEnable={isAllUnitDataSubmitted ? false : true}
              onEdit={(id) => handleEditUnitById(id)}
              identifier={'id'}
            />
          )}
        </div>
      </div>
    </>
  );
};
