import axiosInstance from './axiosInstance';
import {
  API_BASE_URL,
  PINCODE_API_BASE_URL,
  APPLICATION_API_BASE_URL,
  USERMANAGEMENT_API_BASE_URL,
  DOCGENERATION_API_BASE_URL,
  LIVEFEED_API_BASE_URL
} from '../configuration/config';
// import generateLandPayload from '../../../utils/generateLandPayload';
// import generateProjectPayload from '../../../utils/generateProjectPayload';
import generateBlockNames from '../../../utils/generateBlockNames';
import { getHeaders, getFileHeaders } from '../../../utils/getToken';
const loadash = require('lodash');

/* 
 GET
*/
export async function getAllCompanies() {
  try {
    const headers = await getHeaders();
    const response = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/company?limit=500`,
      headers
    );
    return response.data.data;
  } catch (error) {
    // console.error('Error fetching companies:', error.response?.status);
    throw new Error('Failed to load companies data');
  }
}

export async function getCompanyByID(companyId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/company/${companyId}?limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('company data not loaded');
  }
}

export async function getStateAndCity(pincode) {
  try {
    const data = await axiosInstance.get(`${PINCODE_API_BASE_URL}/${pincode}`);
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('getStateAndCity data not loaded');
  }
}

/* 
 POST
*/
export async function createCompany(newCompany) {
  newCompany = {
    ...newCompany,
    pan: newCompany?.pan?.toUpperCase(),
    gst: newCompany?.gst?.toUpperCase()
  };

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/company`,
      newCompany,
      headers
    );
    return data.data.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      err.message = err.response.data.message;
    } else {
      err.message = 'New company not created';
    }

    throw err; // Re-throw the full error object for further handling
  }
}

/* 
  PUT
*/
export async function updateCompany(updatedCompany, companyId) {
  console.log({ updatedCompany });
  Object.keys(updatedCompany).forEach((key) => {
    if (updatedCompany[key] === '') {
      delete updatedCompany[key];
    }
  });

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/company/${companyId}`,
      {
        ...updatedCompany
      },
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating company: ${err}`);
    throw new Error('Company update failed');
  }
}

// Add Acccommodation's Add_Independent function
export async function addIndependentStatic(independent, setIndependent, postData) {
  try {
    setIndependent([...independent, postData]);
    return postData;
  } catch (err) {
    console.error(`Error adding independent: ${err}`);
    throw new Error('Error adding new independent');
  }
}

// Add Acccommodation's Add_Group function
export async function addGroupStatic(group, setGroup, postData) {
  try {
    setGroup([...group, postData]);
    return postData;
  } catch (err) {
    console.error(`Error adding group: ${err}`);
    throw new Error('Error adding new group');
  }
}

// Add Acccommodation's Add_Parking function
export async function addParkingStatic(parking, setParking, postData) {
  try {
    setParking([...parking, postData]);
    return postData;
  } catch (err) {
    console.error(`Error adding parking: ${err}`);
    throw new Error('Error adding new parking');
  }
}

export async function addAccommodationStatic(id, postData) {
  try {
    let selectedData = postData.filter((item) => item.id === id);
    return selectedData[0];
  } catch (err) {
    console.error(`Error adding parking: ${err}`);
    throw new Error('Error adding new parking');
  }
}

// Add Project function
export async function addProjectStatic(project, setProject, postData) {
  try {
    setProject([...project, postData]);
    return postData;
  } catch (err) {
    console.error(`Error adding Project: ${err}`);
    throw new Error('Error adding new Project');
  }
}

export const addProject = async (newProject) => {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/project`,
      newProject,
      headers
    );
    return data.data.data;
  } catch (error) {
    console.error('Add Project Error', error);
  }
};

export function getIdProjectStatic(id, postData) {
  try {
    let selectedData = postData.filter((item) => item.id === id);
    return selectedData[0];
  } catch (err) {
    console.error(`Error adding getidProject: ${err}`);
    throw new Error('Error adding new getidProject');
  }
}

///////////////////////////////////////
/// Users API's
export async function getAllUsers(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${USERMANAGEMENT_API_BASE_URL}/users?page=${page}&limit=${limit}`,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Users data not loaded');
  }
}

export async function getUserById(userId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${USERMANAGEMENT_API_BASE_URL}/users/${userId}?limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('User data not loaded');
  }
}

export async function createUser(newUser) {
  // newUser = { ...newUser, role_id: 1, project_id: 1 };

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${USERMANAGEMENT_API_BASE_URL}/users`, newUser, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating new user: ${err}`);
    throw new Error('New user not created');
  }
}

export async function deleteUserById(userId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.delete(
      `${USERMANAGEMENT_API_BASE_URL}/users/${userId}`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error deleting new user: ${err}`);
    throw new Error('User not deleted');
  }
}

export async function updateUserById(userId, updatedUser) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${USERMANAGEMENT_API_BASE_URL}/users/${userId}`,
      updatedUser,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating user: ${err}`);
    throw new Error('User not updated');
  }
}

// User Permission Api

export async function editPermission(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${USERMANAGEMENT_API_BASE_URL}/permissions/bulk`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error editPermission user: ${err}`);
    throw new Error('editPermission not updated');
  }
}

///////////////////////////////////////
/// Roles API's
export async function getAllRoles() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/roles?limit=100`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Roles data not loaded');
  }
}

export async function getAllDesignations() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/designations?limit=50`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error designations data: ${err}`);
    throw new Error('Designations data not loaded');
  }
}

// Add Land API for static data
export function getIdLandsStatic(id, postData) {
  try {
    let selectedData = postData.filter((item) => item.id === parseInt(id));
    return selectedData[0];
  } catch (err) {
    console.error(`Error adding getidLand: ${err}`);
    throw new Error('Error adding new getIdLands');
  }
}

// Add PLC for Unit API for static data
export function addUnitPLC(Unit, setUnit, postData) {
  try {
    setUnit([...Unit, postData]);
    return postData;
  } catch (err) {
    console.error(`Error adding Unit: ${err}`);
    throw new Error('Error adding new Unit');
  }
}

// Add PLC for List API for static data
export function addListPLC(List, setList, postData) {
  try {
    setList([...List, postData]);
    return postData;
  } catch (err) {
    console.error(`Error adding List: ${err}`);
    throw new Error('Error adding new List');
  }
}

export async function createLand(newLand) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/land`, newLand, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating company: ${err}`);
    throw new Error('New company not created');
  }
}

export async function getAllLands() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/land?limit=200`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Lands data not loaded');
  }
}

export async function getLandById(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/land/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Land data not loaded');
  }
}

export async function editLandById(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/land/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error editing lands: ${err}`);
    throw new Error('Land data not edited');
  }
}

// Project Stats api
export async function getProjectStats(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/project/project_stats?company_id=${id}`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Stats data not loaded');
  }
}

//////////////////////////////////////////
/// PROJECTS API's
export async function createProject(newProject) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/project`,
      newProject,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error creating project: ${err}`);
    throw new Error('New project not created');
  }
}

export async function getAllProjects() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/project?limit=100`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Projects data not loaded');
  }
}

// Get Project by Company Id
export async function getProjectsByCompanyId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/project?company_id=${id}&&limit=100`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Projects data not loaded');
  }
}

export async function getProjectByID(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/project/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Project data not loaded');
  }
}

export async function editProject(id, payload) {
  // Object.keys(payload).forEach((key) => {
  //   if (payload[key] === '') {
  //     delete payload[key];
  //   } else if (
  //     typeof payload[key] === 'object' &&
  //     !Array.isArray(payload[key]) &&
  //     Object.keys(payload[key]).length === 0
  //   ) {
  //     delete payload[key];
  //   }
  // });
  console.log({ payload });

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/project/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error editing project: ${err}`);
    throw new Error('Project data not edited');
  }
}

//////////////////////////////////////////
/// ACCOMMODATION API's
export async function createAccommodation(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/accomodation`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error creating accommodation: ${err}`);
    throw new Error('New accommodation not created');
  }
}

export async function getAllAccommodations() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/accomodation?limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Accommodation data not loaded');
  }
}
export async function getAllAccommodationsByProject(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/accomodation?projectId=${id}&limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Accommodation data not loaded');
  }
}

export async function editAccommodation(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/accomodation/${id}?limit=500`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updated accommodation: ${err}`);
    throw new Error('Accommodation not updated');
  }
}

//////////////////////////////////////////
/// BLOCK API's
export async function createBLock(payload) {
  // payload.area = parseInt(payload.area);
  // payload.levels = generateBlockNames(payload.number_of_levels);
  // payload.project_id = parseInt(payload.project_id);
  // payload.number_of_levels = parseInt(payload.number_of_levels);

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/block?limit=500`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error creating block: ${err}`);
    throw new Error('New block not created');
  }
}

export async function getAllBlocks(project_id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/block?projectId=${project_id}&limit=300`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error fetching blocks: ${err}`);
    throw new Error('Error fetching blocks');
  }
}

export async function getBlockByID(blockId) {
  if (!blockId) return;

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/block/${blockId}?limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error fetching block: ${err}`);
    throw new Error('Error fetching blocks');
  }
}

export async function addPaymentStatic(blocks, setBlocks, newBlock) {
  try {
    setBlocks([...blocks, newBlock]);
    return newBlock;
  } catch (err) {
    throw new Error('Error adding new block', err);
  }
}

// Edit Block
export async function editBlock(id, payload) {
  payload.area = parseInt(payload?.area);

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/block/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating block: ${err}`);
    throw new Error('Block not updated');
  }
}

// Edit Levels
export async function editLevel(id, payload) {
  payload = loadash.omit(payload, ['noOfUnits']);

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/level/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating level: ${err}`);
    throw new Error('Level not updated');
  }
}

// Edit Basement
export async function editUnits(id, payload) {
  payload.area = parseInt(payload?.area);
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/unit/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating unit: ${err}`);
    throw new Error('Unit not updated');
  }
}

export async function createLevel(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/level`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating level: ${err}`);
    throw new Error('New level not created');
  }
}

export async function getLevelById(levelId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/level/${levelId}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error getting level: ${err}`);
    throw new Error('Level data not fetched');
  }
}

export async function cretaeUnit(payload) {
  payload.project_id = parseInt(payload?.project_id);

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/unit`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating unit: ${err}`);
    throw new Error('Unit data not created');
  }
}

// PLC APIs
export async function createPLCList(payload) {
  payload.rate = parseInt(payload.rate);
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/plc`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error('Error in PLC List', err);
    throw new Error('No PLC list');
  }
}

export async function getPlcbyBlockId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/unit?block_id=${id}&limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error('Error in PLC by block id', err);
    throw new Error('No PLC list by block id');
  }
}

export async function getUnitsById(id, blockId) {
  console.log(id);

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/unit/${id}?limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error('Error in PLC by Units id', err);
    throw new Error('Units by id');
  }
}

export async function getPlcbyId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/plc/${id}?limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error('Error in PLC by block id', err);
    throw new Error('No PLC list by  id');
  }
}

// update plc

export async function updateplc(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(`${APPLICATION_API_BASE_URL}/plc/${id}`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error('Error in plc update', err);
    throw new Error('No PLC plc update');
  }
}

export async function getPlcbyAssociatedBlockId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/block/${id}?limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error('Error in PLC by block id', err);
    throw new Error('No PLC list by block id');
  }
}

export async function getCommonPLC() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/common-entity/common-entities?limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error('Error in PLC by block id', err);
    throw new Error('No PLC list by block id');
  }
}

export async function getPlc() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/plc?limit=100`, headers);
    return data.data.data;
  } catch (err) {
    console.error('Error in PLC ', err);
    throw new Error('No PLC list');
  }
}
// export async function getPlcByProjectId() {
//   try {
//     const headers = await getHeaders();
//     const data = await axiosInstance.get(`${API_BASE_URL}/plc?`, headers);
//     return data.data.data;
//   } catch (err) {
//     console.error('Error in PLC ', err);
//     throw new Error('No PLC list');
//   }
// }

export async function createPLCUnit(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/unitplc`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error('Error in PLC List', err);
    throw new Error('No PLC list');
  }
}

//////////////////////////////////////////
/// PRICING API's
export async function createPricing(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/unit/pricing`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error creating pricing: ${err}`);
    throw new Error('Pricing data not created');
  }
}

export async function getAllPricingByProjectId(projectId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/unitpricing?project_id=${projectId}&limit=100`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error('Error in Pricing by project id', err);
    throw new Error('No Pricing list by project id');
  }
}

export async function editPricing(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/unit/pricing/`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating pricing: ${err}`);
    throw new Error('Pricing not updated');
  }
}

export async function getSinglePricingById(pricingId, projectId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/unitpricing/${pricingId}`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error getting pricing: ${err}`);
    throw new Error('Pricing data not fetched');
  }
}

export async function updatePLCPricing(updatedPLCPricing) {
  // Create a new object excluding empty values
  const cleanedPLCPricing = Object.fromEntries(
    Object.entries(updatedPLCPricing).filter(([_, value]) => value !== '')
  );

  try {
    const headers = await getHeaders();
    const response = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/plc/pricing`,
      cleanedPLCPricing,
      headers // Ensure headers are correctly passed inside an object
    );
    return response.data.data;
  } catch (error) {
    console.error(`Error updating PLC pricing: ${error.message}`, error);
    throw new Error('Failed to update PLC pricing');
  }
}

export async function getUnitPricingByBlockId(blockId, page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const response = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/unit?block_id=${blockId}&page=${page}&limit=${limit}`,
      headers
    );
    return response.data;
  } catch (err) {
    console.error('Error in Pricing by block id', err);
    throw new Error('No Pricing list by block id');
  }
}

//get all unit status api
export async function getUnitStatus() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/status/units`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Unit status data not loaded');
  }
}

//update unit status api
export async function updateUnitStatus(selectedUnitId, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/unit/${selectedUnitId}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating unit status: ${err}`);
    throw new Error('Unit status not updated');
  }
}

//////////////////////////////////////////
/// SALES API's
export async function getAllLead(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/leads?page=${page}&limit=${limit}`,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Leads data not loaded');
  }
}
// get All leads by status

export async function getLeadsByStatus(status, page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/leads?status=${status}&page=${page}&limit=${limit}`,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('getLeadsByStatus data not loaded');
  }
}
export async function getLeadByID(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/leads/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Lead data not loaded');
  }
}

export async function getActivityByID(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/activity?lead_id=${id}&limit=100`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Activity data not loaded');
  }
}
export async function approveActivity(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/activity/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Activity data not loaded');
  }
}

export async function getEvent(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/event?lead_id=${id}`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Event data not loaded');
  }
}

/////////////////////////////////////////
/// VENDOR API's
export async function createVendor(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/vendor`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating vendor: ${err}`);
    throw new Error('New vendor not created');
  }
}

export async function updateVendor(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/vendor/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Vendor data not loaded');
  }
}

export async function getAllVendor(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/vendor?page=${page}&limit=${limit}`,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Vendor data not loaded');
  }
}

export async function getVendorById(Id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/vendor/${Id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Vendor data not loaded');
  }
}

export async function getVendorDeleteId(Id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.delete(`${APPLICATION_API_BASE_URL}/vendor/${Id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error deleting vendor: ${err}`);
    throw new Error('Vendor not deleted');
  }
}

// Deactivate Vendor
export async function deactivateVendor(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.patch(
      `${APPLICATION_API_BASE_URL}/vendor/deactivate`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error deactivating vendor: ${err}`);
    throw new Error('Vendor status not updated');
  }
}

/////////////////////////////////////////
/// SERVICE PROVIDER API's
export async function createServiceProvider(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/serviceprovider`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error creating service provider: ${err}`);
    throw new Error('Service Provider not created');
  }
}

export async function getAllServiceProvider(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/serviceprovider?page=${page}&limit=${limit}`,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Service Providers data not loaded');
  }
}

export async function deleteServiceProvider(Id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.delete(
      `${APPLICATION_API_BASE_URL}/serviceprovider/${Id}`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error deleting service provider: ${err}`);
    throw new Error('Service Providers data not deleted');
  }
}
//write api for get all categories
export async function getAllCategoriesForServiceProvider() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/categoryprovider`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Categories data not loaded');
  }
}

/////////////////////////////////////////
/// MATERIALS API's
export async function createMaterial(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/items`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating material: ${err}`);
    throw new Error('Material not created');
  }
}
export async function updateMaterial(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(`${APPLICATION_API_BASE_URL}/items`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating material: ${err}`);
    throw new Error('Material not created');
  }
}

export async function getAllMaterials() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/material/material`, headers);
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Materials data not loaded');
  }
}

export async function getAllCategories(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/category?page=${page}&limit=${limit}`,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Categories data not loaded');
  }
}

export async function addCategory(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/category`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error adding category: ${err}`);
    throw new Error('Category not added');
  }
}

export async function addBrand(payload) {
  console.log({ payload });

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/brand`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error adding brand: ${err}`);
    throw new Error('Brand not added');
  }
}

export async function getBrandsByCategory(categoryId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/brand?category_id=${categoryId}`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Brands data not loaded');
  }
}

/// Payment Stages API
export async function createPaymentStages(blockId, payload) {
  let newArr = [];
  for (let i = 0; i < payload.payment.length; i++) {
    const percentage = parseInt(payload.payment[i].percentage);
    if (!isNaN(percentage)) {
      newArr.push({
        name: payload.payment[i].name,
        percentage: percentage
      });
    }
  }
  let newpayload = {
    block_id: blockId,
    stages: newArr
  };

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/payment`,
      newpayload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error creating payment stages: ${err}`);
    throw new Error('Payment Stage not created');
  }
}

export async function getPaymentStages(id) {
  if (id == null) {
    return;
  }

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/payment?block_id=${id}&limit=100`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error fetching payment stages: ${err}`);
    throw new Error('Payment Stages not fetched');
  }
}

export async function getByIdPaymentStages(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/payment/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error fetching payment stage: ${err}`);
    throw new Error('Payment Stage not fetched');
  }
}

export async function updatePaymentStages(payload, id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/payment/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating payment stage: ${err}`);
    throw new Error('Payment Stage not updated');
  }
}

export async function deletePaymentStages(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.delete(`${APPLICATION_API_BASE_URL}/payment/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error Delete in Payment Stage: ${err}`);
    throw new Error('Delete in Payment Stage');
  }
}

// Requirement API's

export async function getRequirements(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/requirement?approved=true&page=${page}&limit=${limit}`,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Requiremnt : ${err}`);
    throw new Error('Error Get in Requiremnt');
  }
}

export async function getByIdRequirements(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/requirement/${id}?approved=true`,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Requiremnt : ${err}`);
    throw new Error('Error in Get in requirement BY Id');
  }
}

export async function editrequirementQuantity(payload, id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/requirmentmapping/${id}`,
      payload,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Update in Requiremnt: ${err}`);
    throw new Error('Error Update in Requiremnt');
  }
}

export async function deleteRequirement(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.delete(
      `${APPLICATION_API_BASE_URL}/requirmentmapping/${id}`,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Delete in Requirement: ${err}`);
    throw new Error('Error Delete in Requirement');
  }
}

export async function updateStatusRequirement(payload, id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/requirement/${id}`,
      payload,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Update in Requiremnt: ${err}`);
    throw new Error('Error Update in Requiremnt');
  }
}

// Close Deals

export async function getCloseDeals(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/closeddeal?page=${page}&limit=${limit}`,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Closed Deals : ${err}`);
    throw new Error('Error Get in Closed Deals');
  }
}

export async function getCloseDealsById(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/closeddeal/${id}`, headers); // Using APPLICATION_API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Closed Deals : ${err}`);
    throw new Error('Error Get in Closed Deals');
  }
}

//writw thw api call for getConsolidatedUnitList
export async function getConsolidatedUnitList(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/closeddeal/orderlist?page=${page}&limit=${limit}`,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Closed Deals : ${err}`);
    throw new Error('Error Get in Closed Deals');
  }
}

// Request Quatation api

export async function requestQuatation(payload) {
  console.log({ payload });

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/quotationrequest`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`quotationrequest adding brand: ${err}`);
    throw new Error('quotationrequest not added');
  }
}

export async function getQuatationByRequirement(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/quotation?requirement_id=${id}&limit=100`,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Quatation  : ${err}`);
    throw new Error('Error Quatation get');
  }
}

// getQuatationRequestItems To get the items

export async function getQuatationRequestItems(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/quotationrequestitems?quotation_id=${id}`,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Quatation  : ${err}`);
    throw new Error('Error Quatation get');
  }
}

// Update the Status

export async function updateQuatationRequestItems(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/quotation/${id}`,
      payload,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in QuatationRequestItems  : ${err}`);
    throw new Error('Error QuatationRequestItems get');
  }
}

// Orders API

export async function getOrders(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/orders?page=${page}&limit=${limit}`,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Orders  : ${err}`);
    throw new Error('Error Orders get');
  }
}

// Assuming getHeaders and APPLICATION_API_BASE_URL are defined elsewhere
export async function updateOrdersStatus(id, payload) {
  try {
    const headers = await getHeaders();
    const response = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/orders/${id}`,
      payload,
      headers
    ); // Using APPLICATION_API_BASE_URL constant
    const res = response.data.data;
    return res;
  } catch (err) {
    // Check if the error has a response from the server
    if (err.response && err.response.data && err.response.data.message) {
      const errorMessage = err.response.data.message;
      console.error(`Error updating order: ${errorMessage}`);
      // Throw the specific error message
      throw new Error(errorMessage);
    } else {
      // Fallback to a generic error message
      console.error(`Error updating order: ${err.message || err}`);
      throw new Error('Error updating order');
    }
  }
}

//write api for downloaidng invoice for order
export async function downloadInvoiceForOrder(id) {
  try {
    const headers = await getFileHeaders();
    console.log(headers);
    const response = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/orders/invoice/${id}`, {
      ...headers,
      responseType: 'arraybuffer'
    });
    console.log(response, 'response');
    return response.data;
  } catch (err) {
    console.error(`Error Getting invoice: ${err}`);
    throw new Error('Error fetching invoice');
  }
}

//write api for downloaidng bill for order
export async function downloadBillForOrder(id) {
  try {
    const headers = await getFileHeaders();
    console.log(headers);
    const response = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/orders/bill/${id}`, {
      ...headers,
      responseType: 'arraybuffer'
    });
    console.log(response, 'response');
    return response.data;
  } catch (err) {
    console.error(`Error Getting bill: ${err}`);
    throw new Error('Error fetching bill');
  }
}

// Allotment Letter
export async function getallotmentLetter(id) {
  try {
    const headers = await getFileHeaders();
    console.log(headers);
    const response = await axiosInstance.get(`${DOCGENERATION_API_BASE_URL}/allotment/${id}`, {
      ...headers,
      responseType: 'arraybuffer'
    });
    console.log(response, 'response');
    return response.data;
  } catch (err) {
    console.error(`Error Getting Allotment Letter: ${err}`);
    throw new Error('Error fetching allotment letter');
  }
}

export async function uploadAllotmentLetter(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${DOCGENERATION_API_BASE_URL}/allotment/${id}`,
      payload,
      headers
    ); // Using API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Post Allotment  : ${err}`);
    throw new Error('Error Post Allotment');
  }
}

// Download signed allotment letter
export async function downloadAllotment(id) {
  try {
    const headers = await getFileHeaders();
    console.log(headers);
    const response = await axiosInstance.get(
      `${DOCGENERATION_API_BASE_URL}/allotment/signedcopy/${id}`,
      {
        ...headers,
        responseType: 'arraybuffer'
      }
    );
    console.log(response, 'response');
    return response.data;
  } catch (err) {
    console.error(`Error Getting Allotment Letter: ${err}`);
    throw new Error('Error fetching allotment letter');
  }
}

//agreement letter
export async function getAgreementLetter(id) {
  try {
    const headers = await getFileHeaders();
    console.log(headers);
    const response = await axiosInstance.get(`${DOCGENERATION_API_BASE_URL}/agreement/${id}`, {
      ...headers,
      responseType: 'arraybuffer'
    });
    console.log(response, 'response');
    return response.data;
  } catch (err) {
    console.error(`Error Getting agreement Letter: ${err}`);
    throw new Error('Error fetching agreement letter');
  }
}

// upload Agreement letter

export async function uploadAgreementLetter(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${DOCGENERATION_API_BASE_URL}/agreement/${id}`,
      payload,
      headers
    ); // Using API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Post Allotment  : ${err}`);
    throw new Error('Error Post Allotment');
  }
}

// Signed download Agreement Letter

export async function downloadAgreement(id) {
  try {
    const headers = await getFileHeaders();
    console.log(headers);
    const response = await axiosInstance.get(
      `${DOCGENERATION_API_BASE_URL}/agreement/signedcopy/${id}`,
      {
        ...headers,
        responseType: 'arraybuffer'
      }
    );
    console.log(response, 'response');
    return response.data;
  } catch (err) {
    console.error(`Error Getting agreement Letter: ${err}`);
    throw new Error('Error fetching agreement letter');
  }
}

//write api for download invoice
export async function downloadInvoice(id) {
  try {
    const headers = await getFileHeaders();
    console.log(headers);
    const response = await axiosInstance.get(
      `${DOCGENERATION_API_BASE_URL}/invoices/invoice/${id}?source=Advance`,
      {
        ...headers,
        responseType: 'arraybuffer'
      }
    );
    console.log(response, 'response');
    return response.data;
  } catch (err) {
    console.error(`Error Getting invoice: ${err}`);
    throw new Error('Error fetching invoice');
  }
}
export async function downloadInvoiceForPaymentStages(id, sourceName) {
  console.log(id, sourceName);
  try {
    const headers = await getFileHeaders();
    console.log(headers);
    const response = await axiosInstance.get(
      `${DOCGENERATION_API_BASE_URL}/invoices/invoice/${id}?source=${sourceName}&limit=500`,
      {
        ...headers,
        responseType: 'arraybuffer'
      }
    );
    console.log(response, 'response');
    return response.data;
  } catch (err) {
    console.error(`Error Getting invoice: ${err}`);
    throw new Error('Error fetching invoice');
  }
}

// Invoices API's

export async function getInvoicesList(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${DOCGENERATION_API_BASE_URL}/invoices?page=${page}&limit=${limit}`,
      headers
    ); // Using API_BASE_URL constant
    const res = data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Invoices  : ${err}`);
    throw new Error('Error Invoice get');
  }
}

export async function updateInvoiceStatus(id, payload) {
  console.log({ payload });

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${DOCGENERATION_API_BASE_URL}/invoices/${id}`,
      payload,
      headers
    ); // Using API_BASE_URL constant
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in update Status invoice  : ${err}`);
    throw new Error('Error update Status invoice get');
  }
}

//wrie api call for paymentHistoryForDeals
export async function getPaymentHistoryForDeals(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${DOCGENERATION_API_BASE_URL}/invoices/payment/history?page=${page}&limit=${limit}`,
      headers
    ); // Using API_BASE_URL constant
    const res = data.data;
    return res;
  } catch (err) {
    console.error(`Error Get in Payment History  : ${err}`);
    throw new Error('Error Payment History get');
  }
}

//late fee apis
export async function addLateFeeConfig(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/latefee`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error adding late fee config:${err}`);
    throw new Error('Late fee config not added');
  }
}

export async function updateLateFeeConfig(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/latefee/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error adding late fee config:${err}`);
    throw new Error('Late fee config not added');
  }
}

export async function getlatefeebyId(id) {
  // if (isNaN(id)) {
  //   return;
  // }
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/latefee?project_id=${id}&limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error adding late fee config: ${err}`);
    throw new Error('Late fee config not getting');
  }
}

export async function getBuildingsByProjectId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/building?project_id=${id}&limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Project data not loaded');
  }
}

//application api
export async function getAllApplication(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/application?limit=500`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error adding application:${err}`);
    throw new Error('Application not added');
  }
}

//get application by deal id
export async function getApplicationByDealId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/application?deal_id=${id}&limit=500`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Application data not loaded');
  }
}

//update application api
export async function updateApplication(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/application/${id}`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating application:${err}`);
    throw new Error('Application not updated');
  }
}
// Live Feed APIs
//wrote api for get all posts
export async function getPosts() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${LIVEFEED_API_BASE_URL}/post`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Posts data not loaded');
  }
}
//wrioet api to create post
export async function createPost(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${LIVEFEED_API_BASE_URL}/post/create`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating post: ${err}`);
    throw new Error('Post not created');
  }
}

//wite api to edit post
export async function editPost(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${LIVEFEED_API_BASE_URL}/post/update`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error updating post: ${err}`);
    throw new Error('Post not updated');
  }
}

//write api to get allcomments based on post id
export async function getCommentsByPostId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${LIVEFEED_API_BASE_URL}/comment/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Comments data not loaded');
  }
}

//write api to create comment
export async function createComment(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${LIVEFEED_API_BASE_URL}/comment/create`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error creating comment: ${err}`);
    throw new Error('Comment not created');
  }
}

//write api to Create Reply
export async function createReply(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${LIVEFEED_API_BASE_URL}/reply/create`,
      payload,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error creating reply: ${err}`);
    throw new Error('Reply not created');
  }
}

//write api to get all replies based on comment id
export async function getRepliesByCommentId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${LIVEFEED_API_BASE_URL}/reply/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Replies data not loaded');
  }
}

// Reports API

export const getBuilderReport = async () => {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/reports?limit=500`, {
      ...headers,
      responseType: 'arraybuffer'
    });
    console.log(data);

    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Application data not loaded');
  }
};

export const getReportSuperAdmin = async (builderId) => {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/reports?builderId=${builderId}&limit=500`,
      {
        ...headers,
        responseType: 'arraybuffer'
      }
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Super-Admin Reports data not loaded');
  }
};

// Leads Reports

export const getReportLeads = async () => {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/reports/leads?limit=500`, {
      ...headers,
      responseType: 'arraybuffer'
    });
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Leads data not loaded');
  }
};

export const getReportSuperAdminLeads = async (builderId) => {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/reports/leads?builderId=${builderId}&limit=500`,
      {
        ...headers,
        responseType: 'arraybuffer'
      }
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Reports Admin leads data not loaded');
  }
};

//write zoho api for post API
export async function addZohoIntegration(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/zoho`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error connecting: ${err}`);
    throw new Error('Zoho not connected');
  }
}

//write api for get all zoho
export async function getZohoIntegration() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/zoho?limit=300`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Zoho data not loaded');
  }
}

//wriet api for resend email invites
export async function resendEmailInvites(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/auth/invite`,
      payload,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error connecting: ${err}`);
    throw new Error('Email not connected');
  }
}


//write api call for buildreports/receivables-reports
export async function getReceivablesReports(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/buildreports/receivables-reports?page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Receivables reports data not loaded');
  }
}

//write api call for /buildreports/inventory/Report
export async function getInventoryReports(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/buildreports/inventory/Report?page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Inventory reports data not loaded');
  }
}

//write api call for /buildreports/target-reports
export async function getTargetReports(page = 1, limit = 200, selectedMonth, selectedYear) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/buildreports/target-reports?month=${selectedMonth}&year=${selectedYear}&page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Target reports data not loaded');
  }
}

//write api call for /buildreports/revenue-reports
export async function getBlockWiseRevenueReports(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/buildreports/revenue-reports?page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Revenue reports data not loaded');
  }
}

// write api call for /buildreports/unitwiserevenue-reports 
export async function getUnitWiseRevenueReports(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/buildreports/unitwiserevenue-reports?page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Unit wise revenue reports data not loaded');
  }
}

//accounts reort apis 
//write api call for accountreports/project-expenditures
export async function getProjectExpendituresReports(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/accountreports/project-expenditures?page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Project Expenditures reports data not loaded');
  }
}

//write api call for /accountReports/account-gst
export async function getAccountGstReports(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/accountReports/account-gst?page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('GST Account reports data not loaded');
  }
}

//writw api call for accountreports/account-tds
export async function getAccountTdsReports(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/accountReports/account-tds?page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('TDS Account reports data not loaded');
  }
}

export async function getAccountPaidBillsReports(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/accountReports/account-paidbills?page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Paid Bills reports data not loaded');
  }
}

export async function getAccountPendingBillsReports(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/accountReports/account-pending-bills?page=${page}&limit=${limit}`,
      headers
    );
    console.log(data.data, 'api call data')
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Pending Bills reports data not loaded');
  }
}

//get all sales user
export async function getAllSalesUsers(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${USERMANAGEMENT_API_BASE_URL}/users?desgignation_id=3&page=${page}&limit=${limit}`,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Sales data not loaded');
  }
}

// write put api for /target/bulk/update 
export async function bulkUpdateTarget(payload, selectedMonth, selectedYear) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(`${APPLICATION_API_BASE_URL}/target/bulk/update?month=${selectedMonth}&year=${selectedYear}`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error updating target:${err}`);
    throw new Error('Target not updated');
  }
}

//write api call for getorderactivity by order id 
export async function getOrderActivityByOrderId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/orderactivity?order_id=${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Order activity data not loaded');
  }
}

//write api call for createapplication /application 
export async function createApplication(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/application`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating application:${err}`);
    throw new Error('Application not created');
  }
}

//write api call for get application 
export async function getApplication() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/application?limit=1000`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Application data not loaded');
  }
}

//template creation 

// write api call for template creation post api
export async function createTemplate(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/template`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error creating template: ${err}`);
    throw new Error('Template not created');
  }
}

//write api call for get template 
export async function getTemplate(projectId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/template?project_id=${projectId}&limit=1000`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Template data not loaded');
  }
}

//write api call for get annexure
export async function getAnnexures() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/annexure?limit=1000`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Annexure data not loaded');
  }
}

//writw api call for get dynamicfields
export async function getDynamicFields() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/dynamicfield?limit=1000`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Dynamic fields data not loaded');
  }
}

//write api to get documents by template id 
export async function getDocumentsByTemplateId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/document?template_id=${id}&limit=1000`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Documents data not loaded');
  }
}

//write api to generate document
export async function generateDocument(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/document`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error generating document: ${err}`);
    throw new Error('Document not generated');
  }
}

//write api to download document
export async function downloadDocument(documentId) {
  try {
    const headers = await getFileHeaders();
    const response = await axiosInstance.get(`${DOCGENERATION_API_BASE_URL}/document/doc/${documentId}`, {
      ...headers,
      responseType: 'arraybuffer'
    });
    return response.data;
  } catch (err) {
    console.error(`Error Getting document: ${err}`);
    throw new Error('Error fetching document');
  }
}

//write api to upload document
export async function uploadDocument(documentId, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/document/${documentId}`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error uploading document: ${err}`);
    throw new Error('Document not uploaded');
  }
}

//write api to download signed document
export async function downloadSignedDocument(documentId) {
  try {
    const headers = await getFileHeaders();
    const response = await axiosInstance.get(`${DOCGENERATION_API_BASE_URL}/document/${documentId}`, {
      ...headers,
      responseType: 'arraybuffer'
    });
    return response.data;
  } catch (err) {
    console.error(`Error Getting signed document: ${err}`);
    throw new Error('Error fetching signed document');
  }
}

//write spi for get document by id
export async function getDocumentById(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/document/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Document data not loaded');
  }
}

//writw get document by deal id
export async function getDocumentByDealId(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/document?deal_id=${id}&limit=1000`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Document data not loaded');
  }
}

//write api to update template 
export async function updateTemplate(id, payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(`${APPLICATION_API_BASE_URL}/template/${id}`, payload, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error updating template: ${err}`);
    throw new Error('Template not updated');
  }
}

//write api to get template by id
export async function getTemplateById(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/template/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Template data not loaded');
  }
}



