import React from 'react';
import { useNavigate } from 'react-router-dom';
import company_image from '../../../shared/assets/svg/landscape-placeholder.svg';
import Button from '../../../shared/components/ui/Button';

const CompanyCard = ({ data }) => {
  const navigate = useNavigate();

  const handleViewCompany = () => {
    if (data?.id) {
      navigate(`/builder/management/company/${data.id}`);
    }
  };

  // Safely access the logo URL, fallback to default image if not available
  const logoUrl = data?.company_logo?.[0]?.url || company_image;

  return (
    <div className="bg-white w-[31%] p-6 rounded-xl flex flex-col gap-4 flex-shrink-0">
      <img
        src={logoUrl}
        alt="Company logo"
        className="w-full h-64 bg-center bg-cover object-contain bg-gray-100 rounded-lg object-center"
      />

      <span className="text-xl font-semibold text-gray-700">
        {data?.company_name || 'Company Name Not Available'}
      </span>

      <span className="mt-[-8px] text-gray-400">
        {data?.company_type ? `${data.company_type} Company` : 'Company Type Not Specified'}
      </span>

      <div className="flex justify-between items-center space-x-2 mt-2">
        <Button displayType="secondary" onClick={handleViewCompany} disabled={!data?.id}>
          View Company
        </Button>
        <Button
          onClick={() => data?.id && navigate(`/builder/management/company/${data.id}/projects`)}
          disabled={!data?.id}>
          View Projects
        </Button>
      </div>
    </div>
  );
};

export default CompanyCard;
