import React, { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import Multiselect from 'multiselect-react-dropdown';
import {
  getAllProjects,
  getAllRoles,
  getAllCompanies,
  getAllDesignations
} from '../../services/api/implementation/impl';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

// Validation schema with added vertical field
const validationSchema = yup.object().shape({
  vertical: yup.string().required('Vertical is required'),
  designation_id: yup.number().required('Designation is required'),
  name: yup
    .string()
    .required('Full name is required')
    .min(3, 'Full name should be at least 3 characters')
    .max(50, 'Full name should be at most 50 characters'),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .matches(
      /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      'Phone number is not valid'
    )
    .test(
      'len',
      'Must be a valid phone number',
      (val) => !val || (val && val.replace(/[^\d]/g, '').length === 10)
    ),
  email: yup.string().required('Email is required').email('Invalid email format'),
  // Ensures that at least one company is selected
  company_ids: yup.array().min(1, 'At least one company must be selected')
});

export const AddUser = ({ onClose, onSubmit, users, isSubmitting }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    // Default values now include vertical as well as the other fields
    defaultValues: {
      vertical: '',
      designation_id: '',
      name: '',
      phone_number: '',
      email: '',
      company_ids: []
    }
  });

  // Local states for extra fields
  const [salesId, setSalesId] = useState('');
  const [maxDiscount, setMaxDiscount] = useState('');
  const [selectedVertical, setSelectedVertical] = useState('');

  // Fetch data
  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getAllRoles
  });
  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: getAllProjects
  });
  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: getAllCompanies
  });
  const { data: designations } = useQuery({
    queryKey: ['designations'],
    queryFn: getAllDesignations
  });

  console.log({ designations });

  let userRoleId = Cookies.get('userRoleId');
  console.log({ userRoleId });

  // Form submission handler
  const onSubmitForm = (data) => {
    console.log({ data });
    // Destructure to exclude max_discount_limit from data
    const { max_discount_limit, ...restData } = data;
    
    const modifiedData = {
      ...restData,
      user_type: 'Employee',
      // Map company objects to just their IDs
      company_ids: data.company_ids.map((item) => item.id),
      role_id: 2,
      authorized_person: true
    };
  
    // Only add max_discount_limit if designation is Sales (salesId equals 3)
    if (parseInt(salesId) === 3 && max_discount_limit !== "") {
      modifiedData.max_discount_limit = parseInt(max_discount_limit, 10);
    }
  
    console.log({ modifiedData });
    onSubmit(modifiedData);
  };
  

  const transformedDataCompany = companies?.map((item) => ({
    name: item.company_name?.trim(),
    id: item.id
  }));

  // Helper to only keep digits and set phone number
  const handlePhoneNumber = useCallback(
    (val) => {
      const newVal = val.replace(/[^0-9]/g, '');
      setValue('phone_number', newVal);
    },
    [setValue]
  );

  // Helper for full name: only letters and spaces, up to 50 chars
  const handleNameChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^a-zA-Z\s]/g, '').slice(0, 50);
      setValue('name', cleanedValue);
    },
    [setValue]
  );

  // When a designation is selected, update salesId (to conditionally show max discount)
  const handleDesignationChange = (value) => {
    setSalesId(value);
  };

  // When vertical is changed, update state and reset designation field
  const handleVerticalChange = (value) => {
    setSelectedVertical(value);
    setValue('vertical', value);
    // Reset designation if vertical changes
    setValue('designation_id', '');
    setSalesId('');
  };

  // Filter the designations based on the selected vertical
  const filteredDesignations = selectedVertical
    ? designations?.filter(
        (item) => item.vertical && item.vertical.toLowerCase() === selectedVertical.toLowerCase()
      )
    : [];

  const designationOptions = [
    {
      value: '',
      label: selectedVertical ? 'Select Designation' : 'Select Vertical first',
      selected: true,
      disabled: true
    },
    ...(filteredDesignations?.map((item) => ({
      value: parseInt(item.id),
      label: item.name ? item.name.replace(/\b\w/g, (char) => char.toUpperCase()) : ''
    })) || [])
  ];

  // Helper for max discount input: only numbers and a max of 100
  const handleMaxDiscountChange = (e) => {
    let inputVal = e.replace(/\D/g, ''); // remove non-digits
    const numericVal = parseInt(inputVal, 10);
    if (!isNaN(numericVal) && numericVal > 100) {
      inputVal = '100';
      toast.error('Max discount cannot exceed 100');
    }
    setMaxDiscount(inputVal);
    setValue('max_discount_limit', inputVal);
  };

  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">User details</span>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
        {/* Row 1: Vertical & Designation */}
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Vertical'}
            isRequired
            register={register}
            register_key={'vertical'}
            type={'dropdown'}
            options={[
              { value: '', label: 'Select Vertical', selected: true, disabled: true },
              { value: 'Management', label: 'Management' },
              { value: 'Sales', label: 'Sales' },
              { value: 'Operations', label: 'Operations' },
              { value: 'Accounts', label: 'Accounts' }
            ]}
            onDropdownChange={handleVerticalChange}
            errors={errors.vertical}
          />
          <FormInput
            width={48}
            label={'Designation'}
            isRequired
            register={register}
            register_key={'designation_id'}
            type={'dropdown'}
            options={designationOptions}
            onDropdownChange={handleDesignationChange}
            errors={errors.designation_id}
            disabled={selectedVertical === ''}
          />
        </div>

        {/* Row 2: Full Name & Phone Number */}
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Full Name'}
            isRequired
            register_key={'name'}
            register={register}
            type={'text'}
            onType={handleNameChange}
            errors={errors.name}
          />
          <FormInput
            width={48}
            label={'Phone Number'}
            isRequired
            register={register}
            register_key={'phone_number'}
            type={'text'}
            onType={handlePhoneNumber}
            errors={errors.phone_number}
            maxLength={10}
          />
        </div>

        {/* Row 3: Email Address */}
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Email address'}
            register={register}
            isRequired
            register_key={'email'}
            type={'text'}
            errors={errors.email}
          />
          {/* An empty div to maintain layout consistency */}
          <div className="w-[48%]">
            <label className="text-gray-600 mb-1 block">Assign Company *</label>
            <Controller
              name="company_ids"
              control={control}
              render={({ field }) => (
                <Multiselect
                  options={transformedDataCompany || []}
                  selectedValues={field.value}
                  onSelect={(selectedList) => field.onChange(selectedList)}
                  onRemove={(selectedList) => field.onChange(selectedList)}
                  displayValue="name"
                  placeholder=""
                />
              )}
            />
            {errors.company_ids && (
              <span className="text-red-500">{errors.company_ids.message}</span>
            )}
          </div>
        </div>

        {/* Row 4: Company assignment & Max Discount (conditionally rendered) */}
        <div className="flex justify-between items-center w-full">
          {parseInt(salesId) === 3 ? (
            <FormInput
              width={48}
              label={'Max Discount (%)'}
              register={register}
              register_key={'max_discount_limit'}
              type={'text'}
              onType={handleMaxDiscountChange}
              defaultValuePre={maxDiscount}
              errors={errors.max_discount_limit}
            />
          ) : null}
        </div>

        {/* Action Buttons */}
        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </div>
      </form>
    </>
  );
};
