// HomeRedirect.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const HomeRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get('token');
    const userRole = Cookies.get('userRole');

    // If no token, go to login
    if (!token) {
      navigate('/auth/login', { replace: true });
      return;
    }

    // If token is present, redirect by role
    if (userRole === 'builder') {
      navigate('/builder/management/company', { replace: true });
    } else if (userRole === 'admin') {
      navigate('/super-admin/home', { replace: true });
    } else {
      // Default to login if role is unknown
      navigate('/auth/login', { replace: true });
    }
  }, [navigate]);

  // Render nothing (or a small loading spinner if you want)
  return null;
};

export default HomeRedirect;
