import React, { useEffect, useState } from 'react';
import Button from '../../../shared/components/ui/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../../shared/components/ui/FormInput';
import { useQuery } from '@tanstack/react-query';
import {
  getUserById,
  getAllCompanies,
  getAllDesignations
} from '../../services/api/implementation/impl';
import Multiselect from 'multiselect-react-dropdown';
import toast from 'react-hot-toast';

// Updated validation schema (using consistent keys: vertical & designation_id)
const validationSchema = yup.object().shape({
  vertical: yup.string().required('Vertical is required'),
  designation_id: yup.number().required('Designation is required'),
  name: yup
    .string()
    .required('Full name is required')
    .max(50, 'Full name should be at most 50 characters'),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .matches(
      /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      'Phone number is not valid'
    )
    .test(
      'len',
      'Must be a valid phone number',
      (val) => !val || (val && val.replace(/[^\d]/g, '').length === 10)
    ),
  email: yup.string().required('Email is required').email('Invalid email format'),
  company_ids: yup.array().min(1, 'At least one company must be selected')
});

export const EditUser = ({ userId, onSubmit, onClose, isSubmitting }) => {
  // Local state for conditionally showing max discount (for Sales) and for filtering designations by vertical
  const [salesId, setSalesId] = useState('');
  const [selectedVertical, setSelectedVertical] = useState('');
  const [maxDiscount, setMaxDiscount] = useState('');
  const [selectedDesignation, setSelectedDesignation] = useState('');

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      vertical: '',
      designation_id: '',
      name: '',
      phone_number: '',
      email: '',
      company_ids: []
    }
  });

  // Fetch user data
  const {
    data: user,
    isSuccess: userLoaded,
    refetch: refetchUser
  } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUserById(userId),
    enabled: !!userId
  });

  // Fetch companies and designations
  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: getAllCompanies
  });

  const { data: designations, isSuccess: designationLoaded } = useQuery({
    queryKey: ['designations'],
    queryFn: getAllDesignations
  });

  // Refetch user when userId changes
  useEffect(() => {
    refetchUser();
  }, [userId, refetchUser]);

  // **Force a reset to empty defaults whenever a new userId is provided.**
  useEffect(() => {
    reset({
      vertical: '',
      designation_id: '',
      name: '',
      phone_number: '',
      email: '',
      company_ids: []
    });
    // Also clear our local state
    setSelectedVertical('');
    setSalesId('');
    setMaxDiscount('');
  }, [userId, reset]);

  // When both user data and designations are loaded, prefill the form.
  useEffect(() => {
    if (userLoaded && designationLoaded && user && designations) {
      // Get the user's designation ID from the nested object (preferred) or fallback field
      const userDesgId = user.designation?.id || user.desgignation_id;
      // Find the matching designation from the full list
      const matchedDesg = designations.find(
        (item) => parseInt(item.id, 10) === parseInt(userDesgId, 10)
      );
      const verticalValue = matchedDesg ? matchedDesg.vertical : '';
      const designationValue = matchedDesg ? matchedDesg.id : '';

      // Build new default values for the form
      const newValues = {
        vertical: verticalValue,
        designation_id: designationValue,
        name: user.name,
        phone_number: user.phone_number,
        email: user.email,
        max_discount_limit: user.max_discount_limit,
        company_ids: user.userCompanyMapping
          ? user.userCompanyMapping.map((item) => ({
              id: item.company.id,
              name: item.company.company_name?.trim()
            }))
          : []
      };

      // Reset the form with the new values
      console.log({ newValues });
      reset(newValues);
      setSelectedDesignation(designationValue);
      setSelectedVertical(verticalValue);
      setSalesId(designationValue);
    }
  }, [userLoaded, designationLoaded, user, designations, reset, userId]);

  const verticalOptions = [
    { value: '', label: 'Select Vertical', selected: true, disabled: true },
    { value: 'Management', label: 'Management' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Operations', label: 'Operations' },
    { value: 'Accounts', label: 'Accounts' }
  ];

  const filteredDesignations = selectedVertical
    ? designations?.filter(
        (item) => item.vertical && item.vertical.toLowerCase() === selectedVertical.toLowerCase()
      )
    : [];

  // Build designation dropdown options (with a placeholder)
  const designationOptions = [
    {
      value: '',
      label: selectedVertical ? 'Select Designation' : 'Select Vertical first',
      selected: true,
      disabled: true
    },
    ...(filteredDesignations?.map((item) => ({
      value: parseInt(item.id, 10),
      label: item.name ? item.name.replace(/\b\w/g, (char) => char.toUpperCase()) : ''
    })) || [])
  ];

  useEffect(() => {
    setValue('designation_id', selectedDesignation);
  }, [selectedDesignation, setValue, designationOptions, userId]);

  const handleVerticalChange = (value) => {
    setSelectedVertical(value);
    setValue('vertical', value);
    setValue('designation_id', '');
    setSalesId('');
  };

  const handleDesignationChange = (value) => {
    setSalesId(value);
    setValue('designation_id', value);
  };

  // Handler for max discount changes (only allow digits; cap at 100)
  const handleMaxDiscountChange = (e) => {
    let inputVal = e.replace(/\D/g, '');
    const numericVal = parseInt(inputVal, 10);
    if (!isNaN(numericVal) && numericVal > 100) {
      inputVal = '100';
      toast.error('Max discount cannot exceed 100');
    }
    setMaxDiscount(inputVal);
    setValue('max_discount_limit', inputVal);
  };

  const transformedDataCompany = companies?.map((item) => ({
    id: item.id,
    name: item.company_name?.trim()
  }));

  const onFormSubmit = (data) => {
    const { max_discount_limit, ...restData } = data;
    const processedData = {
      ...restData,
      company_ids: data.company_ids.map((company) => company.id)
    };
    if (parseInt(salesId, 10) === 3 && max_discount_limit !== '') {
      processedData.max_discount_limit = parseInt(max_discount_limit, 10);
    }
    onSubmit(processedData);
  };

  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">User details</span>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex flex-col gap-4 pt-6">
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Vertical'}
            isRequired
            register={register}
            register_key={'vertical'}
            type={'dropdown'}
            options={verticalOptions}
            onDropdownChange={handleVerticalChange}
            errors={errors.vertical}
          />
          <FormInput
            width={48}
            label={'Designation'}
            isRequired
            register={register}
            register_key={'designation_id'}
            type={'dropdown'}
            options={designationOptions}
            onDropdownChange={handleDesignationChange}
            errors={errors.designation_id}
            disabled={selectedVertical === ''}
          />
        </div>
        {/* Row 2: Full Name & Phone Number */}
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Full Name'}
            isRequired
            register_key={'name'}
            register={register}
            type={'text'}
            errors={errors.name}
          />
          <FormInput
            width={48}
            label={'Phone Number'}
            isRequired
            register={register}
            register_key={'phone_number'}
            type={'text'}
            errors={errors.phone_number}
          />
        </div>
        {/* Row 3: Email Address */}
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Email address'}
            register={register}
            isRequired
            register_key={'email'}
            type={'text'}
            errors={errors.email}
          />
          <div className="w-[48%]">
            <label className="text-gray-600 mb-1 block">Assign Company *</label>
            <Controller
              name="company_ids"
              control={control}
              rules={{ required: 'At least one company must be selected' }}
              render={({ field }) => (
                <Multiselect
                  options={transformedDataCompany || []}
                  selectedValues={field.value}
                  onSelect={(selectedList) => field.onChange(selectedList)}
                  onRemove={(selectedList) => field.onChange(selectedList)}
                  displayValue="name"
                  style={{
                    optionContainer: { border: '1px solid', backgroundColor: 'white' },
                    option: { color: 'black', backgroundColor: 'white' },
                    chips: { background: '#2A565E', color: 'white' }
                  }}
                />
              )}
            />
            {errors.company_ids && (
              <span className="text-red-500">{errors.company_ids.message}</span>
            )}
          </div>{' '}
        </div>
        <div className="flex justify-between items-center w-full">
          {parseInt(salesId, 10) === 3 && (
            <FormInput
              width={48}
              label={'Max Discount (%)'}
              register={register}
              isRequired
              register_key={'max_discount_limit'}
              type={'text'}
              onType={handleMaxDiscountChange}
              defaultValuePre={maxDiscount}
              errors={errors.max_discount_limit}
            />
          )}
        </div>
        {/* Action Buttons */}
        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </>
  );
};
