import React, { useCallback, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import { getAllLands, getStateAndCity } from '../../services/api/implementation/impl';
import user from '../../../shared/assets/svg/organisation.svg';
import { convertToISO } from '../../../super-admin/utils/DateFormat';
import Multiselect from 'multiselect-react-dropdown';

const AddProject = ({ onSubmit, onClose, upShift, setUpShift, isSubmitting }) => {
  const fileUrls = useSelector((state) => state.file.fileUrls);
  const MultiFiles = useSelector((state) => state.file.multifiles);

  const companyId = useParams();
  const companyIdInt = parseInt(companyId.id, 10);

  console.log({ companyIdInt });

  const [numBuildings, setNumBuildings] = useState(1);
  const [developementType, setDevelopementType] = useState('company');
  const [city, setCity] = useState('Select');
  const [state, setState] = useState('-');
  const [district, setDistrict] = useState('Select');
  const [tehsil, setTehsil] = useState('Select');
  const [cityDropdownOptions, setCityDropdownOptions] = useState([]);
  const [districtDropdownOptions, setDistrictDropdownOptions] = useState([]);
  const [tehsilDropdownOptions, setTehsilDropdownOptions] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch
  } = useForm();

  const currentDevelopmentType = watch('development_type');

  const handleAddBuilding = useCallback(() => {
    setNumBuildings(numBuildings + 1);
    setUpShift(upShift + 6);
  }, [numBuildings, setUpShift, upShift]);

  const handleRemoveBuilding = useCallback(() => {
    setNumBuildings(numBuildings - 1);
    setUpShift(upShift - 6);
  }, [numBuildings, setUpShift, upShift]);

  const { data: lands } = useQuery({
    queryKey: ['lands:-project'],
    queryFn: getAllLands
  });

  const selectedLandId = useWatch({
    control,
    name: 'land_id',
    defaultValue: ''
  });

  // Find the land that matches the selected ID
  const selectedLand = lands?.find((land) => land.id === parseInt(selectedLandId));
  console.log({ lands, selectedLand });

  // Extract the state in lowercase (for any condition checks)
  const selectedLandState = selectedLand?.state?.toLowerCase() || '';
  console.log('selectedLandState', selectedLandState);

  // Gather all owners
  const owners = selectedLand?.ownerLandMappings?.map((mapping) => mapping.owner) || [];
  console.log({ owners });

  // Flatten the khasra arrays from *all* owners and map to desired shape
  const allKhasraNumbers = owners
    .flatMap((owner) => owner?.khasra || [])
    .map((k) => ({
      name: k?.number,
      id: k?.id
    }));

  console.log({ allKhasraNumbers });

  const formatFiles = (files, defaultValue = 'dummy_string') => {
    if (!files || files.length === 0) {
      return [];
    }
    return files?.map((file) => ({ name: file.name || 'default_name', url: file.url || file }));
  };

  let value = convertToISO('2024-10-11');
  console.log({ value });

  const preparePayload = (data) => {
    const newPayload = {
      land_id: parseInt(data?.land_id),
      name: data.name,
      tncp_registration_number: data.tncp_registration_number,
      tncp_registration_date: data.tncp_registration_date,
      start_date: data.start_date,
      tncp_permission_end_date: data.tncp_permission_end_date,
      project_type: data.project_type,
      development_type: data.development_type,
      price_list_share: data.price_list_share,
      measurement_type: data.unit_of_measurement,
      project_images: formatFiles(MultiFiles.project_images || []),
      tncp_approved_plan: formatFiles(fileUrls[`tncp_approved_plan`] || []),
      brochure_files: formatFiles(fileUrls[`project_brochure`] || []),
      tncp_sanctioned_layout_plan: formatFiles(fileUrls[`tncp_sanctioned_layout_plan`] || []),
      // KAN-657 Changes
      projectSpecification: formatFiles(fileUrls['projectSpecification'] || []),
      company_id: companyIdInt,
      google_location_link: data.google_location_url,
      google_images: formatFiles(MultiFiles.google_images || []),
      buildings: Array.from({ length: numBuildings }, (_, index) => ({
        permission_no: data[`permission_no_${index}`],
        permission_date: data[`permission_date_${index}`],
        permission_end_date: data[`permission_end_date_${index}`],
        layout_plan: formatFiles(MultiFiles[`layout_plan_${index}`] || []),
        development_order: formatFiles(MultiFiles[`development_order_${index}`] || []),
        upload_letterhead: formatFiles(fileUrls[`upload_letterhead_${index}`] || []),
        rera_registration_no: data[`rera_registration_no_${index}`],
        rera_approval_date: data[`rera_approval_date_${index}`],
        rera_permission_tenure: data[`rera_permission_tenure_${index}`],
        google_map_image: formatFiles(fileUrls[`google_map_image_${index}`] || []),
        additional_documents: formatFiles(MultiFiles[`additional_documents_${index}`] || []),
        site_address: data[`site_address_${index}`],
        estimated_budget: data[`estimated_budget_${index}`],
        khasra_number:
          data[`khasra_number_${index}`]?.map((k) => ({
            id: k.id,
            name: k.name
          })) || []
      }))
    };

    // Only include joint-related fields if development_type is 'joint'
    if (data.development_type === 'joint') {
      Object.assign(newPayload, {
        joint_registration_no: data.joint_registration_no,
        joint_registered_date: data.joint_registered_date,
        joint_registered_pincode: data.joint_registered_pincode,
        joint_registered_state: data.joint_registered_state,
        joint_registered_city: data.joint_registered_city
      });
    }

    console.log('newPayload', newPayload);
    return newPayload;
  };
  const onFormSubmit = (data) => {
    const payload = preparePayload(data);
    console.log({ payload });
    onSubmit(payload);
  };

  const handleNavigate = () => {
    navigate('/builder/management/land');
  };

  const handleDevelopementType = useCallback(
    (val) => {
      setDevelopementType(val);

      // Clear joint-related fields when switching to 'company'
      if (val === 'company') {
        setValue('joint_registration_no', '');
        setValue('joint_registered_date', '');
        setValue('joint_registered_pincode', '');
        setValue('joint_registered_state', '');
        setValue('joint_registered_city', '');
      }
    },
    [setValue]
  );
  const handlePincodeChange = useCallback(async (pincode) => {
    const cleanedPincode = pincode.replace(/[^0-9]/g, '');
    setValue('pincode', cleanedPincode);
    if (cleanedPincode.length === 6) {
      try {
        const pincodeList = await getStateAndCity(pincode);
        const postOfficeList = pincodeList[0].PostOffice;
        if (postOfficeList.length > 0) {
          setState(postOfficeList[0].State);
          const uniqueCircles = [...new Set(postOfficeList?.map((office) => office.Name))];
          const uniqueDistricts = [...new Set(postOfficeList?.map((office) => office.District))];
          const uniqueTehsils = [...new Set(postOfficeList?.map((office) => office.Block))];
          setCityDropdownOptions(
            uniqueCircles?.map((circle) => ({ value: circle, label: circle }))
          );
          setDistrictDropdownOptions(
            uniqueDistricts?.map((district) => ({ value: district, label: district }))
          );
          setTehsilDropdownOptions(
            uniqueTehsils?.map((tehsil) => ({ value: tehsil, label: tehsil }))
          );

          setValue('joint_registered_state', postOfficeList[0].State);
          setValue('joint_registered_city', uniqueCircles[0]);
          setValue('district', uniqueDistricts[0]);
          setValue('tehsil', uniqueTehsils[0]);

          setState(postOfficeList[0].State);
          setCity(uniqueCircles[0]);
          setDistrict(uniqueDistricts[0]);
          setTehsil(uniqueTehsils[0]);
        } else {
          setValue('joint_registered_state', '-');
          setValue('joint_registered_city', 'Select');
          setValue('district', 'Select');
          setValue('tehsil', 'Select');

          setState('-');
          setCity('Select');
          setDistrict('Select');
          setTehsil('Select');
          setCityDropdownOptions([]);
        }
      } catch (error) {
        toast.error('The entered pincode is invalid. Please enter a valid pincode.');
        setValue('state', '-');
        setValue('city', 'Select');
        setValue('district', 'Select');
        setValue('tehsil', 'Select');

        setState('-');
        setCity('Select');
        setDistrict('Select');
        setTehsil('Select');
        setCityDropdownOptions([]);
      }
    }
  }, []);

  const estimatedBudget = useCallback(
    async (val, key) => {
      const newval = val.replace(/[^0-9]/g, '').slice(0, 8);
      setValue(key, newval);
    },
    [setValue]
  );

  const getLocalDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleReraTenureChange = useCallback(
    (index, value) => {
      const cleanedValue = value.replace(/[^0-9.]/g, '').slice(0, 8);
      setValue(`rera_permission_tenure_${index}`, cleanedValue);
    },
    [setValue]
  );

  ////////
  const [registrationDate, setRegistrationDate] = useState('');

  // Function to get the next day in 'YYYY-MM-DD' format
  const getNextDay = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1); // Add 1 day
    return newDate.toISOString().split('T')[0]; // Return the next day in YYYY-MM-DD format
  };

  const handleRegistrationDateChange = (date) => {
    const endDate = getNextDay(date);
    setRegistrationDate(endDate);
  };

  const [permissionDates, setPermissionDates] = useState({});
  const handleBuildingPermissionDateChange = (index, date) => {
    const newDate = getNextDay(date);
    setPermissionDates((prevState) => ({
      ...prevState,
      [index]: newDate
    }));
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onFormSubmit)}>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Project Details</span>
        <div className="flex flex-col w-full flex-1 gap-4">
          <div className="flex space-x-8 justify-between items-start w-full">
            {MultiFiles &&
            MultiFiles.project_images &&
            MultiFiles.project_images[0] &&
            MultiFiles.project_images[0].url ? (
              <img
                src={MultiFiles?.project_images[0]?.url}
                alt="project_images"
                className="w-[10rem] h-[11rem] bg-center bg-cover !object-cover !object-center rounded-lg"
              />
            ) : (
              <div className="bg-gray-300 rounded-xl w-[10rem] h-[10.8rem] flex justify-center items-center">
                <img
                  src={user}
                  alt="user_image"
                  className="w-[6rem] bg-center bg-cover !object-cover !object-center"
                />
              </div>
            )}
            <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
              <div className="w-full flex flex-col gap-4 flex-1">
                <div className="flex justify-between space-x-4">
                  <FormInput
                    width={24}
                    register={register}
                    register_key={'land_id'}
                    label={'Land Name'}
                    type={'dropdown'}
                    defaultbtn={'+ Add Land'}
                    options={[
                      { label: 'Select Land', value: '', selected: true, disabled: true }, // Default option
                      ...(lands?.map((item) => ({
                        value: parseInt(item?.id),
                        label: item?.name
                      })) || [])
                    ]}
                    onClick={handleNavigate}
                    isRequired={true}
                    maxLength={150}
                    minLength={6}
                  />

                  <FormInput
                    width={24}
                    register={register}
                    register_key={'name'}
                    label={'Project Name'}
                    type={'text'}
                    isRequired={true}
                    placeholder={'Enter Project Name'}
                  />
                  <FormInput
                    width={24}
                    register={register}
                    register_key={'tncp_registration_number'}
                    label={'TNCP Permission No'}
                    isRequired={true}
                    placeholder={'Enter TNCP Permission No'}
                  />
                  <FormInput
                    width={24}
                    register={register}
                    register_key={'tncp_registration_date'}
                    label={'TNCP Registration Date'}
                    type={'date'}
                    isRequired={true}
                    CustomFeature={{
                      max: getLocalDate() // Use local date for max
                    }}
                    onType={handleRegistrationDateChange}
                  />
                </div>
              </div>
              <div className="w-full flex justify-between items-center">
                <FormInput
                  width={24}
                  register={register}
                  register_key={'start_date'}
                  label={'Project Start Date'}
                  type={'date'}
                  isRequired={true}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'project_images'}
                  label={'Project Images'}
                  type={'file-secondary'}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'tncp_permission_end_date'}
                  label={'TNCP Permission End Date'}
                  type={'date'}
                  isRequired={true}
                  CustomFeature={{
                    min: registrationDate
                  }}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'tncp_approved_plan'}
                  label={'TNCP Permission Document'}
                  type={'file-btn'}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
              </div>
              <div className="w-full flex justify-start gap-4 items-center">
                <FormInput
                  width={24}
                  register={register}
                  register_key={'project_type'}
                  label={'Project Type'}
                  type={'dropdown'}
                  options={[
                    { label: 'Select', value: '', selected: true, disabled: true },
                    { label: 'Residential ', value: 'Residential' },
                    { label: 'Commercial', value: 'Commercial' },
                    { label: 'Hybrid', value: 'Hybrid' },
                    { label: 'Industrial', value: 'Industrial' }
                  ]}
                  isRequired={true}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={`project_brochure`}
                  label={'Project Brochure'}
                  type={'file-btn'}
                  maxFileSize={25 * 1024 * 1024}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'google_images'}
                  label={'Google Images'}
                  type={'file-secondary'}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={26}
                  register={register}
                  register_key={`google_location_url`}
                  label={'Google Location Url'}
                  type={'text'}
                  placeholder={'Enter Google Location Url'}
                />
              </div>
              <div className="w-full flex justify-start gap-4 items-center">
                <FormInput
                  width={24}
                  register={register}
                  register_key={`tncp_sanctioned_layout_plan`}
                  label={'TNCP Sanctioned Layout'}
                  type={'file-btn'}
                  maxFileSize={25 * 1024 * 1024}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={`projectSpecification`}
                  label={'Project Specification'}
                  type={'file-btn'}
                  maxFileSize={25 * 1024 * 1024}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'development_type'}
                  label={'Development Type'}
                  type={'dropdown'}
                  options={[
                    { label: 'Select', value: '', selected: true, disabled: true },
                    { label: 'Joint', value: 'joint' },
                    { label: 'Company', value: 'company' }
                  ]}
                  onDropdownChange={handleDevelopementType}
                  isRequired={true}
                />

                {currentDevelopmentType === 'joint' && (
                  <>
                    <div className="w-full flex justify-start gap-4 items-center">
                      <FormInput
                        width={45}
                        register={register}
                        register_key={'joint_registration_no'}
                        label={'Joint Registration No'}
                        type={'text'}
                        isRequired={true}
                        placeholder={'Enter Joint Registration No'}
                      />
                      <FormInput
                        width={48}
                        register={register}
                        register_key={'joint_registered_date'}
                        label={'Joint Registration Date'}
                        type={'date'}
                        isRequired={true}
                        CustomFeature={{
                          max: getLocalDate() // Use local date for max
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="">
                {currentDevelopmentType === 'joint' && (
                  <>
                    <div className="w-full flex justify-start gap-4 items-center">
                      <FormInput
                        width={32}
                        register={register}
                        register_key={'joint_registered_pincode'}
                        label={'Pincode'}
                        type={'text'}
                        maxLength={6}
                        onType={handlePincodeChange}
                        placeholder={'Enter Pincode'}
                      />
                      <FormInput
                        width={32}
                        register={register}
                        isRequired
                        register_key={'joint_registered_state'}
                        label={'State'}
                        errors={errors.state}
                        valuePre={state}
                        CustomStyle={{ pointerEvents: 'none' }}
                      />

                      {/* <FormInput
                          width={32}
                          register={register}
                          register_key={'district'}
                          label={'District'}
                          type={'dropdown'}
                          defaultValuePre={district}
                          options={districtDropdownOptions}
                        /> */}
                      <FormInput
                        width={32}
                        register={register}
                        register_key={'joint_registered_city'}
                        label={'City'}
                        type={'dropdown'}
                        defaultValuePre={district}
                        options={cityDropdownOptions}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="w-full flex justify-start gap-4 items-center">
                <FormInput
                  width={24}
                  register={register}
                  register_key={'price_list_share'}
                  label={'Price List'}
                  type={'dropdown'}
                  options={[
                    { label: 'Select', value: '', disabled: true, selected: true },
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  isRequired={true}
                />
                <FormInput
                  width={24}
                  register={register}
                  register_key={'unit_of_measurement'}
                  label={'Unit of Measurement'}
                  type={'dropdown'}
                  options={[
                    { label: 'Select', value: '', disabled: true, selected: true },
                    { label: 'sq/ft', value: 'sq/ft' },
                    { label: 'sq/mt', value: 'sq/mt' }
                  ]}
                  isRequired={true}
                />
                {/* <FormInput
                  width={24}
                  register={register}
                  register_key={'construction_type'}
                  label={'Construction Type'}
                  type={'dropdown'}
                  options={[
                    { label: 'Select', value: '', disabled: true, selected: true },
                    { label: 'Plot', value: "Plot" },
                    { label: 'Building ', value: "Building" }
                  ]}
                  isRequired={true}
                />  */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-12">
        {Array.from({ length: numBuildings }, (_, index) => (
          <div
            className={`flex flex-col gap-8  ${index < numBuildings - 1 && 'border-b-[2px]'} border-b-gray-200 pb-8`}
            key={index}>
            {numBuildings > 1 && index > 0 && (
              <div className="w-full flex justify-end pb-2 mt-[-1.6rem]">
                <span
                  className="text-stone-700 underline underline-offset-2 transition-all duration-300 hover:scale-[102%] cursor-pointer"
                  onClick={handleRemoveBuilding}>
                  - Remove building details
                </span>
              </div>
            )}
            <div className="flex flex-col justify-between items-start gap-4">
              <div className="w-full flex flex-col gap-4">
                <div className="flex justify-between space-x-4">
                  <FormInput
                    width={32}
                    register={register}
                    register_key={`permission_no_${index}`}
                    label={'Building Permission No'}
                    type={'text'}
                    isRequired={true}
                    placeholder={'Enter Building Permission No'}
                  />
                  <FormInput
                    width={32}
                    register={register}
                    register_key={`permission_date_${index}`}
                    type={'BuildingPermissonDate'}
                    isRequired={true}
                    label={'Building Permission Date'}
                    onType={(date) => handleBuildingPermissionDateChange(index, date)} // Pass index and date
                  />

                  <FormInput
                    width={30}
                    register={register}
                    register_key={`permission_end_date_${index}`}
                    label={'Building Permission End Date'}
                    type={'BuildingPermissonDate'}
                    isRequired={true}
                    CustomFeature={{
                      min: permissionDates[index] || ''
                    }}
                  />
                </div>
                <div className="flex justify-between space-x-4">
                  <FormInput
                    width={32}
                    register={register}
                    register_key={`layout_plan_${index}`}
                    label={`${selectedLandState === 'karnataka' ? 'Layout Plan' : 'Building Layout Plan'}`}
                    type={'file-secondary'}
                    accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                  />
                  <FormInput
                    width={32}
                    register={register}
                    register_key={`development_order_${index}`}
                    label={'Development Order'}
                    type={'file-secondary'}
                    accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                  />
                  <FormInput
                    width={30}
                    register={register}
                    register_key={`upload_letterhead_${index}`}
                    label={'Upload Letterhead'}
                    type={'file-btn'}
                    accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between items-start gap-4">
              <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
                RERA Details
              </span>
              <div className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-col gap-4">
                  <div className="flex justify-between space-x-4">
                    <FormInput
                      width={32}
                      register={register}
                      register_key={`rera_registration_no_${index}`}
                      label={'RERA Registration No'}
                      type={'text'}
                      isRequired={true}
                      placeholder={'Enter RERA Registration No'}
                    />
                    <FormInput
                      width={32}
                      register={register}
                      register_key={`rera_approval_date_${index}`}
                      label={'RERA Approval Date'}
                      type={'date'}
                      isRequired={true}
                      CustomFeature={{
                        max: getLocalDate() // Use local date for max
                      }}
                    />
                    <div className="relative w-[32%]">
                      <FormInput
                        width={100}
                        register={register}
                        register_key={`rera_permission_tenure_${index}`}
                        label={'RERA Permission Tenure'}
                        type={'textOnType'}
                        isRequired={true}
                        onType={(e) => handleReraTenureChange(index, e.target.value)}
                        placeholder={'Enter RERA Permission Tenure'}
                      />
                      <span className="p-1 px-2 absolute right-2 top-[52%] bg-gray-100 rounded-md text-sm text-gray-600">
                        Years
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between space-x-4">
                    <FormInput
                      width={32}
                      register={register}
                      register_key={`additional_documents_${index}`}
                      label={'Additional Documents'}
                      type={'file-secondary'}
                      accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                    />
                    <Controller
                      name={`khasra_number_${index}`}
                      control={control}
                      defaultValue={[]} // start with empty array
                      rules={{ required: 'Please select at least one Khasra' }} // example validation
                      render={({ field }) => (
                        <div className="w-[32%]">
                          <label className="block text-sm font-medium text-gray-700">
                            {selectedLandState === 'karnataka'
                              ? 'Survey Numbers'
                              : 'Khasra Numbers'}
                          </label>

                          <Multiselect
                            options={allKhasraNumbers} // => e.g. [{ id: 72, name: '166/K' }, ...]
                            selectedValues={field.value || []} // must be array
                            onSelect={(selectedList) => {
                              // entire array of objects
                              field.onChange(selectedList);
                            }}
                            onRemove={(selectedList) => {
                              // entire array after removal
                              field.onChange(selectedList);
                            }}
                            displayValue="name"
                            isObject={true}
                            placeholder="Select Khasra"
                            style={{
                              optionContainer: {
                                border: '1px solid #ccc',
                                backgroundColor: 'white',
                                maxHeight: '200px',
                                overflowY: 'auto'
                              },
                              option: {
                                color: 'black',
                                backgroundColor: 'white'
                              },
                              chips: {
                                background: '#2A565E',
                                color: 'white'
                              },
                              searchBox: {
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '5px'
                              }
                            }}
                          />
                          {errors[`khasra_number_${index}`] && (
                            <span className="text-red-500 text-sm">
                              {errors[`khasra_number_${index}`].message}
                            </span>
                          )}
                        </div>
                      )}
                    />
                    <FormInput
                      width={32}
                      register={register}
                      register_key={`site_address_${index}`}
                      label={'Site Address'}
                      placeholder={'Enter Site Address'}
                    />
                  </div>
                  <div className="flex gap-6">
                    <div className="relative w-[32%]">
                      <FormInput
                        width={100}
                        register={register}
                        register_key={`estimated_budget_${index}`}
                        label={'Estimated Budget (₹)'}
                        type={'text'}
                        isRequired={true}
                        onType={(text) => estimatedBudget(text, `estimated_budget_${index}`)}
                        placeholder={'Enter Estimated Budget'}
                      />
                      <span className="p-1 px-2 absolute right-2 top-[52%] bg-gray-100 rounded-md text-sm text-gray-600">
                        Lakhs
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-end border-t-[1px] border-stone-200 pb-2 mt-4">
        <span
          className="text-stone-700 underline underline-offset-2 transition-all duration-300 hover:scale-[102%] cursor-pointer mt-4"
          onClick={handleAddBuilding}>
          + Add more building details
        </span>
      </div>
      <div className="w-full flex justify-end items-center space-x-4">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default AddProject;
